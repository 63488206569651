import {
  routes
} from './routes';
import CheckoutController from './checkout/controller';
import ConfirmController from './confirm/controller';

export default angular.module('app.payment', [])
  .config(routes)
  .controller('CheckoutController', CheckoutController)
  .controller('ConfirmController', ConfirmController)
  .name;

