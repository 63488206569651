import moment from "moment";

export default class TransactionListController {
  constructor($state, $interval, TransactionService, EvseService, $scope, EntityService, UIService, $sce, $timeout, AuthenticationService) {
    this.$state = $state;
    this.$interval = $interval;
    this.TransactionService = TransactionService;
    this.EvseService = EvseService;
    this.EntityService = EntityService;
    this.UI = UIService;
    this.id = AuthenticationService.getUser().siteId;
    this.$sce = $sce;
    this.$timeout = $timeout;

    this.TransactionService.loadFilterData()
      .then((r) => {
        this.params = r;
        this.filter = {
          time: {
            from: moment.utc($state.params.from, "DD-MM-YYYY"),
            to: moment.utc($state.params.to, "DD-MM-YYYY"),
          },
          page: $state.params.page || 1,
          sort: "desc",
          order: "begin",
          evses: r.evses,
          users: r.users || [],
        };
        this.loadData();
      })
      .catch((e) => {
        console.log(e);
        this.UI.addToast("Ocorreu um erro ao carregar dados para filtragem");
        this.filterDisabled = true;
      });

    // Destroy intervals
    $scope.$on("$destroy", () => {
      if (this.data)
        this.data.forEach((row) => {
          if (row.updater) {
            $interval.cancel(row.updater);
          }
        });
    });
  }

  clear = () => {
    this.TransactionService.loadFilterData()
      .then((r) => {
        this.params = r;
        this.filter = {
          time: {
            from: moment.utc().subtract(7, 'day'),
            to: moment.utc()
          },
          page: this.$state.params.page || 1,
          sort: "desc",
          order: "begin",
          evses: r.evses,
        };
        this.loadData();
      })
      .catch((e) => {
        this.UI.addToast("Ocorreu um erro ao carregar dados para filtragem");
        this.filterDisabled = true;
      });
  };

  export = () => {
    this.exportingFile = true;
    this.UI.addToast("A iniciar download, por favor aguarde...");
    let from = moment.utc(this.filter.time.from);
    let to = moment.utc(this.filter.time.to);
    let url = `/api/sites/${this.id}/export?from=${from}&to=${to}`;
    var downloadLink = angular.element("<a></a>");
    downloadLink.attr("href", url);
    downloadLink.attr("download", "export.xls");
    downloadLink[0].click();
    this.exportingFile = false;
  };

  report = () => {
    this.exportingFile = true;
    this.UI.addToast("A iniciar download, por favor aguarde...");
    let from = moment.utc(this.filter.time.from);
    let to = moment.utc(this.filter.time.to);
    let url = `/api/sites/${this.id}/report?from=${from}&to=${to}`;
    var downloadLink = angular.element("<a></a>");
    downloadLink.attr("href", url);
    downloadLink.attr("download", "export.pdf");
    downloadLink[0].click();
    this.exportingFile = false;
  };

  createFilter = () => {
    let evseIds = this.filter.evses.map((r) => r.id);
    let userIds = this.filter.users.map((r) => r.id);

    let where = {
      begin: {
        between: [moment.utc(this.filter.time.from).startOf('day'), moment.utc(this.filter.time.to).endOf('day')],
      },
    };
    if (evseIds.length != this.params.evses.length) {
      where.evseId = { inq: evseIds }
    }
    if (userIds.length != this.params.users.length) {
      where.userId = { inq: userIds }
    }
    return {
      where: where,
      limit: 20,
      order: `${this.filter.order} ${this.filter.sort}`,
      skip: (this.filter.page - 1) * 15,
    };
  };

  next = () => {
    if (this.end < this.total) {
      this.filter.page++;
    }
    this.loadData();
  };

  previous = () => {
    if (this.filter.page > 1) {
      this.filter.page--;
    }
    this.loadData();
  };

  loadData = () => {
    this.loaded = false;
    this.$state.go("app.transaction.list", this.filter, {
      // prevent the events onStart and onSuccess from firing
      notify: true,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.TransactionService.list(this.createFilter())
      .then((r) => {
        this.data = r.data;
        this.total = r.total;
        this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
        this.end =
          r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
        this.loaded = true;
        this.data.forEach((row) => {
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  calcBatteryProgression = (row) => {
    if (row.initialStateOfCharge != row.stateOfCharge) {
      return `${row.initialStateOfCharge}% > ${row.stateOfCharge}% (+${row.stateOfCharge - row.initialStateOfCharge
        }%)`;
    }
    return "-";
  };

  calcActivity = (row) => {
    if (row.chargingTime) {
      let started = moment(row.begin);
      let ended = moment(row.chargingTime);
      let duration = ended.diff(started);
      let h = ended.diff(started, "hours", true);
      if (h > 1) {
        return moment.utc(duration).format("HH[h] mm[m] ss[s]");
      } else {
        return moment.utc(duration).format("mm[m] ss[s]");
      }
      return "-";
    } else {
      let started = moment(row.begin);
      let ended = moment(started).add(row.durationSeconds, "seconds");
      let duration = ended.diff(started);
      let h = ended.diff(started, "hours", true);
      if (h > 1) {
        return moment.utc(duration).format("HH[h] mm[m] ss[s]");
      } else {
        return moment.utc(duration).format("mm[m] ss[s]");
      }
      return "-";
    }
  };

  calcTotalDuration = (row) => {
    let started = moment(row.begin);
    let ended;
    if (row.end) {
      ended = moment(row.end);
    } else {
      ended = moment(row.lastUpdated);
    }
    let duration = ended.diff(started);
    let h = ended.diff(started, "hours", true);
    if (h > 1) {
      return moment.utc(duration).format("HH[h] mm[m] ss[s]");
    } else {
      return moment.utc(duration).format("mm[m] ss[s]");
    }
    return "-";
  };

  calcCost = (row) => {
    if (row.userCost) {
      return row.userCost;
    }
    return 0;
  };

  cardDescription = row => {
    if (row.user) {
      let card = row.user.cards.find(r => r.no == row.idTag);
      if (card != undefined && card.description) {
        return `${row.idTag} (${card.description})`;
      }
    }
    return row.idTag;
  }

  calcTotalConsumption = (row) => {
    if (row.energyDelivered) {
      return (row.energyDelivered);
    }
    if (row.meterStop >= 0 && row.meterStart >= 0) {
      return (row.meterStop - row.meterStart) / 1000;
    }
    return 0;
  };

  explain = (row, reason, property) => {
    if (row.hasOwnProperty("reason")) {
      let data = this.EvseService.getReadableReason(reason);
      return data[property];
    }
    return "-";
  };

  expand = (row) => {
    if (!row.hasOwnProperty("unit")) {
      row.unit = "kw";
    }
    row.expanded = !row.expanded;
    if (row.consumption.length > 0) {
      this.createChartData(row);
      row.canShowChart = true;
    } else {
      row.canShowChart = false;
    }
  };



  dialogFilter = (list, selected, property) => {
    return this.UI.showDialog({
      template: require("./select.dialog.html"),
      controller: [
        "$scope",
        "$filter",
        ($scope, $filter) => {
          // Arrays of data
          $scope.property = property;
          $scope.list = list || [];
          // Search filter
          $scope.filter = "";
          $scope.all = false;
          $scope.$watch(() => {
            return $scope.list;
          }, (val) => {
            console.log(val);
          });
          // Pagination
          $scope.pagination = {
            perPage: 10,
            current: 0,
            previous: () => {
              if ($scope.pagination.current > 0) $scope.pagination.current--;
            },
            onFilter: () => {
              const filteredArray = $filter("filter")(
                $scope.list,
                $scope.filter
              );
              const afterFilter =
                Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
              if (afterFilter < $scope.pagination.current) {
                $scope.pagination.current = afterFilter;
              }
            },
            next: () => {
              if ($scope.pagination.current < $scope.pagination.total() - 1)
                $scope.pagination.current++;
            },
            total: () => {
              const filteredArray = $filter("filter")(
                $scope.list,
                $scope.filter
              );
              return Math.ceil(
                filteredArray.length / $scope.pagination.perPage
              );
            },
          };

          $scope.select = function (obj) {
            obj.selected = !obj.selected;
          };

          $scope.cancel = function () {
            $scope.$dismiss("cancel");
          };

          $scope.canOk = () => {
            let selected = $scope.list.find((r) => r.selected);
            return selected != null;
          };

          $scope.ok = () => {
            let selected = $scope.list.filter((r) => r.selected);
            $scope.$close(selected);
          };
        },
      ],
    });
  };

  readableEvseFilter = () => {
    if (!this.params) {
      return "";
    }
    if (this.filter.evses.length === this.params.evses.length) {
      return "Todas os carregadores selecionados";
    }
    if (this.filter.evses.length === 1) {
      return this.filter.evses[0].alias;
    } else {
      return `${this.filter.evses.length} carregadores selecionados`;
    }
  };

  filterEvses = () => {
    let list = angular.copy(this.params.evses);
    console.log(this.params);
    console.log(this.filter);
    let selected = angular.copy(this.filter.evses);
    this.dialogFilter(list, selected, "alias").then((r) => {
      this.filter.evses = r;
    });
  };

  readableUserFilter = () => {
    if (!this.params) {
      return "";
    }
    if (this.filter.users.length === this.params.users.length) {
      return "Todas os utilizadores selecionados";
    }
    if (this.filter.users.length === 1) {
      return this.filter.users[0].name;
    } else {
      return `${this.filter.users.length} utilizadores selecionados`;
    }
  };

  filterUsers = () => {
    let list = angular.copy(this.params.users);
    let selected = angular.copy(this.filter.users);
    this.dialogFilter(list, selected, "name").then((r) => {
      this.filter.users = r;
    });
  };
}

TransactionListController.$inject = [
  "$state",
  "$interval",
  "TransactionService",
  "EvseService",
  "$scope",
  "EntityService",
  "UIService",
  "$sce",
  "$timeout",
  "AuthenticationService",
];
