export default class ProviderDetailsController {
  constructor($state, UIService, ProviderService) {
    this.$state = $state;
    this.UI = UIService;
    this.Providers = ProviderService;
    this.id = $state.params.id;

    this.loaded = false;

    if (this.id) {
      this.label = "Editar"
      this.loadData();
    } else {
      this.data = {};
      this.label = "Adicionar"
      this.loaded = true;
    }
  }

  loadData = () => {
    this.Providers.get(this.id).then(r => {
      this.loaded = true;
      this.data = r;
    }).catch(e => {
      this.loaded = true;
      this.UI.addToast("Ocorreu um erro ao carregar grupos de carregadores");
    });
  }

  save = () => {
    if (this.validate()) {
      this.Providers.save(this.data).then(r => {
        if (this.id)
          this.UI.addToast("Fornecedor alterado com sucesso");
        else
          this.UI.addToast("Fornecedor adicionado com sucesso");
        this.$state.go('app.provider.list');
      }).catch(e => {
        this.UI.addToast("Não foi possível gravar fornecedor");
      });
    }
  }

  validate = () => {
    if (!this.data.name) {
      this.UI.addToast("Insira o nome do fornecedor");
      return false;
    }
    if (!this.data.country) {
      this.UI.addToast("Insira o código do país");
      return false;
    }
    return true;
  }
}

ProviderDetailsController.$inject = ['$state', 'UIService', 'ProviderService'];
