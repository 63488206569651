export default class SiteDetailsController {
  constructor($state, $window, UIService, SiteService, AuthorizationService, AuthenticationService, EvseService, ProfileService) {
    this.$state = $state;
    this.$window = $window;
    this.UI = UIService;
    this.Site = SiteService;
    this.Authorization = AuthorizationService;
    this.Auth = AuthenticationService;
    this.Evse = EvseService;
    this.Client = ProfileService;
    this.user = this.Auth.getUser();

    this.pages = {
      e: 1,
      u: 1
    }

    this.date = moment.utc();

    this.id = $state.params.id;
    this.entityId = this.user.site.entityId;

    if (this.id) {
      this.label = "Editar";
      this.loadData(false);
    } else {
      this.label = "Adicionar";
      this.data = {
        entityId: this.user.site.entityId,
        active: true
      };
      this.loadData(true);
    }
  }

  getConsumptionChart = () => {
    this.consumptionLoaded = false;

    this.Site.consumption(this.id, this.date).then(origin => {
      this.consumption = origin;
      this.consumptionLoaded = true;
    })
  }

  loadData = (skipSite) => {
    if (skipSite) {
      this.loaded = false;
      this.Site.getTariffs(this.user.site.entityId).then(t => {
        this.tariffs = t;
        this.loaded = true;
      }).catch(e => {
        this.tariffs = [];
        this.UI.addToast("Ocorreu um erro ao carregar tarifários");
        this.loaded = true;
      });
    } else {
      this.loaded = false;
      this.Site.get(this.id).then(r => {
        angular.copy(r);
        this.Site.getTariffs(r.entityId).then(t => {
          this.tariffs = t;
          this.tariff = t.find(s => {
            console.log(angular.copy(s.id));
            console.log(angular.copy(r.tariffId));
            return s.id === r.tariffId
          });
          console.log(this.tariff);
          this.data = r;
          this.loaded = true;
        }).catch(e => {
          this.tariffs = [];
          this.UI.addToast("Ocorreu um erro ao carregar tarifários");
          this.data = r;
          this.loaded = true;
        });
      }).catch(() => {
        this.UI.addToast("Ocorreu um erro ao carregar instalação");
      });
    }

    this.getConsumptionChart();
  }

  checkForAdministrator = (row) => {
    //check user permissions
    //if is administrator show disabled groups
    let administrator = this.Authorization.canPerform(['removeSite']);
    if (administrator || row.active)
      return true;
    return false;
  }


  showCards = () => {
    return this.id != undefined;
  }

  save = () => {
    if (this.validate()) {
      if (!this.id) {
        this.data.entityId = this.entityId;
      }
      this.data.tariffId = this.tariff.id;
      this.Site.save(this.data).then(r => {
        if (this.id)
          this.UI.addToast("Instalação alterada com sucesso");
        else
          this.UI.addToast("Instalação adicionada com sucesso");
        this.$state.go('app.site.list');
      }).catch(e => {
        this.UI.addToast("Não foi possível gravar instalação");
      });
    }
  }

  validate = () => {
    if (!this.data.name) {
      this.UI.addToast("Insira o nome da instalação");
      return false;
    }
    if (!this.data.address) {
      this.UI.addToast("Insira a morada a instalação");
      return false;
    }
    if (!this.tariff) {
      this.UI.addToast("Indique o tarifário da instalação");
      return false;
    }
    return true;
  }

  editable = () => {
    return this.Authorization.canPerform(['editSite']);
  }

  removeEvse = evse => {
    this.UI.showConfirm(`Deseja remover o carregador ${evse.alias ? evse.alias : evse.chargeBoxSerialNumber}?`).then(a => {
      if (a) {
        this.Evse.delete(evse.id).then(r => {
          this.UI.addToast('Carregador removido');
          this.loadData();
        }).catch(e => {
          this.UI.addToast('Ocorreu um erro ao remover carregador');
          this.loadData();
        });
      }
    })
  }

  showUserDialog = () => {
    let list = [];
    let users = [];
    this.data.access.forEach(r => {
      users.push(r.userId);
    })
    let options = {
      size: 'lg',
      template: require('./user.dialog.html'),
      controller: ['$scope', '$filter', 'Tenant', ($scope, $filter, Tenant) => {
        $scope.loaded = false;

        Tenant.find({
          filter: {
            where: {
              entityId: this.data.entityId,
              userId: {
                nin: users
              }
            },
            include: 'user'
          }
        }).$promise.then(r => {
          list = [];
          r.forEach(_ => {
            if (_.user) {
              list.push(_.user);
            }
          });
          $scope.available = list;
          $scope.loaded = true;
        });


        // Arrays of data
        $scope.selected = [];
        // Search filter
        $scope.filter = '';
        // Pagination
        $scope.pagination = {
          perPage: 5,
          current: 0,
          previous: () => {
            if ($scope.pagination.current > 0)
              $scope.pagination.current--;
          },
          onFilter: () => {
            const filteredArray = $filter('filter')($scope.available, $scope.filter);
            const afterFilter = Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
            if (afterFilter < $scope.pagination.current) {
              $scope.pagination.current = afterFilter;
            }
          },
          next: () => {
            if ($scope.pagination.current < $scope.pagination.total() - 1)
              $scope.pagination.current++;
          },
          total: () => {
            const filteredArray = $filter('filter')($scope.available, $scope.filter);
            return Math.ceil(filteredArray.length / $scope.pagination.perPage);
          }
        };

        $scope.select = function (user) {
          user.oldIdx = $scope.available.indexOf(user);
          $scope.selected.push(user);
          $scope.available.splice(user.oldIdx, 1);
        };

        $scope.pop = function (user) {
          $scope.selected.splice($scope.selected.indexOf(user), 1);
          $scope.available.splice(user.oldIdx, 0, user);
        };

        $scope.ok = function () {
          $scope.$close($scope.selected);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };


      }]
    };

    this.UI.showDialog(options).then((results) => {
      console.log(results);
      this.Site.giveAccess(results, this.id).then(r => {
        this.UI.addToast(`Adicionados ${results.length} utilizadores à instalação`);
        this.loadData();
      });
    });
  };

  removeUser = (row) => {
    this.UI.showConfirm(`Deseja remover ${row.user.name} da lista de utilizadores autorizados?`).then(r => {
      this.Site.removeUser(row.userId, row.siteId).then(r => {
        this.UI.addToast("Lista de utilizadores atualizada");
        this.loadData();
      }).catch(e => {
        this.UI.addToast("Não foi possível atualizar lista");
        this.loadData();
      });
    });
  }

  setExpiration = (row) => {
    let o = angular.copy(row);
    this.UI.showDialog({
      template: require('./expiration.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.cancel = () => {
          $scope.$dismiss();
        }

        $scope.canSave = () => {
          if (!$scope.data.expires)
            return false;
          if ($scope.data.begin == undefined || $scope.data.end == undefined) return true;
          return moment.utc($scope.data.begin).isBefore($scope.data.end);
        }

        $scope.o = {
          format: 'DD/MM/YYYY',
          minDate: moment.utc()
        };

        $scope.$watch(() => {
          return $scope.data;
        }, (data) => {
          if (data.expires) {
            if (moment.utc(data.begin).isValid()) {
              $scope.o = {
                format: 'DD/MM/YYYY',
                minDate: data.begin
              }
            }
            if (moment.utc(data.begin).isAfter(data.end)) {
              $scope.data.end = undefined;
            }
          }
        });

        $scope.data = {
          expires: o.expires || false,
        }

        $scope.ok = () => {
          if ($scope.data.expires) {
            $scope.data.begin = moment.utc($scope.data.begin).startOf('d');
            $scope.data.end = moment.utc($scope.data.begin).startOf('d');
          }
          $scope.$close($scope.data);
        }
      }]
    }).then(r => {
      o.expires = r.expires;
      if (r.expires) {
        o.begin = r.begin;
        o.end = r.end;
      } else {
        delete o.begin;
        delete o.end;
      }
      o.modified = moment.utc();
      this.Site.updateUser(o).then(r => {
        this.UI.addToast("Autorização alterada com sucesso");
        row.expires = r.expires;
        row.modified = o.modified;
        if (r.expires) {
          row.begin = r.begin;
          row.end = r.end;
        } else {
          delete row.begin;
          delete row.end;
        }
      });
    });
  }

  export = (row) => {
    this.exportConsumption(row, { format: 'export', extension: 'xls' });
  }

  report = (row) => {
    this.exportConsumption(row, { format: 'report', extension: 'pdf' });
  }

  exportConsumption = (row, format) => {
    let from = moment.utc(row.data).startOf('month');
    let to = moment.utc(row.data).endOf('month');
    let url = `/api/sites/${this.id}/${format.format}?from=${from}&to=${to}`;
    var downloadLink = angular.element("<a></a>");
    downloadLink.attr("href", url);
    downloadLink.attr("download", `export.${format.extension}`);
    downloadLink[0].click();
    this.UI.addToast("Download iniciado, aguarde por favor");
  }
}

SiteDetailsController.$inject = ['$state', '$window', 'UIService', 'SiteService', 'AuthorizationService', 'AuthenticationService', 'EvseService', 'ProfileService'];
