import { Observable } from "rxjs";

export default class EvseService {
  constructor($q, $rootScope, AuthenticationService, AuthorizationService, Site, Evse, Consumption, Transaction, $http) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$http = $http;
    this.Site = Site;
    this.Evse = Evse;
    this.Auth = AuthenticationService;
    this.Authorization = AuthorizationService;
    this.Consumption = Consumption;
    this.Transaction = Transaction;
  }

  reset = (id) => {
    let defer = this.$q.defer();
    this.Evse.reset({
      id: id,
    }).$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  forceStart = (idTag, id, connectorId) => {
    let defer = this.$q.defer();
    this.Evse.forceStart({
      idTag: idTag,
      evseId: id,
      connectorId: connectorId,
    }).$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  unlock = (id, connectorId) => {
    let defer = this.$q.defer();
    this.Evse.unlockConnector({
      id: id,
      connectorId: connectorId,
    }).$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  sendLocalList = (evseId) => {
    let defer = this.$q.defer();
    this.Evse.sendLocalList({ evseId: evseId }).$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  save = (data) => {
    // TODO: Handle user
    let user = this.Auth.getUser();
    let defer = this.$q.defer();
    delete data.messages;
    delete data.selected;
    if (!data.alias) {
      data.alias = data.chargeBoxSerialNumber;
    }
    data.lastModified = new Date();
    this.Evse.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((err) => defer.reject(err));
    return defer.promise;
  };

  list = (filter) => {
    let defer = this.$q.defer();
    if (!this.Authorization.belongsTo("administrator")) {
      let user = this.Auth.getUser();
      this.Site.find({
        filter: {
          fields: {
            id: true,
          },
          where: {
            id: user.siteId

          },
        },
      }).$promise.then((res) => {
        let sites = res.map((r) => r.id);
        filter.where.siteId = { inq: sites };

        let observer = this.createObservable(filter,
          sites,
          false
        );
        defer.resolve(observer);
      });
    } else {
      let observer = this.createObservable({}, null, true);
      defer.resolve(observer);
    }
    return defer.promise;
  };

  mapCharger = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    let filter = {
      where: {
        active: true,
        setup: true,
        siteId: user.siteId
      }
    }

    this.Evse.find({filter: filter}).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));

    return defer.promise;
  }

  listBySite = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    let where = {};
    if (!this.Authorization.belongsTo("administrator")) {
      where = {
        siteId: user.siteId
      };
    }
    this.Site.find({
      filter: {
        where: where,
        include: ["evses"],
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  createObservable = (filter, list, isAdmin) => {
    let a = moment()
      .startOf('month');
    let b = moment(a).endOf('month');
    let observable = Observable.create((observer) => {
      this.Evse.count({
        where: filter.where,
      })
        .$promise.then((c) => {
          filter.include = [
            { relation: "info" },
            {
              relation: "site",
              scope: {
                include: "entity",
              },
            },
            { relation: "tariff" },
            { relation: "evseGroup" },
            { relation: "evseProfile" },
            {
              relation: "metrics",
              scope: {
                where: {
                  timestamp: {
                    between: [a, b]
                  }
                }
              }
            },
            {
              relation: "qol",
              scope: {
                where: {
                  timestamp: {
                    between: [a, b]
                  }
                }
              }
            }
          ];
          this.Evse.find({
            filter: filter,
          })
            .$promise.then((r) => {
              let a = {
                total: c.count,
                data: r,
              };
              let ev = this.$rootScope.$eventWatchers.find((r) => {
                return r.url.includes(
                  "/api/evses/change-stream?_format=event-stream"
                );
              });
              if (!ev) {
                ev = new EventSource(
                  "/api/evses/change-stream?_format=event-stream"
                );
                this.$rootScope.$eventWatchers.push(ev);
              }
              ev.addEventListener("data", (evt) => {
                let msg = JSON.parse(evt.data);
                switch (msg.type) {
                  case "create": {
                    if (isAdmin) {
                      a.total++;
                      if (a.data < 50) {
                        a.data.push(msg.data);
                      }
                    } else {
                      if (
                        msg.data &&
                        msg.data.siteId &&
                        sites.includes(msg.data.siteId)
                      ) {
                        a.total++;
                        if (a.data < 50) {
                          a.data.push(msg.data);
                        }
                      }
                    }
                    break;
                  }
                  case "update": {
                    let target = msg.target;
                    let exists = a.data.find((r) => r.id == target);
                    if (exists) {
                      let i = a.data.indexOf(exists);
                      Object.assign(a.data[i], msg.data)
                      //a.data[i] = msg.data;
                    }
                    break;
                  }
                  case "remove": {
                    let target = msg.target;
                    let exists = a.data.find((r) => r.id == target);
                    if (exists) {
                      let i = a.data.indexOf(exists);
                      a.data.splice(i, 1);
                    }
                    break;
                  }
                }
                observer.next(a);
              }, false);
              observer.next(a);
            })
            .catch((e) => {
              observer.complete(e);
            });
        })
        .catch((e) => {
          observer.complete(e);
        });
    });
    return observable;
  };

  getWhere = where => {
    let defer = this.$q.defer();
    this.Evse.findOne({
      filter: {
        where: where
      }
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  get = (id) => {
    let defer = this.$q.defer();
    this.Evse.findById({
      id: id,
      filter: {
        include: ["info", {
          relation: "transactions",
          scope: {
            include: ["profile", "consumptionData"],
          },
        }],
      },
    })
      .$promise.then((r) => {
        r.history = [];
        r.active = [];
        // Send transactions to the correct arrays
        r.transactions.forEach((t) => {
          if (t.hasOwnProperty("end")) {
            r.history.push(t);
          } else {
            r.active.push(t);
          }
        });
        defer.resolve(r);
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  getTransactionDetails = (transactionId) => {
    let defer = this.$q.defer();

    this.Consumption.digestData({
      transactionId: transactionId,
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));

    return defer.promise;
  };

  getChargerPhoto = (file) => {
    let defer = this.$q.defer();
    this.$http.get('api/assets/containers/photos/files/' + file + '/download', { responseType: 'blob' })
      .then((r) => defer.resolve(r))
      .catch((err) => defer.reject(err));
    return defer.promise;
  }

  getReadableConnectorStatus = (status) => {
    switch (status) {
      case "Available":
        return "Disponível";
      case "Ocuppied":
        return "Ocupado";
      case "Preparing":
        return "Preparar para carga";
      case "Charging":
        return "A carregar";
      case "Finishing":
        return "Aguarda retirada do veículo";
      case "Faulted":
        return "Erro";
      case "SuspendedEV":
        return "Carga parada pelo veículo";
      case "SuspendedEVSE":
        return "Carga parada pelo carregador";
      case "Reserved":
        return "Reservado";
      case "Unavailable":
        return "Indisponível";
      case "Faulted":
        return "Erro no carregador";
      default:
        return "";
    }
  };

  getReadableReason = (reason) => {
    switch (reason) {
      case "EmergencyStop": {
        return {
          title: "Paragem de emergência",
          description: "Botão de paragem de emergência premido",
        };
      }

      case "EVDisconnected": {
        return {
          title: "Cabo removido",
          description: "Cabo removido do carregador/carro",
        };
      }

      case "HardReset": {
        return {
          title: "Reínicio forçado via SGI",
          description:
            "A plataforma recebeu um pedido de reinício forçado para este carregador",
        };
      }

      case "Local": {
        return {
          title: "Conclusão pelo Utilizador",
          description:
            "Parado localmente a pedido do utilizador no carregamento\r\n(O utilizador premiu o botão de paragem/apresentou tag para paragem)",
        };
      }

      default: {
        return {
          title: "Sem informação",
          description: "Nenhuma informação disponível",
        };
      }

      case "PowerLoss": {
        return {
          title: "Perda de energia",
          description: "O carregador ficou sem eletricidade",
        };
      }

      case "Reboot": {
        return {
          title: "Reinício",
          description: "Reinício local do carregador",
        };
      }

      case "Remote": {
        return {
          title: "Remotamente",
          description: "Parado remotamente a pedido do cliente",
        };
      }

      case "SoftReset": {
        return {
          title: "Reínicio via SGI",
          description:
            "A plataforma recebeu um pedido de reinício para este carregador",
        };
      }

      case "UnlockCommand": {
        return {
          title: "Desbloqueio via SGI",
          description: "A plataforma enviou o comando para desbloquear conetor",
        };
      }

      case "DeAuthorized": {
        return {
          title: "Sem autorização",
          description:
            "O identificador utilizador perdeu acesso a este carregador",
        };
      }
    }
  };

  getPeakOffPeak = (id) => {
    let defer = this.$q.defer();
    this.Evse.peakHours({
      id: id,
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  delete = id => {
    let defer = this.$q.defer();
    this.Evse.prototype$updateAttributes({ id: id }, { siteId: null }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  update = id => {
    let defer = this.$q.defer();
    this.Evse.updateFirmware({ id: id }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  migrate = (evseId, siteId) => {
    let defer = this.$q.defer();
    this.Evse.prototype$updateAttributes({ id: evseId }, { siteId: siteId }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  adopt = (evseId, profileId) => {
    let user = this.Auth.getUser();
    let defer = this.$q.defer();
    this.Evse.prototype$updateAttributes({ id: evseId }, { siteId: user.siteId, profileId: profileId }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  pending = () => {
    return this.Evse.pending().$promise;
  }


}

EvseService.$inject = [
  "$q",
  "$rootScope",
  "AuthenticationService",
  "AuthorizationService",
  "Site",
  "Evse",
  "Consumption",
  "Transaction",
  "$http"
];
