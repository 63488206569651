export default class EventListController {
  constructor($state, UIService, EventService) {
    this.loaded = false;
    this.$state = $state;
    this.UI = UIService;
    this.Event = EventService;

    this.Event.loadFilterData()
      .then((r) => {
        this.params = r;

        this.filter = {
          time: {
            from: moment.utc($state.params.from, "DD-MM-YYYY"),
            to: moment.utc($state.params.to, "DD-MM-YYYY"),
          },
          page: $state.params.page || 1,
          sort: "desc",
          order: "date",
          modules: this.params.modules,
        };
        this.loadData();
      })
      .catch((e) => {
        this.loadData();
      });
  }

  sort = (prop) => {};

  clear = () => {
    this.filter = {
      time: {
        from: moment.utc().subtract(7, "day"),
        to: moment.utc(),
      },
      page: 1,
      sort: "desc",
      order: "date",
      module: this.params.modules,
    };
  };

  createFilter = () => {
    let modules = this.filter.modules.map((r) => r.name);
    let where = {
      date: {
        between: [
          moment.utc(this.filter.time.from).startOf("day"),
          moment.utc(this.filter.time.to).endOf("day"),
        ],
      },
    };
    if (modules.length != this.params.modules.length) {
      where.module = { inq: modules };
    }

    return {
      where: where,
      limit: 20,
      order: `${this.filter.order} ${this.filter.sort}`,
      skip: (this.filter.page - 1) * 15,
    };
  };

  export = () => {
    this.exportingFile = true;
    this.UI.addToast("A iniciar download, por favor aguarde...");
    let url = `/api/loggers/export?filter=${JSON.stringify(
      this.createFilter()
    )}`;
    var downloadLink = angular.element("<a></a>");
    downloadLink.attr("href", url);
    downloadLink.attr("download", "export.xls");
    downloadLink[0].click();
    this.exportingFile = false;
  };

  loadData = () => {
    this.loaded = false;
    this.$state.go(
      "app.events.list",
      {
        page: this.filter.page,
        order: this.filter.order,
        sort: this.filter.sort,
        from: moment(this.filter.time.from).format("DD-MM-YYYY"),
        to: moment(this.filter.time.to).format("DD-MM-YYYY"),
      },
      {
        // prevent the events onStart and onSuccess from firing
        notify: true,
        // prevent reload of the current state
        reload: false,
        // replace the last record when changing the params so you don't hit the back button and get old params
        location: "replace",
        // inherit the current params on the url
        inherit: true,
      }
    );
    this.Event.list(this.createFilter())
      .then((r) => {
        this.data = r.data;
        this.total = r.total;
        this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
        this.end =
          r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
        this.loaded = true;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  readableModuleFilter = () => {
    if (!this.params) {
      return "";
    }
    if (this.filter.modules.length === this.params.modules.length) {
      return "Todos os módulos selecionados";
    }
    if (this.filter.modules.length === 1) {
      return this.filter.modules[0].name;
    } else {
      return `${this.filter.modules.length} módulos selecionados`;
    }
  };

  filterModule = () => {
    let list = angular.copy(this.params.modules);
    let selected = angular.copy(this.filter.modules);
    this.dialogFilter(list, selected, "name").then((r) => {
      this.filter.modules = r;
    });
  };

  dialogFilter = (list, selected, property) => {
    return this.UI.showDialog({
      template: require("./select.dialog.html"),
      controller: [
        "$scope",
        "$filter",
        ($scope, $filter) => {
          // Arrays of data
          $scope.property = property;
          $scope.list = list || [];
          // Search filter
          $scope.filter = "";
          // Pagination
          $scope.pagination = {
            perPage: 10,
            current: 0,
            previous: () => {
              if ($scope.pagination.current > 0) $scope.pagination.current--;
            },
            onFilter: () => {
              const filteredArray = $filter("filter")(
                $scope.list,
                $scope.filter
              );
              const afterFilter =
                Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
              if (afterFilter < $scope.pagination.current) {
                $scope.pagination.current = afterFilter;
              }
            },
            next: () => {
              if ($scope.pagination.current < $scope.pagination.total() - 1)
                $scope.pagination.current++;
            },
            total: () => {
              const filteredArray = $filter("filter")(
                $scope.list,
                $scope.filter
              );
              return Math.ceil(
                filteredArray.length / $scope.pagination.perPage
              );
            },
          };

          $scope.select = function (obj) {
            obj.selected = !obj.selected;
          };

          $scope.cancel = function () {
            $scope.$dismiss("cancel");
          };

          $scope.canOk = () => {
            let selected = $scope.list.find((r) => r.selected);
            return selected != null;
          };

          $scope.ok = () => {
            let selected = $scope.list.filter((r) => r.selected);
            $scope.$close(selected);
          };
        },
      ],
    });
  };

  next = () => {
    if (this.end < this.total) {
      this.filter.page++;
    }
    this.loadData();
  };

  previous = () => {
    if (this.filter.page > 1) {
      this.filter.page--;
    }
    this.loadData();
  };
}

EventListController.$inject = ["$state", "UIService", "EventService"];
