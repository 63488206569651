export default class TariffcycleListController {
    constructor(TariffcycleService, $state) {
        this.$state = $state;
        this.Tariffcycle = TariffcycleService;
        this.filter = {
            page: $state.params.page || 1,
            term: $state.params.term || "",
            sort: "asc",
            order: "name"
        };
        this.loadData();
    }

    loadData = () => {
        this.$state.go("app.tariffcycle.list", this.filter, {
            // prevent the events onStart and onSuccess from firing
            notify: false,
            // prevent reload of the current state
            reload: false,
            // replace the last record when changing the params so you don't hit the back button and get old params
            location: "replace",
            // inherit the current params on the url
            inherit: true,
        });
        this.Tariffcycle.list(this.createFilter()).then(r => {
            this.data = r.data;
            this.total = r.total;
            this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
            this.end = r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
            this.loaded = true;
        });
    }

    createFilter = () => {
        let ob = {};
        let prop = "";
        let pattern = "";
        let where = {};

        if (!this.filter.term.isEmpty()) {
            // Filter by name
            prop = `name`;
            pattern = {
                like: `.*${this.filter.term}.*`,
                options: "i",
            };
            ob = {};
            ob[prop] = pattern;
            where = ob;
        }
        return {
            where: where,
            limit: 20,
            order: `${this.filter.order} ${this.filter.sort}`,
            skip: (this.filter.page - 1) * 20,
        };
    };

    clear = () => {
        this.filter.page = this.$state.params.page || 1;
        this.filter.term = "";
        this.filter.sort = "asc";
        this.filter.order = "name";
        this.loadData();
    };

    next = () => {
        if (this.end < this.total) {
            this.filter.page++;
        }
        this.loadData();
    };

    previous = () => {
        if (this.filter.page > 1) {
            this.filter.page--;
        }
        this.loadData();
    };
}

TariffcycleListController.$inject = ['TariffcycleService', '$state'];
