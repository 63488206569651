export default class ProfileUploaderController {
  constructor($dialog, FileUploader, UIService, type, sites) {
    this.dialog = $dialog;
    this.UI = UIService;
    this.type = type;
    this.sites = sites;
    this.progress = 0;
    this.uploading = false;
    this.emptyFile = true;

    this.item = null;
    this.auxSite = { selected: null };


    switch(this.type) {
      case 'profiles':
        this.title = 'Importar Perfis de Utilizadores';
        break;
    }

    // Setup file uploader
    this.uploader = new FileUploader({
      url: '/api/Upload/HGP/upload',
      queueLimit: 1
    });

    // Force queue limit = 1
    this.uploader.onAfterAddingFile = (item) => {
      this.emptyFile = false;
      this.item = item;
      if (this.uploader.queue.length > 1) {
        this.uploader.queue.splice(0, this.uploader.queue.length - 1);
      }
    };

    this.uploader.onWhenAddingFileFailed = () => {
      this.UI.addToast('Erro ao adicionar ficheiro');
      // this.cancel();
    };
  }

  disabledOk = () => {
    return this.uploader.queue.length === 0;
  };

  ok = () => {
    this.dialog.close({item: this.item, site: this.auxSite.selected});
  };

  clearFile = () => {
    this.emptyFile = true;
    this.item = null;
    if (this.uploader.queue && this.uploader.queue.length > 0)
      this.uploader.clearQueue();
  };

  cancel = () => {
    this.dialog.dismiss('cancel');
  };
}

ProfileUploaderController.$inject = ['$dialog', 'FileUploader', 'UIService', 'type', 'sites'];
