export default class DashboardManagerController {
  constructor(Metrics, $interval, AuthenticationService) {
    this.Metrics = Metrics;
    let user = AuthenticationService.getUser();
    this.id = user.siteId;
    this.period = 30;
    this.loaded = {
      energy: false,
      revenue: false,
      statusChart: false,
      issues: false,
      active: false,
      sessions: false
    }
    this.metrics = {};
    this.loadData();
    $interval(() => {
      this.loadData();
    }, 2 * 1000 * 60);
  }

  loadData = () => {
    this.siteIssues();
    this.siteCharging();
    this.siteSessions();
    this.siteEnergy();
    this.siteRevenue();
    this.getStatusChart();
  }

  siteIssues = () => {
    this.loaded.issues = false;
    this.Metrics.siteIssues({id: this.id}).$promise.then(r => {
      console.log(r);
      this.metrics.issues = r;
      this.loaded.issues = true;
    });
  }

  siteCharging = () => {
    this.loaded.active = false;
    this.Metrics.siteCharging({id: this.id}).$promise.then(r => {
      this.metrics.active = r;
      this.loaded.active = true;
    });
  }

  siteSessions = () => {
    this.loaded.sessions = false;
    this.Metrics.siteSessions({id: this.id}).$promise.then(r => {
      this.metrics.sessions = r;
      this.loaded.sessions = true;
    });
  }

  siteEnergy = () => {
    this.loaded.energy = false;
    this.Metrics.siteEnergy({id: this.id}).$promise.then(r => {
      this.metrics.energy = r;
      this.loaded.energy = true;
    });
  }

  siteRevenue = () => {
    this.loaded.revenue = false;
    this.Metrics.siteRevenue({id: this.id}).$promise.then(r => {
      this.metrics.revenue = r;
      this.loaded.revenue = true;
    });
  }

  getStatusChart = () => {
    this.loaded.typeChart = false;
    this.Metrics.siteStatusChart({id: this.id}).$promise.then(r => {
      this.createStatusChart(r);
    });
  }

  createStatusChart = (data) => {
    let labels = ["Disponível", "Ocupado", "A carregar", "Erro", "Offline"];
    let values = [
      (data.available / data.total * 100).toFixed(1),
      (data.occupied / data.total * 100).toFixed(1),
      (data.charging / data.total * 100).toFixed(1),
      (data.error / data.total * 100).toFixed(1),
      (data.offline / data.total * 100).toFixed(1),
    ];
    this.statusChart = {
      labels: labels,
      data: values,
      options: {
        tooltips: {
          callbacks: {
            label: (data) => {
              return `${labels[data.index]}: ${values[data.index]}%`
            }
          }
        },
        responsive: false,
        legend: {
          display: true,
          position: 'left'
        }
      }
    }
    this.loaded.typeChart = true;
  }
}

DashboardManagerController.$inject = ['Metric', '$interval', 'AuthenticationService'];
