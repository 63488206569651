export default class CheckoutController {
  constructor($state, $window, UIService, User, Payment, LoopBackAuth, $http) {
    this.$state = $state;
    this.$window = $window;
    this.UI = UIService;
    this.User = User;
    this.Payment = Payment;
    this.loaded = false;
    this.id = $state.params.c;
    this.token = $state.params.t;
    this.LoopBackAuth = LoopBackAuth;
    this.$http = $http;
    this.loaded = false;

    $window.addEventListener("flutterInAppWebViewPlatformReady", function (event) {
    });

    if (this.id == undefined || this.token == undefined) {
      this.close();
    }

    this.values = [20, 40, 60];

    this.loadData();
  }

  close = (args) => {
    console.log(args);
    try {
      this.$window.flutter_inappwebview.callHandler('closeMe', args);
    } catch (e) {
      console.log(e);
    }
    this.$window.close();
  }

  loadData = () => {
    this.$http.defaults.headers.common['authorization'] = `${this.token}`;
    this.LoopBackAuth.currentUserId = this.id;
    this.LoopBackAuth.accessTokenId = this.token;
    this.User.findById({ id: this.id }).$promise.then(r => {
      this.user = r;
      this.data = {
        value: null,
        payment: {
          type: 'mbway',
          value: r.phone
        },
        billing: {
          type: 'same'
        }
      }
      this.loaded = true;
    }).catch(e => {
      console.log(e);
      this.close(e);
    })
  }

  save = () => {
    this.UI.showConfirm("Deseja finalizar processo?").then(r => {
      if (this.data.billing.type == 'same') {
        this.data.billing.data = this.user.billing;
      }
      this.Payment.create({
        userId: this.id,
        date: Date.now(),
        value: this.data.value,
        payment: this.data.payment,
        billing: this.data.billing.data,
        status: 'pending'
      }).$promise.then(_ => {
        if (this.data.remember == true) {
          this.User.prototype$patchAttributes(
            {
              id: this.id,
            },
            {
              billing: this.data.billing.data
            });
        }
        this.$state.go('paywall.confirm', { i: _.id });
      }).catch(e => {
        this.UI.addToast("Não foi possível satisfazer o seu pedido neste momento.");
      })
    })
  }
}

CheckoutController.$inject = ['$state', '$window', 'UIService', 'User', 'Payment', 'LoopBackAuth', '$http'];
