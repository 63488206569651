export default class EntityService {
  constructor($q, AuthenticationService, Entity, Site) {
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.Entity = Entity;
    this.Site = Site;
  }

  list = (filter) => {
    let defer = this.$q.defer();
    filter.include = "sites";

    this.Entity.count({
      where: filter.where,
    })
      .$promise.then((c) => {
        this.Entity.find({
          filter: filter,
        })
          .$promise.then((r) => {
            defer.resolve({
              total: c.count,
              data: r,
            });
          })
          .catch((e) => {
            defer.reject(e);
          });
      })
      .catch((e) => {
        defer.reject(e);
      });

    return defer.promise;
  };

  get = (id) => {
    let defer = this.$q.defer();
    this.Entity.findById({
      id: id,
      filter: {
        include: {
          relation: "sites",
        },
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  save = (data) => {
    let defer = this.$q.defer();
    this.Entity.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  saveSite = (data) => {
    let defer = this.$q.defer();
    this.Site.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  favicon = (data) => {
    // Fetch favicon
    let defer = this.$q.defer();
    if (!data.url) {
      defer.reject(e);
    }
    this.$http.get(data.url).then((r) => {
      console.log(r);
      defer.resolve(r);
    });

    return defer.promise;
  };

  suggest = (nif) => {
    let defer = this.$q.defer();
    this.Entity.loadDataFromTaxNumber({ no: nif })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  canDeleteSite = (id) => {
    let defer = this.$q.defer();
    this.Site.findOne({
      filter: {
        where: {
          active: true,
          id: id,
        },
        include: [
          {
            relation: "evses",
            scope: {
              where: { active: true },
            },
          },
          {
            relation: "profiles",
            scope: {
              where: { active: true },
            },
          },
        ],
      },
    })
      .$promise.then((r) => {
        console.log(r);
        if (r.active == false) {
          // Já "removido"
          defer.reject(true);
        } else {
          defer.resolve(r.evses.length == 0 && r.profiles.length == 0);
        }
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

EntityService.$inject = ["$q", "AuthenticationService", "Entity", "Site"];
