export default class UserEditAdminController {
  constructor($rootScope, $state, $stateParams, AdministrationService, AuthenticationService, UIService, User, Balance) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.Administration = AdministrationService;
    this.Authentication = AuthenticationService;
    this.UI = UIService;
    this.User = User;
    this.Balance = Balance;

    this.user = {
      imap: false,
      blocked: false
    };
    this.loaded = false;
    this.cardsLoaded = false;
    if (angular.isUndefined($stateParams.id)) {
      $state.go('app.administration.main');
    }

    this.id = $stateParams.id;
    this.loadUserInfo($stateParams.id);
  }

  isNotUser = () => {
    return (this.Authentication.getId() !== this.user.id);
  };

  blockUser = () => {
    let message = 'Deseja ' + ((this.user.blocked) ? 'des' : '') + 'bloquear utilizador?';
    let confirm = this.UI.showConfirm(message);
    confirm.then(() => {
      // OK
      this.user.blocked = !this.user.blocked;
      user.$save().then(() => {
        let messaging = 'Utilizador ' + ((this.user.blocked) ? 'des' : '') + 'bloqueado';
        this.UI.addToast(messaging);
      });
    }).catch(() => {
      // Cancel
    });
  };

  editBalanceLine = (row, index) => {
    let p = this.user.balances[index + 1];
    this.UI.showDialog({
      template: require('./balance.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.cancel = () => {
          $scope.$dismiss();
        }

        let round = (num, n) => +(Math.round(num + `e+${n}`) + `e-${n}`);

        $scope.data = angular.copy(row);
        let value = row.transaction ? row.transaction.userCost * -1 : row.value;
        $scope.data.value = value;
        $scope.data.updated = round((p ? p.updated : 0) + value, 5);
        $scope.ok = () => {
          $scope.$close($scope.data);
        }
      }]
    }).then(r => {
      if (r) {
        this.Balance.upsert(r).$promise.then(_ => {
          this.UI.addToast("Atualizada linha de movimento");
          row.value = r.value;
          row.updated = r.updated;
        });
      }
    });
  }

  addBalance = () => {
    this.UI.showDialog({
      template: require('./funds.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.data = {
          value: 0,
          payment: {
            type: 'admin',
          },
          status: 'pending',
          userId: this.id,
        }

        $scope.loaded = true;

        $scope.cancel = () => {
          $scope.$dismiss();
        }

        $scope.ok = () => {
          $scope.data.date = new Date();
          $scope.$close($scope.data);
        }
      }]
    }).then(r => {
      if (r) {
        this.Administration.createPayment(r).then(_ => {
          this.UI.addToast(`Entrada de ${r.value}€ adicionados ao cliente`);
          this.loadUserInfo(this.id);
        }).catch(e => {
          this.UI.addToast("Não foi possível adicionar saldo");
        })
      }
    });
  }

  getOperationName = op => {
    switch (op) {
      case 'chargement':
        return "Carregamento de Saldo";
      case 'service_tax':
        return "Taxa de Serviço";
      case 'transaction':
        return "Carregamento EV";
      case 'adjust':
        return "Acerto de contas";
      default:
        return "";
    }
  }

  getType = type => {
    switch (type) {
      case 1:
        return "Condomínio";
      case 0:
        return "Empresa";
      case -1:
        return "Sistema";
      default:
        return 'Privado';
    }
  }


  loadEntitySites = () => {
    this.entityLoaded = false;
    this.entitySelected = this.entitySelected || 0;
    this.Administration.getTenantSites(this.user.id).then(r => {
      this.entities = r;
      this.entityLoaded = true;
    });
  }

  removeUser = () => {
    let dialog = this.UI.showConfirm('Deseja remover utilizador do sistema?');
    dialog.then(() => {
      this.Administration.deleteUser(this.user.id).then((result) => {
        this.UI.addToast('Utilizador removido');
        this.$state.go('app.administration.main');
      });
    });
  };

  loadUserInfo = (id) => {
    this.loaded = false;
    this.Administration.getUser(id).then((user) => {
      this.user = user[0];
      this.loaded = true;
      this.cardsLoaded = true;
    }).catch(err => {
      console.log(err);
      this.UI.addToast('Erro ao carregar dados');
      //this.$state.go('app.administration.main');
    });
  };

  // Form for user password change
  changePassword = () => {
    this.UI.showDialog({
      template: require("./change-password.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.ok = () => {
          $scope.$close($scope.password);
        };

        $scope.cancel = () => {
          $dialog.dismiss();
        };
      }]
    }).then(password => {
      this.Administration.setUserPassword(this.user.id, password).then(() => {
        this.UI.addToast("Password alterada");
      }).catch((err) => {
        this.UI.addToast("Erro na alteração da password.");
      });
    }).catch(() => { });
  };

  showUserDialog = () => {
    let list = [];
    let ids = this.user.tenants.map(r => r.entityId);
    let options = {
      size: 'lg',
      template: require('./list.dialog.html'),
      controller: ['$scope', '$filter', 'Entity', ($scope, $filter, Entity) => {
        $scope.loaded = false;

        Entity.find({
          filter: {
            where: {
              id: {
                inq: ids
              }
            }
          }
        }).$promise.then(a => {
          $scope.selected = a;
          Entity.find({
            filter: {
              where: {
                id: {
                  nin: ids
                }
              }
            }
          }).$promise.then(r => {
            list = r;
            $scope.available = list;
            $scope.loaded = true;
          });
        })
        // Search filter
        $scope.filter = '';
        // Pagination
        $scope.pagination = {
          perPage: 5,
          current: 0,
          previous: () => {
            if ($scope.pagination.current > 0)
              $scope.pagination.current--;
          },
          onFilter: () => {
            const filteredArray = $filter('filter')($scope.available, $scope.filter);
            const afterFilter = Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
            if (afterFilter < $scope.pagination.current) {
              $scope.pagination.current = afterFilter;
            }
          },
          next: () => {
            if ($scope.pagination.current < $scope.pagination.total() - 1)
              $scope.pagination.current++;
          },
          total: () => {
            const filteredArray = $filter('filter')($scope.available, $scope.filter);
            return Math.ceil(filteredArray.length / $scope.pagination.perPage);
          }
        };

        $scope.select = function (user) {
          user.oldIdx = $scope.available.indexOf(user);
          $scope.selected.push(user);
          $scope.available.splice(user.oldIdx, 1);
        };

        $scope.pop = function (user) {
          $scope.selected.splice($scope.selected.indexOf(user), 1);
          $scope.available.splice(user.oldIdx, 0, user);
        };

        $scope.ok = function () {
          $scope.$close($scope.selected.map(r => r.id));
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };


      }]
    };

    this.UI.showDialog(options).then((results) => {
      this.Administration.updateEntities(this.id, results).then(r => {
        this.loadUserInfo(this.id);
        this.UI.addToast("Entidades associadas atualizadas");
      })
    });
  };

  entityPicker = () => {
    let wait = this.UI.showWaiting();
    this.Administration.getEntities().then(list => {
      wait.close();
      this.UI.showDialog({
        template: require('./list.dialog.html'),
        controller: ['$scope', $scope => {
          $scope.choice = {};
          $scope.title = "Adicionar empresa";
          $scope.field = "name";
          $scope.label = "Escolha uma empresa da lista";
          $scope.list = list;
          $scope.ok = () => { $scope.$close($scope.choice) };
          $scope.cancel = () => { $scope.$dismiss() };
        }]
      }).then(r => {
        if (r) {
          this.Administration.setEntityUser(this.user.id, r.selected.id).then(r => {
            this.UI.addToast("Empresa adicionada");
            this.loadUserInfo(this.id);
          }).catch(e => {
            console.log(e);
            this.UI.addToast("Não foi possível associar empresa a utilizador");
          });
        }
      });
    }).catch(e => {
      wait.close();
      this.UI.addToast("Ocorreu um erro a carregar empresas");
    })

  }

  save = () => {
    this.User.upsert(this.user).$promise.then(_ => {
      this.user = _;
      this.UI.addToast("Dados gerais da conta alterados com sucesso");
    }).catch(e => {
      this.UI.addToast("Erro ao alterar dados da conta");
    });
  }

  manageCard = (row, index) => {
    this.cardsLoaded = false;
    this.UI.showDialog({
      template: require('./card.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.ok = () => {
          $scope.data.no = $scope.data.no.toUpperCase();
          $scope.$close($scope.data);
        }

        $scope.delete = () => {
          this.UI.showConfirm("Tem a certeza que deseja eliminar identificador?").then(r => {
            $scope.$close(undefined);
          });
        }

        $scope.cancel = () => { $scope.$dismiss(); }
        if (row) {
          $scope.isEdit = true;
          $scope.data = {
            no: row.no,
            description: row.description,
            blocked: row.blocked
          }
        }
      }]
    }).then(r => {
      if (r == undefined && index != undefined) {
        this.user.cards.splice(index, 1);
        this.User.upsert(this.user).$promise.then(_ => {
          this.user = _;
          this.UI.addToast("Identificador eliminado com sucesso");
          this.cardsLoaded = true;
        }).catch(e => {

        });
      } else {
        if (row != undefined) {
          this.user.cards[index] = r;
        } else {
          this.user.cards = this.user.cards || [];
          this.user.cards.push(r);
        }
        this.User.upsert(this.user).$promise.then(_ => {
          this.user = _;
          if (row != undefined) {
            this.UI.addToast("Identificador alterado com sucesso");
          } else {
            this.UI.addToast("Identificador adicionado com sucesso");
          }
          this.cardsLoaded = true;
        }).catch(e => {

        });
      }
    }).catch(e => {
      this.cardsLoaded = true;
    });
  }

  pickSite = (row) => {
    if (row.picked == false) {
      if (this.user.siteId == undefined) {
        this.Administration.setPropertyUser(this.user.id, { siteId: row.id });
      }
      this.Administration.setAccess(row.id, this.user.id).then(r => {
        this.UI.addToast("Autorizado utilizador à instalação");
      })
    } else {
      this.Administration.removeAccess(row.id, this.user.id).then(r => {
        this.UI.addToast("Removido utilizador da instalação");
      })
    }
  }

  isNoSites = () => {
    let picked = this.entities[this.entitySelected].sites.filter(r => r.picked == true).length;
    return picked == 0;
  }

  isAllSites = () => {
    let picked = this.entities[this.entitySelected].sites.filter(r => r.picked == true).length;
    let length = this.entities[this.entitySelected].sites.length;
    return picked == length;
  }

  isSomeSites = () => {
    let picked = this.entities[this.entitySelected].sites.filter(r => r.picked == true).length;
    let length = this.entities[this.entitySelected].sites.length;
    return picked < length && picked > 0;
  }

  pickAll = () => {
    this.entities[this.entitySelected].sites.forEach(r => {
      r.picked = true;
    });
    this.Administration.setAccessArray(this.user.id, this.entities[this.entitySelected].sites).then(r => {
      this.UI.addToast("Autorizado utilizador às instalações");
    });
  }

  pickNone = () => {
    this.entities[this.entitySelected].sites.forEach(r => {
      r.picked = false;
    });
    this.Administration.removeAccessArray(this.user.id, this.entities[this.entitySelected].sites).then(r => {
      this.UI.addToast("Removido utilizador das instalações");
    });
  }

  pick = () => {
    if (this.isAllSites()) {
      this.pickNone();
    } else {
      this.pickAll();
    }
  }

  export = () => {
    this.UI.showDialog({
      template: require('./export.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.options = {
          format: 'MM/YYYY'
        }

        $scope.ok = () => {
          $scope.$close($scope.date);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    }).then(r => {
      let from = moment.utc(r).startOf('month');
      let to = moment.utc(r).endOf('month');
      let url = `/api/users/${this.id}/extract?from=${from}&to=${to}`;
      var downloadLink = angular.element("<a></a>");
      downloadLink.attr("href", url);
      downloadLink.attr("download", `extracto-${this.user.name}.pdf`);
      downloadLink[0].click();

      this.UI.addToast("Download iniciado, aguarde por favor");
    });
  }
}

UserEditAdminController.$inject = ['$rootScope', '$state', '$stateParams', 'AdministrationService', 'AuthenticationService', 'UIService', 'User', 'Balance'];
