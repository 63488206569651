import angular from "angular";

export default class TariffcycleNewController {
  constructor($state, UIService, TariffcycleService, AuthorizationService, TariffService) {
    this.$state = $state;
    this.UI = UIService;
    this.Tariffcycle = TariffcycleService;
    this.Authorization = AuthorizationService;
    this.Tariff = TariffService;

    this.id = $state.params.id;
    this.aux = {};
    this.label = "Adicionar";
    this.data = {
      entityId: $state.params.entityId,
      periods: [
        // Horário legal de verão
        {
          isDST: true,
        },
        // Horário legal de inverno
        {
          isDST: false,
        },
      ],
    };

    this.Tariffcycle.cycles().then((r) => {
      this.loaded = true;
      this.cycles = r;
      this.aux.cycle = r[0];
      this.Tariff.tableGenerator().then((table) => {
        if (!this.aux.table) {
          this.aux.table = {
            summer: angular.copy(table),
            winter: angular.copy(table),
          };
        }
      });
    }).catch((e) => {
      this.UI.addToast("Ocorreu um erro ao carregador opções horárias");
    });
  }

  loadData = () => {
    this.Tariffcycle.cycles().then((r) => {
      this.cycles = r;
    }).catch((e) => {
      this.UI.addToast("Ocorreu um erro ao carregador opções horárias");
    });

    this.Tariffcycle.get(this.id).then(r => {
      this.data = r;
      console.log(r);
      this.aux.cycle = r.cycle;
      this.loaded = true;
    }).catch(() => {
      this.UI.addToast("Ocorreu um erro ao carregar instalação");
    });
  }

  save = () => {
    if (this.validate()) {
      this.data.cycleId = this.aux.cycle.id;
      this.data.periods[0].timetable = [];
      this.aux.table.summer.forEach((row) => {
        row.timetable.forEach((time) => {
          this.data.periods[0].timetable.push({
            weekDay: row.dayOfWeek,
            cost: 0,
            band: time.band,
            hour: moment()
              .startOf("d")
              .seconds(time.i * 15)
              .format("mm:ss"),
          });
        });
      });
      // Horário legal de inverno
      this.data.periods[1].timetable = [];
      this.aux.table.winter.forEach((row) => {
        row.timetable.forEach((time) => {
          this.data.periods[1].timetable.push({
            weekDay: row.dayOfWeek,
            band: time.band,
            hour: moment()
              .startOf("d")
              .seconds(time.i * 15)
              .format("mm:ss"),
          });
        });
      });
      // Informação das bandas
      this.aux.cycle.bands.forEach((e) => {
        e.summer = 0;
        e.winter = 0;
      });
      this.data.bands = this.aux.cycle.bands;

      this.Tariffcycle.save(this.data).then(r => {
        this.$state.go('app.tariffcycle.details', {
          id: r.id
        });
        this.UI.addToast("Ciclo horário adicionado com sucesso");
      }).catch(e => {
        this.UI.addToast("Não foi possível gravar ciclo horário");
      });
    }
  }

  apply = (row) => {
    if (angular.isUndefined(this.aux.band)) {
      return;
    }
    row.band = angular.copy(this.aux.band);
  };

  clear = (row) => {
    row.band = undefined;
  };

  validate = () => {
    if (!this.data.name) {
      this.UI.addToast("Insira o nome do ciclo");
      return false;
    }

    if (!this.aux.cycle) {
      this.UI.addToast("Insira opção horária");
      return false;
    }

    return true;
  }

  applyHover = ($event, row) => {
    if (angular.isUndefined(this.aux.band)) {
      return;
    }
    if ($event.buttons == 1) row.band = angular.copy(this.aux.band);
    if ($event.buttons == 2) row.band = undefined;
  };

  selectBand = (band) => {
    this.aux.band = band;
    this.UI.addToast(`${band.name} seleccionado`);
  };

  dayOfWeek = (num) => {
    switch (num.dayOfWeek) {
      case 1:
        return "Segunda-Feira";
      case 2:
        return "Terça-Feira";
      case 3:
        return "Quarta-Feira";
      case 4:
        return "Quinta-Feira";
      case 5:
        return "Sexta-Feira";
      case 6:
        return "Sábado";
      case 7:
        return "Domingo";
    }
  };
}

TariffcycleNewController.$inject = ['$state', 'UIService', 'TariffcycleService', 'AuthorizationService', 'TariffService'];
