export default class LoggingController {
    constructor($rootScope, $state, NgTableParams, UIService, Backlog) {
      this.$rootScope = $rootScope;
      this.$state = $state;
      this.NgTableParams = NgTableParams;
      this.UI = UIService;
      this.Backlog = Backlog;

      this.logs = [];

      this.getData();

      this.loaded = false;
    }

    getData = () => {
      this.Backlog.find({
        filter: {include: 'user'}
      }).$promise.then(res => {
        if(angular.isDefined(res) && res !== null) {
          this.logs = res;
          this.tableData = new this.NgTableParams({
            sorting: {id: 'desc'}
          }, {
            dataset: this.logs
          });
          this.loaded = true;
        }
      }).catch(err => {
        console.log(err);
      })
    };

    hasLogs = () => {
      return (angular.isDefined(this.logs) && this.logs !== null && this.logs.length > 0);
    };
}

LoggingController.$inject = ['$rootScope', '$state', 'NgTableParams', 'UIService', 'Backlog'];
