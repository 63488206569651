import { Observable } from "rxjs";

export default class DashboardService {
  constructor(
    $q,
    $rootScope,
    Evse,
    Site,
    Profile,
    Event,
    Alarm,
    Transaction,
    Auth,
    Authorization,
  ) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.Evse = Evse;
    this.Profile = Profile;
    this.Event = Event;
    this.Alarm = Alarm;
    this.Site = Site;
    this.Transaction = Transaction;
    this.Auth = Auth;
    this.Authorization = Authorization;
  }

  status = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();

    this.Evse.find({
      filter: {
        wh
      }
    }).$promise.then(list => {
      console.log(list);
      defer.resolve({
        total: list.length,
        offline: list.filter(r => r.offline).length,
        available: list.filter(r => r.connectors.filter(s => s.status.includes("Available"))).filter(r => !r.offline).length,
        charging: list.filter(r => r.connectors.filter(s => s.status.includes("Charging") || s.status.includes("SuspendedEv") || s.status.includes("SuspendedEVSE"))).filter(r => !r.offline).length,
        occupied: list.filter(r => r.connectors.filter(s => s.status.includes("Preparing") || s.status.includes("Finishing"))).filter(r => !r.offline).length,
        error: list.filter(r => !r.connectors.filter(s => s.status.includes("Error"))).filter(r => !r.offline).length,
      });
    }).catch(e => defer.reject(e));
    return defer.promise;
  }

  consumption = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Site.find({
      filter: {
        fields: {
          id: true,
          entityId: true, active: true,
        },
        where: {
          active: true,
          entityId: {
            inq: user.entityIds,
          },
        },
      },
    })
      .$promise.then((r) => {
        r = r.map((r) => r.id);
        this.Transaction.dashConsumption({
          sites: r,
        })
          .$promise.then((c) => {
            defer.resolve(c);
          })
          .catch((e) => defer.reject(e));
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  sessions = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Site.find({
      filter: {
        fields: {
          id: true,
          entityId: true, active: true,
        },
        where: {
          active: true,
          entityId: {
            inq: user.entityIds,
          },
        },
      },
    })
      .$promise.then((r) => {
        r = r.map((r) => r.id);
        this.Transaction.dashSessions({
          sites: r,
        })
          .$promise.then((c) => {
            defer.resolve(c);
          })
          .catch((e) => defer.reject(e));
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

DashboardService.$inject = [
  "$q",
  "$rootScope",
  "Evse",
  "Site",
  "Profile",
  "Event",
  "Alarm",
  "Transaction",
  "AuthenticationService",
  "AuthorizationService"
];
