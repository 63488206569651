export function routes($stateProvider) {
  $stateProvider
    .state("auth.redirecting", {
      url: "/redirect?access-token&user-id",
      controller: "RedirectController",
      controllerAs: "vm",
      template: require("./redirect/redirect.view.html"),
    })
    .state("auth.login", {
      url: "/login",
      title: "Iniciar Sessão",
      template: require("./login/view.html"),
      controller: "LoginController",
      controllerAs: "vm",
    })
    .state("auth.forgot-password", {
      url: "/forgot-password",
      title: "Esqueceu-se da Password",
      template: require("./forgot-password/forgot-password.view.html"),
      controller: "ForgotPasswordController",
      controllerAs: "vm",
    })
    .state("auth.reset-password", {
      url: "/reset-password?token",
      title: "Reposição de Password",
      template: require("./reset-password/view.html"),
      controller: "ResetPasswordController",
      controllerAs: "vm",
    })
    .state("auth.two-factor", {
      url: "/verify",
      title: "Confirmar acesso",
      template: require("./two-factor/two-factor.view.html"),
      controller: "TwoFactorController",
      controllerAs: "vm",
      params: {
        allow: false,
      },
    });
}

export function routing($urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true);
  $urlRouterProvider.otherwise("/");
}

routing.$inject = ["$urlRouterProvider", "$locationProvider"];
routes.$inject = ["$stateProvider"];
