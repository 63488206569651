import {
  routes
} from './routes';
import EventService from './service';
import EventListController from './list/controller';

export default angular.module('app.events', [])
  .config(routes)
  .service('EventService', EventService)
  .controller('EventListController', EventListController)
  .name;

