"use strict"

import angular from "angular";

export default angular.module('app.intlPhone', [])
  .constant('ipnConfig', {
    allowExtensions: false,
    autoFormat: true,
    autoHideDialCode: true,
    autoPlaceholder: true,
    customPlaceholder: null,
    defaultCountry: "",
    geoIpLookup: null,
    nationalMode: true,
    numberType: "MOBILE",
    onlyCountries: undefined,
    preferredCountries: ['pt', 'es', 'fr'],
    skipUtilScriptDownload: false,
    utilsScript: ""
  })
  .directive('internationalPhoneNumber', ['$timeout', 'ipnConfig', ($timeout, ipnConfig) => {
    return {
      restrict: 'A',
      require: '^ngModel',
      scope: {
        ngModel: '=',
        country: '='
      },
      link: (scope, element, attrs, ctrl) => {
        if (ctrl) {
          if (element.val() != '') {
            $timeout(() => {
              element.intlTelInput('setNumber', element.val());
              ctrl.$setViewValue(element.val());
            }, 0);
          }
        }

        let read = () => {
          ctrl.$setViewValue = element.val();
        }

        let handleWhatsSupposedToBeAnArray = (value) => {
          if (value instanceof Array)
            return value
          else
            return value.toString().replace(/[ ]/g, '').split(',')
        }

        let options = angular.copy(ipnConfig);

        angular.forEach(options, (value, key) => {
          if (!attrs.hasOwnProperty(key) && angular.isDefined(attrs[key])) {
            return;
          }
          let option = attrs[key];
          if (key == 'preferredCountries') {
            options.preferredCountries = handleWhatsSupposedToBeAnArray(option);

          }
          if (key == 'onlyCountries') {
            options.onlyCountries = handleWhatsSupposedToBeAnArray(option);

          }
          else if (typeof (value) == "boolean") {
            options[key] = (option == "true");
          }
          else
            options[key] = option
        });



        scope.$watch('country', (newValue) => {
          if (newValue != null && newValue != undefined && newValue != '')
            element.intlTelInput("selectCountry", newValue);
        });

        ctrl.$formatters.push((value) => {
          if (!value) {
            return value;
          }
          element.intlTelInput('setNumber', value);
          return element.val();
        });

        ctrl.$parsers.push((value) => {
          if (!value) {
            return value;
          }
          return value.replace(/[^\d]/g, '');
        });

        ctrl.$validators.internationalPhoneNumber((value) => {
          let selectCountry = element.intlTelInput('getSelectedCountryData');
          if (!value || (selectCountry && selectCountry.dialCode == value)) {
            return true;
          }
          return element.intlTelInput("isValidNumber");
        });

        element.on('blur keyup change', (event) => {
          $scope.$apply(read);
        });

        element.on('$destroy', () => {
          element.intlTelInput('destroy');
          element.off('blur keyip change');
        });

      }
    }
  }])
  .name;
