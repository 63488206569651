import { defer } from "lodash";

export default class TransactionService {
  constructor(Transaction, $q, Site, AuthenticationService) {
    this.Transaction = Transaction;
    this.$q = $q;
    this.Site = Site;
    this.Auth = AuthenticationService;
  }

  loadFilterData = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    // Site filter
    let siteFilter = {
      filter: {
        include: ["evses", {
          relation: "access",
          scope: {
            include: "user"
          }
        }],
        where: {
          active: true,
          id: user.siteId
        },
      },
    };
    this.Site.find(siteFilter)
      .$promise.then((r) => {
        r = r[0];
        let evses = [];
        let users = [];
        let t = r.evses.map((r) => r);
        let u = r.access.map((r => r));
        t.forEach((e) => {
          if (Array.isArray(e)) {
            evses.push(...e);
          } else {
            evses.push(e);
          }
        });
        u.forEach((e) => {
          if (e.user != undefined) {
            users.push(e.user);
          }
        });
        defer.resolve({
          evses: evses,
          users: users
        });
        // Load chargers
      })
      .catch((e) => {
        defer.reject(e);
      });

    return defer.promise;
  };

  export = (filter) => {
    filter.include = [
      "user",
      "evse"
    ];
    // TODO: Find a way to use the export function from within the model
    let defer = this.$q.defer();
    let url = `/api/transactions/export?filter=${JSON.stringify(filter)}`;
    defer.resolve(url);
    return defer.promise;
  };

  list = (filter) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    filter.where.siteId = user.siteId;
    filter.include = [
      "user",
      "evse",
    ];
    this.Transaction.count({
      where: filter.where,
    }).$promise.then((r) => {
      this.Transaction.find({
        filter: filter,
      }).$promise.then((list) => {
        defer.resolve({
          total: r.count,
          data: list,
        });
      }).catch((err) => {
        defer.reject(err);
      });
    }).catch((err) => {
      defer.reject(err);
    });
    return defer.promise;
  };

  get = id => {
    let defer = this.$q.defer();
    this.Transaction.findOne({
      filter: {
        where: {
          id: id
        },
        include: [
          "user",
          "evse",
          "consumption",
          "logger"
        ]
      }
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }
}

TransactionService.$inject = ["Transaction", "$q", "Site", "AuthenticationService"];
