export function routes($stateProvider) {
  $stateProvider
    .state('app.entity', {
      url: '/entity',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.entity.list', {
      url: '?{page:int}&{term:string}&{order:string}&{sort:string}',
      template: require('./list/view.html'),
      controller: 'EntityListController',
      controllerAs: 'vm',
      role: ['readEntity']
    }).state('app.entity.details', {
      url: '/{id}',
      template: require('./details/view.html'),
      controller: 'EntityDetailsController',
      controllerAs: 'vm'
    }).state('app.entity.new', {
      url: '/add',
      template: require('./details/view.html'),
      controller: 'EntityDetailsController',
      controllerAs: 'vm'
    });
}

routes.$inject = ['$stateProvider'];
