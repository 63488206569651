export default class EVSEListController {
  constructor($state, $scope, $timeout, $interval, UIService, EvseService, GroupService, TariffService, EntityService, SiteService, AuthorizationService, FileUploader) {
    // Models
    this.$state = $state;
    this.$timeout = $timeout;
    this.$interval = $interval;
    this.UI = UIService;
    this.Evse = EvseService;
    this.Group = GroupService;
    this.Tariff = TariffService;
    this.Entity = EntityService;
    this.Sites = SiteService;
    this.Authorization = AuthorizationService;
    this.FileUploader = FileUploader;
    // Variables
    this.loaded = false;
    this.loadedPhoto = false;
    this.data = [];

    this.zzTag = false;
    this.zzAuth = false;
    this.zzActive = false;
    this.zzSidebar = false;
    this.filter = "";
    this.filterSerie = "";
    this.groupSelected;


    this.uploader = new FileUploader({
      url: "/api/assets/containers/photos/files",
      queueLimit: 1,
      autoUpload: true,
      removeAfterUpload: true
    });

    this.uploader.filters.push({
      name: 'isImage',
      fn: function (item, options) {
        let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|png|jpeg|jpg|gif|'.indexOf(type) !== -1;
      }
    });

    this.uploader.onBeforeUploadItem = (item) => {
      item.evseId = this.details.id;
      // rename into uuid
      let uuid = (a) => (a ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid));
      let extension = item.file.name.split(".").pop();
      item.file.name = `${uuid()}.${extension}`;

    };

    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      if (filter.name == "queueLimit") {
        this.uploader.clearQueue();
        this.uploader.addToQueue(item);
      }
    };

    this.uploader.onSuccessItem = (item, response) => {
      this.details.photo = response[0];
      this.getChargerPhoto(this.details);
      this.Evse.save(this.details);
    }

    this.newTagPlaceholder = undefined;


    $scope.$watch(
      () => {
        return this.zzTag;
      },
      (val) => {
        if (!val) {
          this.newTagPlaceholder = undefined;
        }
      }
    );

    $scope.$on("$destroy", () => {
      // unregister event streams
    });

    this.filter = {
      page: $state.params.page || 1,
      term: $state.params.term || "",
      sort: "asc",
      order: "alias",
      charging: $state.params.charging || false,
      offline: $state.params.offline || false,
    };

    this.loadData();
  }

  expand = (row, $evt) => {
    row.expanded = !row.expanded;
    console.log(row);
  };


  calcQOL = (data) => {
    data.qol = data.qol || [];
    let count = data.qol.length;
    let ok = data.qol.filter(r => r.value == 1) || [];
    let t = (ok.length / count) * 100;
    if (isNaN(t)) {
      t = 100;
    }
    return t.toFixed(0);
  };

  calcNetworkUsage = (data) => {
    let usage = 0;
    data.metrics = data.metrics || [];
    data.metrics.forEach(r => {
      usage += (r.deltaRead + r.deltaWrite);
    });
    return usage;
  }

  loadData = () => {
    this.loaded = false;

    this.$state.go("app.evse.list", this.filter, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });

    this.Evse.list(this.createFilter()).then((observer) => {
      this.observer = observer;
      observer.subscribe((r) => {
        this.loaded = true;
        this.total = r.total;
        this.start = r.total > 0 ? (this.filter.page - 1) * 50 + 1 : 0;
        this.end = r.total > 0 ? (this.filter.page - 1) * 50 + r.data.length : 0;
        this.$timeout(() => {
          // anything you want can go here and will safely be run on the next digest.
          this.data = r.data;
          if (this.data && this.details) {
            // Update current charger connector info
            let exists = this.data.find((r) => r.id.includes(this.details.id));
            if (exists) {
              this.details.connectors = exists.connectors;
              this.newDetails.connectors = exists.connectors;
            }
          }
        });
        if (this.total > r.data.length) {
          this.hasNavigation = true;
        } else {
          this.hasNavigation = false;
        }
      });
    });
    this.Group.list("").then(r => {
      this.group = r.data;
    });

    this.Tariff.list({}).then(r => {
      this.tariff = r.data;
      if (this.tariff.length > 0) {
        this.Entity.get(this.tariff[0].entityId).then(r => {
          this.contacts = r.contacts;
        });
      }
    });

  };

  getState = row => {
    if (row.offline) {
      return "Offline";
    }
    row.connectors = row.connectors || [];
    let connector = row.connectors.find(r => r.connectorId == 1);
    if (connector) {
      return this.getConnectorStatus(connector.status);
    }
    return "N/D";
  }

  checkForAdministrator = (evse) => {
    //check user permissions
    //if administrator show disabled chargers
    return this.Authorization.canPerform(['removeClient']);
  };

  greaterThan = (prop, val) => {
    return (item) => {
      return item[prop] > val;
    };
  };

  close = () => {
    this.zzSidebar = false;
    this.zzActive = false;
    this.zzAuth = false;
    this.details = undefined;
    this.newDetails = undefined;
    // Cancel peak/off peak chart
    this.peakChart = undefined;
    this.peakLoaded = false;
    this.loadData();
  };

  showDetails = (row) => {
    this.view = "details"; //config, stats
    this.zzSidebar = true;
    this.zzActive = row.active;
    this.zzAuth = row.isFreeVending;
    this.details = angular.copy(row);
    this.newDetails = angular.copy(row);
    this.groupSelected = row.evseGroup || "";
    this.tariffSelected = row.tariff || "";
    this.contactSelected = row.contact || "";
    // Get peak/off peak chart
    this.peakChart = undefined;
    this.peakLoaded = false;
    this.getPeakOffPeak();
    this.getChargerPhoto(row);
  };

  showView = (view) => {
    if (!view.includes("config")) {
      this.zzTag = false;
    }
    if (this.zzSidebar) this.view = view;
  };

  isOperative = () => {
    if (!this.details) {
      return false;
    } else {
      let unavailable = this.details.connectors.find(
        (r) => r.connectorId === 0 && r.status.includes("Unavailable")
      );
      return unavailable == null;
    }
  };

  currentView = (view) => {
    if (this.zzSidebar) {
      return this.view.includes(view);
    } else {
      return false;
    }
  };

  addTag = (evt) => {
    if (evt.which === 13) {
      this.newDetails.tags = this.newDetails.tags || [];
      this.newDetails.tags.push(this.newTagPlaceholder);
      this.zzTag = false;
      this.newTagPlaceholder = undefined;
    }
  };

  adopt = () => {
    this.UI.showDialog({
      template: require("./adopt.dialog.html"),
      controller: [
        "$scope", "EvseProfile", ($scope, EvseProfile) => {

          $scope.loaded = false;

          $scope.profiles = [];

          $scope.data = {};

          $scope.newProfile = () => {
            this.UI.showDialog({
              template: require('./profile.dialog.html'),
              controller: ['$scope', $scope => {
                $scope.data = {
                  connectors: [],
                  partNumbers: []
                };

                $scope.ok = () => {
                  $scope.$close($scope.data);
                }

                $scope.cancel = () => {
                  $scope.$dismiss();
                }

                $scope.add = () => {
                  $scope.isAdding = true;
                  $scope.connector = {}
                }

                $scope.delete = (index) => {
                  $scope.data.connectors.splice(index, 1);
                }

                $scope.save = () => {
                  $scope.isAdding = false;
                  $scope.data.connectors = $scope.data.connectors || [];
                  let exists = $scope.data.connectors.find(r => r.connectorId == $scope.connector.connectorId);
                  if (exists == undefined) {
                    $scope.data.connectors.push(angular.copy($scope.connector));
                  }
                  $scope.connector = {};

                }
              }]
            }).then(r => {
              EvseProfile.create(r).$promise.then(profile => {
                $scope.profiles.push(profile);
                $scope.data.profile = profile;
              });
            })
          }


          EvseProfile.find().$promise.then(profiles => {
            $scope.profiles = profiles;
            this.Evse.pending().then(r => {
              $scope.evses = r;
              $scope.loaded = true;
            }).catch(e => { });
          }).catch(e => { });

          $scope.ok = () => {
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss("cancel");
          };
        },
      ],
    }).then((result) => {
      this.Evse.adopt(result.evse.id, result.profile.id).then(r => {
        this.UI.addToast("Carregador adicionado com sucesso");
        this.loadData();
      }).catch(e => {
        this.UI.addToast("Não foi possível adicionar carregador");
      })

    });
  }

  reset = () => {
    this.UI.showConfirm("Reiniciar carregador?").then((r) => {
      if (r) {
        this.Evse.reset(this.details.id)
          .then((r) => {
            if (r.result) {
              this.UI.addToast("Pedido de reinício efetuado");
            } else {
              this.UI.addToast("O carregador rejeitou o pedido de reinício");
            }
          })
          .catch((e) => {
            this.UI.addToast("O carregador rejeitou o pedido");
          });
      }
    });
  };

  synchronize = (evseId) => {
    this.UI.showConfirm("Sincronizar lista de indentificadores com carregador?").then((r) => {
      if (r) {
        this.Evse.sendLocalList(evseId)
          .then((r) => {
            if (r.result) {
              this.UI.addToast("Pedido de sincronização efetuado");
            } else {
              this.UI.addToast("O carregador rejeitou o pedido de sincronização");
            }
          })
          .catch((e) => {
            this.UI.addToast("O carregador rejeitou o pedido");
          });
      }
    });
  };

  forceStart = () => {
    let result;
    let err;
    this.UI.showConfirm("Forçar início de carregamento?").then((r) => {
      if (r) {
        this.details.connectors.forEach((connector) => {
          this.Evse.startTransaction(this.details.chargeBoxSerialNumber, this.details.id, connector.connectorId)
            .then((r) => {
              result = r.result;
            })
            .catch((e) => {
              err = e;
            });
        });

        if (result) {
          this.UI.addToast("Pedido de início de carregamento efetuado");
        } else if (!err) {
          this.UI.addToast("O carregador rejeitou o pedido de início de carregamento");
        } else {
          this.UI.addToast("Erro a estabelecer ligação com carregador");
        }
      }
    });
  };

  unlock = (connectorId) => {
    let result;
    let err;
    this.UI.showConfirm("Desbloquear carregador?").then((r) => {
      if (r) {
        this.Evse.unlock(this.details.id, connectorId)
          .then((r) => {
            result = r.result;
          }).catch((e) => {
            err = e;
          });

        if (result) {
          this.UI.addToast("Pedido de desbloqueio efetuado");
        } else if (!err) {
          this.UI.addToast("O carregador rejeitou o pedido de desbloqueio");
        } else {
          this.UI.addToast("Erro a estabelecer ligação com carregador");
        }
      }
    });
  };

  getChargerPhoto = (charger) => {
    if (!charger.photo) {
      this.photo = "https://assets.ey.com/content/dam/ey-sites/ey-com/en_gl/topics/power-and-utilities/ey-electric-car-charging-station-on-london-street.jpg";
      this.loadedPhoto = true;
      return;
    }

    let filename = charger.photo.filename;
    this.Evse.getChargerPhoto(filename)
      .then((r) => {
        this.photo = URL.createObjectURL(r.data);
        this.loadedPhoto = true;
      }).catch((err) => {
        this.UI.addToast("Erro a carregar fotografia do carregador");
      });
  }

  cancel = () => {
    this.newDetails = angular.copy(this.details);
    this.close();
  };

  update = () => {
    if (this.groupSelected)
      this.newDetails.evseGroupId = this.groupSelected.id;
    else
      this.newDetails.evseGroupId = "";

    if (this.tariffSelected)
      this.newDetails.tariffId = this.tariffSelected.id;
    else
      this.newDetails.tariffId = "";

    if (this.contactSelected)
      this.newDetails.contact = this.contactSelected;
    else
      this.newDetails.contact = "";
    this.newDetails.active = this.zzActive || false;
    this.newDetails.isFreeVending = this.zzAuth || false;
    this.Evse.save(this.newDetails).then((r) => {
      this.newDetails = r;
      this.details = angular.copy(r);
      this.UI.addToast("Dados atualizados com sucesso");
      this.close();
    }).catch((err) => {
      this.UI.addToast("Erro a atualizar dados, verifique campos preenchidos");
    });
  };

  getPeakOffPeak = () => {
    this.Evse.getPeakOffPeak(this.details.id)
      .then((r) => {
        this.peakLoaded = true;
        // Create chart and update data every 5 min
        this.peakChart = {
          options: {
            datalabel: false,
            animation: false,
            legend: {
              display: false,
            },
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  type: "time",
                  distribution: "linear",
                  time: {
                    stepSize: 4,
                    minUnit: "hour",
                    displayFormats: {
                      hour: "HH:mm",
                    },
                    tooltipFormat: "HH:mm",
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    stepSize: 25,
                    suggestedMax: 100,
                    beginAtZero: true,
                    callback: function (value, index, values) {
                      return value + "%";
                    },
                  },
                },
              ],
            },
          },
        };

        this.peakChart.data = [[]];

        this.peakChart.datasets = [
          {
            label: "Utilização (%)",
          },
        ];

        r.forEach((details, index) => {
          let date = moment();
          date.hour(index);
          date.minute(0);
          date.second(0);
          this.peakChart.data[0].push({
            x: date,
            y: Number((details * 100).toFixed(2)),
          });
        });
      })
      .catch((e) => {
        this.peakLoaded = true;
        this.peakChart = undefined;
      });
  };

  getConnectorStatus = (status) => {
    return this.Evse.getReadableConnectorStatus(status);
  };

  showOfflines = () => {
    this.filter.charging = false;
    this.filter.offline = !this.filter.offline;
    this.loadData();
  }

  showAll = () => {
    this.filter.offline = false;
    this.filter.charging = false;
    this.loadData();
  }

  showCharging = () => {
    this.filter.offline = false;
    this.filter.charging = !this.filter.charging;
    this.loadData();
  }

  getTypeIcon = (type) => {
    switch (type) {
      case "T1":
        return "mdi-ev-plug-type1";
      case "T2":
        return "mdi-ev-plug-type2";
      case "CCS":
        return "mdi-ev-plug-ccs2";
      case "C":
        return "mdi-ev-plug-chademo";
      case "TESLA":
        return "mdi-ev-plug-tesla";
      case "SOCK":
        return "mdi-power-socket-eu";
      default:
        return "mdi-ev-plug-type2";
    }
  };

  getTypeDescription = (type) => {
    switch (type) {
      case "T1":
        return "Tipo 1";
      case "T2":
        return "Tipo 2";
      case "CCS":
        return "Tipo 2 - CCS";
      case "C":
        return "CHAdeMO";
      case "TESLA":
        return "Tesla";
      case "SOCK":
        return "Tipo F";
      default:
        return "Tipo 2";
    }
  };

  getReadableConnectorError = (connector) => {
    let error = "";
    switch (connector.errorCode) {
      case "ConnectorLockFailure":
        error = "Erro ao bloquear/desbloquear tomada";
        break;
      case "EVCommunicationError":
        error = "Erro ao comunicar com o veículo";
        break;
      case "GroundFailure":
        error = `Erro no circuito elétrico (Ligação à terra) (${connector.vendorErrorCode})`;
        break;
      case "HighTemperature":
        error = "Temperatura do carregador elevada";
        break;
      case "InternalError":
        error = "Erro de hardware do carregador";
        break;
      case "OtherError":
        error = `Consultar manual`;
        break;
      case "OverCurrentFailure":
        error = "Erro no circuito elétrico (Corrente demasiado elevada)";
        break;
      case "OverVoltage":
        error = `Erro no circuito elétrico (Tensão demasiado elevada)`;
        break;
      case "PowerMeterFailure":
        error = "Erro no contador de energia";
        break;
      case "PowerSwitchFailure":
        error = "Erro no controlador do interruptor";
        break;
      case "ReaderFailure":
        error = "Erro no leitor de identificadores";
        break;
      case "ResetFailure":
        error = "Erro ao tentar reiniciar o carregador";
        break;
      case "UnderVoltage":
        error = `Erro no circuito elétrico (Tensão demasiado baixa)`;
        break;
      case "WeakSignal":
        error = "Ligação WiFi com pouco sinal";
        break;

      default:
        return "Sem erros";
    }
    return `${connector.vendorErrorCode} | ${error}`
  };

  getSideBarStatus = status => {
    if (status.includes("available")) {
      return "available";
    }
    if (status.includes("preparing") || status.includes("finishing") || status.includes("suspendedev") || status.includes("suspendedevse")) {
      return "occupied";
    }
    if (status.includes("charging")) {
      return "charging";
    }
    if (status.includes("error") || status.includes("unavailable")) {
      return "unavailable";
    }
  }

  createFilter = () => {
    let ob = {};
    let prop = "";
    let pattern = "";
    let where = {};

    if (this.filter.charging == true) {
      where['connectors.status'] = {
        inq: ['Charging', 'SuspendedEV', 'SuspendedEVSE', 'Finishing', 'Preparing']
      }
    } else {
      delete where['connectors.status'];
    }

    if (this.filter.offline == true) {
      where.offline = true;
    } else {
      delete where.offline;
    }

    if (!this.filter.term.isEmpty()) {
      // Filter by name
      pattern = {
        like: `.*${this.filter.term}.*`,
        options: "i",
      };
      where.alias = pattern;
    }
    if (this.filterSerie && this.filterSerie.chargeBoxSerialNumber !== "") {
      //filter by serial number
      let filterSerie = this.filterSerie.chargeBoxSerialNumber || "";
      pattern = {
        like: `.*${filterSerie}.*`,
        options: "i",
      };
      where.chargeBoxSerialNumber = pattern;
    }
    if (!this.checkForAdministrator()) {
      where.active = true;
    }

    return {
      where: where,
      limit: 50,
      order: `${this.filter.order} ${this.filter.sort}`,
      skip: (this.filter.page - 1) * 50,
    };
  };

  next = () => {
    if (this.end < this.total) {
      this.filter.page++;
    }
    this.loadData();
  };

  previous = () => {
    if (this.filter.page > 1) {
      this.filter.page--;
    }
    this.loadData();
  };

  clear = () => {
    this.filter.page = this.$state.params.page || 1;
    this.filter.term = "";
    this.filter.sort = "asc";
    this.filter.order = "name";
    this.filter.charging = false;
    this.filter.offline = false;
    this.loadData();
  };

  validEnergyReadings = (connector) => angular.isDefined(connector.currentInstantWatts) && angular.isDefined(connector.power) && !isNaN(connector.currentInstantWatts) && !isNaN(connector.power);

  checkForUpdate = row => {
    let a = row.firmware;
    if (!a) {
      return false;
    }
    if (!row.evseProfile) {
      return false;
    } else {
      let b = row.evseProfile.latestVersion;
      if (!b) {
        return false;
      }
      let result = a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
      return result == -1;
    }
  }

  updateFirmware = row => {
    /*
    if (row.offline == true) {
      this.UI.addToast("O carregador está offline");
      return;
    }*/
    this.UI.showConfirm(`Deseja atualizar carregador para versão ${row.info.latestVersion}?\r\nO carregador ficará inoperacional durante esta operação e qualquer sessão de carga será finalizada.`).then(_ => {
      if (_) {
        this.Evse.update(row.id).then(r => {
          this.UI.addToast("A preparar atualização...");
        }).catch(e => {
          this.UI.addToast("Não foi possível enviar pedido de atualização");
        })
      }
    })
  }

  migrate = () => {
    this.UI.showDialog({
      template: require('./migrate.dialog.html'),
      controller: ['$scope', 'Site', ($scope, Site) => {

        $scope.data = {};

        $scope.groupFn = (item) => {
          return item.entity.name;
        }

        $scope.loaded = false;

        Site.find({
          filter: {
            where: {
              active: true
            },
            include: {
              relation: 'entity',
              scope: {
                where: {
                  active: true
                }
              }
            }
          }
        }).$promise.then(r => {
          r = r.filter(_ => _.entity != null);
          $scope.list = r;
          $scope.loaded = true;
        });


        $scope.cancel = () => {
          $scope.$dismiss();
        }

        $scope.ok = () => {
          $scope.$close($scope.data);
        }
      }]
    }).then(result => {
      if (result) {
        console.log(result);
        console.log(this.details);
        this.Evse.migrate(this.details.id, result.site.id).then(r => {
          this.UI.addToast("Carregador migrado com sucesso");
          this.loadData();
        }).catch(e => {
          this.UI.addToast("Não foi possível migrar carregador");
          this.loadData();
        })
      }
    })
  }
}


EVSEListController.$inject = ["$state", "$scope", "$timeout", "$interval", "UIService", "EvseService", "GroupService", "TariffService", "EntityService", "SiteService", "AuthorizationService", "FileUploader"];
