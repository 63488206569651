export default class ProviderListController {
  constructor(ProviderService, AuthorizationService, $state, UIService) {
    this.Provider = ProviderService;
    this.Authorization = AuthorizationService;
    this.UI = UIService;
    this.$state = $state;
    this.filter = {
      page: $state.params.page || 1,
      term: $state.params.term || "",
      sort: "asc",
      order: "name"
    };
    this.loadData();
  }

  loadData = () => {
    this.$state.go("app.provider.list", this.filter, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.Provider.list(this.createFilter()).then(r => {
      this.data = r.data;
      this.total = r.total;
      this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
      this.end = r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
      this.loaded = true;
    });
  }

  viewProvider = row => {
    if (this.Authorization.canPerform(['controlPanel'])) {
      this.$state.go('app.provider.details', {
        id: row.id
      })
    }
  }

  createFilter = () => {
    let ob = {};
    let prop = "";
    let pattern = "";
    let where = {};

    if (!this.filter.term.isEmpty()) {
      // Filter by name
      prop = `name`;
      pattern = {
        like: `.*${this.filter.term}.*`,
        options: "i",
      };
      ob = {};
      ob[prop] = pattern;
      where = ob;
    }
    return {
      where: where,
      limit: 20,
      order: `${this.filter.order} ${this.filter.sort}`,
      skip: (this.filter.page - 1) * 20,
    };
  };

  clear = () => {
    this.filter.page = this.$state.params.page || 1;
    this.filter.term = "";
    this.filter.sort = "asc";
    this.filter.order = "name";
    this.loadData();
  };

  next = () => {
    if (this.end < this.total) {
      this.filter.page++;
    }
    this.loadData();
  };

  previous = () => {
    if (this.filter.page > 1) {
      this.filter.page--;
    }
    this.loadData();
  };

  remove = (provider, $evt) => {
    $evt.stopPropagation();
    let dialog = this.UI.showConfirm('Deseja remover fornecedor ' + provider.name + ' do sistema?');
    dialog.then(() => {
      this.Provider.remove(provider).then((result) => {
        let index = this.data.indexOf(provider);
        this.data.splice(index, 1);
        let snackbar = this.UI.addSnackbar('Fornecedor removido', 'Anular', true);
        snackbar.then((res) => {
          if (res) {
            this.Provider.remove(provider).then((res) => {
              this.data.splice(index, 0, provider);
            });
          }
        })
        this.loadData();
      });
    });
  }
}

ProviderListController.$inject = ['ProviderService', 'AuthorizationService', '$state', 'UIService'];
