export default class GroupService {
  constructor($q, AuthenticationService, Site, EvseGroups, Evse) {
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.Site = Site;
    this.EvseGroups = EvseGroups;
    this.Evse = Evse;
  }

  list = (filter) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();

    let where = {
      entityId: user.site.entityId,
    };
    this.EvseGroups.count({
      where: where,
    })
      .$promise.then((c) => {
        this.EvseGroups.find({
          filter: {
            where: where,
            include: [
              {
                relation: "evse",
                scope: {
                  where: { active: true },
                },
              },
            ],
          },
        })
          .$promise.then((r) => {
            return defer.resolve({
              total: c.count,
              data: r,
            });
          })
          .catch((e) => defer.reject(e));
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  get = (id) => {
    let defer = this.$q.defer();
    this.EvseGroups.findById({
      id: id,
      filter: {
        include: [
          {
            relation: "evse",
            scope: {
              include: "site",
            },
          },
        ],
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  delete = (id) => {
    let defer = this.$q.defer();
    this.EvseGroups.deleteById({ id: id })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.rejet(e));
    return defer.promise;
  };

  save = (data) => {
    let user = this.Auth.getUser();

    let entityId = user.site.entityId;
    let defer = this.$q.defer();
    data.entityId = entityId;
    this.EvseGroups.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

GroupService.$inject = [
  "$q",
  "AuthenticationService",
  "Site",
  "EvseGroups",
  "Evse",
];
