export function routes($stateProvider) {
  $stateProvider
    .state('app.provider', {
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.provider.list', {
      url: '/provider?{page:int}&{term:string}&{order:string}&{sort:string}',
      template: require('./list/view.html'),
      controller: 'ProviderListController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.provider.details', {
      url: '/provider/?{id}',
      template: require('./details/view.html'),
      controller: 'ProviderDetailsController',
      controllerAs: 'vm',
      role: ['controlPanel']
    });
}

routes.$inject = ['$stateProvider'];
