import {
  routes
} from './routes';
import GroupService from './service';
import GroupListController from './list/controller';
import GroupDetailsController from './details/controller';

export default angular.module('app.Group', [])
  .config(routes)
  .service('GroupService', GroupService)
  .controller('GroupListController', GroupListController)
  .controller('GroupDetailsController', GroupDetailsController)
  .name;