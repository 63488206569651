export default class TariffcycleDetailsController {
  constructor($state, UIService, TariffcycleService, AuthorizationService) {
    this.$state = $state;
    this.UI = UIService;
    this.Tariffcycle = TariffcycleService;
    this.Authorization = AuthorizationService;

    this.id = $state.params.id;
    this.auxBand = {};

    this.loadData();
  }

  fillColor = (band) => {
    let data = {
      'background-color': band ? band.color : 'default'
    };
    return data;
  }

  applyBorder = index => (index + 1) % 4 == 0 && index > 0 && index < 95;

  loadData = () => {
    this.Tariffcycle.cycles().then((r) => {
      this.cycles = r;
    }).catch((e) => {
      this.UI.addToast("Ocorreu um erro ao carregador opções horárias");
    });

    this.Tariffcycle.get(this.id).then(r => {
      this.data = r;
      this.auxCycle = r.cycle;

      // Generate table compatible data
      this.tables = [];
      this.data.periods.forEach((p) => {
        // Group by weekDay
        let groupBy = p.timetable.reduce((r, a) => {
          let key = a.weekDay || "weekDay";
          r[key] = r[key] || [];
          r[key].push(a);
          return r;
        }, Object.create(null));
        // Create 2 tables based on DST
        let aux = [];
        Object.values(groupBy).forEach((v, i) => {
          aux.push({
            dayOfWeek: i + 1,
            timetable: v,
          });
        });
        // Add pretty-formatted table
        this.tables.push({
          isDST: p.isDST,
          timetable: aux,
        });
      });
      this.loaded = true;
    }).catch(() => {
      this.UI.addToast("Ocorreu um erro ao carregar ciclo");
    });
  }

  apply = (row) => {
    if (angular.isUndefined(this.auxBand)) {
      return;
    }
    row.band = angular.copy(this.auxBand);
  };

  applyHover = ($event, row) => {
    if (angular.isUndefined(this.auxBand)) {
      return;
    }
    if ($event.buttons == 1) row.band = angular.copy(this.auxBand);
    if ($event.buttons == 2) row.band = undefined;
  };

  selectBand = (band) => {
    this.auxBand = band;
    this.UI.addToast(`${band.name} seleccionado`);
  };

  save = () => {
    if (this.validate()) {
      this.Tariffcycle.save(this.data).then(r => {
        if (this.id) {
          this.loadData();
          this.UI.addToast("Ciclo horário alterado com sucesso");
        } else {
          this.$state.go('app.tariffcycle.details', {
            id: r.id
          });
          this.UI.addToast("Ciclo horário adicionado com sucesso");
        }
      }).catch(e => {
        this.UI.addToast("Não foi possível gravar ciclo horário");
      });
    }
  }

  validate = () => {
    if (!this.data.name) {
      this.UI.addToast("Insira o nome do ciclo");
      return false;
    }

    return true;
  }

  dayOfWeek = (num) => {
    switch (num.dayOfWeek) {
      case 1:
        return "Segunda-Feira";
      case 2:
        return "Terça-Feira";
      case 3:
        return "Quarta-Feira";
      case 4:
        return "Quinta-Feira";
      case 5:
        return "Sexta-Feira";
      case 6:
        return "Sábado";
      case 7:
        return "Domingo";
    }
  };
}

TariffcycleDetailsController.$inject = ['$state', 'UIService', 'TariffcycleService', 'AuthorizationService'];
