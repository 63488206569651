export default class ResetPasswordController {
  constructor($rootScope, $state, AuthenticationService, UIService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.Auth = AuthenticationService;
    this.UI = UIService;
    this.password = "";
    this.confirmPassword = "";

    this.id = $state.params.token || null;
    if (this.id == null) this.$state.go("auth.login");
  }

  hasError = () => {
    return this.error;
  };

  resetPassword = () => {
    if (!this.password.isEmpty() && !this.confirmPassword.isEmpty()) {
      if (this.password.includes(this.confirmPassword)) {
        this.error = false;
        this.Auth.setPasswordToken(this.id, this.password)
          .then((r) => {
            this.UI.addToast("Password redifinida com sucesso");
            this.$state.go("auth.login");
          })
          .catch((e) => {
            this.UI.addToast("Não foi possível redefinir a password");
          });
      } else {
        this.error = true;
      }
    }
  };
}

ResetPasswordController.$inject = [
  "$rootScope",
  "$state",
  "AuthenticationService",
  "UIService",
];
