export function routes($stateProvider) {
  $stateProvider
    .state('app.events', {
      url: '/events',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.events.list', {
      url: '?{page:int}&{order:string}&{sort:string}&{from:string}&{to:string}',
      template: require('./list/view.html'),
      controller: 'EventListController',
      role: ['controlPanel'],
      controllerAs: 'vm',
      params: {
        from: moment.utc().subtract(7,'day').format("DD-MM-YYYY"),
        to: moment.utc().format("DD-MM-YYYY")
      }
    });
}

routes.$inject = ['$stateProvider'];
