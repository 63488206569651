import {
  routes
} from './routes';
import ProviderService from './service';
import ProviderListController from './list/controller';
import ProviderDetailsController from './details/controller';

export default angular.module('app.provider', [])
  .config(routes)
  .service('ProviderService', ProviderService)
  .controller('ProviderListController', ProviderListController)
  .controller('ProviderDetailsController', ProviderDetailsController)
  .name;