export function routes($stateProvider) {
  $stateProvider
    .state('app.tariff', {
      url: '/tariff',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.tariff.list', {
      url: '?{page:int}&{term:string}&{order:string}&{sort:string}',
      template: require('./list/view.html'),
      controller: 'TariffListController',
      controllerAs: 'vm',
      role: ['readTariff']
    })
    .state('app.tariff.wizard', {
      url: '/wizard',
      template: require('./wizard/view.html'),
      controller: 'TariffWizardController',
      controllerAs: 'vm',
      role: ['readTariff']
    })
    .state('app.tariff.details', {
      url: '/:id',
      template: require('./details/view.html'),
      controller: 'TariffDetailsController',
      controllerAs: 'vm',
      role: ['editTariff']
    })
}

routes.$inject = ['$stateProvider'];
