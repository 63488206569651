export function routes($stateProvider) {

  $stateProvider
    .state('app.administration', {
      url: '/administration',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.administration.logging', {
      url: '/logging',
      template: require('./logging/logging.view.html'),
      controller: 'LoggingController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.payments', {
      url: '/payments?{page:int}&{order:string}&{sort:string}&{status:string}&{from:string}&{to:string}',
      template: require('./payment/view.html'),
      controller: 'AdminPaymentController',
      controllerAs: 'vm',
      role: ['controlPanel'],
      params: {
        from: moment.utc().startOf('day').subtract(7, 'day').format("DD-MM-YYYY"),
        to: moment.utc().endOf('day').format("DD-MM-YYYY")
      }
    })
    .state('app.administration.system', {
      url: '/system',
      template: require('./system/system.view.html'),
      controller: 'SystemController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.settings', {
      url: '/settings',
      template: require('./settings/view.html'),
      controller: 'SettingsController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.main', {
      url: '/',
      template: require('./main/main.view.html'),
      controller: 'ManagementController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.sockets', {
      url: '/ocpp',
      template: require('./sockets/view.html'),
      controller: 'AdminSocketController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.viewer', {
      url: '/chargers/status',
      template: require('./viewer/view.html'),
      controller: 'AdminViewerController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.users', {
      url: '/users',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.administration.users.list', {
      url: '?{page:int}&{order:string}&{sort:string}&{term:string}&{site:bool}',
      template: require('./users/list/view.html'),
      controller: 'AdminListUserController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.users.add', {
      url: '/add',
      template: require('./users/add/view.html'),
      controller: 'UserWizardController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.users.edit', {
      url: '/edit/:id',
      template: require('./main/users/edit/edit.view.html'),
      controller: 'UserEditAdminController',
      controllerAs: 'vm',
      role: ['controlPanel']

    })
    .state('app.administration.roles', {
      url: '/roles',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.administration.roles.edit', {
      url: '/edit/:id',
      template: require('./main/roles/edit/edit.view.html'),
      controller: 'RoleEditController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.roles.add', {
      url: '/add',
      template: require('./main/roles/add/add.view.html'),
      controller: 'RoleAddController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.evseProfiles', {
      url: '/ev-profiles?{page:int}&{order:string}&{sort:string}',
      template: require('./evse-profiles/view.html'),
      controller: 'EvseProfileListController',
      controllerAs: 'vm',
      role: ["paramConfig"]
    })
    ;
}

routes.$inject = ['$stateProvider'];
