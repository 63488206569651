export default class TransactionDetailsController {
  constructor($state, TransactionService, UIService) {
    this.$state = $state;
    this.TransactionService = TransactionService;
    this.UIService = UIService;
    this.id = $state.params.id;
    if (!$state.params.id) {
      this.UIService.addToast("Não foi possível carregar sessão de carga");
      $state.go("app.transaction.list");
    }
    this.unit = "a";
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.TransactionService.get(this.id)
      .then((r) => {
        this.data = r;
        this.loaded = true;
        this.createChartData();
      })
      .catch((e) => {
        console.log(e);
        this.UIService.addToast("Ocorreu um erro ao carregar sessão de carga");
        this.$state.go("app.transaction.list");
      });
  };

  getTension = () => {
    if (!this.loaded) {
      return;
    }
    let tension = this.data.consumption[1];
    if (tension == undefined) return "N/D";
    let t =
      (tension.ampsL1 > 0 ? 1 : 0) +
      (tension.ampsL2 > 0 ? 1 : 0) +
      (tension.ampsL3 > 0 ? 1 : 0);
    return t > 1 ? "Trifásico" : "Monofásico";
  };

  createChartData = () => {
    this.chartLoaded = false;
    if (!this.data.consumption.length) {
      this.UIService.addToast("Não é possível apresentar gráfico");
      this.chartLoaded = true;
      return;
    }
    let labels = [];
    let datasetsAmps = [];
    let dataAmps = [[], [], [], []];
    let datasetsKW = [];
    let dataKW = [[], [], [], []];
    let series = ["Total", "L1", "L2", "L3"];
    let colors = ["#0D47A1", "#875736", "#121315", "#7b7b7b"];

    let hexToRgb = (hex) =>
      hex
        .replace(
          /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
          (m, r, g, b) => "#" + r + r + g + g + b + b
        )
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16));

    let nearest = (val, nearest) =>
      parseInt(Math.ceil(val / nearest)) * nearest;
    let maxAmps = Math.max(...this.data.consumption.map((o) => o.amps)) || 30;
    let maxKw =
      Math.max(...this.data.consumption.map((o) => o.watts / 1000)) || 30;

    this.data.consumption.forEach((r) => {
      labels.push(moment(r.date));
      // Amps
      dataAmps[0].push(r.amps);
      dataAmps[1].push(r.ampsL1);
      dataAmps[2].push(r.ampsL2);
      dataAmps[3].push(r.ampsL3);
      // kW
      dataKW[0].push(r.watts / 1000);
      dataKW[1].push(r.wattsL1 / 1000);
      dataKW[2].push(r.wattsL2 / 1000);
      dataKW[3].push(r.wattsL3 / 1000);
    });

    dataAmps.forEach((r, i) => {
      datasetsAmps.push({
        label: series[i],
        data: r,
        backgroundColor: `rgba(${hexToRgb(colors[i]).join(",")},0.65)`,
        borderColor: colors[i],
        borderWidth: 2,
        pointColor: colors[i],
        pointBorder: colors[i],
        hoverColor: colors[i],
        yAxisID: `A`,
        pointRadius: 0,
        pointHoverRadius: 0,
        // Definir cor das linhas
        pointBackgroundColor: colors[i],
        pointHoverBorderColor: colors[i],
        pointHoverBackgroundColor: colors[i],
        order: i + 1,
      });
    });

    dataKW.forEach((r, i) => {
      datasetsKW.push({
        label: series[i],
        data: r,
        backgroundColor: `rgba(${hexToRgb(colors[i]).join(",")},0.65)`,
        borderColor: colors[i],
        borderWidth: 2,
        pointColor: colors[i],
        pointBorder: colors[i],
        hoverColor: colors[i],
        yAxisID: `kw`,
        pointRadius: 0,
        pointHoverRadius: 0,
        // Definir cor das linhas
        pointBackgroundColor: colors[i],
        pointHoverBorderColor: colors[i],
        pointHoverBackgroundColor: colors[i],
        order: i + 1,
      });
    });

    this.chart = [];
    for (let i = 0; i < series.length; i++) {
      this.chart.push({
        a: {
          series: series[i],
          labels: labels,
          data: dataAmps[i],
          datasets: datasetsAmps[i],
          options: {
            animation: false,
            legend: {
              display: true,
              position: "bottom",
            },
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  type: "time",
                  time: {
                    tooltipFormat: "DD-MM-YYYY HH:mm",
                    unit: "minute",
                    unitStepSize: 15,
                    displayFormats: {
                      hour: "HH:mm",
                      minute: "HH:mm",
                    },
                  },
                },
              ],
              yAxes: [
                {
                  id: "A",
                  display: true,
                  stacked: true,
                  ticks: {
                    min: 0,
                    max: nearest(maxAmps, 10),
                    stepSize: 10,
                    beginAtZero: true,
                    callback: function (value, index, values) {
                      return value + " A";
                    },
                  },
                },
              ],
            },
          },
        },
        kw: {
          series: series[i],
          labels: labels,
          data: dataKW[i],
          datasets: datasetsKW[i],
          options: {
            animation: false,
            legend: {
              display: true,
              position: "bottom",
            },
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  type: "time",
                  time: {
                    tooltipFormat: "DD-MM-YYYY HH:mm",
                    unit: "minute",
                    unitStepSize: 15,
                    displayFormats: {
                      hour: "HH:mm",
                      minute: "HH:mm",
                    },
                  },
                },
              ],
              yAxes: [
                {
                  id: "kw",
                  display: true,
                  stacked: true,
                  ticks: {
                    min: 0,
                    max: nearest(maxKw, 5),
                    stepSize: 5,
                    beginAtZero: true,
                    callback: function (value, index, values) {
                      return value + " kW";
                    },
                  },
                },
              ],
            },
          },
        },
      });
    }
    this.chartLoaded = true;
  };
}

TransactionDetailsController.$inject = [
  "$state",
  "TransactionService",
  "UIService",
];
