export default class EvseProfileDialogController {
  constructor($scope, data) {
    this.$scope = $scope;
    console.log(data);

    if (data == undefined) {
      this.data = {
        connectors: [],
        partNumbers: []
      };
    } else {
      this.data = data;
    }

    this.isAdding = false;
  }

  ok = () => {
    this.$scope.$close(this.data);
  }

  cancel = () => {
    this.$scope.$dismiss();
  }

  add = () => {
    this.isAdding = true;
    this.connector = {};
  }

  delete = (index) => {
    this.data.connectors.splice(index, 1);
  }

  save = () => {
    this.isAdding = false;
    this.data.connectors = this.data.connectors || [];
    let exists = this.data.connectors.find(r => r.connectorId == this.connector.connectorId);
    if (exists == undefined) {
      this.data.connectors.push(angular.copy(this.connector));
    }
    this.connector = {};
  }
}

EvseProfileDialogController.$inject = ['$scope', 'data']
