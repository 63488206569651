import angular from "angular";

export default class EntityDetailsController {
  constructor($state, $scope, UIService, EntityService) {
    this.$state = $state;
    this.$scope = $scope;
    this.UI = UIService;
    this.Entity = EntityService;
    // variables
    this.loaded = false;
    this.id = $state.params.id;

    if (this.id) {
      this.canAddContacts = true;
      this.loadData();
      this.label = "Editar";
    } else {
      this.data = {
        contacts: [],
        active: true,
      };
      this.label = "Adicionar";
      this.loaded = true;
      this.canAddContacts = false;
    }

    $scope.$watch(
      () => {
        return this.data;
      },
      (val) => {
        if (!this.loaded) {
          return;
        }
        if (val.type == 1) {
          if (val.serviceTax == undefined) {
            this.data.serviceTax = 5.0;
          }
          if (val.adminTax == undefined) {
            this.data.adminTax = 0.01;
          }
          if (val.dueDate == undefined) {
            this.data.dueDate = 1;
          }
        }
        if (!this.id && val && val.taxNumber) {
          if (val.taxNumber && val.taxNumber.length == 9 && !val.name) {
            this.Entity.suggest(val.taxNumber).then((r) => {
              if (r.result.includes("success")) {
                this.UI.showConfirm(
                  "Deseja carregar informação de NIF automaticamente?"
                ).then((res) => {
                  if (res) {
                    let fetched = r.records[val.taxNumber];
                    this.data.name = fetched.title;
                    this.data.location = fetched.place.city;
                    this.data.address = fetched.place.address;
                    this.data.zipcode = `${fetched.place.pc4}-${fetched.place.pc3}`;
                    if (fetched.contacts && fetched.contacts.website) {
                      this.data.url = fetched.contacts.website;
                    }
                  }
                });
              }
            });
          }
        }
      },
      true
    );
  }

  loadData = () => {
    this.Entity.get(this.id)
      .then((r) => {
        r.type = r.type || 0;
        this.data = r;
        this.loaded = true;
      })
      .catch(() => {
        this.$state.go("app.entity.list");
        this.UI.addToast("Ocorreu um erro ao carregar empresa");
      });
  };

  showCards = () => {
    return this.id != undefined;
  };

  sites = () => {
    this.$state.go("app.site.new({entityId: vm.data.id})");
  };

  removeSite = (site, $evt) => {
    $evt.stopPropagation();
    this.UI.showConfirm(`Deseja remover a instalação ${site.name}?`).then(
      (r) => {
        if (r) {
          let wait = this.UI.showWaiting();
          site.active = false;
          this.Entity.saveSite(site)
            .then((r) => {
              this.UI.addToast("Instalação removida com sucesso");
              wait.close();
              this.loadData();
            })
            .catch((e) => {
              wait.close();
              console.log(e);
            });
        }
      }
    );
  };

  contacts = (data, index) => {
    this.UI.showDialog({
      template: require("./contacts.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.editing = angular.isDefined(data);

          $scope.data = data || {};

          $scope.cancel = () => {
            $scope.$dismiss("cancel");
          };

          $scope.delete = () => {
            $scope.$close({
              action: "delete",
              data: data,
            });
          };

          $scope.ok = () => {
            $scope.$close({
              action: $scope.editing ? "edit" : "add",
              data: $scope.data,
            });
          };
        },
      ],
    }).then((res) => {
      if (res.action.includes("delete")) {
        this.data.contacts.splice(index, 1);
        this.data;
      } else if (res.action.includes("add")) {
        this.data.contacts = this.data.contacts || [];
        this.data.contacts.push(res.data);
      } else if (res.action.includes("edit")) {
        this.data.contacts[index] = res.data;
      }
    });
  };

  save = () => {
    this.Entity.save(this.data)
      .then((r) => {
        this.canAddContacts = true;
        this.UI.addToast("Alterações submetidas com sucesso");
        this.$state.go("app.entity.list");
      })
      .catch(() => {
        this.UI.addToast("Ocorreu um erro ao submeter alterações");
      });
  };

  createChartData = (row) => {
    // If we didn't receive any MeterValue.pdu during charging, we can only show total charge that is given by the StartTransaction.pdu and the StopTransaction.pdu
    if (!row.consumptionData) {
      row.chartAvailable = false;
    } else {
      row.chartAvailable = true;
      row.consumptionData.forEach((record) => {
        // A record can have multiple channels, ensure we have them all below (Battery % depends on charger)
        let kW = [
          "Rede (kW)",
          "L1 (kW)",
          "L2 (kW)",
          "L3 (kW)",
          "Limite",
          "Energia entregue (kW.h)",
          "Custo",
          "Voltagem",
          "L1 (V)",
          "L2 (V)",
          "L3 (V)",
          "Bateria (%)",
        ];
        let a = [
          "Rede (A)",
          "L1 (A)",
          "L2 (A)",
          "L3 (A)",
          "Limite",
          "Energia entregue (A.h)",
          "Custo",
          "Bateria (%)",
        ];
        //
      });
    }
  };
}

EntityDetailsController.$inject = [
  "$state",
  "$scope",
  "UIService",
  "EntityService",
];
