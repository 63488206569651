let XLSX = require('xlsx');
export default class ClientListController {
  constructor(ProfileService, AuthorizationService, AuthenticationService, UIService, Site, $state, $q) {
    this.Profile = ProfileService;
    this.Authorization = AuthorizationService;
    this.user = AuthenticationService.getUser();
    this.UI = UIService;
    this.Site = Site;
    this.$state = $state;
    this.$q = $q;
    this.filter = {
      page: $state.params.page || 1,
      term: $state.params.term || "",
      sort: "asc",
      order: "name"
    };
    this.loadData();
  }

  loadData = () => {
    this.$state.go("app.client.list", this.filter, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.Profile.list(this.createFilter()).then(r => {
      this.data = r.data;
      this.total = r.total;
      this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
      this.end = r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
      this.loaded = true;
    });
  };

  viewClient = row => {
    if (this.Authorization.canPerform(['readClient'])) {
      this.$state.go('app.client.details', {
        siteId: row.siteId,
        id: row.id
      })
    }
  };

  checkForAdministrator = () => {
    //check user permissions
    //if is administrator show disabled clients
    return this.Authorization.canPerform(['removeClient']);
  };

  createFilter = () => {
    let ob = {};
    let prop = "";
    let pattern = "";
    let where = {};

    if (!this.filter.term.isEmpty()) {
      // Filter by name
      prop = `name`;
      pattern = {
        like: `.*${this.filter.term}.*`,
        options: "i",
      };
      ob = {};
      ob[prop] = pattern;
      where = ob;
    }
    ob['active'] = true;
    where = ob;
    return {
      where: where,
      limit: 20,
      include: 'site',
      order: `${this.filter.order} ${this.filter.sort}`,
      skip: (this.filter.page - 1) * 20,
    };
  };

  clear = () => {
    this.filter.page = this.$state.params.page || 1;
    this.filter.term = "";
    this.filter.sort = "asc";
    this.filter.order = "name";
    this.loadData();
  };

  next = () => {
    if (this.end < this.total) {
      this.filter.page++;
    }
    this.loadData();
  };

  previous = () => {
    if (this.filter.page > 1) {
      this.filter.page--;
    }
    this.loadData();
  };

  // Calls modal to upload XLSX and import clients
  importProfiles = () => {
    let hold = this.UI.showWaiting();
    let sites = [];
    // Get sites to choose from
    this.Site.find({
      filter: {
        where: {
          active: true,
          entityId: { inq: this.user.entityIds }
        }
      }
    }).$promise.then((s) => {
      sites = s;
      hold.close();

      let type = 'profiles';
      let options = {
        size: 'lg',
        controller: 'ProfileUploaderController',
        controllerAs: 'm',
        template: require('./files.dialog.html'),
        resolve: {
          type: () => {
            return type;
          },
          sites: () => {
            return sites;
          }
        }
      };

      let dialog = this.UI.showDialog(options);
      dialog.then(res => {
        if (res) {
          let wait = this.UI.showWaiting();

          this.parseProfilesFile(type, res.item._file).then(dataFromFile => {
            // If we have empty clientsFromFile, deny going forward
            if (dataFromFile.profiles.length === 0) {
              wait.close();
              this.UI.showAlert("O ficheiro fornecido não contém perfis para importar.");
            } else {
              this.getDifferencesProfiles(dataFromFile.headers, dataFromFile.profiles, res.site).then(diffs => {
                wait.close();

                this.showConfirmationProfilesModal(diffs);

              }).catch(err => {
                console.log(err);
                this.UI.addToast("Não foi possível importar perfis. Por favor recarregue a página.");
              });
            }
          }).catch(err => {
            wait.close();
            console.log(err);
            this.UI.showAlert("Erro ao ler ficheiro de importação.\nVerifique se o ficheiro tem o formato apropriado.");
          });
        }
      });
    }).catch(() => {
      this.UI.addToast("Não foi possível consultar instalações para importar dados. Recarregue a página.");
    });

  };

  // Parse the XLSX file with profiles
  parseProfilesFile = (type, file) => {
    return new Promise((resolve, reject) => {

      // Parse file from modal
      let reader = new FileReader();
      reader.onload = (e) => {
        let data = e.target.result;
        let wb = XLSX.read(data, {
          type: 'binary'
        });
        // Use the first worksheet only if exists
        if (wb.SheetNames.length > 0) {
          let rowObj = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1 }); // Use all data from first sheet, including header

          // Remove empty lines
          _.remove(rowObj, c => c.length === 0);

          // Assume first line is the headers
          let headers = rowObj.shift();
          let profiles = [];

          // Fix date/time fields and add profile to list
          let profileStartDateIndex = headers.findIndex(x => x.toUpperCase() === 'EXPIRAÇÃO - INÍCIO');
          let profileEndDateIndex = headers.findIndex(x => x.toUpperCase() === 'EXPIRAÇÃO - FIM');
          let identifierStartDateIndex = headers.findIndex(x => x.toUpperCase() === 'IDENTIFICADOR EXPIRAÇÃO - INÍCIO');
          let identifierEndDateIndex = headers.findIndex(x => x.toUpperCase() === 'IDENTIFICADOR EXPIRAÇÃO - FIM');

          rowObj.forEach(r => {
            if (profileStartDateIndex >= 0) {
              r[profileStartDateIndex] = this.Profile.numberToDate(r[profileStartDateIndex]);
              if (r[profileStartDateIndex])
                r[profileStartDateIndex] = r[profileStartDateIndex].startOf('day');
            }
            if (profileEndDateIndex >= 0) {
              r[profileEndDateIndex] = this.Profile.numberToDate(r[profileEndDateIndex]);
              if (r[profileEndDateIndex])
                r[profileEndDateIndex] = r[profileEndDateIndex].endOf('day');

            }
            if (identifierStartDateIndex >= 0) {
              r[identifierStartDateIndex] = this.Profile.numberToDate(r[identifierStartDateIndex]);
              if (r[identifierStartDateIndex])
                r[identifierStartDateIndex] = r[identifierStartDateIndex].startOf('day');

            }
            if (identifierEndDateIndex >= 0) {
              r[identifierEndDateIndex] = this.Profile.numberToDate(r[identifierEndDateIndex]);
              if (r[identifierEndDateIndex])
                r[identifierEndDateIndex] = r[identifierEndDateIndex].endOf('day');
            }
            profiles.push(r);
          });

          // We need to make sure there's Name and Identifier in Profiles
          if (headers.findIndex(x => x.toUpperCase() === 'NOME *') < 0 || headers.findIndex(x => x.toUpperCase() === 'IDENTIFICADOR - Nº *') < 0) {
            reject();
          } else {
            // Resolves to:
            // Array of headers:
            // ['Nome *','Email','Descrição','Expiração - Início','Expiração - Fim','Identificador - Nº *', 'Identificador - Descrição', 'Identificador Expiração - Início', 'Identificador Expiração - Fim']
            // Array of arrays for profiles for header
            resolve({
              headers: headers,
              profiles: profiles
            });
          }
        } else {
          reject(); // Should never happen
        }
      };
      reader.onerror = (e => {
        reject(e);
      });
      // reader.readAsArrayBuffer(file);
      reader.readAsBinaryString(file);
    });
  };

  // Returns object with differences for changed or new identifiers
  getDifferencesProfiles = (headers, profilesFromFile, site) => {
    let defer = this.$q.defer();

    // Find index of Identifier - We can assume it's always there because we tested before
    if (headers.findIndex(x => x.toUpperCase() === 'NOME *') >= 0 && headers.findIndex(x => x.toUpperCase() === 'IDENTIFICADOR - Nº *') >= 0) {
      let nomeIndex = headers.findIndex(x => x.toUpperCase() === 'NOME *');
      let emailIndex = headers.findIndex(x => x.toUpperCase() === 'EMAIL');
      let descriptionIndex = headers.findIndex(x => x.toUpperCase() === 'DESCRIÇÃO');
      let startDateIndex = headers.findIndex(x => x.toUpperCase() === 'EXPIRAÇÃO - INÍCIO');
      let endDateIndex = headers.findIndex(x => x.toUpperCase() === 'EXPIRAÇÃO - FIM');
      let identifierIndex = headers.findIndex(x => x.toUpperCase() === 'IDENTIFICADOR - Nº *');
      let identifierDescriptionIndex = headers.findIndex(x => x.toUpperCase() === 'IDENTIFICADOR - DESCRIÇÃO');
      let identifierStartDateIndex = headers.findIndex(x => x.toUpperCase() === 'IDENTIFICADOR EXPIRAÇÃO - INÍCIO');
      let identifierEndDateIndex = headers.findIndex(x => x.toUpperCase() === 'IDENTIFICADOR EXPIRAÇÃO - FIM');

      if (nomeIndex < 0 || identifierIndex < 0) {
        defer.reject();
      }
      // Go through the array and see if we have already have the Identifier in the file. If so, add to an exclude array
      let usedIdentifiers = [];
      let excludeProfilesIndexes = [];

      profilesFromFile.forEach((c, i) => {
        if (!c[identifierIndex] || usedIdentifiers.find(n => n === c[identifierIndex])) { // Doesn't exist or already added, exclude
          excludeProfilesIndexes.push(i);
        } else { // Haven't found it yet, add it to the list and move on.
          usedIdentifiers.push(c[identifierIndex]);
        }
      });

      // Get the count for excluding clients
      let countExcludedProfiles = excludeProfilesIndexes.length;

      // Remove bad or repeated identifiers
      // Reverse to preserve indexes while removing
      excludeProfilesIndexes.reverse();
      for (let i = 0; i < excludeProfilesIndexes.length; i++) {
        profilesFromFile.splice(excludeProfilesIndexes[i], 1);
      }

      // See which identifiers already exist in the system. If if doesn't exist, means it's new Profile
      let identifiers = [];
      profilesFromFile.forEach(c => {
        identifiers.push(c[identifierIndex]);
      });

      this.Profile.findExisting(identifiers, site).then((existingIds) => {
        let newIdentifiers = identifiers.filter(x => !existingIds.includes(x));

        let newProfiles = [];
        let changedProfiles = [];
        profilesFromFile.forEach(p => {
          let profileObject = {
            siteId: site.id,
            name: p[nomeIndex],
            email: p[emailIndex],
            description: p[descriptionIndex],
            startDate: p[startDateIndex],
            endDate: p[endDateIndex],
            no: p[identifierIndex],
            identifierDescription: p[identifierDescriptionIndex],
            identifierStartDate: p[identifierStartDateIndex],
            identifierEndDate: p[identifierEndDateIndex]
          };
          if (newIdentifiers.find(x => x === p[identifierIndex]))
            newProfiles.push(profileObject);
          else if (existingIds.find(x => x === p[identifierIndex]))
            changedProfiles.push(profileObject);
        });

        defer.resolve({ new: newProfiles, changed: changedProfiles, excluded: countExcludedProfiles });
      }).catch(err => {
        console.log(err);
        defer.reject();
      });
    } else {
      defer.reject();
    }
    return defer.promise;
  };

  // Show modal with confirmation of changes to clausulas
  showConfirmationProfilesModal = async (diffs) => {

    let clientsTitle = 'Confirmação de Alterações - Perfis';
    let options = {
      size: 'lg',
      template: require('./profiles.confirm.dialog.html'),
      controller: ['$scope', function ($scope) {
        $scope.diffs = diffs;
        $scope.label = clientsTitle;
        $scope.showNew = false;
        $scope.showChanged = false;
        if (diffs.excluded > 0) { // If we have exclusions, construct the message
          if (diffs.excluded === 1) {
            $scope.excludedMessage = "NOTA: Foi excluído da importação 1 perfil com dados mal preenchidos ou repetidos.";
          } else {
            $scope.excludedMessage = `NOTA: Foram excluídos da importação ${diffs.excluded} perfis com dados mal preenchidos ou repetidos.`;
          }
        } else {
          $scope.excludedMessage = null;
        }

        // Called when clicking chevron
        $scope.expand = (x) => {
          switch (x) {
            case 'new':
              if ($scope.showNew) {
                $scope.showNew = !$scope.showNew;
              } else {
                $scope.showNew = true;
                $scope.showChanged = false;
                $scope.showRemoved = false;
              }
              break;
            case 'changed':
              if ($scope.showChanged) {
                $scope.showChanged = !$scope.showChanged;
              } else {
                $scope.showNew = false;
                $scope.showChanged = true;
                $scope.showRemoved = false;
              }
              break;
          }
        };

        $scope.ok = () => {
          $scope.$close(null);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    };

    let dialogConfirm = this.UI.showDialog(options);
    dialogConfirm.then(async ok => {
      // Do the changes to database
      let newProfilesPromise = this.Profile.createProfiles(diffs.new);
      let changedProfilesPromise = this.Profile.changeProfiles(diffs.changed);

      // Wait until all promises resolve or reject
      Promise.allSettled([newProfilesPromise, changedProfilesPromise]).then(counts => {
        wait.close();
        let alertString = "";
        let countAdded = counts[0].value ? counts[0].value.count : 0;
        let errorAdded = !counts[0].status.includes("fulfilled") || counts[0].value.error;
        let countChanged = counts[1].value ? counts[1].value.count : 0;
        let errorChanged = !counts[1].status.includes("fulfilled") || counts[1].value.error;

        alertString += "Perfis adicionados: " + countAdded + "\n";
        if (errorAdded) alertString += "Erro detetado na criação de perfis. Tente novamente\n";
        // alertString += "Perfis alterados: " + countChanged + "\n";
        // if (errorChanged) alertString += "Erro detetado na alteração de perfis. Tente novamente\n";

        this.UI.showAlert(alertString);
        this.loadData();
        if (!errorAdded && !errorChanged && (countAdded + countChanged) > 0)
          this.UI.addToast("Todas as alterações realizadas com sucesso");
      }).catch(err => {
        console.log(err);
      });
      this.loadData();
    }, (err) => {
      console.log(err);
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
    this.loadData();
  };
}

ClientListController.$inject = ['ProfileService', 'AuthorizationService', 'AuthenticationService', 'UIService', 'Site', '$state', '$q'];
