export default class AdminPaymentController {
  constructor($state, AdminService, UIService) {
    this.loaded = false;
    this.$state = $state;
    this.AdminService = AdminService;
    this.UI = UIService;

    this.status = [{
      id: 'pending', name: "Aguarda confirmação de pagamento",
    }, {
      id: 'cancelled', name: "Cancelado/Rejeitado",
    }, {
      id: 'received', name: "Pagamento recebido",
    }];
    this.AdminService.getUsers().then(r => {
      this.params = {
        users: r
      }
      this.filter = {
        page: $state.params.page || 1,
        sort: "desc",
        order: "date",
        status: $state.params.status,
        time: {
          from: moment.utc($state.params.from, 'DD-MM-YYYY'),
          to: moment.utc($state.params.to, 'DD-MM-YYYY')
        },
        users: r
      };
      this.loadData();
    });
  }

  clear = () => {
    this.filter = {
      page: 1,
      status: undefined,
      users: this.params.users,
      time: {
        from: moment.utc().subtract(7, 'day'),
        to: moment.utc()
      },
    };
    this.loadData();
  }

  poll = (id) => {
    this.loaded = false;
    this.AdminService.pollPayment(id).then(r => {
      this.loadData();
    });
    this.loadData();
  }

  createFilter = () => {

    let userIds = this.filter.users.map((r) => r.id);

    let where = {
      date: {
        between: [moment.utc(this.filter.time.from).startOf('day'), moment.utc(this.filter.time.to).endOf('day')],
      },
    };

    if (this.filter.status != undefined) {
      where.status = this.filter.status;
    }
    if (this.filter.users.length != this.params.users.length) {
      where.userId = { inq: userIds }
    }
    return {
      where: where,
      limit: 20,
      order: `${this.filter.order} ${this.filter.sort}`,
      include: 'user',
      skip: (this.filter.page - 1) * 15,
    };
  }

  loadData = () => {
    this.loaded = false;
    this.$state.go("app.administration.payments", this.filter, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });

    this.AdminService.getPayments(this.createFilter()).then(r => {
      this.total = r.total;
      this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
      this.end = r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
      this.end = this.end > this.total ? this.total : this.end;
      r.data.forEach(row => {
        row.lastUpdated = row.lastUpdated || row.date
      });
      this.data = r.data;
      this.loaded = true;
    }).catch(e => {
      this.loaded = true;
      this.UI.addToast("Ocorreu um erro ao carregar dados");
    });
  }

  getStatus = (status) => {
    return this.status.find(r => r.id == status).name;
  }

  next = () => {
    if (this.end < this.total) {
      this.filter.page++;
    }
    this.loadData();
  };

  previous = () => {
    if (this.filter.page > 1) {
      this.filter.page--;
    }
    this.loadData();
  };

  readableUserFilter = () => {
    if (!this.params) {
      return "";
    }
    if (this.filter.users.length === this.params.users.length) {
      return "Todos os utilizadores selecionados";
    }
    if (this.filter.users.length === 1) {
      return this.filter.users[0].name;
    } else {
      return `${this.filter.users.length} utilizadores selecionados`;
    }
  };

  filterUsers = () => {
    let list = angular.copy(this.params.users);
    let selected = angular.copy(this.filter.users);
    this.dialogFilter(list, selected, "name").then((r) => {
      this.filter.users = r;
    });
  };

  dialogFilter = (list, selected, property) => {
    return this.UI.showDialog({
      template: require("./select.dialog.html"),
      controller: [
        "$scope",
        "$filter",
        ($scope, $filter) => {
          // Arrays of data
          $scope.property = property;
          $scope.list = list || [];
          // Search filter
          $scope.filter = "";
          $scope.all = false;
          $scope.$watch(() => {
            return $scope.list;
          }, (val) => {
            console.log(val);
          });
          // Pagination
          $scope.pagination = {
            perPage: 10,
            current: 0,
            previous: () => {
              if ($scope.pagination.current > 0) $scope.pagination.current--;
            },
            onFilter: () => {
              const filteredArray = $filter("filter")(
                $scope.list,
                $scope.filter
              );
              const afterFilter =
                Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
              if (afterFilter < $scope.pagination.current) {
                $scope.pagination.current = afterFilter;
              }
            },
            next: () => {
              if ($scope.pagination.current < $scope.pagination.total() - 1)
                $scope.pagination.current++;
            },
            total: () => {
              const filteredArray = $filter("filter")(
                $scope.list,
                $scope.filter
              );
              return Math.ceil(
                filteredArray.length / $scope.pagination.perPage
              );
            },
          };

          $scope.select = function (obj) {
            obj.selected = !obj.selected;
          };

          $scope.cancel = function () {
            $scope.$dismiss("cancel");
          };

          $scope.canOk = () => {
            let selected = $scope.list.find((r) => r.selected);
            return selected != null;
          };

          $scope.ok = () => {
            let selected = $scope.list.filter((r) => r.selected);
            $scope.$close(selected);
          };
        },
      ],
    });
  };

  hasBilling = row => {
    let billing = row.billing;
    if (billing == undefined) {
      billing = row.user.billing;
    }
    return row.status == 'received' && billing != undefined;
  }

  paidButNoBilling = row => {
    let billing = row.billing;
    if (billing == undefined) {
      billing = row.user.billing;
    }
    return row.status == 'received' && billing == undefined;
  }

  getBilling = row => {
    if (!this.hasBilling(row)) {
      return;
    }

    if (row.status == 'received') {
      this.UI.showDialog({
        template: require('./billing.dialog.html'),
        controller: ['$scope', $scope => {
          $scope.data = row.billing;

          $scope.ok = () => {
            $scope.$dismiss();
          }
        }]
      });
    }
  }
}

AdminPaymentController.$inject = ['$state', 'AdministrationService', 'UIService']
