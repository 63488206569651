export default class EVSEDetailsContoller {
  constructor($state, $scope, UIService, Evse) {
    this.$state = $state;
    this.label = "";
    this.UI = UIService;
    this.Evse = Evse;
    this.id = $state.params.id;

    this.charts = {};
    $scope.$on('chart-create', (event, chart) => {
      this.charts[chart.chart.canvas.id] = chart;
      console.log(chart);
    });

    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.Evse.get(this.id).then(r => {
      this.label = r.alias;
      this.data = r;
      this.data.history.forEach(con => {
        this.Evse.getTransactionDetails(con.id).then(b => {
          con.chartData = b;
          this.createChartData(con);
        }).catch(e => {
          con.chartData = [];
        });
      });
      this.loaded = true;
    }).catch(e => {
      this.UI.addToast("Não foi possível obter sessões de carga do carregador");
      this.loaded = true;
    });
  }

  calcTotalDuration = row => {
    if (row.chartData) {
      let started = moment(row.begin);
      let ended = moment(started).add(row.chartData.currentTotalDurationSecs, 'seconds');
      let duration = ended.diff(started);
      let h = ended.diff(started, 'hours', true);
      if (h > 1) {
        return moment.utc(duration).format("HH[h] mm[m] ss[s]");
      } else {
        return moment.utc(duration).format("mm[m] ss[s]");
      }
    }
    return "-";
  }

  calcTotalConsumption = row => {
    if (row.chartData) {
      return `${row.chartData.currentTotalConsumptionWh.toFixed(1)} kWh`
    }
    return "-";
  }
  explain = (reason, property) => {
    let data = this.Evse.getReadableReason(reason);
    return data[property];
  }

  refresh = () => {
    this.loadData();
  }

  expand = row => {
    if (this.data.history.length === 0) {
      return;
    }
    this.data.history.forEach(r => {
      if (!r.id.includes(row.id)) {
        r.expanded = false;
      }
    });
    if (!row.hasOwnProperty("showKwChart")) {
      row.showKwChart = true;
    }
    row.expanded = !row.expanded;
  }

  createChartData = row => {
    // kW
    row.chartKw = {};
    row.chartKw.options = {
      datalabel: false,
      animation: false,
      legend: {
        onClick: (e, item) => {
          let id = e.srcElement.id;
          if (id) {
            let chart = this.charts[id];
            chart.data.datasets[item.datasetIndex].hidden = !chart.data.datasets[item.datasetIndex].hidden;
            if (chart.data.datasets[item.datasetIndex].hasOwnProperty('yAxisID')) {
              chart.scales[chart.data.datasets[item.datasetIndex].yAxisID].options.display = !chart.data.datasets[item.datasetIndex].hidden;
            }
            chart.update();
          }
        },
        display: true,
        position: 'bottom',
      },
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          type: 'time',
          distribution: 'series',
          time: {
            stepSize: 15,
            minUnit: 'minute',
            displayFormats: {
              minute: 'HH:mm',
            },
            tooltipFormat: "HH:mm"
          }
        }],
        yAxes: [{
            id: 'v',
            display: false,
            ticks: {
              stepSize: 50,
              beginAtZero: true,
              callback: function (value, index, values) {
                return value + 'V';
              }
            }
          },
          {
            id: 'kw',
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return value + 'kW';
              }
            },
          },
          {
            id: 'eur',
            display: false,
            position: 'right',
            ticks: {
              stepSize: 0.5,
              beginAtZero: true,
              callback: function (value, index, values) {
                return value.toFixed(2) + '€';
              }
            },
          }
        ]
      }
    }
    row.chartKw.series = [{
        label: 'Rede (kW)',
        field: 'watts',
        yAxisID: 'kw',
        pointRadius: 1
      }, {
        label: 'Rede L1 (kW)',
        field: 'wattsL1',
        yAxisID: 'kw',
        pointRadius: 1
      }, {
        label: 'Rede L2 (kW)',
        field: 'wattsL2',
        yAxisID: 'kw',
        pointRadius: 1
      }, {
        label: 'Rede L3 (kW)',
        field: 'wattsL3',
        yAxisID: 'kw',
        pointRadius: 1
      }, {
        label: 'Limite Rede (kW)',
        field: 'wattsLimit',
        yAxisID: 'kw',
        pointRadius: 1
      },
      {
        label: 'Energia transferida (kW.h)',
        field: 'energyDelivered',
        pointRadius: 1,
        yAxisID: 'kw',
      },
      {
        label: 'Custo (€)',
        field: 'cost',
        pointRadius: 1,
        yAxisID: 'eur'
      }, {
        label: 'Bateria (%)',
        field: 'stateOfCharge',
        pointRadius: 1
      }, {
        label: 'Tensão (V)',
        field: 'volts',
        yAxisID: 'v',
        steppedLine: true,
        pointRadius: 1
      }
    ];

    row.chartKw.data = [];
    row.chartKw.datasets = [];

    row.chartKw.series.forEach((serie, index) => {
      row.chartKw.data.push(row.chartData.values.map(b => {
        return {
          x: b.date,
          y: b[serie.field]
        }
      }));
      if (index > 0) {
        serie.hidden = true;
      }
      row.chartKw.datasets.push(serie);
    });

    // Amps
    row.chartAmps = {};
    row.chartAmps.options = {
      datalabel: false,
      animation: false,
      legend: {
        onClick: (e, item) => {
          let id = e.srcElement.id;
          if (id) {
            let chart = this.charts[id];
            chart.data.datasets[item.datasetIndex].hidden = !chart.data.datasets[item.datasetIndex].hidden;
            if (chart.data.datasets[item.datasetIndex].hasOwnProperty('yAxisID')) {
              chart.scales[chart.data.datasets[item.datasetIndex].yAxisID].options.display = !chart.data.datasets[item.datasetIndex].hidden;
            }
            chart.update();
          }
        },
        display: true,
        position: 'bottom',
      },
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          type: 'time',
          distribution: 'series',
          time: {
            stepSize: 15,
            minUnit: 'minute',
            displayFormats: {
              minute: 'HH:mm',
            },
            tooltipFormat: "HH:mm"
          }
        }],
        yAxes: [{
            id: 'v',
            display: false,
            ticks: {
              stepSize: 50,
              beginAtZero: true,
              callback: function (value, index, values) {
                return value + 'V';
              }
            }
          },
          {
            id: 'A',
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return value + 'A';
              }
            },
          },
          {
            id: 'eur',
            display: false,
            position: 'right',
            ticks: {
              stepSize: 0.5,
              beginAtZero: true,
              callback: function (value, index, values) {
                return value.toFixed(2) + '€';
              }
            },
          }
        ]
      }
    }
    row.chartAmps.series = [{
        label: 'Rede (A)',
        field: 'amps',
        yAxisID: 'A',
        pointRadius: 1
      }, {
        label: 'Rede L1 (A)',
        field: 'ampsL1',
        yAxisID: 'A',
        pointRadius: 1
      }, {
        label: 'Rede L2 (A)',
        field: 'ampsL2',
        yAxisID: 'A',
        pointRadius: 1
      }, {
        label: 'Rede L3 (A)',
        field: 'ampsL3',
        yAxisID: 'A',
        pointRadius: 1
      }, {
        label: 'Limite Rede (A)',
        field: 'ampsLimit',
        yAxisID: 'A',
        pointRadius: 1
      },
      {
        label: 'Energia transferida (A.h)',
        field: 'cumulativeAh',
        pointRadius: 1,
        yAxisID: 'A',
      },
      {
        label: 'Custo (€)',
        field: 'cost',
        pointRadius: 1,
        yAxisID: 'eur'
      }, {
        label: 'Bateria (%)',
        field: 'stateOfCharge',
        pointRadius: 1
      }, {
        label: 'Tensão (V)',
        field: 'volts',
        yAxisID: 'v',
        steppedLine: true,
        pointRadius: 1
      }
    ];

    row.chartAmps.data = [];
    row.chartAmps.datasets = [];

    row.chartAmps.series.forEach((serie, index) => {
      row.chartAmps.data.push(row.chartData.values.map(b => {
        return {
          x: b.date,
          y: b[serie.field]
        }
      }));
      if (index > 0) {
        serie.hidden = true;
      }
      row.chartAmps.datasets.push(serie);
    });
  }
}



EVSEDetailsContoller.$inject = ['$state', '$scope', 'UIService', 'EvseService'];
