import moment from "moment/moment";

export default class SiteService {
  constructor($q, AuthenticationService, Site, Access, Tariff) {
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.Site = Site;
    this.Access = Access;
    this.Tariff = Tariff;
  }

  consumption = (id, date) => {
    return this.Site.consumption({
      id: id,
      date: moment.utc(date).toISOString(),
    }).$promise;
  }

  giveAccess = (array, id) => {
    let defer = this.$q.defer();

    let promises = [];
    array.forEach(row => {
      promises.push(this.Access.create({
        userId: row.id,
        siteId: id,
        begin: moment.utc(),
        modified: moment.utc(),
        expires: false
      }));
    });

    this.$q.all(promises).then(_ => defer.resolve()).catch(e => defer.reject());

    return defer.promise;
  }

  list = (filter) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();

    filter.where.active = true;
    //filter correct entity
    filter.where.entityId = user.site.entityId;

    this.Site.count({
      where: filter.where
    }).$promise.then(c => {
      this.Site.find({
        filter: filter
      }).$promise.then(r => {
        return defer.resolve({
          total: c.count,
          data: r
        });
      }).catch(e => defer.reject(e));
    }).catch(e => defer.reject(e));
    return defer.promise;
  }

  listDropdown = (filter) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();

    //filter correct entity
    filter.where.entityId = user.site.entityId;

    this.Site.find({
      filter: filter
    }).$promise.then(r => {
      return defer.resolve(r);
    }).catch(e => defer.reject(e));
    return defer.promise;
  }

  get = id => {
    let defer = this.$q.defer();
    this.Site.findById({
      id: id,
      filter: {
        include: ["entity",
          {
            relation: "access",
            scope: {
              include: "user"
            }
          },
          {
            relation: "evses",
            scope: {
              include: "evseGroup"
            }
          }
        ]
      }
    }).$promise.then(r => {
      let index = r.access.length - 1;
      while (index >= 0) {
        if (r.access[index].user == undefined) {
          r.access.splice(index, 1);
        }
        index--;
      }
      defer.resolve(r);
    }).catch(e => defer.reject(e));

    return defer.promise;
  }

  save = data => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    if (!data.entityId)
      data.entityId = user.site.entityId;
    this.Site.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  removeUser = (userId, siteId) => {
    let defer = this.$q.defer();
    this.Access.find({
      filter: {
        where: {
          userId: userId,
          siteId: siteId
        },
        limit: 1
      }
    }).$promise.then(r => {
      if (r.length > 0) {
        this.Access.deleteById({ id: r[0].id }).$promise.then(r => {
          defer.resolve();
        }).catch(e => defer.reject(e));

      } else {
        defer.resolve();
      }
    }).catch(e => defer.resolve());
    return defer.promise;
  }

  updateUser = (data) => {
    return this.Access.upsert(data).$promise;
  }

  getTariffs = (entity) => {
    return this.Tariff.find({
      filter: {
        where: {
          entityId: entity
        }
      }
    }).$promise;
  }
}

SiteService.$inject = ['$q', 'AuthenticationService', 'Site', 'Access', 'Tariff'];
