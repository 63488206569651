export default class UIService {
  constructor($q, $rootScope, Toast, Snackbar, Dialog) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    $rootScope.status = {};
    this.Toast = Toast;
    this.Snackbar = Snackbar;
    this.Dialog = Dialog;
    this.sidebar = false;

    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      this.$rootScope.status = data;
    });
  }

  sidebarClick = () => {
    this.sidebar = !this.sidebar;
  };

  sidebarStatus = () => {
    return this.sidebar;
  };

  addToast = (message, length) => {
    this.Toast.add(message, length);
  };

  addToast = (message) => {
    this.Toast.add(message, this.Toast.LENGTH_SHORT);
  };

  addSnackbar = (message, action, dismissable) => {
    return this.Snackbar.add(message, action, dismissable);
  };

  showAlert = (message) => {
    return this.Dialog.alert(message);
  };

  showConfirm = (message) => {
    return this.Dialog.confirm(message);
  };

  showDialog = (options) => {
    return this.Dialog.dialog(options);
  };

  showWaiting = () => {
    return this.Dialog.waiting();
  };
}

UIService.$inject = ['$q', '$rootScope', 'Toast', 'Snackbar', 'Dialog'];
