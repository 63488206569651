import moment from "moment";

let jrQrcode = require("jr-qrcode");

export default class ProfileController {
  constructor(
    Identity,
    $timeout,
    $state,
    $location,
    $rootScope,
    $filter,
    AuthenticationService,
    AuthorizationService,
    UIService
  ) {
    this.Identity = Identity;
    this.t = AuthenticationService.getToken();
    this.$location = $location;
    this.AuthenticationService = AuthenticationService;
    this.AuthorizationService = AuthorizationService;
    this.UI = UIService;
    let success = $state.params.link;
    if (angular.isDefined(success)) {
      if (success) {
        AuthenticationService.getUserInfo(true);
        this.UI.addToast("Conta vinculada com sucesso");
      } else {
        this.UI.addToast("Erro ao vincular conta");
      }
      $timeout(() => {
        $state.go(
          "app.profile",
          {},
          {
            inherit: false,
            notify: false,
            reload: true,
          }
        );
      }, 100);
    }
    $rootScope.$on("$receivedServerStatus", (evt, data) => {
      this.description = data.description;
      this.providers = data.providers;
    });
    this.user = AuthenticationService.getUser();
    this.getBalance();

    // Update date difference in user profile box
    $timeout(() => {
      this.user.dateDiff = $filter("dateDiff")(this.user.lastLogin, false);
    }, 5 * 1000);
    this.user.dateDiff = $filter("dateDiff")(this.user.lastLogin, false);
  }


  getOperationName = op => {
    switch (op) {
      case 'chargement':
        return "Carregamento de Saldo";
      case 'service_tax':
        return "Taxa de Serviço";
      case 'transaction':
        return "Carregamento EV";
      default:
        return "";
    }
  }

  getType = type => {
    switch (type) {
      case 1:
        return "Condomínio";
      case 0:
        return "Empresa";
      case -1:
        return "Sistema";
      default:
        return 'Privado';
    }
  }

  getBalance = () => {
    this.filter = this.filter || {
      from: moment.utc().subtract(30, 'days'),
      to: moment.utc(),
    }
    this.loaded = false;
    this.AuthenticationService.getBalance(this.filter.from, this.filter.to).then(r => {
      this.loaded = true;
      this.balance = r.filter(row => row.updated != undefined);
    });
  }

  // Form for user password change
  changePassword = () => {
    let self = this;
    let options = {
      template: require("./change-password.html"),
      controller: [
        "$scope",
        "$dialog",
        function ($scope, $dialog) {
          $scope.ok = () => {
            self.AuthenticationService.changePassword(
              $scope.oldPassword,
              $scope.newPassword
            )
              .then(() => {
                $dialog.close();
              })
              .catch((err) => { });
          };

          $scope.cancel = () => {
            $dialog.dismiss();
          };
        },
      ],
    };
    this.UI.showDialog(options)
      .then(() => {
        this.UI.addToast("Password alterada");
      })
      .catch((err) => { });
  };

  setupIdentity = (identity) => {
    window.location.href = identity.url;
  };

  removeIdentity = (identity) => {
    this.Identity.deleteById({
      id: identity.id,
    }).$promise.then((r) => {
      this.UI.addToast("Conta desvinculada com sucesso");
      this.AuthenticationService.getUserInfo();
      this.user.identities.splice(identity, 1);
    });
  };

  identityHasPhoto = (identity, link) => {
    return identity && identity.profile && identity.profile.photos.length > 0;
  };

  usePhotoFrom = (identity, link) => {
    if (identity && identity.profile && identity.profile.photos.length > 0) {
      this.AuthenticationService.update({ photo: identity.profile.photos[0].value });
    }
  };

  getIdentity = (identity, link) => {
    // TODO: Handle different link paths (so far, just using Google)
    if (identity.profile) {
      return identity.profile.displayName;
    }
    return "";
  };

  getIdentityEmail = (identity, link) => {
    // TODO: Handle different link paths (so far, just using Google)
    if (identity.profile) {
      return identity.profile.emails[0].value;
    }
    return "";
  };

  containsIdentity = (identity) => {
    return angular.isDefined(
      this.user.identities.find((i) => i.provider === identity.name)
    );
  };

  // Form to enable 2FA
  setTwoFactor = () => {
    if (this.user.twofactor) {
      this.UI.showConfirm("Deseja desativar autenticação 2 passos?").then(
        (res) => {
          if (res) {
            this.AuthenticationService.update("twofactor", false).then(
              this.UI.addToast("Autenticação 2 passos desativada")
            );
            this.user.twofactor = false;
          }
        }
      );
    } else {
      let self = this;

      let options = {
        template: require("./two-factor.html"),
        controller: [
          "$scope",
          "$dialog",
          function ($scope, $dialog) {
            $scope.hasError = false;
            $scope.verify = "";

            $scope.validator = self.user.validator;

            $scope.$watch("verify", function (val) {
              if (val.length > 0) $scope.hasError = false;
            });

            $scope.drawQRCode = function () {
              let authUrl =
                "otpauth://totp/" +
                self.user.email +
                "?secret=" +
                self.user.validator +
                "&issuer=" +
                self.description +
                "&algorithm=SHA1&digits=6&period=30";
              return jrQrcode.getQrBase64(authUrl, {
                padding: 0,
                width: 180,
                height: 180,
                correctLevel: QRErrorCorrectLevel.M,
              });
            };

            $scope.cancel = function () {
              $dialog.dismiss("cancel");
            };

            $scope.ok = function () {
              self.AuthenticationService.twoFactorTest($scope.verify).then(
                (res) => {
                  if (res) {
                    $dialog.close(true);
                  } else {
                    $scope.hasError = true;
                    $scope.verify = "";
                  }
                }
              );
            };
          },
        ],
      };

      this.UI.showDialog(options).then((res) => {
        if (res) {
          this.UI.addToast("Autenticação 2 passos ativada");
          this.user.twofactor = true;
        }
      });
    }
  };

  save = () => {
    this.UI.showConfirm("Guardar alterações?").then(a => {
      if (a) {
        this.AuthenticationService.update({
          phone: this.user.phone,
          billing: this.user.billing
        }).then(r => {
          this.UI.addToast("Perfil atualizado com sucesso");
        }).catch(e => {
          this.UI.addToast("Não foi possível atualizar perfil")
        })
      }
    });
  }
}

ProfileController.$inject = [
  "Identity",
  "$timeout",
  "$state",
  "$location",
  "$rootScope",
  "$filter",
  "AuthenticationService",
  "AuthorizationService",
  "UIService",
];
