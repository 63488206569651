import angular from 'angular';
import {routes} from './administration.routes'

import ManagementController from './main/main.controller';
import UserAdminController from './main/users/add/add.controller';
import UserEditAdminController from './main/users/edit/edit.controller';
import RoleEditController from './main/roles/edit/edit.controller';
import AdministrationService from "./administration.service";
import RoleAddController from "./main/roles/add/add.controller";
import LoggingController from "./logging/logging.controller";
import SystemController from "./system/system.controller";
import SettingsController from './settings/controller';
import AdminPaymentController from './payment/controller';
import AdminListUserController from './users/list/controller';
import AdminSocketController from './sockets/controller';
import UserWizardController from './users/add/controller';
import EvseProfileListController  from './evse-profiles/controller';
import AdminUserService from './users/service';
import EvseProfileService from './evse-profiles/service';
import EvseProfileDialogController from './evse-profiles/dialog/controller';
import AdminViewerController from './viewer/controller';

export default angular.module('app.administration', [])
  .config(routes)
  .controller('ManagementController', ManagementController)
  .controller('LoggingController', LoggingController)
  .controller('SystemController', SystemController)
  .controller('UserAdminController', UserAdminController)
  .controller('RoleEditController', RoleEditController)
  .controller('RoleAddController', RoleAddController)
  .controller('AdminPaymentController', AdminPaymentController)
  .controller('AdminListUserController', AdminListUserController)
  .controller('UserEditAdminController', UserEditAdminController)
  .controller('UserWizardController', UserWizardController)
  .controller('AdminSocketController', AdminSocketController)
  .controller('SettingsController', SettingsController)
  .controller('EvseProfileListController', EvseProfileListController)
  .controller('EvseProfileDialogController', EvseProfileDialogController)
  .controller('AdminViewerController', AdminViewerController)
  .service('AdministrationService', AdministrationService)
  .service('AdminUserService', AdminUserService)
  .service('EvseProfileService', EvseProfileService)
  .filter('secondsToTime', function () {

    function padTime(t) {
      return t < 10 ? "0" + t : t;
    }

    return function (_seconds) {
      if (typeof _seconds !== "number" || _seconds < 0)
        return "00:00:00";

      var hours = Math.floor(_seconds / 3600),
        minutes = Math.floor((_seconds % 3600) / 60),
        seconds = Math.floor(_seconds % 60);

      return padTime(hours) + ":" + padTime(minutes) + ":" + padTime(seconds);
    };
  })
  .name;
