export default class TariffcycleService {
    constructor($q, AuthenticationService, Tariffcycle, Cycle) {
        this.$q = $q;
        this.Auth = AuthenticationService;
        this.Tariffcycle = Tariffcycle;
        this.Cycle = Cycle;
    }

    list = (filter) => {
        let defer = this.$q.defer();

        filter.include = ["cycle", "tariffs"];
        this.Tariffcycle.count({
            where: filter.where
        }).$promise.then(c => {
            this.Tariffcycle.find({
                filter: filter
            }).$promise.then(r => {
                return defer.resolve({
                    total: c.count,
                    data: r
                });
            }).catch(e => defer.reject(e));
        }).catch(e => defer.reject(e));
        return defer.promise;
    }

    cycles = () => {
        let defer = this.$q.defer();
        this.Cycle.find({})
            .$promise.then((r) => defer.resolve(r))
            .catch((e) => defer.reject(e));
        return defer.promise;
    };

    get = id => {
        let defer = this.$q.defer();
        this.Tariffcycle.findById({
            id: id,
            filter: {
                include: [
                    {
                        relation: "cycle"
                    }
                ]
            }
        }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
        return defer.promise;
    }

    save = data => {
        let defer = this.$q.defer();
        let user = this.Auth.getUser();
        if (!data.entityId)
            data.entityId = user.entityIds[0];
        this.Tariffcycle.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
        return defer.promise;
    }
}

TariffcycleService.$inject = ['$q', 'AuthenticationService', 'Tariffcycle', 'Cycle'];
