import site from "../site";

export default class AuthenticationService {
  constructor($rootScope, Users, LoopBackAuth, $q, $state, $cookies, Balance) {
    this.$rootScope = $rootScope;
    this.Users = Users;
    this.LoopBackAuth = LoopBackAuth;
    this.$q = $q;
    this.$cookies = $cookies;
    this.user = {};
    this.allowed = {
      access: false,
    };
    this.Balance = Balance;
    this.$state = $state;
    if (this.isAuthenticated()) {
      $cookies.put("access_token", LoopBackAuth.accessTokenId);
      $cookies.put("userId", LoopBackAuth.currentUserId);
    }
    if (this.isAuthenticated() && !localStorage.getItem("user")) {
      this.allowed.access = true;
      this.user.id = LoopBackAuth.currentUserId;
      localStorage.setItem("allowed", JSON.stringify(this.allowed));
      this.getUserInfo(true);
    } else if (localStorage.getItem("user") && this.isAuthenticated()) {
      this.allowed = JSON.parse(localStorage.getItem("allowed"));
      this.user = JSON.parse(localStorage.getItem("user"));
      this.getUserInfo();
    }
  }

  // Attempt login with given email and password
  login = (email, password) => {
    let deferred = this.$q.defer();

    this.Users.login(
      {
        rememberMe: true,
      },
      {
        email,
        password,
      }
    )
      .$promise.then((res) => {
        this.Users.findById({
          id: res.user.id,
          filter: {
            include: [
              {
                relation: "site",
                scope: {
                  include: "entity"
                }
              },
              {
                relation: "groups",
                scope: {
                  include: {
                    relation: "rolegroup",
                    scope: {
                      where: {
                        active: true,
                      },
                      include: {
                        relation: "role",
                      },
                    },
                  },
                },
              },
            ],
          },
        }).$promise.then((user) => {
          this.allowed.access = !user.twofactor;
          this.user = user;
          this.active = user.active;
          if (this.active) {
            localStorage.setItem("user", JSON.stringify(this.user));
            localStorage.setItem("allowed", JSON.stringify(this.allowed));
            if (this.allowed.access) {
              location.reload(true); // Solution proposed by @antonio.gomes, this fixes the bug that requires F5 to reload jQuery
            } else {
              deferred.resolve(this.user);
            }
          } else {
            deferred.resolve();
          }
        });
      })
      .catch((err) => {
        deferred.reject(err);
      });
    return deferred.promise;
  };

  // Logouts user and invalidates access token in the server
  logout = () => {
    localStorage.removeItem("user");
    this.Users.logout()
      .$promise.then(() => {
        this.clearUser();
        location.reload(true); // Solution proposed by @antonio.gomes, this fixes the bug that requires F5 to reload jQuery
      })
      .catch((err) => {
        // In case of error ignore, since it will be catched by the Authentication Handler
        this.clearUser();
        location.reload(true);
      });
  };

  // Clears cache that saves user data
  clearUser = () => {
    this.LoopBackAuth.clearUser();
    this.LoopBackAuth.clearStorage();
    this.LoopBackAuth.currentUserId = null;
    this.LoopBackAuth.accessTokenId = null;
    this.LoopBackAuth.save();
    delete this.$cookies["access_token"];
    this.allowed.access = false;
    localStorage.removeItem("user");
    localStorage.removeItem("allowed");
  };

  // Get authenticated user access token
  getToken = () => {
    return this.LoopBackAuth.accessTokenId;
  };

  // Get authenticated user Id
  getId = () => {
    return this.user.id;
  };

  // Retrieve user information in cache
  getUser = () => {
    return this.user;
  };

  // Handle oauth authentication
  socialLogin = (token, userId) => {
    this.LoopBackAuth.currentUserId = userId;
    this.LoopBackAuth.accessTokenId = token;
    this.LoopBackAuth.save();
    this.getUserInfo(true);
  };


  getBalance = (from, to) => {
    let defer = this.$q.defer();
    this.Balance.find({
      filter: {
        where: {
          userId: this.user.id,
          timestamp: {
            between: [from, to]
          }
        },
        include: {
          relation: 'site',
          scope: {
            include: 'entity'
          }
        },
        order: 'timestamp DESC'
      }
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  // Retrieve user information from the server
  getUserInfo = (reload) => {
    this.Users.findById({
      id: this.user.id,
      filter: {
        include: [
          {
            relation: "site",
            scope: {
              include: "entity"
            }
          },
          {
            relation: "groups",
            scope: {
              include: {
                relation: "rolegroup",
                scope: {
                  where: {
                    active: true,
                  },
                  include: {
                    relation: "role",
                  },
                },
              },
            },
          },
        ],
      },
    })
      .$promise.then((user) => {
        this.user = user;
        localStorage.setItem("user", JSON.stringify(this.user));
        if (reload) {
          location.reload(true);
        }
      })
      .catch((err) => {
        this.logout();
      });
  };

  // Ask server to validate the access token in cache
  isAuthenticated = () => {
    return this.Users.isAuthenticated();
  };

  // Updates specified field of user with given value
  update = (data) => {
    let defer = this.$q.defer();
    Object.keys(data).forEach(r => {
      this.user[r] = data[r];
    });
    this.user
      .$save()
      .then((r) => {
        this.getUserInfo();
        defer.resolve(r);
      })
      .catch((err) => defer.reject(err));
    return defer.promise;
  };

  // Tests 2FA token, if result it's true, enable for user
  twoFactorTest = (_token) => {
    let defer = this.$q.defer();
    let token = Number(_token);
    this.Users.verifyToken({
      token: token,
    })
      .$promise.then((res) => {
        if (res.result) {
          defer.resolve(true);
          this.update("twofactor", true);
        } else {
          defer.resolve(false);
        }
      })
      .catch((err) => defer.reject(err));
    return defer.promise;
  };

  // Checks if user token is valid, and enables access to app
  twoFactor = (_token) => {
    let defer = this.$q.defer();
    let token = Number(_token);
    this.Users.verifyToken({
      token: token,
    })
      .$promise.then((res) => {
        if (res.result) {
          this.allowed.access = true;
          localStorage.setItem("allowed", JSON.stringify(this.allowed));
          defer.resolve(true);
        } else {
          defer.resolve(false);
        }
      })
      .catch((err) => defer.reject(err));
    return defer.promise;
  };

  // If access is enabled (Enabled automatically if account has 2FA turned off)
  isAllowed = () => {
    return this.allowed.access;
  };

  // Change password
  changePassword = (oldPassword, newPassword) => {
    let defer = this.$q.defer();

    this.Users.changePassword({
      id: this.getId(),
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
      .$promise.then((res) => {
        defer.resolve(res);
      })
      .catch((err) => {
        defer.reject(err);
      });

    return defer.promise;
  };

  updateEntity = (entity) => {
    let defer = this.$q.defer();
    this.Users.prototype$patchAttributes(
      {
        id: this.user.id,
      },
      {
        entityIds: [entity.id],
      }
    ).$promise.then((r) => {
      this.user.entityIds = [entity.id];
      this.user.entities = [entity];
      localStorage.setItem("user", JSON.stringify(this.user));
      this.getUserInfo(false);
      this.$rootScope.$broadcast("$entityUpdate");
    });
    return defer.promise;
  };

  updateSite = (site) => {
    let defer = this.$q.defer();
    this.Users.prototype$patchAttributes(
      {
        id: this.user.id,
      },
      {
        siteId: site.id
      }
    ).$promise.then((r) => {
      this.user.siteId = site.id;
      this.user.entityId = site.entityId;
      this.user.site = site;
      localStorage.setItem("user", JSON.stringify(this.user));
      this.getUserInfo(false);
      this.$rootScope.$broadcast("$entityUpdate");
    });
    return defer.promise;
  };

  resetPassword = (email) => {
    let defer = this.$q.defer();
    this.Users.resetPassword({ email: email })
      .$promise.then((res) => {
        defer.resolve(res);
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  };

  setPasswordToken = (token, password) => {
    let defer = this.$q.defer();
    this.Users.setPassword(
      {
        access_token: token,
      },
      {
        newPassword: password,
      }
    )
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

AuthenticationService.$inject = [
  "$rootScope",
  "User",
  "LoopBackAuth",
  "$q",
  "$state",
  "$cookies",
  "Balance"
];
