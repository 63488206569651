export function routes($stateProvider) {
  $stateProvider
    .state('app.client', {
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.client.list', {
      url: '/client?{page:int}&{order:string}&{sort:string}&{term:string}',
      template: require('./list/view.html'),
      controller: 'ClientListController',
      controllerAs: 'vm',
      role: ['readClient']
    })
    .state('app.client.details', {
      url: '/site/{siteId}/client/?{id}',
      template: require('./details/view.html'),
      controller: 'ClientDetailsController',
      controllerAs: 'vm',
      role: ['readClient']
    });
}

routes.$inject = ['$stateProvider'];
