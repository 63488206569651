export default class SettingsController {
  constructor($state, UIService, AdministrationService) {
    this.$state = $state;
    this.UI = UIService;
    this.Administration = AdministrationService;

    this.groups = [
      {
        name: "Sistema",
        id: "System",
      },
      {
        name: "Carregadores",
        id: "EVSE",
      },
      {
        name: "Tarifário de venda",
        id: "vending"
      }
    ];

    this.loadData();
  }

  loadData = () => {
    this.Administration.getSettings().then((data) => {
      this.settings = data;
    });
  };

  showValue = (setting) => {
    if (setting.type.contains(["number", "text"])) {
      return setting.value;
    }
    return "Binário";
  };

  edit = (setting) => {
    this.UI.showDialog({
      template: require("./dialog.html"),
      controller: [
        "$scope",
        "FileUploader",
        ($scope, FileUploader) => {
          $scope.data = setting;

          $scope.ok = () => {
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };

          // Handle JSON type file

          $scope.jsonUploader = new FileUploader({
            url: "/",
            queueLimit: 1,
          });

          $scope.jsonUploader.onWhenAddingFileFailed = (item, filter) => {
            if (filter.name == "queueLimit") {
              $scope.jsonUploader.clearQueue();
              $scope.jsonUploader.addToQueue(item);
            }
          };

          $scope.jsonUploader.onAfterAddingFile = (file) => {
            let fileReader = new FileReader();
            fileReader.onload = (ev) => {
              try {
                $scope.data.value = JSON.parse(ev.target.result);
                console.log($scope.data.value);
              } catch (e) {
                console.log(e);
              }
            };
            fileReader.readAsText(file._file);
          };
        },
      ],
    })
      .then((r) => {
        this.Administration.saveSetting(r).then((r) => {
          this.UI.addToast("Parâmetro atualizado");
        });
      })
      .catch((e) => {
        // Do nothing, user pressed button "CANCEL"
      });
  };
}

SettingsController.$inject = ["$state", "UIService", "AdministrationService"];
