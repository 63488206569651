export default class ProviderService {
  constructor($q, AuthenticationService, Site, Provider, Evse) {
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.Provider = Provider;
  }

  list = (filter) => {
    let defer = this.$q.defer();

    this.Provider.count({
      where: filter.where,
    }).$promise.then(c => {
      this.Provider.find({
        filter: filter
      }).$promise.then(r => {
        return defer.resolve({
          total: c.count,
          data: r
        });
      }).catch(e => defer.reject(e));
    }).catch(e => defer.reject(e));

    return defer.promise;
  }

  get = id => {
    let defer = this.$q.defer();
    this.Provider.findById({
      id: id,
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  save = data => {
    let defer = this.$q.defer();
    this.Provider.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  remove = data => {
    let defer = this.$q.defer();
    this.Provider.delete(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

}

ProviderService.$inject = ['$q', 'AuthenticationService', 'Site', 'Provider', 'Evse'];
