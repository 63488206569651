export default class RoleEditController {
  constructor($state, $stateParams, AdministrationService, AuthenticationService, AuthorizationService, UIService, $interval, $filter) {
    this.$state = $state;
    this.Administration = AdministrationService;
    this.Authentication = AuthenticationService;
    this.Authorization = AuthorizationService;
    this.UI = UIService;
    this.$interval = $interval;
    this.$filter = $filter;

    this.features = [];
    this.fIndex = 0;
    this.roleInfo = {};
    this.users = [];

    this.isEditing = false;
    this.loaded = false;

    if (angular.isUndefined($stateParams.id)) {
      $state.go('app.administration.main');
    }

    this.init($stateParams.id);
  }

  enableEditing = () => {
    if (!this.isEditing) {
      this.UI.addToast("A editar cargo");
    }
    else {
      this.saveGroup(this.roleInfo, this.features, this.users);
    }
    this.isEditing = !this.isEditing;
  };

  init = (id) => {
    this.getRoleData(id);
  };

  /* Role handling */
  getRoleData = (id) => {
    if (_.isEmpty(this.roleInfo)) {
      // Update date difference
      this.$interval(() => {
        this.roleInfo.dateDiff = this.$filter('dateDiff')(this.roleInfo.edited, false);
      }, 5 * 1000);
    }
    this.loaded = false;
    this.Administration.getGroupInfo(id).then(role => {
      this.roleInfo = role;
      this.Administration.getGroupRoles(id).then(features => {
        // Doing some mambo-jambo for view to work
        this.features = _(features)
          // Group by featureId
          .groupBy(f => f.role.featureId)
          // Remap array to be in format k,v where k = feature and v = roles
          .map((value, key) => ({
            feature: _.head(value).role.feature, // feature = the feature of the first role grouped
            roles: value
          }))
          .value();
        // Users
        this.users = angular.copy(this.roleInfo.users);
        this.loaded = true;
        this.roleInfo.dateDiff = this.$filter('dateDiff')(this.roleInfo.edited, false);
      });
    });
  };

  saveGroup = (info, roles, users) => {
    // Update more info
    let cenas = this.UI.showWaiting();
    this.Administration.updateGroup(info, roles, users).then(() => {
      cenas.close();
      this.UI.addToast('Alteração efetuada');
    });
  };

  removeGroup = () => {
    this.Administration.groupHasUsers(this.roleInfo.id).then((res) => {
      if (!res) {
        // Group has no members, allow remove
        let confirm = this.UI.showConfirm("Deseja remover o cargo?");
        confirm.then((res) => {
          if (res) {
            this.Administration.deleteGroup(this.roleInfo.id).then(() => {
              this.UI.addToast('Cargo removido');
              this.$state.go('app.administration.main');
            });
          }
        });
      } else {
        this.UI.addToast('Remova utilizadores do cargo previamente');
      }
    })
  };

  /* User handling */

  isUser = (data) => {
    return data.id === this.Authentication.getId();
  };

  showUserDialog = () => {
    console.log(this.users);
    // Obtem lista de users não mapeados à role
    this.Administration.getUsersNotInRole(this.roleInfo.id).then((list) => {
      if (_.isEmpty(list)) {
        this.UI.showAlert('Não existem utilizadores disponíveis');
        return;
      }
      list = list.filter(r => {
        return this.users.find(u => r.id === u.id) === undefined;
      });
      let options = {
        size: 'lg',
        template: require('./user-dialog.html'),
        controller: ['$scope', '$filter', '$dialog', ($scope, $filter, $dialog) => {
          // Arrays of data
          $scope.available = list;
          $scope.selected = [];
          // Search filter
          $scope.filter = '';
          // Pagination
          $scope.pagination = {
            perPage: 5,
            current: 0,
            previous: () => {
              if ($scope.pagination.current > 0)
                $scope.pagination.current--;
            },
            onFilter: () => {
              const filteredArray = $filter('filter')($scope.available, $scope.filter);
              const afterFilter = Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
              if (afterFilter < $scope.pagination.current) {
                $scope.pagination.current = afterFilter;
              }
            },
            next: () => {
              if ($scope.pagination.current < $scope.pagination.total() - 1)
                $scope.pagination.current++;
            },
            total: () => {
              const filteredArray = $filter('filter')($scope.available, $scope.filter);
              return Math.ceil(filteredArray.length / $scope.pagination.perPage);
            }
          };

          $scope.select = function (user) {
            user.oldIdx = $scope.available.indexOf(user);
            $scope.selected.push(user);
            $scope.available.splice(user.oldIdx, 1);
          };

          $scope.pop = function (user) {
            $scope.selected.splice($scope.selected.indexOf(user), 1);
            $scope.available.splice(user.oldIdx, 0, user);
          };

          $scope.ok = function () {
            $dialog.close($scope.selected);
          };

          $scope.cancel = function () {
            $dialog.dismiss('cancel');
          };


        }]
      };

      let modal = this.UI.showDialog(options);

      modal.then((results) => {
        results.forEach(r => {
          r.selected = false;
          this.users.push(r);
        });
      });
    });
  };

  hasUsersSelected = () => {
    return this.users.some(user => {
      return user.selected === true;
    });
  };

  removeUsers = () => {
    this.users = _.reject(this.users, user => {
      return user.selected === true;
    });
  };
};

RoleEditController.$inject = ['$state', '$stateParams', 'AdministrationService', 'AuthenticationService', 'AuthorizationService', 'UIService', '$timeout', '$filter'];
