export default class UserWizardController {
  constructor($state, UIService, AdminUserService) {
    this.$state = $state;
    this.UI = UIService;
    this.cardsLoaded = true;
    this.Admin = AdminUserService;

    this.loaded = true;

    this.type = 'password';
    this.iconType = 'mdi-lock';

    this.data = {
      user: {
        email: '',
        phone: '',
        name: '',
        password: '',
        cards: [],
        siteId: '',
        billing: {}
      },
      access: [],
      tenants: []
    };
    this.entities = [];
  }

  validate = () => {

    let checkIfEmail = (str) => {
      // Regular expression to check if string is email
      const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

      return regexExp.test(str);
    };

    if (!this.data.user.name) {
      this.UI.addToast("Introduza o nome do utilizador");
      return false;
    }
    if (!this.data.user.email || !checkIfEmail(this.data.user.email)) {
      this.UI.addToast("Introduza um email válido");
      return false;
    }
    if (!this.data.user.phone) {
      this.UI.addToast("Introduza o número de telemóvel");
      return false;
    }
    if (!this.data.user.password) {
      this.UI.addToast("Introduza uma password para o utilizador");
      return false;
    }
    if (this.data.user.password.length < 8) {
      this.UI.addToast("O password introduzida não é segura (mínimo 8 caracteres)");
      return false;
    }
    // Verifica se tem entidades associadas
    if (this.data.tenants.length == 0) {
      this.UI.addToast("O utilizador não tem nenhum entidade associada");
      return false;
    }
    // Verifica se tem instalações autorizadas
    this.entities.forEach(e => {
      // Verifica se a entidade foi removida da lista, se sim, remove os acessos da instalação
      let exists = this.data.tenants.find(r => r.id == e.id);
      if (exists == undefined) {
        e.sites.forEach(r => {
          if (this.isPicked(r)) {
            this.data.access = this.data.access.filter(a => a != r.id);
          }
        });
      }
    });
    if (this.data.access.length == 0) {
      this.UI.addToast("O utilizador não tem acesso a nenhuma instalação");
      return false;
    }
    if (!this.data.user.siteId) {
      this.data.user.siteId = this.data.access[0];
    } else {
      // Verifica se a instalação existe na lista
      let exists = this.data.access.find(r => r == this.data.user.siteId);
      if (!exists) {
        this.data.user.siteId = this.data.access[0];
      }
    }
    // Define saldo a 0.00 caso não esteja preenchido
    this.data.user.balance = this.data.user.balance || 0;
    return true;
  }

  submit = () => {
    let result = this.validate();
    if (result) {
      console.log(this.data);
      this.Admin.create(this.data).then(r => {
        this.UI.addToast("Utilizador registado");
        this.$state.go('app.administration.users.edit', {id: r.id});
      }).catch(e => {
        console.log(e);
        this.UI.addToast("Falha ao registar utilizador");
      })
    }
  }

  randomizePassword = () => {
    let chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
    let pass = "";
    for (let x = 0; x < 12; x++) {
      let i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    this.data.user.password = pass;
  };

  showPassword = () => {
    if (this.type.includes('password')) {
      this.type = 'text';
      this.iconType = 'mdi-lock-open';
    } else {
      this.type = 'password';
      this.iconType = 'mdi-lock';
    }
  };

  manageCard = (row, index) => {
    this.cardsLoaded = false;
    this.UI.showDialog({
      template: require('./card.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.ok = () => {
          $scope.data.no = $scope.data.no.toUpperCase();
          $scope.$close($scope.data);
        }

        $scope.delete = () => {
          this.UI.showConfirm("Tem a certeza que deseja eliminar identificador?").then(r => {
            $scope.$close(undefined);
          });
        }

        $scope.cancel = () => { $scope.$dismiss(); }
        if (row) {
          $scope.isEdit = true;
          $scope.data = {
            no: row.no,
            description: row.description,
            blocked: row.blocked
          }
        }
      }]
    }).then(r => {
      if (r == undefined && index != undefined) {
        this.data.user.cards.splice(index, 1);
        this.UI.addToast("Identificador eliminado");
      } else {
        if (row != undefined) {
          this.data.user.cards[index] = r;
          this.UI.addToast("Identificador atualizado");
        } else {
          // Verifica se identificador já existe
          this.Admin.identifierExists(r).then(status => {
            if (status == true) {
              this.data.user.cards = this.data.user.cards || [];
              this.data.user.cards.push(r);
              this.UI.addToast("Identificador adicionado");
            } else {
              this.UI.addToast("Identificador já associado a outro utilizador");
            }
          }).catch(e => {
            this.UI.addToast("Erro ao ligar ao servidor, tente novamente");
          });

        }

      }
    }).catch(e => {
      this.cardsLoaded = true;
    });
  }

  showUserDialog = () => {
    let list = [];
    let ids = this.data.tenants.map(r => r.id);
    let options = {
      size: 'lg',
      template: require('./list.dialog.html'),
      controller: ['$scope', '$filter', 'Entity', ($scope, $filter, Entity) => {
        $scope.loaded = false;

        Entity.find({
          filter: {
            where: {
              id: {
                inq: ids
              }
            }
          }
        }).$promise.then(a => {
          $scope.selected = a;
          Entity.find({
            filter: {
              where: {
                id: {
                  nin: ids
                }
              }
            }
          }).$promise.then(r => {
            list = r;
            $scope.available = list;
            $scope.loaded = true;
          });
        })
        // Search filter
        $scope.filter = '';
        // Pagination
        $scope.pagination = {
          perPage: 5,
          current: 0,
          previous: () => {
            if ($scope.pagination.current > 0)
              $scope.pagination.current--;
          },
          onFilter: () => {
            const filteredArray = $filter('filter')($scope.available, $scope.filter);
            const afterFilter = Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
            if (afterFilter < $scope.pagination.current) {
              $scope.pagination.current = afterFilter;
            }
          },
          next: () => {
            if ($scope.pagination.current < $scope.pagination.total() - 1)
              $scope.pagination.current++;
          },
          total: () => {
            const filteredArray = $filter('filter')($scope.available, $scope.filter);
            return Math.ceil(filteredArray.length / $scope.pagination.perPage);
          }
        };

        $scope.select = function (user) {
          user.oldIdx = $scope.available.indexOf(user);
          $scope.selected.push(user);
          $scope.available.splice(user.oldIdx, 1);
        };

        $scope.pop = function (user) {
          $scope.selected.splice($scope.selected.indexOf(user), 1);
          $scope.available.splice(user.oldIdx, 0, user);
        };

        $scope.ok = function () {
          $scope.$close($scope.selected);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };


      }]
    };

    this.UI.showDialog(options).then((results) => {
      this.data.tenants = results;
    });
  };

  loadEntitySites = () => {
    this.entityLoaded = false;
    this.entitySelected = this.entitySelected || 0;
    this.Admin.pickTenantSites(this.data.tenants.map(r => r.id), true).then(r => {
      this.entities = r;
      this.entityLoaded = true;
    });
  }

  isNoSites = () => {
    let picked = 0;
    this.entities[this.entitySelected].sites.forEach(site => {
      if (this.isPicked(site)) {
        picked++;
      }
    });
    return picked == 0;
  }

  isAllSites = () => {
    let picked = 0;
    this.entities[this.entitySelected].sites.forEach(site => {
      if (this.isPicked(site)) {
        picked++;
      }
    });
    let length = this.entities[this.entitySelected].sites.length;
    return picked == length;
  }

  isSomeSites = () => {
    let picked = 0;
    this.entities[this.entitySelected].sites.forEach(site => {
      if (this.isPicked(site)) {
        picked++;
      }
    });
    let length = this.entities[this.entitySelected].sites.length;
    return picked < length && picked > 0;
  }

  pickAll = () => {
    this.entities[this.entitySelected].sites.forEach(r => {
      if (!this.isPicked(r)) {
        this.data.access.push(r.id);
      }
    });
    this.UI.addToast(`Autorizado a todas as instalações`);
  }

  pickNone = () => {
    this.entities[this.entitySelected].sites.forEach(r => {
      this.data.access = this.data.access.filter(a => a != r.id);
    });
    this.UI.addToast(`Removido acesso a todas as instalações`);
  }

  pick = () => {
    if (this.isAllSites()) {
      this.pickNone();
    } else {
      this.pickAll();
    }
  }

  isPreferedSite = site => {
    return this.data.user.siteId == site.id;
  }

  setPreferedSite = site => {
    if (site) {
      this.data.user.siteId = site.id;
      if (!this.isPicked(site)) {
        this.data.access.push(site.id)
      }
    } else {
      this.data.user.siteId = undefined;
    }
  }

  isPicked = site => this.data.access.includes(site.id);

  picker = site => {
    if (this.isPicked(site)) {
      this.data.access = this.data.access.filter(r => r != site.id);
    } else {
      this.data.access.push(site.id);
    }
  }
}

UserWizardController.$inject = ['$state', 'UIService', 'AdminUserService']
