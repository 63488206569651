import {
  routes
} from './routes';
import PublicService from './service';
import CreationController from './creation/controller';
import RecoverController from './recover/controller';

export default angular.module('app.public', [])
  .config(routes)
  .service('PublicService', PublicService)
  .controller('CreationController', CreationController)
  .controller('RecoverController', RecoverController)
  .name;

