export default class ConfirmController {
  constructor($window) {
    $window.addEventListener("flutterInAppWebViewPlatformReady", function (event) {
    });
    this.close = () => {
      $window.flutter_inappwebview.callHandler('closeMe');
      $window.close();
    }
  }
}

ConfirmController.$inject = ['$window'];
