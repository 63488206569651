export default class ReportUserController {
  constructor($state, UIService, ReportService) {
    this.$state = $state;
    this.UI = UIService;
    this.Report = ReportService;
    this.id = $state.params.id;
    this.consumptionLoaded = false;
    this.date = moment.utc();

    this.options = {
      format: 'MM/YYYY'
    };

    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.Report.getUsers().then(r => {
      this.users = r;
      this.loaded = true;
    });
  }

  loadUserData = () => {
    if (this.id == undefined) {
      this.UI.addToast("Escolha um utilizador da lista primeira");
      return;
    }
    this.consumptionLoaded = false;
    this.Report.user(this.id, this.date).then(origin => {
      origin = origin.filter(r =>
        moment.utc(r.timestamp).isSame(this.date, 'month'));
      this.consumption = origin;
      this.consumptionLoaded = true;
    }).catch(e => {
      this.UI.addToast("Não foi possível gerar resumos mensais da instalação");
    })
  }

  exportXLS = (row) => {
    this.exportConsumption(row, { format: 'export', extension: 'xls' });
  }

  exportPDF = (row) => {
    this.exportConsumption(row, { format: 'report', extension: 'PDF' });
  }

  exportConsumption = (row, format) => {
    let from = moment.utc(row.data).startOf('month');
    let to = moment.utc(row.data).endOf('month');
    let url = `/api/sites/${this.id}/${format.format}?from=${from}&to=${to}`;
    var downloadLink = angular.element("<a></a>");
    downloadLink.attr("href", url);
    downloadLink.attr("download", `export.${format.extension}`);
    downloadLink[0].click();
    this.UI.addToast("Download iniciado, aguarde por favor");
  }

  readableUserFilter = () => {
    if (this.user == undefined) {
      return "Escolha um utilizador da lista";
    } else {
      return this.user.name;
    }
  }

  filterUser = () => {
    this.UI.showDialog({
      template: require('./dialog.html'),
      controller: ['$scope', '$filter', ($scope, $filter) => {
        // Arrays of data
        $scope.property = 'name';
        $scope.list = this.users || [];
        // Search filter
        $scope.filter = "";
        // Pagination
        $scope.pagination = {
          perPage: 10,
          current: 0,
          previous: () => {
            if ($scope.pagination.current > 0) $scope.pagination.current--;
          },
          onFilter: () => {
            const filteredArray = $filter("filter")(
              $scope.list,
              $scope.filter
            );
            const afterFilter =
              Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
            if (afterFilter < $scope.pagination.current) {
              $scope.pagination.current = afterFilter;
            }
          },
          next: () => {
            if ($scope.pagination.current < $scope.pagination.total() - 1)
              $scope.pagination.current++;
          },
          total: () => {
            const filteredArray = $filter("filter")(
              $scope.list,
              $scope.filter
            );
            return Math.ceil(
              filteredArray.length / $scope.pagination.perPage
            );
          },
        };


        $scope.select = function (obj) {
          $scope.$close(obj);
        };

        $scope.cancel = function () {
          $scope.$dismiss("cancel");
        };
      }]
    }).then(r => {
      this.user = r;
      this.id = r.id;
      this.loadUserData();
    });
  }

  export = () => {
    if (!this.user) {
      return;
    }
    let from = moment.utc(this.date).startOf('month');
    let to = moment.utc(this.date).endOf('month');
    let url = `/api/users/${this.id}/extract?from=${from}&to=${to}`;
    var downloadLink = angular.element("<a></a>");
    downloadLink.attr("href", url);
    downloadLink.attr("download", `extracto-${this.user.name}.pdf`);
    downloadLink[0].click();
    this.UI.addToast("Download iniciado, aguarde por favor");
  }

  getOperationName = op => {
    switch (op) {
      case 'chargement':
        return "Carregamento de Saldo";
      case 'service_tax':
        return "Taxa de Serviço";
      case 'transaction':
        return "Carregamento EV";
      case 'adjust':
        return "Acerto de contas";
      default:
        return "";
    }
  }

  getType = type => {
    switch (type) {
      case 1:
        return "Condomínio";
      case 0:
        return "Empresa";
      case -1:
        return "Sistema";
      default:
        return 'Privado';
    }
  }
}

ReportUserController.$inject = ['$state', 'UIService', 'ReportService'];
