export default class EventService {
  constructor($q, Logger, AuthenticationService) {
    this.$q = $q;
    this.Logger = Logger;
    this.AuthenticationService = AuthenticationService;
  }

  modules = () => {
    return [{ name: "OCPP" }, { name: "Sistema" }];
  };

  loadFilterData = () => {
    let defer = this.$q.defer();
    let modules = this.modules();

    defer.resolve({
      modules: modules,
    });

    return defer.promise;
  };

  list = (filter) => {
    let siteId = this.AuthenticationService.getUser().siteId;
    filter.where = filter.where || {};
    filter.where.siteId = siteId;
    let defer = this.$q.defer();
    filter.include = ["site", "evse", "user"];
    this.Logger.count({
      where: filter.where,
    })
      .$promise.then((r) => {
        this.Logger.find({
          filter: filter,
        })
          .$promise.then((list) => {
            defer.resolve({
              total: r.count,
              data: list,
            });
          })
          .catch((err) => {
            defer.reject(err);
          });
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  };
}

EventService.$inject = ["$q", "Logger", "AuthenticationService"];
