export default class EntityListController {
  constructor($state, UIService, EntityService, AuthorizationService) {
    this.$state = $state;
    this.UI = UIService;
    this.Entity = EntityService;
    this.Authorization = AuthorizationService;
    this.filter = {
      page: $state.params.page || 1,
      term: $state.params.term || "",
      sort: "asc",
      order: "name"
    };
    this.loadData();
  }

  getEntityType = row => {
    switch (row.type) {
      case 1:
        return "Condomínio"
      default:
        return 'Empresa';
    }
  }

  loadData = () => {
    this.loaded = false;
    this.$state.go("app.entity.list", this.filter, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.Entity.list(this.createFilter()).then(r => {
      this.total = r.total;
      this.data = r.data;
      this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
      this.end = r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
      this.loaded = true;
    }).catch(() => {
      this.UI.addToast("Ocorreu um erro ao carregar empresas");
    });
  }

  getFavicon = row => {
    if (row.url) {
      return `https://www.google.com/s2/favicons?sz=16&domain_url=${row.url}`;
    } else {
      return "";
    }
  }

  checkForAdministrator = () => {
    //check user permissions
    //if is administrator show disabled entities
    return this.Authorization.canPerform(['removeEntity']);
  }

  createFilter = () => {
    let ob = {};
    let prop = "";
    let pattern = "";
    let where = {};

    if (!this.filter.term.isEmpty()) {
      // Filter by name
      prop = `name`;
      pattern = {
        like: `.*${this.filter.term}.*`,
        options: "i",
      };
      ob = {};
      ob[prop] = pattern;
      where = ob;
    }
    if (!this.checkForAdministrator()) {
      ob['active'] = true;
      where = ob;
    }
    return {
      where: where,
      limit: 20,
      order: `${this.filter.order} ${this.filter.sort}`,
      skip: (this.filter.page - 1) * 20,
    };
  };

  clear = () => {
    this.filter.page = this.$state.params.page || 1;
    this.filter.term = "";
    this.filter.sort = "asc";
    this.filter.order = "name";
    this.loadData();
  };

  next = () => {
    if (this.end < this.total) {
      this.filter.page++;
    }
    this.loadData();
  };

  previous = () => {
    if (this.filter.page > 1) {
      this.filter.page--;
    }
    this.loadData();
  };
}

EntityListController.$inject = ['$state', 'UIService', 'EntityService', 'AuthorizationService'];
