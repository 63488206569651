export default class TariffDetailsController {
  constructor($state, TariffService, UIService) {
    this.$state = $state;
    this.Tariff = TariffService;
    this.UI = UIService;
    this.id = $state.params.id;
    this.loadData();
  }

  delete = () => {
    this.UI.showConfirm("Tem a certeza que pretende apagar este tarifário?").then(r => {
      this.Tariff.delete(this.id).then(_ => {
        this.$state.go('app.tariff.list');
        this.UI.addToast("Tarifário eliminado com sucesso");
      }).catch(e => {
        this.UI.addToast("Ocorreu um erro ao eliminar tarifário");
      })
    })
  }

  loadData = () => {
    this.loaded = false;

    this.Tariff.cycles()
      .then((r) => {
        this.loaded = true;
        this.cycles = r;
      })
      .catch((e) => {
        this.UI.addToast("Ocorreu um erro ao carregador ciclos horárias");
      });

    this.Tariff.providers()
      .then((r) => {
        this.loaded = true;
        this.providers = r;
      })
      .catch((e) => {
        this.UI.addToast("Ocorreu um erro ao carregador fornecedores");
      });

    this.Tariff.get(this.id)
      .then((r) => {
        this.data = r;
        this.auxCycle = r.tariffcycle;
        this.auxProvider = r.provider;
        this.data.bands = this.data.bands || this.auxCycle.bands;
        // Generate table compatible data
        this.tables = [];
        this.data.tariffcycle.periods.forEach((p) => {
          // Group by weekDay
          let groupBy = p.timetable.reduce((r, a) => {
            let key = a.weekDay || "weekDay";
            r[key] = r[key] || [];
            r[key].push(a);
            return r;
          }, Object.create(null));
          // Create 2 tables based on DST
          let aux = [];
          Object.values(groupBy).forEach((v, i) => {
            aux.push({
              dayOfWeek: i + 1,
              timetable: v,
            });
          });
          // Add pretty-formatted table
          this.tables.push({
            isDST: p.isDST,
            timetable: aux,
          });
        });
        console.log(this.data);
        this.loaded = true;
      })
      .catch((e) => {
        console.log(e);
        this.UI.addToast("Ocorreu um erro ao carregar tarifário");
      });
  };

  dayOfWeek = (num) => {
    switch (num.dayOfWeek) {
      case 1:
        return "Segunda-Feira";
      case 2:
        return "Terça-Feira";
      case 3:
        return "Quarta-Feira";
      case 4:
        return "Quinta-Feira";
      case 5:
        return "Sexta-Feira";
      case 6:
        return "Sábado";
      case 7:
        return "Domingo";
    }
  };

  save = () => {
    // Fornecedor
    this.data.providerId = this.auxProvider.id;
    // ciclo horária
    this.data.tariffcycleId = this.auxCycle.id;
    // Gravar
    this.Tariff.save(this.data)
      .then((r) => {
        this.UI.addToast("Tarifário alterado com sucesso!");
        this.$state.go('app.tariff.list');
      })
      .catch((e) => {
        this.UI.addToast("Ocorreu um erro ao gravar tarifário");
      });
  };

}

TariffDetailsController.$inject = ["$state", "TariffService", "UIService"];
