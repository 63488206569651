// CommonJS package manager support
if (typeof module !== 'undefined' && typeof exports !== 'undefined' &&
  module.exports === exports) {
  // Export the *name* of this Angular module
  // Sample usage:
  //
  //   import lbServices from './lb-services';
  //   angular.module('app', [lbServices]);
  //
  module.exports = "lbServices";
}

(function(window, angular, undefined) {
  'use strict';

  var urlBase = "/api";
  var authHeader = 'authorization';

  function getHost(url) {
    var m = url.match(/^(?:https?:)?\/\/([^\/]+)/);
    return m ? m[1] : null;
  }
  // need to use the urlBase as the base to handle multiple
  // loopback servers behind a proxy/gateway where the host
  // would be the same.
  var urlBaseHost = getHost(urlBase) ? urlBase : location.host;

/**
 * @ngdoc overview
 * @name lbServices
 * @module
 * @description
 *
 * The `lbServices` module provides services for interacting with
 * the models exposed by the LoopBack server via the REST API.
 *
 */
  var module = angular.module("lbServices", ['ngResource']);

/**
 * @ngdoc object
 * @name lbServices.Acl
 * @header lbServices.Acl
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Acl` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Acl",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/acls/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Acl#create
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/acls",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Acl#patchOrCreate
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/acls",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Acl#replaceOrCreate
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/acls/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Acl#upsertWithWhere
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/acls/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Acl#exists
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/acls/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Acl#findById
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/acls/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Acl#replaceById
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/acls/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Acl#find
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/acls",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Acl#findOne
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/acls/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Acl#updateAll
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/acls/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Acl#deleteById
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/acls/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Acl#count
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/acls/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Acl#prototype$patchAttributes
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - acl id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/acls/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Acl#createChangeStream
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/acls/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Acl#createMany
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/acls",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Acl#upsert
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Acl#updateOrCreate
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Acl#patchOrCreateWithWhere
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Acl#update
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Acl#destroyById
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Acl#removeById
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Acl#prototype$updateAttributes
             * @methodOf lbServices.Acl
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - acl id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Acl` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Acl#modelName
        * @propertyOf lbServices.Acl
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Acl`.
        */
        R.modelName = "Acl";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Feature
 * @header lbServices.Feature
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Feature` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Feature",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/features/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Feature.roles.findById() instead.
            "prototype$__findById__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/features/:id/roles/:fk",
              method: "GET",
            },

            // INTERNAL. Use Feature.roles.destroyById() instead.
            "prototype$__destroyById__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/features/:id/roles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Feature.roles.updateById() instead.
            "prototype$__updateById__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/features/:id/roles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Feature.roles() instead.
            "prototype$__get__roles": {
              isArray: true,
              url: urlBase + "/features/:id/roles",
              method: "GET",
            },

            // INTERNAL. Use Feature.roles.create() instead.
            "prototype$__create__roles": {
              url: urlBase + "/features/:id/roles",
              method: "POST",
            },

            // INTERNAL. Use Feature.roles.destroyAll() instead.
            "prototype$__delete__roles": {
              url: urlBase + "/features/:id/roles",
              method: "DELETE",
            },

            // INTERNAL. Use Feature.roles.count() instead.
            "prototype$__count__roles": {
              url: urlBase + "/features/:id/roles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#create
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/features",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#patchOrCreate
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/features",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#replaceOrCreate
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/features/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#upsertWithWhere
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/features/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#exists
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/features/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#findById
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/features/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#replaceById
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/features/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#find
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/features",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#findOne
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/features/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#updateAll
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/features/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#deleteById
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/features/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#count
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/features/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#prototype$patchAttributes
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - feature id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/features/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#createChangeStream
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/features/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Feature#createMany
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/features",
              method: "POST",
            },

            // INTERNAL. Use Role.feature() instead.
            "::get::Role::feature": {
              url: urlBase + "/roles/:id/feature",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Feature#upsert
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Feature#updateOrCreate
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Feature#patchOrCreateWithWhere
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Feature#update
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Feature#destroyById
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Feature#removeById
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Feature#prototype$updateAttributes
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - feature id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Feature#modelName
        * @propertyOf lbServices.Feature
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Feature`.
        */
        R.modelName = "Feature";

    /**
     * @ngdoc object
     * @name lbServices.Feature.roles
     * @header lbServices.Feature.roles
     * @object
     * @description
     *
     * The object `Feature.roles` groups methods
     * manipulating `Role` instances related to `Feature`.
     *
     * Call {@link lbServices.Feature#roles Feature.roles()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Feature#roles
             * @methodOf lbServices.Feature
             *
             * @description
             *
             * Queries roles of feature.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - feature id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.roles = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::get::Feature::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Feature.roles#count
             * @methodOf lbServices.Feature.roles
             *
             * @description
             *
             * Counts roles of feature.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - feature id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.roles.count = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::count::Feature::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Feature.roles#create
             * @methodOf lbServices.Feature.roles
             *
             * @description
             *
             * Creates a new instance in roles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - feature id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.roles.create = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::create::Feature::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Feature.roles#createMany
             * @methodOf lbServices.Feature.roles
             *
             * @description
             *
             * Creates a new instance in roles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - feature id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.roles.createMany = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::createMany::Feature::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Feature.roles#destroyAll
             * @methodOf lbServices.Feature.roles
             *
             * @description
             *
             * Deletes all roles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - feature id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.roles.destroyAll = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::delete::Feature::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Feature.roles#destroyById
             * @methodOf lbServices.Feature.roles
             *
             * @description
             *
             * Delete a related item by id for roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - feature id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.roles.destroyById = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::destroyById::Feature::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Feature.roles#findById
             * @methodOf lbServices.Feature.roles
             *
             * @description
             *
             * Find a related item by id for roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - feature id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.roles.findById = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::findById::Feature::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Feature.roles#updateById
             * @methodOf lbServices.Feature.roles
             *
             * @description
             *
             * Update a related item by id for roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - feature id
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.roles.updateById = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::updateById::Feature::roles"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Role
 * @header lbServices.Role
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Role` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Role",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/roles/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Role.feature() instead.
            "prototype$__get__feature": {
              url: urlBase + "/roles/:id/feature",
              method: "GET",
            },

            // INTERNAL. Use Role.principals.findById() instead.
            "prototype$__findById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/roles/:id/principals/:fk",
              method: "GET",
            },

            // INTERNAL. Use Role.principals.destroyById() instead.
            "prototype$__destroyById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/roles/:id/principals/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Role.principals.updateById() instead.
            "prototype$__updateById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/roles/:id/principals/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Role.principals() instead.
            "prototype$__get__principals": {
              isArray: true,
              url: urlBase + "/roles/:id/principals",
              method: "GET",
            },

            // INTERNAL. Use Role.principals.create() instead.
            "prototype$__create__principals": {
              url: urlBase + "/roles/:id/principals",
              method: "POST",
            },

            // INTERNAL. Use Role.principals.destroyAll() instead.
            "prototype$__delete__principals": {
              url: urlBase + "/roles/:id/principals",
              method: "DELETE",
            },

            // INTERNAL. Use Role.principals.count() instead.
            "prototype$__count__principals": {
              url: urlBase + "/roles/:id/principals/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#create
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/roles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#patchOrCreate
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/roles",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#replaceOrCreate
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/roles/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#upsertWithWhere
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/roles/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#exists
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/roles/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#findById
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/roles/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#replaceById
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/roles/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#find
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/roles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#findOne
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/roles/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#updateAll
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/roles/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#deleteById
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/roles/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#count
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/roles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#prototype$patchAttributes
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - role id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/roles/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#createChangeStream
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/roles/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#createMany
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/roles",
              method: "POST",
            },

            // INTERNAL. Use Feature.roles.findById() instead.
            "::findById::Feature::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/features/:id/roles/:fk",
              method: "GET",
            },

            // INTERNAL. Use Feature.roles.destroyById() instead.
            "::destroyById::Feature::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/features/:id/roles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Feature.roles.updateById() instead.
            "::updateById::Feature::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/features/:id/roles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Feature.roles() instead.
            "::get::Feature::roles": {
              isArray: true,
              url: urlBase + "/features/:id/roles",
              method: "GET",
            },

            // INTERNAL. Use Feature.roles.create() instead.
            "::create::Feature::roles": {
              url: urlBase + "/features/:id/roles",
              method: "POST",
            },

            // INTERNAL. Use Feature.roles.createMany() instead.
            "::createMany::Feature::roles": {
              isArray: true,
              url: urlBase + "/features/:id/roles",
              method: "POST",
            },

            // INTERNAL. Use Feature.roles.destroyAll() instead.
            "::delete::Feature::roles": {
              url: urlBase + "/features/:id/roles",
              method: "DELETE",
            },

            // INTERNAL. Use Feature.roles.count() instead.
            "::count::Feature::roles": {
              url: urlBase + "/features/:id/roles/count",
              method: "GET",
            },

            // INTERNAL. Use Group.roles.findById() instead.
            "::findById::Group::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/roles/:fk",
              method: "GET",
            },

            // INTERNAL. Use Group.roles.destroyById() instead.
            "::destroyById::Group::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/roles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Group.roles.updateById() instead.
            "::updateById::Group::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/roles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Group.roles.link() instead.
            "::link::Group::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/roles/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Group.roles.unlink() instead.
            "::unlink::Group::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/roles/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Group.roles.exists() instead.
            "::exists::Group::roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/roles/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Group.roles() instead.
            "::get::Group::roles": {
              isArray: true,
              url: urlBase + "/groups/:id/roles",
              method: "GET",
            },

            // INTERNAL. Use Group.roles.create() instead.
            "::create::Group::roles": {
              url: urlBase + "/groups/:id/roles",
              method: "POST",
            },

            // INTERNAL. Use Group.roles.createMany() instead.
            "::createMany::Group::roles": {
              isArray: true,
              url: urlBase + "/groups/:id/roles",
              method: "POST",
            },

            // INTERNAL. Use Group.roles.destroyAll() instead.
            "::delete::Group::roles": {
              url: urlBase + "/groups/:id/roles",
              method: "DELETE",
            },

            // INTERNAL. Use Group.roles.count() instead.
            "::count::Group::roles": {
              url: urlBase + "/groups/:id/roles/count",
              method: "GET",
            },

            // INTERNAL. Use Rolegroup.role() instead.
            "::get::Rolegroup::role": {
              url: urlBase + "/rolegroups/:id/role",
              method: "GET",
            },

            // INTERNAL. Use Rolemap.role() instead.
            "::get::Rolemap::role": {
              url: urlBase + "/rolemaps/:id/role",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Role#upsert
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Role#updateOrCreate
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Role#patchOrCreateWithWhere
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Role#update
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Role#destroyById
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Role#removeById
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Role#prototype$updateAttributes
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - role id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Role#modelName
        * @propertyOf lbServices.Role
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Role`.
        */
        R.modelName = "Role";


            /**
             * @ngdoc method
             * @name lbServices.Role#feature
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Fetches belongsTo relation feature.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - role id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Feature` object.)
             * </em>
             */
        R.feature = function() {
          var TargetResource = $injector.get("Feature");
          var action = TargetResource["::get::Role::feature"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Role.principals
     * @header lbServices.Role.principals
     * @object
     * @description
     *
     * The object `Role.principals` groups methods
     * manipulating `Rolemap` instances related to `Role`.
     *
     * Call {@link lbServices.Role#principals Role.principals()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Role#principals
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Queries principals of role.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - role id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
        R.principals = function() {
          var TargetResource = $injector.get("Rolemap");
          var action = TargetResource["::get::Role::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Role.principals#count
             * @methodOf lbServices.Role.principals
             *
             * @description
             *
             * Counts principals of role.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - role id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.principals.count = function() {
          var TargetResource = $injector.get("Rolemap");
          var action = TargetResource["::count::Role::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Role.principals#create
             * @methodOf lbServices.Role.principals
             *
             * @description
             *
             * Creates a new instance in principals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - role id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
        R.principals.create = function() {
          var TargetResource = $injector.get("Rolemap");
          var action = TargetResource["::create::Role::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Role.principals#createMany
             * @methodOf lbServices.Role.principals
             *
             * @description
             *
             * Creates a new instance in principals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - role id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
        R.principals.createMany = function() {
          var TargetResource = $injector.get("Rolemap");
          var action = TargetResource["::createMany::Role::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Role.principals#destroyAll
             * @methodOf lbServices.Role.principals
             *
             * @description
             *
             * Deletes all principals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - role id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.principals.destroyAll = function() {
          var TargetResource = $injector.get("Rolemap");
          var action = TargetResource["::delete::Role::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Role.principals#destroyById
             * @methodOf lbServices.Role.principals
             *
             * @description
             *
             * Delete a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - role id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.principals.destroyById = function() {
          var TargetResource = $injector.get("Rolemap");
          var action = TargetResource["::destroyById::Role::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Role.principals#findById
             * @methodOf lbServices.Role.principals
             *
             * @description
             *
             * Find a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - role id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
        R.principals.findById = function() {
          var TargetResource = $injector.get("Rolemap");
          var action = TargetResource["::findById::Role::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Role.principals#updateById
             * @methodOf lbServices.Role.principals
             *
             * @description
             *
             * Update a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - role id
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
        R.principals.updateById = function() {
          var TargetResource = $injector.get("Rolemap");
          var action = TargetResource["::updateById::Role::principals"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Group
 * @header lbServices.Group
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Group` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Group",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/groups/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Group.rolegroup.findById() instead.
            "prototype$__findById__rolegroup": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/rolegroup/:fk",
              method: "GET",
            },

            // INTERNAL. Use Group.rolegroup.destroyById() instead.
            "prototype$__destroyById__rolegroup": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/rolegroup/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Group.rolegroup.updateById() instead.
            "prototype$__updateById__rolegroup": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/rolegroup/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Group.roles.findById() instead.
            "prototype$__findById__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/roles/:fk",
              method: "GET",
            },

            // INTERNAL. Use Group.roles.destroyById() instead.
            "prototype$__destroyById__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/roles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Group.roles.updateById() instead.
            "prototype$__updateById__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/roles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Group.roles.link() instead.
            "prototype$__link__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/roles/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Group.roles.unlink() instead.
            "prototype$__unlink__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/roles/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Group.roles.exists() instead.
            "prototype$__exists__roles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/roles/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Group.users.findById() instead.
            "prototype$__findById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Group.users.destroyById() instead.
            "prototype$__destroyById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Group.users.updateById() instead.
            "prototype$__updateById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Group.users.link() instead.
            "prototype$__link__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/users/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Group.users.unlink() instead.
            "prototype$__unlink__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/users/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Group.users.exists() instead.
            "prototype$__exists__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/users/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Group.rolegroup() instead.
            "prototype$__get__rolegroup": {
              isArray: true,
              url: urlBase + "/groups/:id/rolegroup",
              method: "GET",
            },

            // INTERNAL. Use Group.rolegroup.create() instead.
            "prototype$__create__rolegroup": {
              url: urlBase + "/groups/:id/rolegroup",
              method: "POST",
            },

            // INTERNAL. Use Group.rolegroup.destroyAll() instead.
            "prototype$__delete__rolegroup": {
              url: urlBase + "/groups/:id/rolegroup",
              method: "DELETE",
            },

            // INTERNAL. Use Group.rolegroup.count() instead.
            "prototype$__count__rolegroup": {
              url: urlBase + "/groups/:id/rolegroup/count",
              method: "GET",
            },

            // INTERNAL. Use Group.roles() instead.
            "prototype$__get__roles": {
              isArray: true,
              url: urlBase + "/groups/:id/roles",
              method: "GET",
            },

            // INTERNAL. Use Group.roles.create() instead.
            "prototype$__create__roles": {
              url: urlBase + "/groups/:id/roles",
              method: "POST",
            },

            // INTERNAL. Use Group.roles.destroyAll() instead.
            "prototype$__delete__roles": {
              url: urlBase + "/groups/:id/roles",
              method: "DELETE",
            },

            // INTERNAL. Use Group.roles.count() instead.
            "prototype$__count__roles": {
              url: urlBase + "/groups/:id/roles/count",
              method: "GET",
            },

            // INTERNAL. Use Group.users() instead.
            "prototype$__get__users": {
              isArray: true,
              url: urlBase + "/groups/:id/users",
              method: "GET",
            },

            // INTERNAL. Use Group.users.create() instead.
            "prototype$__create__users": {
              url: urlBase + "/groups/:id/users",
              method: "POST",
            },

            // INTERNAL. Use Group.users.destroyAll() instead.
            "prototype$__delete__users": {
              url: urlBase + "/groups/:id/users",
              method: "DELETE",
            },

            // INTERNAL. Use Group.users.count() instead.
            "prototype$__count__users": {
              url: urlBase + "/groups/:id/users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#create
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/groups",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#patchOrCreate
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/groups",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#replaceOrCreate
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/groups/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#upsertWithWhere
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/groups/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#exists
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/groups/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#findById
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/groups/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#replaceById
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/groups/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#find
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/groups",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#findOne
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/groups/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#updateAll
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/groups/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#deleteById
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/groups/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#count
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/groups/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#prototype$patchAttributes
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/groups/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#createChangeStream
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/groups/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#deleteMe
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Deletes all group data
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Operation details
             */
            "deleteMe": {
              url: urlBase + "/groups/deleteMe",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#updateInfo
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Maps the roles in the group
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` - Model id
             *
             *  - `users` – `{usergroup}` - Array of usergroup
             *
             *  - `roles` – `{rolegroup}` - Array of rolegroup
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Operation details
             */
            "updateInfo": {
              url: urlBase + "/groups/updateInfo",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#createMany
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/groups",
              method: "POST",
            },

            // INTERNAL. Use Rolegroup.group() instead.
            "::get::Rolegroup::group": {
              url: urlBase + "/rolegroups/:id/group",
              method: "GET",
            },

            // INTERNAL. Use Usergroup.group() instead.
            "::get::Usergroup::group": {
              url: urlBase + "/usergroups/:id/group",
              method: "GET",
            },

            // INTERNAL. Use User.groups.findById() instead.
            "::findById::User::groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/groups/:fk",
              method: "GET",
            },

            // INTERNAL. Use User.groups.destroyById() instead.
            "::destroyById::User::groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/groups/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use User.groups.updateById() instead.
            "::updateById::User::groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/groups/:fk",
              method: "PUT",
            },

            // INTERNAL. Use User.groups.link() instead.
            "::link::User::groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/groups/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use User.groups.unlink() instead.
            "::unlink::User::groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/groups/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use User.groups.exists() instead.
            "::exists::User::groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/groups/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use User.groups() instead.
            "::get::User::groups": {
              isArray: true,
              url: urlBase + "/users/:id/groups",
              method: "GET",
            },

            // INTERNAL. Use User.groups.create() instead.
            "::create::User::groups": {
              url: urlBase + "/users/:id/groups",
              method: "POST",
            },

            // INTERNAL. Use User.groups.createMany() instead.
            "::createMany::User::groups": {
              isArray: true,
              url: urlBase + "/users/:id/groups",
              method: "POST",
            },

            // INTERNAL. Use User.groups.destroyAll() instead.
            "::delete::User::groups": {
              url: urlBase + "/users/:id/groups",
              method: "DELETE",
            },

            // INTERNAL. Use User.groups.count() instead.
            "::count::User::groups": {
              url: urlBase + "/users/:id/groups/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Group#upsert
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Group#updateOrCreate
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Group#patchOrCreateWithWhere
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Group#update
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Group#destroyById
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Group#removeById
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Group#prototype$updateAttributes
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Group#modelName
        * @propertyOf lbServices.Group
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Group`.
        */
        R.modelName = "Group";

    /**
     * @ngdoc object
     * @name lbServices.Group.rolegroup
     * @header lbServices.Group.rolegroup
     * @object
     * @description
     *
     * The object `Group.rolegroup` groups methods
     * manipulating `Rolegroup` instances related to `Group`.
     *
     * Call {@link lbServices.Group#rolegroup Group.rolegroup()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Group#rolegroup
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Queries rolegroup of group.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
        R.rolegroup = function() {
          var TargetResource = $injector.get("Rolegroup");
          var action = TargetResource["::get::Group::rolegroup"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.rolegroup#count
             * @methodOf lbServices.Group.rolegroup
             *
             * @description
             *
             * Counts rolegroup of group.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.rolegroup.count = function() {
          var TargetResource = $injector.get("Rolegroup");
          var action = TargetResource["::count::Group::rolegroup"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.rolegroup#create
             * @methodOf lbServices.Group.rolegroup
             *
             * @description
             *
             * Creates a new instance in rolegroup of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
        R.rolegroup.create = function() {
          var TargetResource = $injector.get("Rolegroup");
          var action = TargetResource["::create::Group::rolegroup"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.rolegroup#createMany
             * @methodOf lbServices.Group.rolegroup
             *
             * @description
             *
             * Creates a new instance in rolegroup of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
        R.rolegroup.createMany = function() {
          var TargetResource = $injector.get("Rolegroup");
          var action = TargetResource["::createMany::Group::rolegroup"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.rolegroup#destroyAll
             * @methodOf lbServices.Group.rolegroup
             *
             * @description
             *
             * Deletes all rolegroup of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.rolegroup.destroyAll = function() {
          var TargetResource = $injector.get("Rolegroup");
          var action = TargetResource["::delete::Group::rolegroup"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.rolegroup#destroyById
             * @methodOf lbServices.Group.rolegroup
             *
             * @description
             *
             * Delete a related item by id for rolegroup.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for rolegroup
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.rolegroup.destroyById = function() {
          var TargetResource = $injector.get("Rolegroup");
          var action = TargetResource["::destroyById::Group::rolegroup"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.rolegroup#findById
             * @methodOf lbServices.Group.rolegroup
             *
             * @description
             *
             * Find a related item by id for rolegroup.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for rolegroup
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
        R.rolegroup.findById = function() {
          var TargetResource = $injector.get("Rolegroup");
          var action = TargetResource["::findById::Group::rolegroup"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.rolegroup#updateById
             * @methodOf lbServices.Group.rolegroup
             *
             * @description
             *
             * Update a related item by id for rolegroup.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `fk` – `{*}` - Foreign key for rolegroup
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
        R.rolegroup.updateById = function() {
          var TargetResource = $injector.get("Rolegroup");
          var action = TargetResource["::updateById::Group::rolegroup"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Group.roles
     * @header lbServices.Group.roles
     * @object
     * @description
     *
     * The object `Group.roles` groups methods
     * manipulating `Role` instances related to `Group`.
     *
     * Call {@link lbServices.Group#roles Group.roles()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Group#roles
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Queries roles of group.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.roles = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::get::Group::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.roles#count
             * @methodOf lbServices.Group.roles
             *
             * @description
             *
             * Counts roles of group.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.roles.count = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::count::Group::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.roles#create
             * @methodOf lbServices.Group.roles
             *
             * @description
             *
             * Creates a new instance in roles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.roles.create = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::create::Group::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.roles#createMany
             * @methodOf lbServices.Group.roles
             *
             * @description
             *
             * Creates a new instance in roles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.roles.createMany = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::createMany::Group::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.roles#destroyAll
             * @methodOf lbServices.Group.roles
             *
             * @description
             *
             * Deletes all roles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.roles.destroyAll = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::delete::Group::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.roles#destroyById
             * @methodOf lbServices.Group.roles
             *
             * @description
             *
             * Delete a related item by id for roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.roles.destroyById = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::destroyById::Group::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.roles#exists
             * @methodOf lbServices.Group.roles
             *
             * @description
             *
             * Check the existence of roles relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.roles.exists = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::exists::Group::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.roles#findById
             * @methodOf lbServices.Group.roles
             *
             * @description
             *
             * Find a related item by id for roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.roles.findById = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::findById::Group::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.roles#link
             * @methodOf lbServices.Group.roles
             *
             * @description
             *
             * Add a related item by id for roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.roles.link = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::link::Group::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.roles#unlink
             * @methodOf lbServices.Group.roles
             *
             * @description
             *
             * Remove the roles relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.roles.unlink = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::unlink::Group::roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.roles#updateById
             * @methodOf lbServices.Group.roles
             *
             * @description
             *
             * Update a related item by id for roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `fk` – `{*}` - Foreign key for roles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.roles.updateById = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::updateById::Group::roles"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Group.users
     * @header lbServices.Group.users
     * @object
     * @description
     *
     * The object `Group.users` groups methods
     * manipulating `User` instances related to `Group`.
     *
     * Call {@link lbServices.Group#users Group.users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Group#users
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Queries users of group.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.users = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::get::Group::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.users#count
             * @methodOf lbServices.Group.users
             *
             * @description
             *
             * Counts users of group.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.users.count = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::count::Group::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.users#create
             * @methodOf lbServices.Group.users
             *
             * @description
             *
             * Creates a new instance in users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.users.create = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::create::Group::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.users#createMany
             * @methodOf lbServices.Group.users
             *
             * @description
             *
             * Creates a new instance in users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.users.createMany = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::createMany::Group::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.users#destroyAll
             * @methodOf lbServices.Group.users
             *
             * @description
             *
             * Deletes all users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.users.destroyAll = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::delete::Group::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.users#destroyById
             * @methodOf lbServices.Group.users
             *
             * @description
             *
             * Delete a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.users.destroyById = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::destroyById::Group::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.users#exists
             * @methodOf lbServices.Group.users
             *
             * @description
             *
             * Check the existence of users relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.users.exists = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::exists::Group::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.users#findById
             * @methodOf lbServices.Group.users
             *
             * @description
             *
             * Find a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.users.findById = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::findById::Group::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.users#link
             * @methodOf lbServices.Group.users
             *
             * @description
             *
             * Add a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.users.link = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::link::Group::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.users#unlink
             * @methodOf lbServices.Group.users
             *
             * @description
             *
             * Remove the users relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.users.unlink = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::unlink::Group::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group.users#updateById
             * @methodOf lbServices.Group.users
             *
             * @description
             *
             * Update a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - group id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.users.updateById = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::updateById::Group::users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Rolegroup
 * @header lbServices.Rolegroup
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Rolegroup` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Rolegroup",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/rolegroups/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Rolegroup.group() instead.
            "prototype$__get__group": {
              url: urlBase + "/rolegroups/:id/group",
              method: "GET",
            },

            // INTERNAL. Use Rolegroup.role() instead.
            "prototype$__get__role": {
              url: urlBase + "/rolegroups/:id/role",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#create
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/rolegroups",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#patchOrCreate
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/rolegroups",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#replaceOrCreate
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/rolegroups/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#upsertWithWhere
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/rolegroups/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#exists
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/rolegroups/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#findById
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/rolegroups/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#replaceById
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/rolegroups/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#find
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/rolegroups",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#findOne
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/rolegroups/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#updateAll
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/rolegroups/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#deleteById
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/rolegroups/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#count
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/rolegroups/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#prototype$patchAttributes
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - rolegroup id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/rolegroups/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#createChangeStream
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/rolegroups/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#createMany
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/rolegroups",
              method: "POST",
            },

            // INTERNAL. Use Group.rolegroup.findById() instead.
            "::findById::Group::rolegroup": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/rolegroup/:fk",
              method: "GET",
            },

            // INTERNAL. Use Group.rolegroup.destroyById() instead.
            "::destroyById::Group::rolegroup": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/rolegroup/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Group.rolegroup.updateById() instead.
            "::updateById::Group::rolegroup": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/rolegroup/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Group.rolegroup() instead.
            "::get::Group::rolegroup": {
              isArray: true,
              url: urlBase + "/groups/:id/rolegroup",
              method: "GET",
            },

            // INTERNAL. Use Group.rolegroup.create() instead.
            "::create::Group::rolegroup": {
              url: urlBase + "/groups/:id/rolegroup",
              method: "POST",
            },

            // INTERNAL. Use Group.rolegroup.createMany() instead.
            "::createMany::Group::rolegroup": {
              isArray: true,
              url: urlBase + "/groups/:id/rolegroup",
              method: "POST",
            },

            // INTERNAL. Use Group.rolegroup.destroyAll() instead.
            "::delete::Group::rolegroup": {
              url: urlBase + "/groups/:id/rolegroup",
              method: "DELETE",
            },

            // INTERNAL. Use Group.rolegroup.count() instead.
            "::count::Group::rolegroup": {
              url: urlBase + "/groups/:id/rolegroup/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#upsert
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#updateOrCreate
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#patchOrCreateWithWhere
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#update
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#destroyById
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#removeById
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#prototype$updateAttributes
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - rolegroup id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolegroup` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Rolegroup#modelName
        * @propertyOf lbServices.Rolegroup
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Rolegroup`.
        */
        R.modelName = "Rolegroup";


            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#group
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Fetches belongsTo relation group.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - rolegroup id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R.group = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::get::Rolegroup::group"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Rolegroup#role
             * @methodOf lbServices.Rolegroup
             *
             * @description
             *
             * Fetches belongsTo relation role.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - rolegroup id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.role = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::get::Rolegroup::role"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Usergroup
 * @header lbServices.Usergroup
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Usergroup` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Usergroup",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/usergroups/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Usergroup.group() instead.
            "prototype$__get__group": {
              url: urlBase + "/usergroups/:id/group",
              method: "GET",
            },

            // INTERNAL. Use Usergroup.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/usergroups/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#create
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/usergroups",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#patchOrCreate
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/usergroups",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#replaceOrCreate
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/usergroups/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#upsertWithWhere
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/usergroups/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#exists
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/usergroups/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#findById
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/usergroups/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#replaceById
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/usergroups/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#find
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/usergroups",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#findOne
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/usergroups/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#updateAll
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/usergroups/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#deleteById
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/usergroups/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#count
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/usergroups/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#prototype$patchAttributes
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - usergroup id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/usergroups/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#createChangeStream
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/usergroups/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#createMany
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/usergroups",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Usergroup#upsert
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#updateOrCreate
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#patchOrCreateWithWhere
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#update
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#destroyById
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#removeById
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#prototype$updateAttributes
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - usergroup id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usergroup` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Usergroup#modelName
        * @propertyOf lbServices.Usergroup
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Usergroup`.
        */
        R.modelName = "Usergroup";


            /**
             * @ngdoc method
             * @name lbServices.Usergroup#group
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Fetches belongsTo relation group.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - usergroup id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R.group = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::get::Usergroup::group"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Usergroup#user
             * @methodOf lbServices.Usergroup
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - usergroup id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::get::Usergroup::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Rolemap
 * @header lbServices.Rolemap
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Rolemap` model.
 *
 * **Details**
 *
 * Map principals to roles
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Rolemap",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/rolemaps/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Rolemap.role() instead.
            "prototype$__get__role": {
              url: urlBase + "/rolemaps/:id/role",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#create
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/rolemaps",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#patchOrCreate
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/rolemaps",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#replaceOrCreate
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/rolemaps/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#upsertWithWhere
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/rolemaps/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#exists
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/rolemaps/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#findById
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/rolemaps/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#replaceById
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/rolemaps/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#find
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/rolemaps",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#findOne
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/rolemaps/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#updateAll
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/rolemaps/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#deleteById
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/rolemaps/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#count
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/rolemaps/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#prototype$patchAttributes
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - rolemap id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/rolemaps/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#createChangeStream
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/rolemaps/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#addUser
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Map roles from a group and a user
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `userId` – `{number}` - Model id
             *
             *  - `groupId` – `{number}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
            "addUser": {
              url: urlBase + "/rolemaps/addUser",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#removeUser
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Unmaps roles from a group and a user
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `userId` – `{number}` - Model id
             *
             *  - `groupId` – `{number}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "removeUser": {
              url: urlBase + "/rolemaps/removeUser",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#createMany
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/rolemaps",
              method: "POST",
            },

            // INTERNAL. Use Role.principals.findById() instead.
            "::findById::Role::principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/roles/:id/principals/:fk",
              method: "GET",
            },

            // INTERNAL. Use Role.principals.destroyById() instead.
            "::destroyById::Role::principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/roles/:id/principals/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Role.principals.updateById() instead.
            "::updateById::Role::principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/roles/:id/principals/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Role.principals() instead.
            "::get::Role::principals": {
              isArray: true,
              url: urlBase + "/roles/:id/principals",
              method: "GET",
            },

            // INTERNAL. Use Role.principals.create() instead.
            "::create::Role::principals": {
              url: urlBase + "/roles/:id/principals",
              method: "POST",
            },

            // INTERNAL. Use Role.principals.createMany() instead.
            "::createMany::Role::principals": {
              isArray: true,
              url: urlBase + "/roles/:id/principals",
              method: "POST",
            },

            // INTERNAL. Use Role.principals.destroyAll() instead.
            "::delete::Role::principals": {
              url: urlBase + "/roles/:id/principals",
              method: "DELETE",
            },

            // INTERNAL. Use Role.principals.count() instead.
            "::count::Role::principals": {
              url: urlBase + "/roles/:id/principals/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Rolemap#upsert
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#updateOrCreate
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#patchOrCreateWithWhere
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#update
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#destroyById
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#removeById
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Rolemap#prototype$updateAttributes
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - rolemap id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Rolemap` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Rolemap#modelName
        * @propertyOf lbServices.Rolemap
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Rolemap`.
        */
        R.modelName = "Rolemap";


            /**
             * @ngdoc method
             * @name lbServices.Rolemap#role
             * @methodOf lbServices.Rolemap
             *
             * @description
             *
             * Fetches belongsTo relation role.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - rolemap id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.role = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::get::Rolemap::role"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.User
 * @header lbServices.User
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `User` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "User",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/users/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.User#prototype$__findById__accessTokens
             * @methodOf lbServices.User
             *
             * @description
             *
             * Find a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "prototype$__findById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/accessTokens/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#prototype$__destroyById__accessTokens
             * @methodOf lbServices.User
             *
             * @description
             *
             * Delete a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/accessTokens/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#prototype$__updateById__accessTokens
             * @methodOf lbServices.User
             *
             * @description
             *
             * Update a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "prototype$__updateById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/accessTokens/:fk",
              method: "PUT",
            },

            // INTERNAL. Use User.groups.findById() instead.
            "prototype$__findById__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/groups/:fk",
              method: "GET",
            },

            // INTERNAL. Use User.groups.destroyById() instead.
            "prototype$__destroyById__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/groups/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use User.groups.updateById() instead.
            "prototype$__updateById__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/groups/:fk",
              method: "PUT",
            },

            // INTERNAL. Use User.groups.link() instead.
            "prototype$__link__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/groups/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use User.groups.unlink() instead.
            "prototype$__unlink__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/groups/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use User.groups.exists() instead.
            "prototype$__exists__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/groups/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use User.identities.findById() instead.
            "prototype$__findById__identities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/identities/:fk",
              method: "GET",
            },

            // INTERNAL. Use User.identities.destroyById() instead.
            "prototype$__destroyById__identities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/identities/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use User.identities.updateById() instead.
            "prototype$__updateById__identities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/identities/:fk",
              method: "PUT",
            },

            // INTERNAL. Use User.site() instead.
            "prototype$__get__site": {
              url: urlBase + "/users/:id/site",
              method: "GET",
            },

            // INTERNAL. Use User.access.findById() instead.
            "prototype$__findById__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/access/:fk",
              method: "GET",
            },

            // INTERNAL. Use User.access.destroyById() instead.
            "prototype$__destroyById__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/access/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use User.access.updateById() instead.
            "prototype$__updateById__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/access/:fk",
              method: "PUT",
            },

            // INTERNAL. Use User.balances.findById() instead.
            "prototype$__findById__balances": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/balances/:fk",
              method: "GET",
            },

            // INTERNAL. Use User.balances.destroyById() instead.
            "prototype$__destroyById__balances": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/balances/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use User.balances.updateById() instead.
            "prototype$__updateById__balances": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/balances/:fk",
              method: "PUT",
            },

            // INTERNAL. Use User.tenants.findById() instead.
            "prototype$__findById__tenants": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/tenants/:fk",
              method: "GET",
            },

            // INTERNAL. Use User.tenants.destroyById() instead.
            "prototype$__destroyById__tenants": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/tenants/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use User.tenants.updateById() instead.
            "prototype$__updateById__tenants": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/tenants/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#prototype$__get__accessTokens
             * @methodOf lbServices.User
             *
             * @description
             *
             * Queries accessTokens of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "prototype$__get__accessTokens": {
              isArray: true,
              url: urlBase + "/users/:id/accessTokens",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#prototype$__create__accessTokens
             * @methodOf lbServices.User
             *
             * @description
             *
             * Creates a new instance in accessTokens of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "prototype$__create__accessTokens": {
              url: urlBase + "/users/:id/accessTokens",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#prototype$__delete__accessTokens
             * @methodOf lbServices.User
             *
             * @description
             *
             * Deletes all accessTokens of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__accessTokens": {
              url: urlBase + "/users/:id/accessTokens",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#prototype$__count__accessTokens
             * @methodOf lbServices.User
             *
             * @description
             *
             * Counts accessTokens of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__accessTokens": {
              url: urlBase + "/users/:id/accessTokens/count",
              method: "GET",
            },

            // INTERNAL. Use User.groups() instead.
            "prototype$__get__groups": {
              isArray: true,
              url: urlBase + "/users/:id/groups",
              method: "GET",
            },

            // INTERNAL. Use User.groups.create() instead.
            "prototype$__create__groups": {
              url: urlBase + "/users/:id/groups",
              method: "POST",
            },

            // INTERNAL. Use User.groups.destroyAll() instead.
            "prototype$__delete__groups": {
              url: urlBase + "/users/:id/groups",
              method: "DELETE",
            },

            // INTERNAL. Use User.groups.count() instead.
            "prototype$__count__groups": {
              url: urlBase + "/users/:id/groups/count",
              method: "GET",
            },

            // INTERNAL. Use User.identities() instead.
            "prototype$__get__identities": {
              isArray: true,
              url: urlBase + "/users/:id/identities",
              method: "GET",
            },

            // INTERNAL. Use User.identities.create() instead.
            "prototype$__create__identities": {
              url: urlBase + "/users/:id/identities",
              method: "POST",
            },

            // INTERNAL. Use User.identities.destroyAll() instead.
            "prototype$__delete__identities": {
              url: urlBase + "/users/:id/identities",
              method: "DELETE",
            },

            // INTERNAL. Use User.identities.count() instead.
            "prototype$__count__identities": {
              url: urlBase + "/users/:id/identities/count",
              method: "GET",
            },

            // INTERNAL. Use User.access() instead.
            "prototype$__get__access": {
              isArray: true,
              url: urlBase + "/users/:id/access",
              method: "GET",
            },

            // INTERNAL. Use User.access.create() instead.
            "prototype$__create__access": {
              url: urlBase + "/users/:id/access",
              method: "POST",
            },

            // INTERNAL. Use User.access.destroyAll() instead.
            "prototype$__delete__access": {
              url: urlBase + "/users/:id/access",
              method: "DELETE",
            },

            // INTERNAL. Use User.access.count() instead.
            "prototype$__count__access": {
              url: urlBase + "/users/:id/access/count",
              method: "GET",
            },

            // INTERNAL. Use User.balances() instead.
            "prototype$__get__balances": {
              isArray: true,
              url: urlBase + "/users/:id/balances",
              method: "GET",
            },

            // INTERNAL. Use User.balances.create() instead.
            "prototype$__create__balances": {
              url: urlBase + "/users/:id/balances",
              method: "POST",
            },

            // INTERNAL. Use User.balances.destroyAll() instead.
            "prototype$__delete__balances": {
              url: urlBase + "/users/:id/balances",
              method: "DELETE",
            },

            // INTERNAL. Use User.balances.count() instead.
            "prototype$__count__balances": {
              url: urlBase + "/users/:id/balances/count",
              method: "GET",
            },

            // INTERNAL. Use User.tenants() instead.
            "prototype$__get__tenants": {
              isArray: true,
              url: urlBase + "/users/:id/tenants",
              method: "GET",
            },

            // INTERNAL. Use User.tenants.create() instead.
            "prototype$__create__tenants": {
              url: urlBase + "/users/:id/tenants",
              method: "POST",
            },

            // INTERNAL. Use User.tenants.destroyAll() instead.
            "prototype$__delete__tenants": {
              url: urlBase + "/users/:id/tenants",
              method: "DELETE",
            },

            // INTERNAL. Use User.tenants.count() instead.
            "prototype$__count__tenants": {
              url: urlBase + "/users/:id/tenants/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#create
             * @methodOf lbServices.User
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/users",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#patchOrCreate
             * @methodOf lbServices.User
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/users",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#replaceOrCreate
             * @methodOf lbServices.User
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/users/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#upsertWithWhere
             * @methodOf lbServices.User
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/users/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#exists
             * @methodOf lbServices.User
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/users/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#findById
             * @methodOf lbServices.User
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/users/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#replaceById
             * @methodOf lbServices.User
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/users/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#find
             * @methodOf lbServices.User
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/users",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#findOne
             * @methodOf lbServices.User
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/users/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#updateAll
             * @methodOf lbServices.User
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/users/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#deleteById
             * @methodOf lbServices.User
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/users/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#count
             * @methodOf lbServices.User
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#prototype$patchAttributes
             * @methodOf lbServices.User
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/users/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#createChangeStream
             * @methodOf lbServices.User
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/users/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#login
             * @methodOf lbServices.User
             *
             * @description
             *
             * Login a user with username/email and password.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `include` – `{string=}` - Related objects to include in the response. See the description of return value for more details.
             *   Default value: `user`.
             *
             *  - `rememberMe` - `boolean` - Whether the authentication credentials
             *     should be remembered in localStorage across app/browser restarts.
             *     Default: `true`.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The response body contains properties of the AccessToken created on login.
             * Depending on the value of `include` parameter, the body may contain additional properties:
             *   - `user` - `U+007BUserU+007D` - Data of the currently logged in user. (`include=user`)
             *
             */
            "login": {
              params: {
                include: 'user',
              },
              interceptor: {
                response: function(response) {
                  var accessToken = response.data;
                  LoopBackAuth.setUser(
                    accessToken.id, accessToken.userId, accessToken.user);
                  LoopBackAuth.rememberMe =
                    response.config.params.rememberMe !== false;
                  LoopBackAuth.save();
                  return response.resource;
                },
              },
              url: urlBase + "/users/login",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#logout
             * @methodOf lbServices.User
             *
             * @description
             *
             * Logout a user with access token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `access_token` – `{string=}` - Do not supply this argument, it is automatically extracted from request headers.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "logout": {
              interceptor: {
                response: function(response) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return responseError.resource;
                },
              },
              url: urlBase + "/users/logout",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#prototype$verify
             * @methodOf lbServices.User
             *
             * @description
             *
             * Trigger user's identity verification with configured verifyOptions
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `verifyOptions` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$verify": {
              url: urlBase + "/users/:id/verify",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#confirm
             * @methodOf lbServices.User
             *
             * @description
             *
             * Confirm a user registration with identity verification token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `uid` – `{string}` -
             *
             *  - `token` – `{string}` -
             *
             *  - `redirect` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "confirm": {
              url: urlBase + "/users/confirm",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#resetPassword
             * @methodOf lbServices.User
             *
             * @description
             *
             * Reset password for a user with email.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "resetPassword": {
              url: urlBase + "/users/reset",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#changePassword
             * @methodOf lbServices.User
             *
             * @description
             *
             * Change a user's password.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{*=}` -
             *
             *  - `oldPassword` – `{string}` -
             *
             *  - `newPassword` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "changePassword": {
              url: urlBase + "/users/change-password",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#setPassword
             * @methodOf lbServices.User
             *
             * @description
             *
             * Reset user's password via a password-reset token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{*=}` -
             *
             *  - `newPassword` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "setPassword": {
              url: urlBase + "/users/reset-password",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#setNewPassword
             * @methodOf lbServices.User
             *
             * @description
             *
             * Change a user's password.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{*=}` -
             *
             *  - `password` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "setNewPassword": {
              url: urlBase + "/users/set-password",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#otp
             * @methodOf lbServices.User
             *
             * @description
             *
             * Login user by otp code
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `include` – `{string=}` - Related objects to include in the response. See the description of return value for more details.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The response body contains properties of the AccessToken created on login.
             * Depending on the value of `include` parameter, the body may contain additional properties:
             *   - `user` - `U+007BUserU+007D` - Data of the currently logged in user. (`include=user`)
             *
             */
            "otp": {
              url: urlBase + "/users/sms/login",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#chargers
             * @methodOf lbServices.User
             *
             * @description
             *
             * User allowed chargers
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - Model id
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "chargers": {
              isArray: true,
              url: urlBase + "/users/:id/chargers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#verifySMS
             * @methodOf lbServices.User
             *
             * @description
             *
             * Verifies user by sending an SMS, 
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `phone` – `{string}` - User mobile phone
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "verifySMS": {
              url: urlBase + "/users/sms/verify",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#verifyToken
             * @methodOf lbServices.User
             *
             * @description
             *
             * Verifies given 2FA token for user
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             *  - `token` – `{number}` - Token generated by authenticator apps
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "verifyToken": {
              url: urlBase + "/users/verifyToken",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#extract
             * @methodOf lbServices.User
             *
             * @description
             *
             * Gera extrato de utilizador em PDF
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - Id do utilizador
             *
             *  - `from` – `{date}` - Data de início
             *
             *  - `to` – `{date}` - Data de fim
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "extract": {
              url: urlBase + "/users/:id/extract",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#updatePasswordFromToken
             * @methodOf lbServices.User
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `access_token` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `newPassword` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `passwordChanged` – `{boolean=}` -
             */
            "updatePasswordFromToken": {
              url: urlBase + "/users/update-password-from-token",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#createMany
             * @methodOf lbServices.User
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/users",
              method: "POST",
            },

            // INTERNAL. Use Group.users.findById() instead.
            "::findById::Group::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Group.users.destroyById() instead.
            "::destroyById::Group::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Group.users.updateById() instead.
            "::updateById::Group::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Group.users.link() instead.
            "::link::Group::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/users/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Group.users.unlink() instead.
            "::unlink::Group::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/users/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Group.users.exists() instead.
            "::exists::Group::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/groups/:id/users/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Group.users() instead.
            "::get::Group::users": {
              isArray: true,
              url: urlBase + "/groups/:id/users",
              method: "GET",
            },

            // INTERNAL. Use Group.users.create() instead.
            "::create::Group::users": {
              url: urlBase + "/groups/:id/users",
              method: "POST",
            },

            // INTERNAL. Use Group.users.createMany() instead.
            "::createMany::Group::users": {
              isArray: true,
              url: urlBase + "/groups/:id/users",
              method: "POST",
            },

            // INTERNAL. Use Group.users.destroyAll() instead.
            "::delete::Group::users": {
              url: urlBase + "/groups/:id/users",
              method: "DELETE",
            },

            // INTERNAL. Use Group.users.count() instead.
            "::count::Group::users": {
              url: urlBase + "/groups/:id/users/count",
              method: "GET",
            },

            // INTERNAL. Use Usergroup.user() instead.
            "::get::Usergroup::user": {
              url: urlBase + "/usergroups/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Identity.user() instead.
            "::get::Identity::user": {
              url: urlBase + "/identities/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Access.user() instead.
            "::get::Access::user": {
              url: urlBase + "/accesses/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Transaction.profile() instead.
            "::get::Transaction::profile": {
              url: urlBase + "/transactions/:id/profile",
              method: "GET",
            },

            // INTERNAL. Use Transaction.user() instead.
            "::get::Transaction::user": {
              url: urlBase + "/transactions/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Notification.user() instead.
            "::get::Notification::user": {
              url: urlBase + "/notifications/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Backlog.user() instead.
            "::get::Backlog::user": {
              url: urlBase + "/backlogs/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Payment.user() instead.
            "::get::Payment::user": {
              url: urlBase + "/payments/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Tenant.user() instead.
            "::get::Tenant::user": {
              url: urlBase + "/tenants/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Logger.user() instead.
            "::get::Logger::user": {
              url: urlBase + "/loggers/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.User#getCurrent
             * @methodOf lbServices.User
             *
             * @description
             *
             * Get data of the currently logged user. Fail with HTTP result 401
             * when there is no user logged in.
             *
             * @param {function(Object,Object)=} successCb
             *    Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *    `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             */
            'getCurrent': {
              url: urlBase + "/users" + '/:id',
              method: 'GET',
              params: {
                id: function() {
                  var id = LoopBackAuth.currentUserId;
                  if (id == null) id = '__anonymous__';
                  return id;
                },
              },
              interceptor: {
                response: function(response) {
                  LoopBackAuth.currentUserData = response.data;
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return $q.reject(responseError);
                },
              },
              __isGetCurrentUser__: true,
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.User#upsert
             * @methodOf lbServices.User
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.User#updateOrCreate
             * @methodOf lbServices.User
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.User#patchOrCreateWithWhere
             * @methodOf lbServices.User
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.User#update
             * @methodOf lbServices.User
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.User#destroyById
             * @methodOf lbServices.User
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.User#removeById
             * @methodOf lbServices.User
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.User#prototype$updateAttributes
             * @methodOf lbServices.User
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];

        /**
         * @ngdoc method
         * @name lbServices.User#getCachedCurrent
         * @methodOf lbServices.User
         *
         * @description
         *
         * Get data of the currently logged user that was returned by the last
         * call to {@link lbServices.User#login} or
         * {@link lbServices.User#getCurrent}. Return null when there
         * is no user logged in or the data of the current user were not fetched
         * yet.
         *
         * @returns {Object} A User instance.
         */
        R.getCachedCurrent = function() {
          var data = LoopBackAuth.currentUserData;
          return data ? new R(data) : null;
        };

        /**
         * @ngdoc method
         * @name lbServices.User#isAuthenticated
         * @methodOf lbServices.User
         *
         * @returns {boolean} True if the current user is authenticated (logged in).
         */
        R.isAuthenticated = function() {
          return this.getCurrentId() != null;
        };

        /**
         * @ngdoc method
         * @name lbServices.User#getCurrentId
         * @methodOf lbServices.User
         *
         * @returns {Object} Id of the currently logged-in user or null.
         */
        R.getCurrentId = function() {
          return LoopBackAuth.currentUserId;
        };

        /**
        * @ngdoc property
        * @name lbServices.User#modelName
        * @propertyOf lbServices.User
        * @description
        * The name of the model represented by this $resource,
        * i.e. `User`.
        */
        R.modelName = "User";

    /**
     * @ngdoc object
     * @name lbServices.User.groups
     * @header lbServices.User.groups
     * @object
     * @description
     *
     * The object `User.groups` groups methods
     * manipulating `Group` instances related to `User`.
     *
     * Call {@link lbServices.User#groups User.groups()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.User#groups
             * @methodOf lbServices.User
             *
             * @description
             *
             * Queries groups of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R.groups = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::get::User::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.groups#count
             * @methodOf lbServices.User.groups
             *
             * @description
             *
             * Counts groups of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.groups.count = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::count::User::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.groups#create
             * @methodOf lbServices.User.groups
             *
             * @description
             *
             * Creates a new instance in groups of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R.groups.create = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::create::User::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.groups#createMany
             * @methodOf lbServices.User.groups
             *
             * @description
             *
             * Creates a new instance in groups of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R.groups.createMany = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::createMany::User::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.groups#destroyAll
             * @methodOf lbServices.User.groups
             *
             * @description
             *
             * Deletes all groups of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.groups.destroyAll = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::delete::User::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.groups#destroyById
             * @methodOf lbServices.User.groups
             *
             * @description
             *
             * Delete a related item by id for groups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.groups.destroyById = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::destroyById::User::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.groups#exists
             * @methodOf lbServices.User.groups
             *
             * @description
             *
             * Check the existence of groups relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R.groups.exists = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::exists::User::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.groups#findById
             * @methodOf lbServices.User.groups
             *
             * @description
             *
             * Find a related item by id for groups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R.groups.findById = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::findById::User::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.groups#link
             * @methodOf lbServices.User.groups
             *
             * @description
             *
             * Add a related item by id for groups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R.groups.link = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::link::User::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.groups#unlink
             * @methodOf lbServices.User.groups
             *
             * @description
             *
             * Remove the groups relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.groups.unlink = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::unlink::User::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.groups#updateById
             * @methodOf lbServices.User.groups
             *
             * @description
             *
             * Update a related item by id for groups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R.groups.updateById = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::updateById::User::groups"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.User.identities
     * @header lbServices.User.identities
     * @object
     * @description
     *
     * The object `User.identities` groups methods
     * manipulating `Identity` instances related to `User`.
     *
     * Call {@link lbServices.User#identities User.identities()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.User#identities
             * @methodOf lbServices.User
             *
             * @description
             *
             * Queries identities of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
        R.identities = function() {
          var TargetResource = $injector.get("Identity");
          var action = TargetResource["::get::User::identities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.identities#count
             * @methodOf lbServices.User.identities
             *
             * @description
             *
             * Counts identities of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.identities.count = function() {
          var TargetResource = $injector.get("Identity");
          var action = TargetResource["::count::User::identities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.identities#create
             * @methodOf lbServices.User.identities
             *
             * @description
             *
             * Creates a new instance in identities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
        R.identities.create = function() {
          var TargetResource = $injector.get("Identity");
          var action = TargetResource["::create::User::identities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.identities#createMany
             * @methodOf lbServices.User.identities
             *
             * @description
             *
             * Creates a new instance in identities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
        R.identities.createMany = function() {
          var TargetResource = $injector.get("Identity");
          var action = TargetResource["::createMany::User::identities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.identities#destroyAll
             * @methodOf lbServices.User.identities
             *
             * @description
             *
             * Deletes all identities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.identities.destroyAll = function() {
          var TargetResource = $injector.get("Identity");
          var action = TargetResource["::delete::User::identities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.identities#destroyById
             * @methodOf lbServices.User.identities
             *
             * @description
             *
             * Delete a related item by id for identities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for identities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.identities.destroyById = function() {
          var TargetResource = $injector.get("Identity");
          var action = TargetResource["::destroyById::User::identities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.identities#findById
             * @methodOf lbServices.User.identities
             *
             * @description
             *
             * Find a related item by id for identities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for identities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
        R.identities.findById = function() {
          var TargetResource = $injector.get("Identity");
          var action = TargetResource["::findById::User::identities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.identities#updateById
             * @methodOf lbServices.User.identities
             *
             * @description
             *
             * Update a related item by id for identities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `fk` – `{*}` - Foreign key for identities
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
        R.identities.updateById = function() {
          var TargetResource = $injector.get("Identity");
          var action = TargetResource["::updateById::User::identities"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User#site
             * @methodOf lbServices.User
             *
             * @description
             *
             * Fetches belongsTo relation site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.site = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::get::User::site"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.User.access
     * @header lbServices.User.access
     * @object
     * @description
     *
     * The object `User.access` groups methods
     * manipulating `Access` instances related to `User`.
     *
     * Call {@link lbServices.User#access User.access()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.User#access
             * @methodOf lbServices.User
             *
             * @description
             *
             * Queries access of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R.access = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::get::User::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.access#count
             * @methodOf lbServices.User.access
             *
             * @description
             *
             * Counts access of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.access.count = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::count::User::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.access#create
             * @methodOf lbServices.User.access
             *
             * @description
             *
             * Creates a new instance in access of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R.access.create = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::create::User::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.access#createMany
             * @methodOf lbServices.User.access
             *
             * @description
             *
             * Creates a new instance in access of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R.access.createMany = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::createMany::User::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.access#destroyAll
             * @methodOf lbServices.User.access
             *
             * @description
             *
             * Deletes all access of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.access.destroyAll = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::delete::User::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.access#destroyById
             * @methodOf lbServices.User.access
             *
             * @description
             *
             * Delete a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.access.destroyById = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::destroyById::User::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.access#findById
             * @methodOf lbServices.User.access
             *
             * @description
             *
             * Find a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R.access.findById = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::findById::User::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.access#updateById
             * @methodOf lbServices.User.access
             *
             * @description
             *
             * Update a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R.access.updateById = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::updateById::User::access"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.User.balances
     * @header lbServices.User.balances
     * @object
     * @description
     *
     * The object `User.balances` groups methods
     * manipulating `Balance` instances related to `User`.
     *
     * Call {@link lbServices.User#balances User.balances()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.User#balances
             * @methodOf lbServices.User
             *
             * @description
             *
             * Queries balances of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
        R.balances = function() {
          var TargetResource = $injector.get("Balance");
          var action = TargetResource["::get::User::balances"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.balances#count
             * @methodOf lbServices.User.balances
             *
             * @description
             *
             * Counts balances of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.balances.count = function() {
          var TargetResource = $injector.get("Balance");
          var action = TargetResource["::count::User::balances"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.balances#create
             * @methodOf lbServices.User.balances
             *
             * @description
             *
             * Creates a new instance in balances of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
        R.balances.create = function() {
          var TargetResource = $injector.get("Balance");
          var action = TargetResource["::create::User::balances"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.balances#createMany
             * @methodOf lbServices.User.balances
             *
             * @description
             *
             * Creates a new instance in balances of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
        R.balances.createMany = function() {
          var TargetResource = $injector.get("Balance");
          var action = TargetResource["::createMany::User::balances"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.balances#destroyAll
             * @methodOf lbServices.User.balances
             *
             * @description
             *
             * Deletes all balances of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.balances.destroyAll = function() {
          var TargetResource = $injector.get("Balance");
          var action = TargetResource["::delete::User::balances"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.balances#destroyById
             * @methodOf lbServices.User.balances
             *
             * @description
             *
             * Delete a related item by id for balances.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for balances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.balances.destroyById = function() {
          var TargetResource = $injector.get("Balance");
          var action = TargetResource["::destroyById::User::balances"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.balances#findById
             * @methodOf lbServices.User.balances
             *
             * @description
             *
             * Find a related item by id for balances.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for balances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
        R.balances.findById = function() {
          var TargetResource = $injector.get("Balance");
          var action = TargetResource["::findById::User::balances"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.balances#updateById
             * @methodOf lbServices.User.balances
             *
             * @description
             *
             * Update a related item by id for balances.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `fk` – `{*}` - Foreign key for balances
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
        R.balances.updateById = function() {
          var TargetResource = $injector.get("Balance");
          var action = TargetResource["::updateById::User::balances"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.User.tenants
     * @header lbServices.User.tenants
     * @object
     * @description
     *
     * The object `User.tenants` groups methods
     * manipulating `Tenant` instances related to `User`.
     *
     * Call {@link lbServices.User#tenants User.tenants()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.User#tenants
             * @methodOf lbServices.User
             *
             * @description
             *
             * Queries tenants of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
        R.tenants = function() {
          var TargetResource = $injector.get("Tenant");
          var action = TargetResource["::get::User::tenants"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.tenants#count
             * @methodOf lbServices.User.tenants
             *
             * @description
             *
             * Counts tenants of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.tenants.count = function() {
          var TargetResource = $injector.get("Tenant");
          var action = TargetResource["::count::User::tenants"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.tenants#create
             * @methodOf lbServices.User.tenants
             *
             * @description
             *
             * Creates a new instance in tenants of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
        R.tenants.create = function() {
          var TargetResource = $injector.get("Tenant");
          var action = TargetResource["::create::User::tenants"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.tenants#createMany
             * @methodOf lbServices.User.tenants
             *
             * @description
             *
             * Creates a new instance in tenants of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
        R.tenants.createMany = function() {
          var TargetResource = $injector.get("Tenant");
          var action = TargetResource["::createMany::User::tenants"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.tenants#destroyAll
             * @methodOf lbServices.User.tenants
             *
             * @description
             *
             * Deletes all tenants of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tenants.destroyAll = function() {
          var TargetResource = $injector.get("Tenant");
          var action = TargetResource["::delete::User::tenants"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.tenants#destroyById
             * @methodOf lbServices.User.tenants
             *
             * @description
             *
             * Delete a related item by id for tenants.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tenants
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tenants.destroyById = function() {
          var TargetResource = $injector.get("Tenant");
          var action = TargetResource["::destroyById::User::tenants"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.tenants#findById
             * @methodOf lbServices.User.tenants
             *
             * @description
             *
             * Find a related item by id for tenants.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tenants
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
        R.tenants.findById = function() {
          var TargetResource = $injector.get("Tenant");
          var action = TargetResource["::findById::User::tenants"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.User.tenants#updateById
             * @methodOf lbServices.User.tenants
             *
             * @description
             *
             * Update a related item by id for tenants.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - user id
             *
             *  - `fk` – `{*}` - Foreign key for tenants
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
        R.tenants.updateById = function() {
          var TargetResource = $injector.get("Tenant");
          var action = TargetResource["::updateById::User::tenants"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Identity
 * @header lbServices.Identity
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Identity` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Identity",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/identities/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Identity.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/identities/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#create
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/identities",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#patchOrCreate
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/identities",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#replaceOrCreate
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/identities/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#upsertWithWhere
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/identities/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#exists
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/identities/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#findById
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/identities/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#replaceById
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/identities/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#find
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/identities",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#findOne
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/identities/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#updateAll
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/identities/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#deleteById
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/identities/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#count
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/identities/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#prototype$patchAttributes
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - identity id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/identities/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#createChangeStream
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/identities/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Identity#createMany
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/identities",
              method: "POST",
            },

            // INTERNAL. Use User.identities.findById() instead.
            "::findById::User::identities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/identities/:fk",
              method: "GET",
            },

            // INTERNAL. Use User.identities.destroyById() instead.
            "::destroyById::User::identities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/identities/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use User.identities.updateById() instead.
            "::updateById::User::identities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/identities/:fk",
              method: "PUT",
            },

            // INTERNAL. Use User.identities() instead.
            "::get::User::identities": {
              isArray: true,
              url: urlBase + "/users/:id/identities",
              method: "GET",
            },

            // INTERNAL. Use User.identities.create() instead.
            "::create::User::identities": {
              url: urlBase + "/users/:id/identities",
              method: "POST",
            },

            // INTERNAL. Use User.identities.createMany() instead.
            "::createMany::User::identities": {
              isArray: true,
              url: urlBase + "/users/:id/identities",
              method: "POST",
            },

            // INTERNAL. Use User.identities.destroyAll() instead.
            "::delete::User::identities": {
              url: urlBase + "/users/:id/identities",
              method: "DELETE",
            },

            // INTERNAL. Use User.identities.count() instead.
            "::count::User::identities": {
              url: urlBase + "/users/:id/identities/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Identity#upsert
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Identity#updateOrCreate
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Identity#patchOrCreateWithWhere
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Identity#update
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Identity#destroyById
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Identity#removeById
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Identity#prototype$updateAttributes
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - identity id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Identity` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Identity#modelName
        * @propertyOf lbServices.Identity
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Identity`.
        */
        R.modelName = "Identity";


            /**
             * @ngdoc method
             * @name lbServices.Identity#user
             * @methodOf lbServices.Identity
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - identity id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::get::Identity::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Evse
 * @header lbServices.Evse
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Evse` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Evse",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/evses/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Evse.site() instead.
            "prototype$__get__site": {
              url: urlBase + "/evses/:id/site",
              method: "GET",
            },

            // INTERNAL. Use Evse.transactions.findById() instead.
            "prototype$__findById__transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/transactions/:fk",
              method: "GET",
            },

            // INTERNAL. Use Evse.transactions.destroyById() instead.
            "prototype$__destroyById__transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/transactions/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.transactions.updateById() instead.
            "prototype$__updateById__transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/transactions/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Evse.transaction() instead.
            "prototype$__get__transaction": {
              url: urlBase + "/evses/:id/transaction",
              method: "GET",
            },

            // INTERNAL. Use Evse.info() instead.
            "prototype$__get__info": {
              url: urlBase + "/evses/:id/info",
              method: "GET",
            },

            // INTERNAL. Use Evse.evseProfile() instead.
            "prototype$__get__evseProfile": {
              url: urlBase + "/evses/:id/evseProfile",
              method: "GET",
            },

            // INTERNAL. Use Evse.comments.findById() instead.
            "prototype$__findById__comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/comments/:fk",
              method: "GET",
            },

            // INTERNAL. Use Evse.comments.destroyById() instead.
            "prototype$__destroyById__comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/comments/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.comments.updateById() instead.
            "prototype$__updateById__comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/comments/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Evse.tariff() instead.
            "prototype$__get__tariff": {
              url: urlBase + "/evses/:id/tariff",
              method: "GET",
            },

            // INTERNAL. Use Evse.asset() instead.
            "prototype$__get__asset": {
              url: urlBase + "/evses/:id/asset",
              method: "GET",
            },

            // INTERNAL. Use Evse.asset.create() instead.
            "prototype$__create__asset": {
              url: urlBase + "/evses/:id/asset",
              method: "POST",
            },

            // INTERNAL. Use Evse.asset.update() instead.
            "prototype$__update__asset": {
              url: urlBase + "/evses/:id/asset",
              method: "PUT",
            },

            // INTERNAL. Use Evse.asset.destroy() instead.
            "prototype$__destroy__asset": {
              url: urlBase + "/evses/:id/asset",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.evseGroup() instead.
            "prototype$__get__evseGroup": {
              url: urlBase + "/evses/:id/evseGroup",
              method: "GET",
            },

            // INTERNAL. Use Evse.qol.findById() instead.
            "prototype$__findById__qol": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/qol/:fk",
              method: "GET",
            },

            // INTERNAL. Use Evse.qol.destroyById() instead.
            "prototype$__destroyById__qol": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/qol/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.qol.updateById() instead.
            "prototype$__updateById__qol": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/qol/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Evse.metrics.findById() instead.
            "prototype$__findById__metrics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/metrics/:fk",
              method: "GET",
            },

            // INTERNAL. Use Evse.metrics.destroyById() instead.
            "prototype$__destroyById__metrics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/metrics/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.metrics.updateById() instead.
            "prototype$__updateById__metrics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/metrics/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Evse.transactions() instead.
            "prototype$__get__transactions": {
              isArray: true,
              url: urlBase + "/evses/:id/transactions",
              method: "GET",
            },

            // INTERNAL. Use Evse.transactions.create() instead.
            "prototype$__create__transactions": {
              url: urlBase + "/evses/:id/transactions",
              method: "POST",
            },

            // INTERNAL. Use Evse.transactions.destroyAll() instead.
            "prototype$__delete__transactions": {
              url: urlBase + "/evses/:id/transactions",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.transactions.count() instead.
            "prototype$__count__transactions": {
              url: urlBase + "/evses/:id/transactions/count",
              method: "GET",
            },

            // INTERNAL. Use Evse.comments() instead.
            "prototype$__get__comments": {
              isArray: true,
              url: urlBase + "/evses/:id/comments",
              method: "GET",
            },

            // INTERNAL. Use Evse.comments.create() instead.
            "prototype$__create__comments": {
              url: urlBase + "/evses/:id/comments",
              method: "POST",
            },

            // INTERNAL. Use Evse.comments.destroyAll() instead.
            "prototype$__delete__comments": {
              url: urlBase + "/evses/:id/comments",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.comments.count() instead.
            "prototype$__count__comments": {
              url: urlBase + "/evses/:id/comments/count",
              method: "GET",
            },

            // INTERNAL. Use Evse.qol() instead.
            "prototype$__get__qol": {
              isArray: true,
              url: urlBase + "/evses/:id/qol",
              method: "GET",
            },

            // INTERNAL. Use Evse.qol.create() instead.
            "prototype$__create__qol": {
              url: urlBase + "/evses/:id/qol",
              method: "POST",
            },

            // INTERNAL. Use Evse.qol.destroyAll() instead.
            "prototype$__delete__qol": {
              url: urlBase + "/evses/:id/qol",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.qol.count() instead.
            "prototype$__count__qol": {
              url: urlBase + "/evses/:id/qol/count",
              method: "GET",
            },

            // INTERNAL. Use Evse.metrics() instead.
            "prototype$__get__metrics": {
              isArray: true,
              url: urlBase + "/evses/:id/metrics",
              method: "GET",
            },

            // INTERNAL. Use Evse.metrics.create() instead.
            "prototype$__create__metrics": {
              url: urlBase + "/evses/:id/metrics",
              method: "POST",
            },

            // INTERNAL. Use Evse.metrics.destroyAll() instead.
            "prototype$__delete__metrics": {
              url: urlBase + "/evses/:id/metrics",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.metrics.count() instead.
            "prototype$__count__metrics": {
              url: urlBase + "/evses/:id/metrics/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#create
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/evses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#patchOrCreate
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/evses",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#replaceOrCreate
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/evses/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#upsertWithWhere
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/evses/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#exists
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/evses/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#findById
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/evses/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#replaceById
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/evses/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#find
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/evses",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#findOne
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/evses/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#updateAll
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/evses/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#deleteById
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/evses/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#count
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/evses/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#prototype$patchAttributes
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/evses/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#createChangeStream
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/evses/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#updateFirmware
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Send UpdateFirmware request to EVSE
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "updateFirmware": {
              url: urlBase + "/evses/:id/updateFirmware",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#sendLocalList
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Sends SendLocalList.req PDU
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `evseId` – `{string=}` - Charger identifier
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "sendLocalList": {
              url: urlBase + "/evses/sendLocalList",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#unlockConnector
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Send UnlockConnector request to EVSE
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `connectorId` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "unlockConnector": {
              url: urlBase + "/evses/unlockConnector",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#changeAvailability
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Send ChangeAvailability.req PDU
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `connectorId` – `{number=}` -
             *
             *  - `status` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "changeAvailability": {
              url: urlBase + "/evses/changeAvailability",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#reset
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Send Reset.req PDU
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "reset": {
              url: urlBase + "/evses/reset",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#postConfiguration
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Send ChangeConfiguration.req PDU
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `key` – `{string}` -
             *
             *  - `value` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "postConfiguration": {
              url: urlBase + "/evses/postConfiguration",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#getConfiguration
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Send GetConfiguration.req PDU
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `key` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "getConfiguration": {
              url: urlBase + "/evses/getConfiguration",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#dashCount
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Dashboard statistics
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `siteId` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "dashCount": {
              url: urlBase + "/evses/dashCount",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#stopTransaction
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Sends RemoteStopTransaction.req PDU
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `transactionId` – `{string}` - Transaction identifier
             *
             *  - `idTag` – `{string}` - Client identifier
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "stopTransaction": {
              url: urlBase + "/evses/stopTransaction",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#startTransaction
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Sends RemoteStartTransaction.req PDU
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `idTag` – `{string}` - Client card identifier
             *
             *  - `evseId` – `{string}` - Charger identifier
             *
             *  - `connectorId` – `{number=}` - Connector
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "startTransaction": {
              url: urlBase + "/evses/startTransaction",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#peakHours
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Get peak/off peak percentage for given charger
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "peakHours": {
              isArray: true,
              url: urlBase + "/evses/:id/peakHours",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#public
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Get list of public chargers in mobi.e network
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "public": {
              isArray: true,
              url: urlBase + "/evses/public",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#setChargingProfile
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Set Charging Profile for charger groups
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `connectorId` – `{number}` -
             *
             *  - `power` – `{number}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "setChargingProfile": {
              url: urlBase + "/evses/setChargingProfile",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#clearChargingProfile
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Clear Charging Profile for charger groups
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `connectorId` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "clearChargingProfile": {
              url: urlBase + "/evses/clearChargingProfile",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#connected
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Shows WebSocket connected data in real-time
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "connected": {
              isArray: true,
              url: urlBase + "/evses/connected",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#pending
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Shows WebSocket connected data in real-time, filtered by those not adopted
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "pending": {
              isArray: true,
              url: urlBase + "/evses/pending",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Evse#createMany
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/evses",
              method: "POST",
            },

            // INTERNAL. Use Site.evses.findById() instead.
            "::findById::Site::evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/evses/:fk",
              method: "GET",
            },

            // INTERNAL. Use Site.evses.destroyById() instead.
            "::destroyById::Site::evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/evses/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Site.evses.updateById() instead.
            "::updateById::Site::evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/evses/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Site.evses() instead.
            "::get::Site::evses": {
              isArray: true,
              url: urlBase + "/sites/:id/evses",
              method: "GET",
            },

            // INTERNAL. Use Site.evses.create() instead.
            "::create::Site::evses": {
              url: urlBase + "/sites/:id/evses",
              method: "POST",
            },

            // INTERNAL. Use Site.evses.createMany() instead.
            "::createMany::Site::evses": {
              isArray: true,
              url: urlBase + "/sites/:id/evses",
              method: "POST",
            },

            // INTERNAL. Use Site.evses.destroyAll() instead.
            "::delete::Site::evses": {
              url: urlBase + "/sites/:id/evses",
              method: "DELETE",
            },

            // INTERNAL. Use Site.evses.count() instead.
            "::count::Site::evses": {
              url: urlBase + "/sites/:id/evses/count",
              method: "GET",
            },

            // INTERNAL. Use Transaction.evse() instead.
            "::get::Transaction::evse": {
              url: urlBase + "/transactions/:id/evse",
              method: "GET",
            },

            // INTERNAL. Use Event.evse() instead.
            "::get::Event::evse": {
              url: urlBase + "/events/:id/evse",
              method: "GET",
            },

            // INTERNAL. Use EvseProfile.evses.findById() instead.
            "::findById::EvseProfile::evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseProfiles/:id/evses/:fk",
              method: "GET",
            },

            // INTERNAL. Use EvseProfile.evses.destroyById() instead.
            "::destroyById::EvseProfile::evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseProfiles/:id/evses/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use EvseProfile.evses.updateById() instead.
            "::updateById::EvseProfile::evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseProfiles/:id/evses/:fk",
              method: "PUT",
            },

            // INTERNAL. Use EvseProfile.evses() instead.
            "::get::EvseProfile::evses": {
              isArray: true,
              url: urlBase + "/evseProfiles/:id/evses",
              method: "GET",
            },

            // INTERNAL. Use EvseProfile.evses.create() instead.
            "::create::EvseProfile::evses": {
              url: urlBase + "/evseProfiles/:id/evses",
              method: "POST",
            },

            // INTERNAL. Use EvseProfile.evses.createMany() instead.
            "::createMany::EvseProfile::evses": {
              isArray: true,
              url: urlBase + "/evseProfiles/:id/evses",
              method: "POST",
            },

            // INTERNAL. Use EvseProfile.evses.destroyAll() instead.
            "::delete::EvseProfile::evses": {
              url: urlBase + "/evseProfiles/:id/evses",
              method: "DELETE",
            },

            // INTERNAL. Use EvseProfile.evses.count() instead.
            "::count::EvseProfile::evses": {
              url: urlBase + "/evseProfiles/:id/evses/count",
              method: "GET",
            },

            // INTERNAL. Use Comment.evse() instead.
            "::get::Comment::evse": {
              url: urlBase + "/comments/:id/evse",
              method: "GET",
            },

            // INTERNAL. Use Tariff.evses.findById() instead.
            "::findById::Tariff::evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/tariffs/:id/evses/:fk",
              method: "GET",
            },

            // INTERNAL. Use Tariff.evses.destroyById() instead.
            "::destroyById::Tariff::evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/tariffs/:id/evses/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Tariff.evses.updateById() instead.
            "::updateById::Tariff::evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/tariffs/:id/evses/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Tariff.evses() instead.
            "::get::Tariff::evses": {
              isArray: true,
              url: urlBase + "/tariffs/:id/evses",
              method: "GET",
            },

            // INTERNAL. Use Tariff.evses.create() instead.
            "::create::Tariff::evses": {
              url: urlBase + "/tariffs/:id/evses",
              method: "POST",
            },

            // INTERNAL. Use Tariff.evses.createMany() instead.
            "::createMany::Tariff::evses": {
              isArray: true,
              url: urlBase + "/tariffs/:id/evses",
              method: "POST",
            },

            // INTERNAL. Use Tariff.evses.destroyAll() instead.
            "::delete::Tariff::evses": {
              url: urlBase + "/tariffs/:id/evses",
              method: "DELETE",
            },

            // INTERNAL. Use Tariff.evses.count() instead.
            "::count::Tariff::evses": {
              url: urlBase + "/tariffs/:id/evses/count",
              method: "GET",
            },

            // INTERNAL. Use Alarm.evse() instead.
            "::get::Alarm::evse": {
              url: urlBase + "/alarms/:id/evse",
              method: "GET",
            },

            // INTERNAL. Use EvseGroups.evse.findById() instead.
            "::findById::EvseGroups::evse": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseGroups/:id/evse/:fk",
              method: "GET",
            },

            // INTERNAL. Use EvseGroups.evse.destroyById() instead.
            "::destroyById::EvseGroups::evse": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseGroups/:id/evse/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use EvseGroups.evse.updateById() instead.
            "::updateById::EvseGroups::evse": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseGroups/:id/evse/:fk",
              method: "PUT",
            },

            // INTERNAL. Use EvseGroups.evse() instead.
            "::get::EvseGroups::evse": {
              isArray: true,
              url: urlBase + "/evseGroups/:id/evse",
              method: "GET",
            },

            // INTERNAL. Use EvseGroups.evse.create() instead.
            "::create::EvseGroups::evse": {
              url: urlBase + "/evseGroups/:id/evse",
              method: "POST",
            },

            // INTERNAL. Use EvseGroups.evse.createMany() instead.
            "::createMany::EvseGroups::evse": {
              isArray: true,
              url: urlBase + "/evseGroups/:id/evse",
              method: "POST",
            },

            // INTERNAL. Use EvseGroups.evse.destroyAll() instead.
            "::delete::EvseGroups::evse": {
              url: urlBase + "/evseGroups/:id/evse",
              method: "DELETE",
            },

            // INTERNAL. Use EvseGroups.evse.count() instead.
            "::count::EvseGroups::evse": {
              url: urlBase + "/evseGroups/:id/evse/count",
              method: "GET",
            },

            // INTERNAL. Use EvseQol.evse.findById() instead.
            "::findById::EvseQol::evse": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseQols/:id/evse/:fk",
              method: "GET",
            },

            // INTERNAL. Use EvseQol.evse.destroyById() instead.
            "::destroyById::EvseQol::evse": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseQols/:id/evse/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use EvseQol.evse.updateById() instead.
            "::updateById::EvseQol::evse": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseQols/:id/evse/:fk",
              method: "PUT",
            },

            // INTERNAL. Use EvseQol.evse() instead.
            "::get::EvseQol::evse": {
              isArray: true,
              url: urlBase + "/evseQols/:id/evse",
              method: "GET",
            },

            // INTERNAL. Use EvseQol.evse.create() instead.
            "::create::EvseQol::evse": {
              url: urlBase + "/evseQols/:id/evse",
              method: "POST",
            },

            // INTERNAL. Use EvseQol.evse.createMany() instead.
            "::createMany::EvseQol::evse": {
              isArray: true,
              url: urlBase + "/evseQols/:id/evse",
              method: "POST",
            },

            // INTERNAL. Use EvseQol.evse.destroyAll() instead.
            "::delete::EvseQol::evse": {
              url: urlBase + "/evseQols/:id/evse",
              method: "DELETE",
            },

            // INTERNAL. Use EvseQol.evse.count() instead.
            "::count::EvseQol::evse": {
              url: urlBase + "/evseQols/:id/evse/count",
              method: "GET",
            },

            // INTERNAL. Use Logger.evse() instead.
            "::get::Logger::evse": {
              url: urlBase + "/loggers/:id/evse",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Evse#upsert
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Evse#updateOrCreate
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Evse#patchOrCreateWithWhere
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Evse#update
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Evse#destroyById
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Evse#removeById
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Evse#prototype$updateAttributes
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Evse#modelName
        * @propertyOf lbServices.Evse
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Evse`.
        */
        R.modelName = "Evse";


            /**
             * @ngdoc method
             * @name lbServices.Evse#site
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Fetches belongsTo relation site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.site = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::get::Evse::site"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Evse.transactions
     * @header lbServices.Evse.transactions
     * @object
     * @description
     *
     * The object `Evse.transactions` groups methods
     * manipulating `Transaction` instances related to `Evse`.
     *
     * Call {@link lbServices.Evse#transactions Evse.transactions()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Evse#transactions
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Queries transactions of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R.transactions = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::get::Evse::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.transactions#count
             * @methodOf lbServices.Evse.transactions
             *
             * @description
             *
             * Counts transactions of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.transactions.count = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::count::Evse::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.transactions#create
             * @methodOf lbServices.Evse.transactions
             *
             * @description
             *
             * Creates a new instance in transactions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R.transactions.create = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::create::Evse::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.transactions#createMany
             * @methodOf lbServices.Evse.transactions
             *
             * @description
             *
             * Creates a new instance in transactions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R.transactions.createMany = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::createMany::Evse::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.transactions#destroyAll
             * @methodOf lbServices.Evse.transactions
             *
             * @description
             *
             * Deletes all transactions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.transactions.destroyAll = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::delete::Evse::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.transactions#destroyById
             * @methodOf lbServices.Evse.transactions
             *
             * @description
             *
             * Delete a related item by id for transactions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for transactions
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.transactions.destroyById = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::destroyById::Evse::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.transactions#findById
             * @methodOf lbServices.Evse.transactions
             *
             * @description
             *
             * Find a related item by id for transactions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for transactions
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R.transactions.findById = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::findById::Evse::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.transactions#updateById
             * @methodOf lbServices.Evse.transactions
             *
             * @description
             *
             * Update a related item by id for transactions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `fk` – `{*}` - Foreign key for transactions
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R.transactions.updateById = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::updateById::Evse::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse#transaction
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Fetches belongsTo relation transaction.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R.transaction = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::get::Evse::transaction"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse#info
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Fetches belongsTo relation info.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
        R.info = function() {
          var TargetResource = $injector.get("EvseProfile");
          var action = TargetResource["::get::Evse::info"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse#evseProfile
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Fetches belongsTo relation evseProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
        R.evseProfile = function() {
          var TargetResource = $injector.get("EvseProfile");
          var action = TargetResource["::get::Evse::evseProfile"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Evse.comments
     * @header lbServices.Evse.comments
     * @object
     * @description
     *
     * The object `Evse.comments` groups methods
     * manipulating `Comment` instances related to `Evse`.
     *
     * Call {@link lbServices.Evse#comments Evse.comments()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Evse#comments
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Queries comments of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R.comments = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::get::Evse::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.comments#count
             * @methodOf lbServices.Evse.comments
             *
             * @description
             *
             * Counts comments of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.comments.count = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::count::Evse::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.comments#create
             * @methodOf lbServices.Evse.comments
             *
             * @description
             *
             * Creates a new instance in comments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R.comments.create = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::create::Evse::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.comments#createMany
             * @methodOf lbServices.Evse.comments
             *
             * @description
             *
             * Creates a new instance in comments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R.comments.createMany = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::createMany::Evse::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.comments#destroyAll
             * @methodOf lbServices.Evse.comments
             *
             * @description
             *
             * Deletes all comments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.comments.destroyAll = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::delete::Evse::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.comments#destroyById
             * @methodOf lbServices.Evse.comments
             *
             * @description
             *
             * Delete a related item by id for comments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for comments
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.comments.destroyById = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::destroyById::Evse::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.comments#findById
             * @methodOf lbServices.Evse.comments
             *
             * @description
             *
             * Find a related item by id for comments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for comments
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R.comments.findById = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::findById::Evse::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.comments#updateById
             * @methodOf lbServices.Evse.comments
             *
             * @description
             *
             * Update a related item by id for comments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `fk` – `{*}` - Foreign key for comments
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R.comments.updateById = function() {
          var TargetResource = $injector.get("Comment");
          var action = TargetResource["::updateById::Evse::comments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse#tariff
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Fetches belongsTo relation tariff.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
        R.tariff = function() {
          var TargetResource = $injector.get("Tariff");
          var action = TargetResource["::get::Evse::tariff"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Evse.asset
     * @header lbServices.Evse.asset
     * @object
     * @description
     *
     * The object `Evse.asset` groups methods
     * manipulating `Asset` instances related to `Evse`.
     *
     * Call {@link lbServices.Evse#asset Evse.asset()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Evse#asset
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Fetches hasOne relation asset.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Asset` object.)
             * </em>
             */
        R.asset = function() {
          var TargetResource = $injector.get("Asset");
          var action = TargetResource["::get::Evse::asset"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.asset#create
             * @methodOf lbServices.Evse.asset
             *
             * @description
             *
             * Creates a new instance in asset of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Asset` object.)
             * </em>
             */
        R.asset.create = function() {
          var TargetResource = $injector.get("Asset");
          var action = TargetResource["::create::Evse::asset"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.asset#createMany
             * @methodOf lbServices.Evse.asset
             *
             * @description
             *
             * Creates a new instance in asset of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Asset` object.)
             * </em>
             */
        R.asset.createMany = function() {
          var TargetResource = $injector.get("Asset");
          var action = TargetResource["::createMany::Evse::asset"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.asset#destroy
             * @methodOf lbServices.Evse.asset
             *
             * @description
             *
             * Deletes asset of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.asset.destroy = function() {
          var TargetResource = $injector.get("Asset");
          var action = TargetResource["::destroy::Evse::asset"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.asset#update
             * @methodOf lbServices.Evse.asset
             *
             * @description
             *
             * Update asset of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Asset` object.)
             * </em>
             */
        R.asset.update = function() {
          var TargetResource = $injector.get("Asset");
          var action = TargetResource["::update::Evse::asset"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse#evseGroup
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Fetches belongsTo relation evseGroup.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
        R.evseGroup = function() {
          var TargetResource = $injector.get("EvseGroups");
          var action = TargetResource["::get::Evse::evseGroup"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Evse.qol
     * @header lbServices.Evse.qol
     * @object
     * @description
     *
     * The object `Evse.qol` groups methods
     * manipulating `EvseQol` instances related to `Evse`.
     *
     * Call {@link lbServices.Evse#qol Evse.qol()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Evse#qol
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Queries qol of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
        R.qol = function() {
          var TargetResource = $injector.get("EvseQol");
          var action = TargetResource["::get::Evse::qol"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.qol#count
             * @methodOf lbServices.Evse.qol
             *
             * @description
             *
             * Counts qol of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.qol.count = function() {
          var TargetResource = $injector.get("EvseQol");
          var action = TargetResource["::count::Evse::qol"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.qol#create
             * @methodOf lbServices.Evse.qol
             *
             * @description
             *
             * Creates a new instance in qol of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
        R.qol.create = function() {
          var TargetResource = $injector.get("EvseQol");
          var action = TargetResource["::create::Evse::qol"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.qol#createMany
             * @methodOf lbServices.Evse.qol
             *
             * @description
             *
             * Creates a new instance in qol of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
        R.qol.createMany = function() {
          var TargetResource = $injector.get("EvseQol");
          var action = TargetResource["::createMany::Evse::qol"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.qol#destroyAll
             * @methodOf lbServices.Evse.qol
             *
             * @description
             *
             * Deletes all qol of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.qol.destroyAll = function() {
          var TargetResource = $injector.get("EvseQol");
          var action = TargetResource["::delete::Evse::qol"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.qol#destroyById
             * @methodOf lbServices.Evse.qol
             *
             * @description
             *
             * Delete a related item by id for qol.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for qol
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.qol.destroyById = function() {
          var TargetResource = $injector.get("EvseQol");
          var action = TargetResource["::destroyById::Evse::qol"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.qol#findById
             * @methodOf lbServices.Evse.qol
             *
             * @description
             *
             * Find a related item by id for qol.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for qol
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
        R.qol.findById = function() {
          var TargetResource = $injector.get("EvseQol");
          var action = TargetResource["::findById::Evse::qol"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.qol#updateById
             * @methodOf lbServices.Evse.qol
             *
             * @description
             *
             * Update a related item by id for qol.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `fk` – `{*}` - Foreign key for qol
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
        R.qol.updateById = function() {
          var TargetResource = $injector.get("EvseQol");
          var action = TargetResource["::updateById::Evse::qol"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Evse.metrics
     * @header lbServices.Evse.metrics
     * @object
     * @description
     *
     * The object `Evse.metrics` groups methods
     * manipulating `EvseMetric` instances related to `Evse`.
     *
     * Call {@link lbServices.Evse#metrics Evse.metrics()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Evse#metrics
             * @methodOf lbServices.Evse
             *
             * @description
             *
             * Queries metrics of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
        R.metrics = function() {
          var TargetResource = $injector.get("EvseMetric");
          var action = TargetResource["::get::Evse::metrics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.metrics#count
             * @methodOf lbServices.Evse.metrics
             *
             * @description
             *
             * Counts metrics of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.metrics.count = function() {
          var TargetResource = $injector.get("EvseMetric");
          var action = TargetResource["::count::Evse::metrics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.metrics#create
             * @methodOf lbServices.Evse.metrics
             *
             * @description
             *
             * Creates a new instance in metrics of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
        R.metrics.create = function() {
          var TargetResource = $injector.get("EvseMetric");
          var action = TargetResource["::create::Evse::metrics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.metrics#createMany
             * @methodOf lbServices.Evse.metrics
             *
             * @description
             *
             * Creates a new instance in metrics of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
        R.metrics.createMany = function() {
          var TargetResource = $injector.get("EvseMetric");
          var action = TargetResource["::createMany::Evse::metrics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.metrics#destroyAll
             * @methodOf lbServices.Evse.metrics
             *
             * @description
             *
             * Deletes all metrics of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.metrics.destroyAll = function() {
          var TargetResource = $injector.get("EvseMetric");
          var action = TargetResource["::delete::Evse::metrics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.metrics#destroyById
             * @methodOf lbServices.Evse.metrics
             *
             * @description
             *
             * Delete a related item by id for metrics.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for metrics
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.metrics.destroyById = function() {
          var TargetResource = $injector.get("EvseMetric");
          var action = TargetResource["::destroyById::Evse::metrics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.metrics#findById
             * @methodOf lbServices.Evse.metrics
             *
             * @description
             *
             * Find a related item by id for metrics.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for metrics
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
        R.metrics.findById = function() {
          var TargetResource = $injector.get("EvseMetric");
          var action = TargetResource["::findById::Evse::metrics"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Evse.metrics#updateById
             * @methodOf lbServices.Evse.metrics
             *
             * @description
             *
             * Update a related item by id for metrics.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evse id
             *
             *  - `fk` – `{*}` - Foreign key for metrics
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
        R.metrics.updateById = function() {
          var TargetResource = $injector.get("EvseMetric");
          var action = TargetResource["::updateById::Evse::metrics"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Entity
 * @header lbServices.Entity
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Entity` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Entity",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/entities/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Entity.sites.findById() instead.
            "prototype$__findById__sites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/entities/:id/sites/:fk",
              method: "GET",
            },

            // INTERNAL. Use Entity.sites.destroyById() instead.
            "prototype$__destroyById__sites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/entities/:id/sites/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Entity.sites.updateById() instead.
            "prototype$__updateById__sites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/entities/:id/sites/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Entity.sites() instead.
            "prototype$__get__sites": {
              isArray: true,
              url: urlBase + "/entities/:id/sites",
              method: "GET",
            },

            // INTERNAL. Use Entity.sites.create() instead.
            "prototype$__create__sites": {
              url: urlBase + "/entities/:id/sites",
              method: "POST",
            },

            // INTERNAL. Use Entity.sites.destroyAll() instead.
            "prototype$__delete__sites": {
              url: urlBase + "/entities/:id/sites",
              method: "DELETE",
            },

            // INTERNAL. Use Entity.sites.count() instead.
            "prototype$__count__sites": {
              url: urlBase + "/entities/:id/sites/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#create
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/entities",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#patchOrCreate
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/entities",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#replaceOrCreate
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/entities/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#upsertWithWhere
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/entities/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#exists
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/entities/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#findById
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/entities/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#replaceById
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/entities/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#find
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/entities",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#findOne
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/entities/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#updateAll
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/entities/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#deleteById
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/entities/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#count
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/entities/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#prototype$patchAttributes
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - entity id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/entities/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#createChangeStream
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/entities/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#loadDataFromTaxNumber
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Loads data from NIF.pt
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `no` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "loadDataFromTaxNumber": {
              url: urlBase + "/entities/fetchNIF",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#report
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Gera relatório da entidade em PDF
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - Id da entidade
             *
             *  - `from` – `{date}` - Data de início
             *
             *  - `to` – `{date}` - Data de fim
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "report": {
              url: urlBase + "/entities/:id/report",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#export
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Gera relatório da entidade em XLS
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - Id da entidade
             *
             *  - `from` – `{date}` - Data de início
             *
             *  - `to` – `{date}` - Data de fim
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "export": {
              url: urlBase + "/entities/:id/export",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#consumption
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Gera informação do consumo de uma entidade nos últimos 12 meses
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - Id da instalação
             *
             *  - `date` – `{date}` - Data de início
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "consumption": {
              isArray: true,
              url: urlBase + "/entities/:id/consumption",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Entity#createMany
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/entities",
              method: "POST",
            },

            // INTERNAL. Use Site.entity() instead.
            "::get::Site::entity": {
              url: urlBase + "/sites/:id/entity",
              method: "GET",
            },

            // INTERNAL. Use EvseGroups.entityId() instead.
            "::get::EvseGroups::entityId": {
              url: urlBase + "/evseGroups/:id/entityId",
              method: "GET",
            },

            // INTERNAL. Use Tenant.entity() instead.
            "::get::Tenant::entity": {
              url: urlBase + "/tenants/:id/entity",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Entity#upsert
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Entity#updateOrCreate
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Entity#patchOrCreateWithWhere
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Entity#update
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Entity#destroyById
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Entity#removeById
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Entity#prototype$updateAttributes
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - entity id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Entity#modelName
        * @propertyOf lbServices.Entity
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Entity`.
        */
        R.modelName = "Entity";

    /**
     * @ngdoc object
     * @name lbServices.Entity.sites
     * @header lbServices.Entity.sites
     * @object
     * @description
     *
     * The object `Entity.sites` groups methods
     * manipulating `Site` instances related to `Entity`.
     *
     * Call {@link lbServices.Entity#sites Entity.sites()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Entity#sites
             * @methodOf lbServices.Entity
             *
             * @description
             *
             * Queries sites of entity.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - entity id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.sites = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::get::Entity::sites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Entity.sites#count
             * @methodOf lbServices.Entity.sites
             *
             * @description
             *
             * Counts sites of entity.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - entity id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.sites.count = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::count::Entity::sites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Entity.sites#create
             * @methodOf lbServices.Entity.sites
             *
             * @description
             *
             * Creates a new instance in sites of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - entity id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.sites.create = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::create::Entity::sites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Entity.sites#createMany
             * @methodOf lbServices.Entity.sites
             *
             * @description
             *
             * Creates a new instance in sites of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - entity id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.sites.createMany = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::createMany::Entity::sites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Entity.sites#destroyAll
             * @methodOf lbServices.Entity.sites
             *
             * @description
             *
             * Deletes all sites of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - entity id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sites.destroyAll = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::delete::Entity::sites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Entity.sites#destroyById
             * @methodOf lbServices.Entity.sites
             *
             * @description
             *
             * Delete a related item by id for sites.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - entity id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sites
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.sites.destroyById = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::destroyById::Entity::sites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Entity.sites#findById
             * @methodOf lbServices.Entity.sites
             *
             * @description
             *
             * Find a related item by id for sites.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - entity id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for sites
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.sites.findById = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::findById::Entity::sites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Entity.sites#updateById
             * @methodOf lbServices.Entity.sites
             *
             * @description
             *
             * Update a related item by id for sites.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - entity id
             *
             *  - `fk` – `{*}` - Foreign key for sites
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.sites.updateById = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::updateById::Entity::sites"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Site
 * @header lbServices.Site
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Site` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Site",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/sites/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Site.evses.findById() instead.
            "prototype$__findById__evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/evses/:fk",
              method: "GET",
            },

            // INTERNAL. Use Site.evses.destroyById() instead.
            "prototype$__destroyById__evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/evses/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Site.evses.updateById() instead.
            "prototype$__updateById__evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/evses/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Site.entity() instead.
            "prototype$__get__entity": {
              url: urlBase + "/sites/:id/entity",
              method: "GET",
            },

            // INTERNAL. Use Site.profiles.findById() instead.
            "prototype$__findById__profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/profiles/:fk",
              method: "GET",
            },

            // INTERNAL. Use Site.profiles.destroyById() instead.
            "prototype$__destroyById__profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/profiles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Site.profiles.updateById() instead.
            "prototype$__updateById__profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/profiles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Site.access.findById() instead.
            "prototype$__findById__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/access/:fk",
              method: "GET",
            },

            // INTERNAL. Use Site.access.destroyById() instead.
            "prototype$__destroyById__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/access/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Site.access.updateById() instead.
            "prototype$__updateById__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/access/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Site.tariff() instead.
            "prototype$__get__tariff": {
              url: urlBase + "/sites/:id/tariff",
              method: "GET",
            },

            // INTERNAL. Use Site.evseGroups.findById() instead.
            "prototype$__findById__evseGroups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/evseGroups/:fk",
              method: "GET",
            },

            // INTERNAL. Use Site.evseGroups.destroyById() instead.
            "prototype$__destroyById__evseGroups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/evseGroups/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Site.evseGroups.updateById() instead.
            "prototype$__updateById__evseGroups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/evseGroups/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Site.evses() instead.
            "prototype$__get__evses": {
              isArray: true,
              url: urlBase + "/sites/:id/evses",
              method: "GET",
            },

            // INTERNAL. Use Site.evses.create() instead.
            "prototype$__create__evses": {
              url: urlBase + "/sites/:id/evses",
              method: "POST",
            },

            // INTERNAL. Use Site.evses.destroyAll() instead.
            "prototype$__delete__evses": {
              url: urlBase + "/sites/:id/evses",
              method: "DELETE",
            },

            // INTERNAL. Use Site.evses.count() instead.
            "prototype$__count__evses": {
              url: urlBase + "/sites/:id/evses/count",
              method: "GET",
            },

            // INTERNAL. Use Site.profiles() instead.
            "prototype$__get__profiles": {
              isArray: true,
              url: urlBase + "/sites/:id/profiles",
              method: "GET",
            },

            // INTERNAL. Use Site.profiles.create() instead.
            "prototype$__create__profiles": {
              url: urlBase + "/sites/:id/profiles",
              method: "POST",
            },

            // INTERNAL. Use Site.profiles.destroyAll() instead.
            "prototype$__delete__profiles": {
              url: urlBase + "/sites/:id/profiles",
              method: "DELETE",
            },

            // INTERNAL. Use Site.profiles.count() instead.
            "prototype$__count__profiles": {
              url: urlBase + "/sites/:id/profiles/count",
              method: "GET",
            },

            // INTERNAL. Use Site.access() instead.
            "prototype$__get__access": {
              isArray: true,
              url: urlBase + "/sites/:id/access",
              method: "GET",
            },

            // INTERNAL. Use Site.access.create() instead.
            "prototype$__create__access": {
              url: urlBase + "/sites/:id/access",
              method: "POST",
            },

            // INTERNAL. Use Site.access.destroyAll() instead.
            "prototype$__delete__access": {
              url: urlBase + "/sites/:id/access",
              method: "DELETE",
            },

            // INTERNAL. Use Site.access.count() instead.
            "prototype$__count__access": {
              url: urlBase + "/sites/:id/access/count",
              method: "GET",
            },

            // INTERNAL. Use Site.evseGroups() instead.
            "prototype$__get__evseGroups": {
              isArray: true,
              url: urlBase + "/sites/:id/evseGroups",
              method: "GET",
            },

            // INTERNAL. Use Site.evseGroups.create() instead.
            "prototype$__create__evseGroups": {
              url: urlBase + "/sites/:id/evseGroups",
              method: "POST",
            },

            // INTERNAL. Use Site.evseGroups.destroyAll() instead.
            "prototype$__delete__evseGroups": {
              url: urlBase + "/sites/:id/evseGroups",
              method: "DELETE",
            },

            // INTERNAL. Use Site.evseGroups.count() instead.
            "prototype$__count__evseGroups": {
              url: urlBase + "/sites/:id/evseGroups/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#create
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/sites",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#patchOrCreate
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/sites",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#replaceOrCreate
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/sites/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#upsertWithWhere
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/sites/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#exists
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/sites/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#findById
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/sites/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#replaceById
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/sites/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#find
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/sites",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#findOne
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/sites/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#updateAll
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/sites/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#deleteById
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/sites/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#count
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/sites/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#prototype$patchAttributes
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/sites/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#createChangeStream
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/sites/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#consumption
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Gera informação do consumo de uma instalação nos últimos 12 meses
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - Id da instalação
             *
             *  - `date` – `{date}` - Data de início
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
            "consumption": {
              isArray: true,
              url: urlBase + "/sites/:id/consumption",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#report
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Gera relatório de instalação em PDF
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - Id da instalação
             *
             *  - `from` – `{date}` - Data de início
             *
             *  - `to` – `{date}` - Data de fim
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
            "report": {
              url: urlBase + "/sites/:id/report",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#export
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Gera relatório de instalação em XLS
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - Id da instalação
             *
             *  - `from` – `{date}` - Data de início
             *
             *  - `to` – `{date}` - Data de fim
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
            "export": {
              url: urlBase + "/sites/:id/export",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Site#createMany
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/sites",
              method: "POST",
            },

            // INTERNAL. Use User.site() instead.
            "::get::User::site": {
              url: urlBase + "/users/:id/site",
              method: "GET",
            },

            // INTERNAL. Use Evse.site() instead.
            "::get::Evse::site": {
              url: urlBase + "/evses/:id/site",
              method: "GET",
            },

            // INTERNAL. Use Entity.sites.findById() instead.
            "::findById::Entity::sites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/entities/:id/sites/:fk",
              method: "GET",
            },

            // INTERNAL. Use Entity.sites.destroyById() instead.
            "::destroyById::Entity::sites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/entities/:id/sites/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Entity.sites.updateById() instead.
            "::updateById::Entity::sites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/entities/:id/sites/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Entity.sites() instead.
            "::get::Entity::sites": {
              isArray: true,
              url: urlBase + "/entities/:id/sites",
              method: "GET",
            },

            // INTERNAL. Use Entity.sites.create() instead.
            "::create::Entity::sites": {
              url: urlBase + "/entities/:id/sites",
              method: "POST",
            },

            // INTERNAL. Use Entity.sites.createMany() instead.
            "::createMany::Entity::sites": {
              isArray: true,
              url: urlBase + "/entities/:id/sites",
              method: "POST",
            },

            // INTERNAL. Use Entity.sites.destroyAll() instead.
            "::delete::Entity::sites": {
              url: urlBase + "/entities/:id/sites",
              method: "DELETE",
            },

            // INTERNAL. Use Entity.sites.count() instead.
            "::count::Entity::sites": {
              url: urlBase + "/entities/:id/sites/count",
              method: "GET",
            },

            // INTERNAL. Use Profile.site() instead.
            "::get::Profile::site": {
              url: urlBase + "/profiles/:id/site",
              method: "GET",
            },

            // INTERNAL. Use Access.site() instead.
            "::get::Access::site": {
              url: urlBase + "/accesses/:id/site",
              method: "GET",
            },

            // INTERNAL. Use Transaction.site() instead.
            "::get::Transaction::site": {
              url: urlBase + "/transactions/:id/site",
              method: "GET",
            },

            // INTERNAL. Use Event.site() instead.
            "::get::Event::site": {
              url: urlBase + "/events/:id/site",
              method: "GET",
            },

            // INTERNAL. Use Alarm.site() instead.
            "::get::Alarm::site": {
              url: urlBase + "/alarms/:id/site",
              method: "GET",
            },

            // INTERNAL. Use EvseGroups.site() instead.
            "::get::EvseGroups::site": {
              url: urlBase + "/evseGroups/:id/site",
              method: "GET",
            },

            // INTERNAL. Use Balance.site() instead.
            "::get::Balance::site": {
              url: urlBase + "/balances/:id/site",
              method: "GET",
            },

            // INTERNAL. Use Logger.site() instead.
            "::get::Logger::site": {
              url: urlBase + "/loggers/:id/site",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Site#upsert
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Site#updateOrCreate
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Site#patchOrCreateWithWhere
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Site#update
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Site#destroyById
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Site#removeById
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Site#prototype$updateAttributes
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Site#modelName
        * @propertyOf lbServices.Site
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Site`.
        */
        R.modelName = "Site";

    /**
     * @ngdoc object
     * @name lbServices.Site.evses
     * @header lbServices.Site.evses
     * @object
     * @description
     *
     * The object `Site.evses` groups methods
     * manipulating `Evse` instances related to `Site`.
     *
     * Call {@link lbServices.Site#evses Site.evses()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Site#evses
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Queries evses of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::get::Site::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.evses#count
             * @methodOf lbServices.Site.evses
             *
             * @description
             *
             * Counts evses of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.evses.count = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::count::Site::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.evses#create
             * @methodOf lbServices.Site.evses
             *
             * @description
             *
             * Creates a new instance in evses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses.create = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::create::Site::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.evses#createMany
             * @methodOf lbServices.Site.evses
             *
             * @description
             *
             * Creates a new instance in evses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses.createMany = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::createMany::Site::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.evses#destroyAll
             * @methodOf lbServices.Site.evses
             *
             * @description
             *
             * Deletes all evses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.evses.destroyAll = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::delete::Site::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.evses#destroyById
             * @methodOf lbServices.Site.evses
             *
             * @description
             *
             * Delete a related item by id for evses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evses
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.evses.destroyById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::destroyById::Site::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.evses#findById
             * @methodOf lbServices.Site.evses
             *
             * @description
             *
             * Find a related item by id for evses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evses
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses.findById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::findById::Site::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.evses#updateById
             * @methodOf lbServices.Site.evses
             *
             * @description
             *
             * Update a related item by id for evses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `fk` – `{*}` - Foreign key for evses
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses.updateById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::updateById::Site::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site#entity
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Fetches belongsTo relation entity.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
        R.entity = function() {
          var TargetResource = $injector.get("Entity");
          var action = TargetResource["::get::Site::entity"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Site.profiles
     * @header lbServices.Site.profiles
     * @object
     * @description
     *
     * The object `Site.profiles` groups methods
     * manipulating `Profile` instances related to `Site`.
     *
     * Call {@link lbServices.Site#profiles Site.profiles()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Site#profiles
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Queries profiles of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R.profiles = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::get::Site::profiles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.profiles#count
             * @methodOf lbServices.Site.profiles
             *
             * @description
             *
             * Counts profiles of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.profiles.count = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::count::Site::profiles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.profiles#create
             * @methodOf lbServices.Site.profiles
             *
             * @description
             *
             * Creates a new instance in profiles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R.profiles.create = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::create::Site::profiles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.profiles#createMany
             * @methodOf lbServices.Site.profiles
             *
             * @description
             *
             * Creates a new instance in profiles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R.profiles.createMany = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::createMany::Site::profiles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.profiles#destroyAll
             * @methodOf lbServices.Site.profiles
             *
             * @description
             *
             * Deletes all profiles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.profiles.destroyAll = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::delete::Site::profiles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.profiles#destroyById
             * @methodOf lbServices.Site.profiles
             *
             * @description
             *
             * Delete a related item by id for profiles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for profiles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.profiles.destroyById = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::destroyById::Site::profiles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.profiles#findById
             * @methodOf lbServices.Site.profiles
             *
             * @description
             *
             * Find a related item by id for profiles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for profiles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R.profiles.findById = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::findById::Site::profiles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.profiles#updateById
             * @methodOf lbServices.Site.profiles
             *
             * @description
             *
             * Update a related item by id for profiles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `fk` – `{*}` - Foreign key for profiles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R.profiles.updateById = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::updateById::Site::profiles"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Site.access
     * @header lbServices.Site.access
     * @object
     * @description
     *
     * The object `Site.access` groups methods
     * manipulating `Access` instances related to `Site`.
     *
     * Call {@link lbServices.Site#access Site.access()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Site#access
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Queries access of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R.access = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::get::Site::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.access#count
             * @methodOf lbServices.Site.access
             *
             * @description
             *
             * Counts access of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.access.count = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::count::Site::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.access#create
             * @methodOf lbServices.Site.access
             *
             * @description
             *
             * Creates a new instance in access of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R.access.create = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::create::Site::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.access#createMany
             * @methodOf lbServices.Site.access
             *
             * @description
             *
             * Creates a new instance in access of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R.access.createMany = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::createMany::Site::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.access#destroyAll
             * @methodOf lbServices.Site.access
             *
             * @description
             *
             * Deletes all access of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.access.destroyAll = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::delete::Site::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.access#destroyById
             * @methodOf lbServices.Site.access
             *
             * @description
             *
             * Delete a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.access.destroyById = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::destroyById::Site::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.access#findById
             * @methodOf lbServices.Site.access
             *
             * @description
             *
             * Find a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R.access.findById = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::findById::Site::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.access#updateById
             * @methodOf lbServices.Site.access
             *
             * @description
             *
             * Update a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R.access.updateById = function() {
          var TargetResource = $injector.get("Access");
          var action = TargetResource["::updateById::Site::access"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site#tariff
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Fetches belongsTo relation tariff.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
        R.tariff = function() {
          var TargetResource = $injector.get("Tariff");
          var action = TargetResource["::get::Site::tariff"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Site.evseGroups
     * @header lbServices.Site.evseGroups
     * @object
     * @description
     *
     * The object `Site.evseGroups` groups methods
     * manipulating `EvseGroups` instances related to `Site`.
     *
     * Call {@link lbServices.Site#evseGroups Site.evseGroups()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Site#evseGroups
             * @methodOf lbServices.Site
             *
             * @description
             *
             * Queries evseGroups of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
        R.evseGroups = function() {
          var TargetResource = $injector.get("EvseGroups");
          var action = TargetResource["::get::Site::evseGroups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.evseGroups#count
             * @methodOf lbServices.Site.evseGroups
             *
             * @description
             *
             * Counts evseGroups of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.evseGroups.count = function() {
          var TargetResource = $injector.get("EvseGroups");
          var action = TargetResource["::count::Site::evseGroups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.evseGroups#create
             * @methodOf lbServices.Site.evseGroups
             *
             * @description
             *
             * Creates a new instance in evseGroups of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
        R.evseGroups.create = function() {
          var TargetResource = $injector.get("EvseGroups");
          var action = TargetResource["::create::Site::evseGroups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.evseGroups#createMany
             * @methodOf lbServices.Site.evseGroups
             *
             * @description
             *
             * Creates a new instance in evseGroups of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
        R.evseGroups.createMany = function() {
          var TargetResource = $injector.get("EvseGroups");
          var action = TargetResource["::createMany::Site::evseGroups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.evseGroups#destroyAll
             * @methodOf lbServices.Site.evseGroups
             *
             * @description
             *
             * Deletes all evseGroups of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.evseGroups.destroyAll = function() {
          var TargetResource = $injector.get("EvseGroups");
          var action = TargetResource["::delete::Site::evseGroups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.evseGroups#destroyById
             * @methodOf lbServices.Site.evseGroups
             *
             * @description
             *
             * Delete a related item by id for evseGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evseGroups
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.evseGroups.destroyById = function() {
          var TargetResource = $injector.get("EvseGroups");
          var action = TargetResource["::destroyById::Site::evseGroups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.evseGroups#findById
             * @methodOf lbServices.Site.evseGroups
             *
             * @description
             *
             * Find a related item by id for evseGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evseGroups
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
        R.evseGroups.findById = function() {
          var TargetResource = $injector.get("EvseGroups");
          var action = TargetResource["::findById::Site::evseGroups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Site.evseGroups#updateById
             * @methodOf lbServices.Site.evseGroups
             *
             * @description
             *
             * Update a related item by id for evseGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - site id
             *
             *  - `fk` – `{*}` - Foreign key for evseGroups
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
        R.evseGroups.updateById = function() {
          var TargetResource = $injector.get("EvseGroups");
          var action = TargetResource["::updateById::Site::evseGroups"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Link
 * @header lbServices.Link
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Link` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Link",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/links/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Link#create
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/links",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Link#patchOrCreate
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/links",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Link#replaceOrCreate
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/links/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Link#upsertWithWhere
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/links/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Link#exists
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/links/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Link#findById
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/links/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Link#replaceById
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/links/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Link#find
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/links",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Link#findOne
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/links/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Link#updateAll
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/links/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Link#deleteById
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/links/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Link#count
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/links/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Link#prototype$patchAttributes
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - link id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/links/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Link#createChangeStream
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/links/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Link#createMany
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/links",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Link#upsert
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Link#updateOrCreate
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Link#patchOrCreateWithWhere
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Link#update
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Link#destroyById
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Link#removeById
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Link#prototype$updateAttributes
             * @methodOf lbServices.Link
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - link id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Link` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Link#modelName
        * @propertyOf lbServices.Link
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Link`.
        */
        R.modelName = "Link";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Profile
 * @header lbServices.Profile
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Profile` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Profile",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/profiles/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Profile.site() instead.
            "prototype$__get__site": {
              url: urlBase + "/profiles/:id/site",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#prototype$__findById___cards
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Find a related item by id for _cards.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for _cards
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "prototype$__findById___cards": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/profiles/:id/_cards/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#prototype$__destroyById___cards
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Delete a related item by id for _cards.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for _cards
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById___cards": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/profiles/:id/_cards/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#prototype$__updateById___cards
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Update a related item by id for _cards.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `fk` – `{*}` - Foreign key for _cards
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "prototype$__updateById___cards": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/profiles/:id/_cards/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Profile.transactions.findById() instead.
            "prototype$__findById__transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/profiles/:id/transactions/:fk",
              method: "GET",
            },

            // INTERNAL. Use Profile.transactions.destroyById() instead.
            "prototype$__destroyById__transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/profiles/:id/transactions/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Profile.transactions.updateById() instead.
            "prototype$__updateById__transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/profiles/:id/transactions/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Profile.client() instead.
            "prototype$__get__client": {
              url: urlBase + "/profiles/:id/client",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#prototype$__get___cards
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Queries _cards of profile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "prototype$__get___cards": {
              isArray: true,
              url: urlBase + "/profiles/:id/_cards",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#prototype$__create___cards
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Creates a new instance in _cards of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "prototype$__create___cards": {
              url: urlBase + "/profiles/:id/_cards",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#prototype$__delete___cards
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Deletes all _cards of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete___cards": {
              url: urlBase + "/profiles/:id/_cards",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#prototype$__count___cards
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Counts _cards of profile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count___cards": {
              url: urlBase + "/profiles/:id/_cards/count",
              method: "GET",
            },

            // INTERNAL. Use Profile.transactions() instead.
            "prototype$__get__transactions": {
              isArray: true,
              url: urlBase + "/profiles/:id/transactions",
              method: "GET",
            },

            // INTERNAL. Use Profile.transactions.create() instead.
            "prototype$__create__transactions": {
              url: urlBase + "/profiles/:id/transactions",
              method: "POST",
            },

            // INTERNAL. Use Profile.transactions.destroyAll() instead.
            "prototype$__delete__transactions": {
              url: urlBase + "/profiles/:id/transactions",
              method: "DELETE",
            },

            // INTERNAL. Use Profile.transactions.count() instead.
            "prototype$__count__transactions": {
              url: urlBase + "/profiles/:id/transactions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#create
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/profiles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#patchOrCreate
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/profiles",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#replaceOrCreate
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/profiles/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#upsertWithWhere
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/profiles/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#exists
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/profiles/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#findById
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/profiles/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#replaceById
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/profiles/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#find
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/profiles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#findOne
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/profiles/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#updateAll
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/profiles/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#deleteById
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/profiles/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#count
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/profiles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#prototype$patchAttributes
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/profiles/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#createChangeStream
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/profiles/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Profile#createMany
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/profiles",
              method: "POST",
            },

            // INTERNAL. Use Site.profiles.findById() instead.
            "::findById::Site::profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/profiles/:fk",
              method: "GET",
            },

            // INTERNAL. Use Site.profiles.destroyById() instead.
            "::destroyById::Site::profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/profiles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Site.profiles.updateById() instead.
            "::updateById::Site::profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/profiles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Site.profiles() instead.
            "::get::Site::profiles": {
              isArray: true,
              url: urlBase + "/sites/:id/profiles",
              method: "GET",
            },

            // INTERNAL. Use Site.profiles.create() instead.
            "::create::Site::profiles": {
              url: urlBase + "/sites/:id/profiles",
              method: "POST",
            },

            // INTERNAL. Use Site.profiles.createMany() instead.
            "::createMany::Site::profiles": {
              isArray: true,
              url: urlBase + "/sites/:id/profiles",
              method: "POST",
            },

            // INTERNAL. Use Site.profiles.destroyAll() instead.
            "::delete::Site::profiles": {
              url: urlBase + "/sites/:id/profiles",
              method: "DELETE",
            },

            // INTERNAL. Use Site.profiles.count() instead.
            "::count::Site::profiles": {
              url: urlBase + "/sites/:id/profiles/count",
              method: "GET",
            },

            // INTERNAL. Use Event.profile() instead.
            "::get::Event::profile": {
              url: urlBase + "/events/:id/profile",
              method: "GET",
            },

            // INTERNAL. Use Client.profiles.findById() instead.
            "::findById::Client::profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/clients/:id/profiles/:fk",
              method: "GET",
            },

            // INTERNAL. Use Client.profiles.destroyById() instead.
            "::destroyById::Client::profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/clients/:id/profiles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Client.profiles.updateById() instead.
            "::updateById::Client::profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/clients/:id/profiles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Client.profiles() instead.
            "::get::Client::profiles": {
              isArray: true,
              url: urlBase + "/clients/:id/profiles",
              method: "GET",
            },

            // INTERNAL. Use Client.profiles.create() instead.
            "::create::Client::profiles": {
              url: urlBase + "/clients/:id/profiles",
              method: "POST",
            },

            // INTERNAL. Use Client.profiles.createMany() instead.
            "::createMany::Client::profiles": {
              isArray: true,
              url: urlBase + "/clients/:id/profiles",
              method: "POST",
            },

            // INTERNAL. Use Client.profiles.destroyAll() instead.
            "::delete::Client::profiles": {
              url: urlBase + "/clients/:id/profiles",
              method: "DELETE",
            },

            // INTERNAL. Use Client.profiles.count() instead.
            "::count::Client::profiles": {
              url: urlBase + "/clients/:id/profiles/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Profile#upsert
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Profile#updateOrCreate
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Profile#patchOrCreateWithWhere
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Profile#update
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Profile#destroyById
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Profile#removeById
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Profile#prototype$updateAttributes
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Profile#modelName
        * @propertyOf lbServices.Profile
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Profile`.
        */
        R.modelName = "Profile";


            /**
             * @ngdoc method
             * @name lbServices.Profile#site
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Fetches belongsTo relation site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.site = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::get::Profile::site"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Profile.transactions
     * @header lbServices.Profile.transactions
     * @object
     * @description
     *
     * The object `Profile.transactions` groups methods
     * manipulating `Transaction` instances related to `Profile`.
     *
     * Call {@link lbServices.Profile#transactions Profile.transactions()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Profile#transactions
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Queries transactions of profile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R.transactions = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::get::Profile::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Profile.transactions#count
             * @methodOf lbServices.Profile.transactions
             *
             * @description
             *
             * Counts transactions of profile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.transactions.count = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::count::Profile::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Profile.transactions#create
             * @methodOf lbServices.Profile.transactions
             *
             * @description
             *
             * Creates a new instance in transactions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R.transactions.create = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::create::Profile::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Profile.transactions#createMany
             * @methodOf lbServices.Profile.transactions
             *
             * @description
             *
             * Creates a new instance in transactions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R.transactions.createMany = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::createMany::Profile::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Profile.transactions#destroyAll
             * @methodOf lbServices.Profile.transactions
             *
             * @description
             *
             * Deletes all transactions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.transactions.destroyAll = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::delete::Profile::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Profile.transactions#destroyById
             * @methodOf lbServices.Profile.transactions
             *
             * @description
             *
             * Delete a related item by id for transactions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for transactions
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.transactions.destroyById = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::destroyById::Profile::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Profile.transactions#findById
             * @methodOf lbServices.Profile.transactions
             *
             * @description
             *
             * Find a related item by id for transactions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for transactions
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R.transactions.findById = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::findById::Profile::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Profile.transactions#updateById
             * @methodOf lbServices.Profile.transactions
             *
             * @description
             *
             * Update a related item by id for transactions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `fk` – `{*}` - Foreign key for transactions
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R.transactions.updateById = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::updateById::Profile::transactions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Profile#client
             * @methodOf lbServices.Profile
             *
             * @description
             *
             * Fetches belongsTo relation client.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - profile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
        R.client = function() {
          var TargetResource = $injector.get("Client");
          var action = TargetResource["::get::Profile::client"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Access
 * @header lbServices.Access
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Access` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Access",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/accesses/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Access.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/accesses/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Access.site() instead.
            "prototype$__get__site": {
              url: urlBase + "/accesses/:id/site",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#create
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/accesses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#patchOrCreate
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/accesses",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#replaceOrCreate
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/accesses/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#upsertWithWhere
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/accesses/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#exists
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/accesses/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#findById
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/accesses/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#replaceById
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/accesses/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#find
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/accesses",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#findOne
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/accesses/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#updateAll
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/accesses/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#deleteById
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/accesses/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#count
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/accesses/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#prototype$patchAttributes
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - access id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/accesses/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#createChangeStream
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/accesses/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Access#createMany
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/accesses",
              method: "POST",
            },

            // INTERNAL. Use User.access.findById() instead.
            "::findById::User::access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/access/:fk",
              method: "GET",
            },

            // INTERNAL. Use User.access.destroyById() instead.
            "::destroyById::User::access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/access/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use User.access.updateById() instead.
            "::updateById::User::access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/access/:fk",
              method: "PUT",
            },

            // INTERNAL. Use User.access() instead.
            "::get::User::access": {
              isArray: true,
              url: urlBase + "/users/:id/access",
              method: "GET",
            },

            // INTERNAL. Use User.access.create() instead.
            "::create::User::access": {
              url: urlBase + "/users/:id/access",
              method: "POST",
            },

            // INTERNAL. Use User.access.createMany() instead.
            "::createMany::User::access": {
              isArray: true,
              url: urlBase + "/users/:id/access",
              method: "POST",
            },

            // INTERNAL. Use User.access.destroyAll() instead.
            "::delete::User::access": {
              url: urlBase + "/users/:id/access",
              method: "DELETE",
            },

            // INTERNAL. Use User.access.count() instead.
            "::count::User::access": {
              url: urlBase + "/users/:id/access/count",
              method: "GET",
            },

            // INTERNAL. Use Site.access.findById() instead.
            "::findById::Site::access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/access/:fk",
              method: "GET",
            },

            // INTERNAL. Use Site.access.destroyById() instead.
            "::destroyById::Site::access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/access/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Site.access.updateById() instead.
            "::updateById::Site::access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/access/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Site.access() instead.
            "::get::Site::access": {
              isArray: true,
              url: urlBase + "/sites/:id/access",
              method: "GET",
            },

            // INTERNAL. Use Site.access.create() instead.
            "::create::Site::access": {
              url: urlBase + "/sites/:id/access",
              method: "POST",
            },

            // INTERNAL. Use Site.access.createMany() instead.
            "::createMany::Site::access": {
              isArray: true,
              url: urlBase + "/sites/:id/access",
              method: "POST",
            },

            // INTERNAL. Use Site.access.destroyAll() instead.
            "::delete::Site::access": {
              url: urlBase + "/sites/:id/access",
              method: "DELETE",
            },

            // INTERNAL. Use Site.access.count() instead.
            "::count::Site::access": {
              url: urlBase + "/sites/:id/access/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Access#upsert
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Access#updateOrCreate
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Access#patchOrCreateWithWhere
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Access#update
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Access#destroyById
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Access#removeById
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Access#prototype$updateAttributes
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - access id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Access` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Access#modelName
        * @propertyOf lbServices.Access
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Access`.
        */
        R.modelName = "Access";


            /**
             * @ngdoc method
             * @name lbServices.Access#user
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - access id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::get::Access::user"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Access#site
             * @methodOf lbServices.Access
             *
             * @description
             *
             * Fetches belongsTo relation site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - access id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.site = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::get::Access::site"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Transaction
 * @header lbServices.Transaction
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Transaction` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Transaction",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/transactions/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Transaction.profile() instead.
            "prototype$__get__profile": {
              url: urlBase + "/transactions/:id/profile",
              method: "GET",
            },

            // INTERNAL. Use Transaction.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/transactions/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Transaction.evse() instead.
            "prototype$__get__evse": {
              url: urlBase + "/transactions/:id/evse",
              method: "GET",
            },

            // INTERNAL. Use Transaction.site() instead.
            "prototype$__get__site": {
              url: urlBase + "/transactions/:id/site",
              method: "GET",
            },

            // INTERNAL. Use Transaction.consumptionData.findById() instead.
            "prototype$__findById__consumptionData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/transactions/:id/consumptionData/:fk",
              method: "GET",
            },

            // INTERNAL. Use Transaction.consumptionData.destroyById() instead.
            "prototype$__destroyById__consumptionData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/transactions/:id/consumptionData/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Transaction.consumptionData.updateById() instead.
            "prototype$__updateById__consumptionData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/transactions/:id/consumptionData/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Transaction.consumption.findById() instead.
            "prototype$__findById__consumption": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/transactions/:id/consumption/:fk",
              method: "GET",
            },

            // INTERNAL. Use Transaction.consumption.destroyById() instead.
            "prototype$__destroyById__consumption": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/transactions/:id/consumption/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Transaction.consumption.updateById() instead.
            "prototype$__updateById__consumption": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/transactions/:id/consumption/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Transaction.consumptionData() instead.
            "prototype$__get__consumptionData": {
              isArray: true,
              url: urlBase + "/transactions/:id/consumptionData",
              method: "GET",
            },

            // INTERNAL. Use Transaction.consumptionData.create() instead.
            "prototype$__create__consumptionData": {
              url: urlBase + "/transactions/:id/consumptionData",
              method: "POST",
            },

            // INTERNAL. Use Transaction.consumptionData.destroyAll() instead.
            "prototype$__delete__consumptionData": {
              url: urlBase + "/transactions/:id/consumptionData",
              method: "DELETE",
            },

            // INTERNAL. Use Transaction.consumptionData.count() instead.
            "prototype$__count__consumptionData": {
              url: urlBase + "/transactions/:id/consumptionData/count",
              method: "GET",
            },

            // INTERNAL. Use Transaction.consumption() instead.
            "prototype$__get__consumption": {
              isArray: true,
              url: urlBase + "/transactions/:id/consumption",
              method: "GET",
            },

            // INTERNAL. Use Transaction.consumption.create() instead.
            "prototype$__create__consumption": {
              url: urlBase + "/transactions/:id/consumption",
              method: "POST",
            },

            // INTERNAL. Use Transaction.consumption.destroyAll() instead.
            "prototype$__delete__consumption": {
              url: urlBase + "/transactions/:id/consumption",
              method: "DELETE",
            },

            // INTERNAL. Use Transaction.consumption.count() instead.
            "prototype$__count__consumption": {
              url: urlBase + "/transactions/:id/consumption/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#create
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/transactions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#patchOrCreate
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/transactions",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#replaceOrCreate
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/transactions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#upsertWithWhere
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/transactions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#exists
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/transactions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#findById
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/transactions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#replaceById
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/transactions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#find
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/transactions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#findOne
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/transactions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#updateAll
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/transactions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#deleteById
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/transactions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#count
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/transactions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#prototype$patchAttributes
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/transactions/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#createChangeStream
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/transactions/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#analyticsConsumption
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Get charging stations consumption per month
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `from` – `{string}` -
             *
             *  - `to` – `{string}` -
             *
             *  - `scale` – `{string=}` - [day, week, month, total]
             *
             *  - `unit` – `{string=}` - [unit, evse]
             *
             *  - `evses` – `{*}` -
             *
             *  - `clients` – `{*}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "analyticsConsumption": {
              isArray: true,
              url: urlBase + "/transactions/analyticsConsumption",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#analyticsUsage
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Get charging stations usage per month
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `from` – `{string}` -
             *
             *  - `to` – `{string}` -
             *
             *  - `scale` – `{string=}` - [day, week, month, total]
             *
             *  - `unit` – `{string=}` - [unit, evse]
             *
             *  - `evses` – `{*}` -
             *
             *  - `clients` – `{*}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "analyticsUsage": {
              isArray: true,
              url: urlBase + "/transactions/analyticsUsage",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#analyticsOccupation
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Get charging stations occupation per month
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `from` – `{string}` -
             *
             *  - `to` – `{string}` -
             *
             *  - `scale` – `{string=}` - [day, week, month, total]
             *
             *  - `unit` – `{string=}` - [unit, evse]
             *
             *  - `evses` – `{*}` -
             *
             *  - `clients` – `{*}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "analyticsOccupation": {
              isArray: true,
              url: urlBase + "/transactions/analyticsOccupation",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#analyticsSessions
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Get charging stations sessions per month
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `from` – `{string}` -
             *
             *  - `to` – `{string}` -
             *
             *  - `scale` – `{string=}` - [day, week, month, total]
             *
             *  - `unit` – `{string=}` - [unit, evse]
             *
             *  - `evses` – `{*}` -
             *
             *  - `clients` – `{*}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "analyticsSessions": {
              isArray: true,
              url: urlBase + "/transactions/analyticsSessions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#analyticsCost
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Get charging stations cost per month
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `from` – `{string}` -
             *
             *  - `to` – `{string}` -
             *
             *  - `scale` – `{string=}` - [day, week, month, total]
             *
             *  - `unit` – `{string=}` - [unit, evse]
             *
             *  - `evses` – `{*}` -
             *
             *  - `clients` – `{*}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "analyticsCost": {
              isArray: true,
              url: urlBase + "/transactions/analyticsCost",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#dashConsumption
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Get consumption per day for the last 7 days
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `sites` – `{*}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "dashConsumption": {
              isArray: true,
              url: urlBase + "/transactions/dashConsumption",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#dashSessions
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Get amount of sessions per day for the last 7 days
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `sites` – `{*}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "dashSessions": {
              isArray: true,
              url: urlBase + "/transactions/dashSessions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#clientConsumption
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Get consumption of the client of the last 30 days
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `clientId` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "clientConsumption": {
              isArray: true,
              url: urlBase + "/transactions/clientConsumption",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#export
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Exports filter data results into XLS
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{string=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "export": {
              url: urlBase + "/transactions/export",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Transaction#createMany
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/transactions",
              method: "POST",
            },

            // INTERNAL. Use Evse.transactions.findById() instead.
            "::findById::Evse::transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/transactions/:fk",
              method: "GET",
            },

            // INTERNAL. Use Evse.transactions.destroyById() instead.
            "::destroyById::Evse::transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/transactions/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.transactions.updateById() instead.
            "::updateById::Evse::transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/transactions/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Evse.transaction() instead.
            "::get::Evse::transaction": {
              url: urlBase + "/evses/:id/transaction",
              method: "GET",
            },

            // INTERNAL. Use Evse.transactions() instead.
            "::get::Evse::transactions": {
              isArray: true,
              url: urlBase + "/evses/:id/transactions",
              method: "GET",
            },

            // INTERNAL. Use Evse.transactions.create() instead.
            "::create::Evse::transactions": {
              url: urlBase + "/evses/:id/transactions",
              method: "POST",
            },

            // INTERNAL. Use Evse.transactions.createMany() instead.
            "::createMany::Evse::transactions": {
              isArray: true,
              url: urlBase + "/evses/:id/transactions",
              method: "POST",
            },

            // INTERNAL. Use Evse.transactions.destroyAll() instead.
            "::delete::Evse::transactions": {
              url: urlBase + "/evses/:id/transactions",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.transactions.count() instead.
            "::count::Evse::transactions": {
              url: urlBase + "/evses/:id/transactions/count",
              method: "GET",
            },

            // INTERNAL. Use Profile.transactions.findById() instead.
            "::findById::Profile::transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/profiles/:id/transactions/:fk",
              method: "GET",
            },

            // INTERNAL. Use Profile.transactions.destroyById() instead.
            "::destroyById::Profile::transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/profiles/:id/transactions/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Profile.transactions.updateById() instead.
            "::updateById::Profile::transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/profiles/:id/transactions/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Profile.transactions() instead.
            "::get::Profile::transactions": {
              isArray: true,
              url: urlBase + "/profiles/:id/transactions",
              method: "GET",
            },

            // INTERNAL. Use Profile.transactions.create() instead.
            "::create::Profile::transactions": {
              url: urlBase + "/profiles/:id/transactions",
              method: "POST",
            },

            // INTERNAL. Use Profile.transactions.createMany() instead.
            "::createMany::Profile::transactions": {
              isArray: true,
              url: urlBase + "/profiles/:id/transactions",
              method: "POST",
            },

            // INTERNAL. Use Profile.transactions.destroyAll() instead.
            "::delete::Profile::transactions": {
              url: urlBase + "/profiles/:id/transactions",
              method: "DELETE",
            },

            // INTERNAL. Use Profile.transactions.count() instead.
            "::count::Profile::transactions": {
              url: urlBase + "/profiles/:id/transactions/count",
              method: "GET",
            },

            // INTERNAL. Use Event.transaction() instead.
            "::get::Event::transaction": {
              url: urlBase + "/events/:id/transaction",
              method: "GET",
            },

            // INTERNAL. Use Balance.transaction() instead.
            "::get::Balance::transaction": {
              url: urlBase + "/balances/:id/transaction",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Transaction#upsert
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Transaction#updateOrCreate
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Transaction#patchOrCreateWithWhere
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Transaction#update
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Transaction#destroyById
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Transaction#removeById
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Transaction#prototype$updateAttributes
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Transaction#modelName
        * @propertyOf lbServices.Transaction
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Transaction`.
        */
        R.modelName = "Transaction";


            /**
             * @ngdoc method
             * @name lbServices.Transaction#profile
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Fetches belongsTo relation profile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.profile = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::get::Transaction::profile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction#user
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::get::Transaction::user"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction#evse
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Fetches belongsTo relation evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::get::Transaction::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction#site
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Fetches belongsTo relation site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.site = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::get::Transaction::site"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Transaction.consumptionData
     * @header lbServices.Transaction.consumptionData
     * @object
     * @description
     *
     * The object `Transaction.consumptionData` groups methods
     * manipulating `Consumption` instances related to `Transaction`.
     *
     * Call {@link lbServices.Transaction#consumptionData Transaction.consumptionData()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Transaction#consumptionData
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Queries consumptionData of transaction.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
        R.consumptionData = function() {
          var TargetResource = $injector.get("Consumption");
          var action = TargetResource["::get::Transaction::consumptionData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction.consumptionData#count
             * @methodOf lbServices.Transaction.consumptionData
             *
             * @description
             *
             * Counts consumptionData of transaction.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.consumptionData.count = function() {
          var TargetResource = $injector.get("Consumption");
          var action = TargetResource["::count::Transaction::consumptionData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction.consumptionData#create
             * @methodOf lbServices.Transaction.consumptionData
             *
             * @description
             *
             * Creates a new instance in consumptionData of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
        R.consumptionData.create = function() {
          var TargetResource = $injector.get("Consumption");
          var action = TargetResource["::create::Transaction::consumptionData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction.consumptionData#createMany
             * @methodOf lbServices.Transaction.consumptionData
             *
             * @description
             *
             * Creates a new instance in consumptionData of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
        R.consumptionData.createMany = function() {
          var TargetResource = $injector.get("Consumption");
          var action = TargetResource["::createMany::Transaction::consumptionData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction.consumptionData#destroyAll
             * @methodOf lbServices.Transaction.consumptionData
             *
             * @description
             *
             * Deletes all consumptionData of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.consumptionData.destroyAll = function() {
          var TargetResource = $injector.get("Consumption");
          var action = TargetResource["::delete::Transaction::consumptionData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction.consumptionData#destroyById
             * @methodOf lbServices.Transaction.consumptionData
             *
             * @description
             *
             * Delete a related item by id for consumptionData.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for consumptionData
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.consumptionData.destroyById = function() {
          var TargetResource = $injector.get("Consumption");
          var action = TargetResource["::destroyById::Transaction::consumptionData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction.consumptionData#findById
             * @methodOf lbServices.Transaction.consumptionData
             *
             * @description
             *
             * Find a related item by id for consumptionData.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for consumptionData
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
        R.consumptionData.findById = function() {
          var TargetResource = $injector.get("Consumption");
          var action = TargetResource["::findById::Transaction::consumptionData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction.consumptionData#updateById
             * @methodOf lbServices.Transaction.consumptionData
             *
             * @description
             *
             * Update a related item by id for consumptionData.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `fk` – `{*}` - Foreign key for consumptionData
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
        R.consumptionData.updateById = function() {
          var TargetResource = $injector.get("Consumption");
          var action = TargetResource["::updateById::Transaction::consumptionData"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Transaction.consumption
     * @header lbServices.Transaction.consumption
     * @object
     * @description
     *
     * The object `Transaction.consumption` groups methods
     * manipulating `Usage` instances related to `Transaction`.
     *
     * Call {@link lbServices.Transaction#consumption Transaction.consumption()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Transaction#consumption
             * @methodOf lbServices.Transaction
             *
             * @description
             *
             * Queries consumption of transaction.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
        R.consumption = function() {
          var TargetResource = $injector.get("Usage");
          var action = TargetResource["::get::Transaction::consumption"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction.consumption#count
             * @methodOf lbServices.Transaction.consumption
             *
             * @description
             *
             * Counts consumption of transaction.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.consumption.count = function() {
          var TargetResource = $injector.get("Usage");
          var action = TargetResource["::count::Transaction::consumption"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction.consumption#create
             * @methodOf lbServices.Transaction.consumption
             *
             * @description
             *
             * Creates a new instance in consumption of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
        R.consumption.create = function() {
          var TargetResource = $injector.get("Usage");
          var action = TargetResource["::create::Transaction::consumption"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction.consumption#createMany
             * @methodOf lbServices.Transaction.consumption
             *
             * @description
             *
             * Creates a new instance in consumption of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
        R.consumption.createMany = function() {
          var TargetResource = $injector.get("Usage");
          var action = TargetResource["::createMany::Transaction::consumption"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction.consumption#destroyAll
             * @methodOf lbServices.Transaction.consumption
             *
             * @description
             *
             * Deletes all consumption of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.consumption.destroyAll = function() {
          var TargetResource = $injector.get("Usage");
          var action = TargetResource["::delete::Transaction::consumption"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction.consumption#destroyById
             * @methodOf lbServices.Transaction.consumption
             *
             * @description
             *
             * Delete a related item by id for consumption.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for consumption
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.consumption.destroyById = function() {
          var TargetResource = $injector.get("Usage");
          var action = TargetResource["::destroyById::Transaction::consumption"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction.consumption#findById
             * @methodOf lbServices.Transaction.consumption
             *
             * @description
             *
             * Find a related item by id for consumption.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for consumption
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
        R.consumption.findById = function() {
          var TargetResource = $injector.get("Usage");
          var action = TargetResource["::findById::Transaction::consumption"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Transaction.consumption#updateById
             * @methodOf lbServices.Transaction.consumption
             *
             * @description
             *
             * Update a related item by id for consumption.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - transaction id
             *
             *  - `fk` – `{*}` - Foreign key for consumption
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
        R.consumption.updateById = function() {
          var TargetResource = $injector.get("Usage");
          var action = TargetResource["::updateById::Transaction::consumption"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Consumption
 * @header lbServices.Consumption
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Consumption` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Consumption",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/consumptions/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Consumption#create
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/consumptions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#patchOrCreate
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/consumptions",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#replaceOrCreate
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/consumptions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#upsertWithWhere
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/consumptions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#exists
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/consumptions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#findById
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/consumptions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#replaceById
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/consumptions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#find
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/consumptions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#findOne
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/consumptions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#updateAll
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/consumptions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#deleteById
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/consumptions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#count
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/consumptions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#prototype$patchAttributes
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - consumption id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/consumptions/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#createChangeStream
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/consumptions/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#digestData
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Returns digested consumption data from a transaction
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `transactionId` – `{string}` - Transaction id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
            "digestData": {
              url: urlBase + "/consumptions/digest",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Consumption#createMany
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/consumptions",
              method: "POST",
            },

            // INTERNAL. Use Transaction.consumptionData.findById() instead.
            "::findById::Transaction::consumptionData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/transactions/:id/consumptionData/:fk",
              method: "GET",
            },

            // INTERNAL. Use Transaction.consumptionData.destroyById() instead.
            "::destroyById::Transaction::consumptionData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/transactions/:id/consumptionData/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Transaction.consumptionData.updateById() instead.
            "::updateById::Transaction::consumptionData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/transactions/:id/consumptionData/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Transaction.consumptionData() instead.
            "::get::Transaction::consumptionData": {
              isArray: true,
              url: urlBase + "/transactions/:id/consumptionData",
              method: "GET",
            },

            // INTERNAL. Use Transaction.consumptionData.create() instead.
            "::create::Transaction::consumptionData": {
              url: urlBase + "/transactions/:id/consumptionData",
              method: "POST",
            },

            // INTERNAL. Use Transaction.consumptionData.createMany() instead.
            "::createMany::Transaction::consumptionData": {
              isArray: true,
              url: urlBase + "/transactions/:id/consumptionData",
              method: "POST",
            },

            // INTERNAL. Use Transaction.consumptionData.destroyAll() instead.
            "::delete::Transaction::consumptionData": {
              url: urlBase + "/transactions/:id/consumptionData",
              method: "DELETE",
            },

            // INTERNAL. Use Transaction.consumptionData.count() instead.
            "::count::Transaction::consumptionData": {
              url: urlBase + "/transactions/:id/consumptionData/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Consumption#upsert
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Consumption#updateOrCreate
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Consumption#patchOrCreateWithWhere
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Consumption#update
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Consumption#destroyById
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Consumption#removeById
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Consumption#prototype$updateAttributes
             * @methodOf lbServices.Consumption
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - consumption id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Consumption` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Consumption#modelName
        * @propertyOf lbServices.Consumption
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Consumption`.
        */
        R.modelName = "Consumption";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Event
 * @header lbServices.Event
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Event` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Event",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/events/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Event.profile() instead.
            "prototype$__get__profile": {
              url: urlBase + "/events/:id/profile",
              method: "GET",
            },

            // INTERNAL. Use Event.site() instead.
            "prototype$__get__site": {
              url: urlBase + "/events/:id/site",
              method: "GET",
            },

            // INTERNAL. Use Event.evse() instead.
            "prototype$__get__evse": {
              url: urlBase + "/events/:id/evse",
              method: "GET",
            },

            // INTERNAL. Use Event.transaction() instead.
            "prototype$__get__transaction": {
              url: urlBase + "/events/:id/transaction",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#create
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/events",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#patchOrCreate
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/events",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#replaceOrCreate
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/events/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#upsertWithWhere
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/events/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#exists
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/events/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#findById
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/events/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#replaceById
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/events/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#find
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/events",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#findOne
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/events/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#updateAll
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/events/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#deleteById
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/events/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#count
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/events/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#prototype$patchAttributes
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - event id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/events/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#createChangeStream
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/events/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Event#createMany
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/events",
              method: "POST",
            },

            // INTERNAL. Use Notification.event() instead.
            "::get::Notification::event": {
              url: urlBase + "/notifications/:id/event",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Event#upsert
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Event#updateOrCreate
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Event#patchOrCreateWithWhere
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Event#update
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Event#destroyById
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Event#removeById
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Event#prototype$updateAttributes
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - event id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Event#modelName
        * @propertyOf lbServices.Event
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Event`.
        */
        R.modelName = "Event";


            /**
             * @ngdoc method
             * @name lbServices.Event#profile
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Fetches belongsTo relation profile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - event id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R.profile = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::get::Event::profile"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Event#site
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Fetches belongsTo relation site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - event id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.site = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::get::Event::site"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Event#evse
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Fetches belongsTo relation evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - event id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::get::Event::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Event#transaction
             * @methodOf lbServices.Event
             *
             * @description
             *
             * Fetches belongsTo relation transaction.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - event id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R.transaction = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::get::Event::transaction"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.EvseProfile
 * @header lbServices.EvseProfile
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `EvseProfile` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "EvseProfile",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/evseProfiles/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use EvseProfile.evses.findById() instead.
            "prototype$__findById__evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseProfiles/:id/evses/:fk",
              method: "GET",
            },

            // INTERNAL. Use EvseProfile.evses.destroyById() instead.
            "prototype$__destroyById__evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseProfiles/:id/evses/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use EvseProfile.evses.updateById() instead.
            "prototype$__updateById__evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseProfiles/:id/evses/:fk",
              method: "PUT",
            },

            // INTERNAL. Use EvseProfile.evses() instead.
            "prototype$__get__evses": {
              isArray: true,
              url: urlBase + "/evseProfiles/:id/evses",
              method: "GET",
            },

            // INTERNAL. Use EvseProfile.evses.create() instead.
            "prototype$__create__evses": {
              url: urlBase + "/evseProfiles/:id/evses",
              method: "POST",
            },

            // INTERNAL. Use EvseProfile.evses.destroyAll() instead.
            "prototype$__delete__evses": {
              url: urlBase + "/evseProfiles/:id/evses",
              method: "DELETE",
            },

            // INTERNAL. Use EvseProfile.evses.count() instead.
            "prototype$__count__evses": {
              url: urlBase + "/evseProfiles/:id/evses/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#create
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/evseProfiles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#patchOrCreate
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/evseProfiles",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#replaceOrCreate
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/evseProfiles/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#upsertWithWhere
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/evseProfiles/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#exists
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/evseProfiles/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#findById
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/evseProfiles/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#replaceById
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/evseProfiles/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#find
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/evseProfiles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#findOne
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/evseProfiles/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#updateAll
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/evseProfiles/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#deleteById
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/evseProfiles/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#count
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/evseProfiles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#prototype$patchAttributes
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseProfile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/evseProfiles/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#createChangeStream
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/evseProfiles/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#createMany
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/evseProfiles",
              method: "POST",
            },

            // INTERNAL. Use Evse.info() instead.
            "::get::Evse::info": {
              url: urlBase + "/evses/:id/info",
              method: "GET",
            },

            // INTERNAL. Use Evse.evseProfile() instead.
            "::get::Evse::evseProfile": {
              url: urlBase + "/evses/:id/evseProfile",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#upsert
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#updateOrCreate
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#patchOrCreateWithWhere
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#update
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#destroyById
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#removeById
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#prototype$updateAttributes
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseProfile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseProfile` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.EvseProfile#modelName
        * @propertyOf lbServices.EvseProfile
        * @description
        * The name of the model represented by this $resource,
        * i.e. `EvseProfile`.
        */
        R.modelName = "EvseProfile";

    /**
     * @ngdoc object
     * @name lbServices.EvseProfile.evses
     * @header lbServices.EvseProfile.evses
     * @object
     * @description
     *
     * The object `EvseProfile.evses` groups methods
     * manipulating `Evse` instances related to `EvseProfile`.
     *
     * Call {@link lbServices.EvseProfile#evses EvseProfile.evses()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.EvseProfile#evses
             * @methodOf lbServices.EvseProfile
             *
             * @description
             *
             * Queries evses of evseProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseProfile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::get::EvseProfile::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile.evses#count
             * @methodOf lbServices.EvseProfile.evses
             *
             * @description
             *
             * Counts evses of evseProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseProfile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.evses.count = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::count::EvseProfile::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile.evses#create
             * @methodOf lbServices.EvseProfile.evses
             *
             * @description
             *
             * Creates a new instance in evses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseProfile id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses.create = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::create::EvseProfile::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile.evses#createMany
             * @methodOf lbServices.EvseProfile.evses
             *
             * @description
             *
             * Creates a new instance in evses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseProfile id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses.createMany = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::createMany::EvseProfile::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile.evses#destroyAll
             * @methodOf lbServices.EvseProfile.evses
             *
             * @description
             *
             * Deletes all evses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseProfile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.evses.destroyAll = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::delete::EvseProfile::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile.evses#destroyById
             * @methodOf lbServices.EvseProfile.evses
             *
             * @description
             *
             * Delete a related item by id for evses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseProfile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evses
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.evses.destroyById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::destroyById::EvseProfile::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile.evses#findById
             * @methodOf lbServices.EvseProfile.evses
             *
             * @description
             *
             * Find a related item by id for evses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseProfile id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evses
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses.findById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::findById::EvseProfile::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseProfile.evses#updateById
             * @methodOf lbServices.EvseProfile.evses
             *
             * @description
             *
             * Update a related item by id for evses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseProfile id
             *
             *  - `fk` – `{*}` - Foreign key for evses
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses.updateById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::updateById::EvseProfile::evses"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Notification
 * @header lbServices.Notification
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Notification` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Notification",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/notifications/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Notification.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/notifications/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Notification.event() instead.
            "prototype$__get__event": {
              url: urlBase + "/notifications/:id/event",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#create
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/notifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#patchOrCreate
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/notifications",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#replaceOrCreate
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/notifications/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#upsertWithWhere
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/notifications/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#exists
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/notifications/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#findById
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/notifications/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#replaceById
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/notifications/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#find
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/notifications",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#findOne
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/notifications/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#updateAll
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/notifications/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#deleteById
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/notifications/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#count
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/notifications/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#prototype$patchAttributes
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - notification id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/notifications/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#createChangeStream
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/notifications/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notification#createMany
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/notifications",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Notification#upsert
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Notification#updateOrCreate
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Notification#patchOrCreateWithWhere
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Notification#update
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Notification#destroyById
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Notification#removeById
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Notification#prototype$updateAttributes
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - notification id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notification` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Notification#modelName
        * @propertyOf lbServices.Notification
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Notification`.
        */
        R.modelName = "Notification";


            /**
             * @ngdoc method
             * @name lbServices.Notification#user
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - notification id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::get::Notification::user"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Notification#event
             * @methodOf lbServices.Notification
             *
             * @description
             *
             * Fetches belongsTo relation event.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - notification id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Event` object.)
             * </em>
             */
        R.event = function() {
          var TargetResource = $injector.get("Event");
          var action = TargetResource["::get::Notification::event"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Comment
 * @header lbServices.Comment
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Comment` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Comment",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/comments/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Comment.evse() instead.
            "prototype$__get__evse": {
              url: urlBase + "/comments/:id/evse",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#create
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/comments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#patchOrCreate
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/comments",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#replaceOrCreate
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/comments/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#upsertWithWhere
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/comments/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#exists
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/comments/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#findById
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/comments/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#replaceById
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/comments/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#find
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/comments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#findOne
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/comments/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#updateAll
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/comments/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#deleteById
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/comments/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#count
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/comments/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#prototype$patchAttributes
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/comments/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#createChangeStream
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/comments/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Comment#createMany
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/comments",
              method: "POST",
            },

            // INTERNAL. Use Evse.comments.findById() instead.
            "::findById::Evse::comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/comments/:fk",
              method: "GET",
            },

            // INTERNAL. Use Evse.comments.destroyById() instead.
            "::destroyById::Evse::comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/comments/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.comments.updateById() instead.
            "::updateById::Evse::comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/comments/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Evse.comments() instead.
            "::get::Evse::comments": {
              isArray: true,
              url: urlBase + "/evses/:id/comments",
              method: "GET",
            },

            // INTERNAL. Use Evse.comments.create() instead.
            "::create::Evse::comments": {
              url: urlBase + "/evses/:id/comments",
              method: "POST",
            },

            // INTERNAL. Use Evse.comments.createMany() instead.
            "::createMany::Evse::comments": {
              isArray: true,
              url: urlBase + "/evses/:id/comments",
              method: "POST",
            },

            // INTERNAL. Use Evse.comments.destroyAll() instead.
            "::delete::Evse::comments": {
              url: urlBase + "/evses/:id/comments",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.comments.count() instead.
            "::count::Evse::comments": {
              url: urlBase + "/evses/:id/comments/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Comment#upsert
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Comment#updateOrCreate
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Comment#patchOrCreateWithWhere
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Comment#update
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Comment#destroyById
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Comment#removeById
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Comment#prototype$updateAttributes
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Comment` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Comment#modelName
        * @propertyOf lbServices.Comment
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Comment`.
        */
        R.modelName = "Comment";


            /**
             * @ngdoc method
             * @name lbServices.Comment#evse
             * @methodOf lbServices.Comment
             *
             * @description
             *
             * Fetches belongsTo relation evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - comment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::get::Comment::evse"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Client
 * @header lbServices.Client
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Client` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Client",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/clients/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Client.profiles.findById() instead.
            "prototype$__findById__profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/clients/:id/profiles/:fk",
              method: "GET",
            },

            // INTERNAL. Use Client.profiles.destroyById() instead.
            "prototype$__destroyById__profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/clients/:id/profiles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Client.profiles.updateById() instead.
            "prototype$__updateById__profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/clients/:id/profiles/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#prototype$__get__ClientInfo
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Fetches hasOne relation ClientInfo.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
            "prototype$__get__ClientInfo": {
              url: urlBase + "/clients/:id/ClientInfo",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#prototype$__create__ClientInfo
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Creates a new instance in ClientInfo of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
            "prototype$__create__ClientInfo": {
              url: urlBase + "/clients/:id/ClientInfo",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#prototype$__update__ClientInfo
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Update ClientInfo of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
            "prototype$__update__ClientInfo": {
              url: urlBase + "/clients/:id/ClientInfo",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#prototype$__destroy__ClientInfo
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Deletes ClientInfo of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroy__ClientInfo": {
              url: urlBase + "/clients/:id/ClientInfo",
              method: "DELETE",
            },

            // INTERNAL. Use Client.asset() instead.
            "prototype$__get__asset": {
              url: urlBase + "/clients/:id/asset",
              method: "GET",
            },

            // INTERNAL. Use Client.asset.create() instead.
            "prototype$__create__asset": {
              url: urlBase + "/clients/:id/asset",
              method: "POST",
            },

            // INTERNAL. Use Client.asset.update() instead.
            "prototype$__update__asset": {
              url: urlBase + "/clients/:id/asset",
              method: "PUT",
            },

            // INTERNAL. Use Client.asset.destroy() instead.
            "prototype$__destroy__asset": {
              url: urlBase + "/clients/:id/asset",
              method: "DELETE",
            },

            // INTERNAL. Use Client.smartphones.findById() instead.
            "prototype$__findById__smartphones": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/clients/:id/smartphones/:fk",
              method: "GET",
            },

            // INTERNAL. Use Client.smartphones.destroyById() instead.
            "prototype$__destroyById__smartphones": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/clients/:id/smartphones/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Client.smartphones.updateById() instead.
            "prototype$__updateById__smartphones": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/clients/:id/smartphones/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Client.profiles() instead.
            "prototype$__get__profiles": {
              isArray: true,
              url: urlBase + "/clients/:id/profiles",
              method: "GET",
            },

            // INTERNAL. Use Client.profiles.create() instead.
            "prototype$__create__profiles": {
              url: urlBase + "/clients/:id/profiles",
              method: "POST",
            },

            // INTERNAL. Use Client.profiles.destroyAll() instead.
            "prototype$__delete__profiles": {
              url: urlBase + "/clients/:id/profiles",
              method: "DELETE",
            },

            // INTERNAL. Use Client.profiles.count() instead.
            "prototype$__count__profiles": {
              url: urlBase + "/clients/:id/profiles/count",
              method: "GET",
            },

            // INTERNAL. Use Client.smartphones() instead.
            "prototype$__get__smartphones": {
              isArray: true,
              url: urlBase + "/clients/:id/smartphones",
              method: "GET",
            },

            // INTERNAL. Use Client.smartphones.create() instead.
            "prototype$__create__smartphones": {
              url: urlBase + "/clients/:id/smartphones",
              method: "POST",
            },

            // INTERNAL. Use Client.smartphones.destroyAll() instead.
            "prototype$__delete__smartphones": {
              url: urlBase + "/clients/:id/smartphones",
              method: "DELETE",
            },

            // INTERNAL. Use Client.smartphones.count() instead.
            "prototype$__count__smartphones": {
              url: urlBase + "/clients/:id/smartphones/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#create
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/clients",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#patchOrCreate
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/clients",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#replaceOrCreate
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/clients/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#upsertWithWhere
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/clients/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#exists
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/clients/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#findById
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/clients/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#replaceById
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/clients/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#find
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/clients",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#findOne
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/clients/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#updateAll
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/clients/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#deleteById
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/clients/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#count
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/clients/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#prototype$patchAttributes
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/clients/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#createChangeStream
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/clients/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#login
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Login a user with username/email and password.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `include` – `{string=}` - Related objects to include in the response. See the description of return value for more details.
             *   Default value: `user`.
             *
             *  - `rememberMe` - `boolean` - Whether the authentication credentials
             *     should be remembered in localStorage across app/browser restarts.
             *     Default: `true`.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The response body contains properties of the AccessToken created on login.
             * Depending on the value of `include` parameter, the body may contain additional properties:
             *   - `user` - `U+007BUserU+007D` - Data of the currently logged in user. (`include=user`)
             *
             */
            "login": {
              params: {
                include: 'user',
              },
              interceptor: {
                response: function(response) {
                  var accessToken = response.data;
                  LoopBackAuth.setUser(
                    accessToken.id, accessToken.userId, accessToken.user);
                  LoopBackAuth.rememberMe =
                    response.config.params.rememberMe !== false;
                  LoopBackAuth.save();
                  return response.resource;
                },
              },
              url: urlBase + "/clients/login",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#logout
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Logout a user with access token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `access_token` – `{string=}` - Do not supply this argument, it is automatically extracted from request headers.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "logout": {
              interceptor: {
                response: function(response) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return responseError.resource;
                },
              },
              url: urlBase + "/clients/logout",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#prototype$verify
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Trigger user's identity verification with configured verifyOptions
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `verifyOptions` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$verify": {
              url: urlBase + "/clients/:id/verify",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#confirm
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Confirm a user registration with identity verification token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `uid` – `{string}` -
             *
             *  - `token` – `{string}` -
             *
             *  - `redirect` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "confirm": {
              url: urlBase + "/clients/confirm",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#resetPassword
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Reset password for a user with email.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "resetPassword": {
              url: urlBase + "/clients/reset",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#changePassword
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Change a user's password.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{*=}` -
             *
             *  - `oldPassword` – `{string}` -
             *
             *  - `newPassword` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "changePassword": {
              url: urlBase + "/clients/change-password",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#setPassword
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Reset user's password via a password-reset token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{*=}` -
             *
             *  - `newPassword` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "setPassword": {
              url: urlBase + "/clients/reset-password",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#registerPhone
             * @methodOf lbServices.Client
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `smartphone` – `{Smartphone}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "registerPhone": {
              url: urlBase + "/clients/:id/registerPhone",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#updatePasswordFromToken
             * @methodOf lbServices.Client
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `access_token` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `newPassword` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `passwordChanged` – `{boolean=}` -
             */
            "updatePasswordFromToken": {
              url: urlBase + "/clients/update-password-from-token",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#createMany
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/clients",
              method: "POST",
            },

            // INTERNAL. Use Profile.client() instead.
            "::get::Profile::client": {
              url: urlBase + "/profiles/:id/client",
              method: "GET",
            },

            // INTERNAL. Use Smartphone.client() instead.
            "::get::Smartphone::client": {
              url: urlBase + "/smartphones/:id/client",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Client#getCurrent
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Get data of the currently logged user. Fail with HTTP result 401
             * when there is no user logged in.
             *
             * @param {function(Object,Object)=} successCb
             *    Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *    `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             */
            'getCurrent': {
              url: urlBase + "/clients" + '/:id',
              method: 'GET',
              params: {
                id: function() {
                  var id = LoopBackAuth.currentUserId;
                  if (id == null) id = '__anonymous__';
                  return id;
                },
              },
              interceptor: {
                response: function(response) {
                  LoopBackAuth.currentUserData = response.data;
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return $q.reject(responseError);
                },
              },
              __isGetCurrentUser__: true,
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Client#upsert
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Client#updateOrCreate
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Client#patchOrCreateWithWhere
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Client#update
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Client#destroyById
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Client#removeById
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Client#prototype$updateAttributes
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];

        /**
         * @ngdoc method
         * @name lbServices.Client#getCachedCurrent
         * @methodOf lbServices.Client
         *
         * @description
         *
         * Get data of the currently logged user that was returned by the last
         * call to {@link lbServices.Client#login} or
         * {@link lbServices.Client#getCurrent}. Return null when there
         * is no user logged in or the data of the current user were not fetched
         * yet.
         *
         * @returns {Object} A Client instance.
         */
        R.getCachedCurrent = function() {
          var data = LoopBackAuth.currentUserData;
          return data ? new R(data) : null;
        };

        /**
         * @ngdoc method
         * @name lbServices.Client#isAuthenticated
         * @methodOf lbServices.Client
         *
         * @returns {boolean} True if the current user is authenticated (logged in).
         */
        R.isAuthenticated = function() {
          return this.getCurrentId() != null;
        };

        /**
         * @ngdoc method
         * @name lbServices.Client#getCurrentId
         * @methodOf lbServices.Client
         *
         * @returns {Object} Id of the currently logged-in user or null.
         */
        R.getCurrentId = function() {
          return LoopBackAuth.currentUserId;
        };

        /**
        * @ngdoc property
        * @name lbServices.Client#modelName
        * @propertyOf lbServices.Client
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Client`.
        */
        R.modelName = "Client";

    /**
     * @ngdoc object
     * @name lbServices.Client.profiles
     * @header lbServices.Client.profiles
     * @object
     * @description
     *
     * The object `Client.profiles` groups methods
     * manipulating `Profile` instances related to `Client`.
     *
     * Call {@link lbServices.Client#profiles Client.profiles()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Client#profiles
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Queries profiles of client.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R.profiles = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::get::Client::profiles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.profiles#count
             * @methodOf lbServices.Client.profiles
             *
             * @description
             *
             * Counts profiles of client.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.profiles.count = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::count::Client::profiles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.profiles#create
             * @methodOf lbServices.Client.profiles
             *
             * @description
             *
             * Creates a new instance in profiles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R.profiles.create = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::create::Client::profiles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.profiles#createMany
             * @methodOf lbServices.Client.profiles
             *
             * @description
             *
             * Creates a new instance in profiles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R.profiles.createMany = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::createMany::Client::profiles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.profiles#destroyAll
             * @methodOf lbServices.Client.profiles
             *
             * @description
             *
             * Deletes all profiles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.profiles.destroyAll = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::delete::Client::profiles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.profiles#destroyById
             * @methodOf lbServices.Client.profiles
             *
             * @description
             *
             * Delete a related item by id for profiles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for profiles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.profiles.destroyById = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::destroyById::Client::profiles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.profiles#findById
             * @methodOf lbServices.Client.profiles
             *
             * @description
             *
             * Find a related item by id for profiles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for profiles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R.profiles.findById = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::findById::Client::profiles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.profiles#updateById
             * @methodOf lbServices.Client.profiles
             *
             * @description
             *
             * Update a related item by id for profiles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `fk` – `{*}` - Foreign key for profiles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Profile` object.)
             * </em>
             */
        R.profiles.updateById = function() {
          var TargetResource = $injector.get("Profile");
          var action = TargetResource["::updateById::Client::profiles"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Client.asset
     * @header lbServices.Client.asset
     * @object
     * @description
     *
     * The object `Client.asset` groups methods
     * manipulating `Asset` instances related to `Client`.
     *
     * Call {@link lbServices.Client#asset Client.asset()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Client#asset
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Fetches hasOne relation asset.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Asset` object.)
             * </em>
             */
        R.asset = function() {
          var TargetResource = $injector.get("Asset");
          var action = TargetResource["::get::Client::asset"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.asset#create
             * @methodOf lbServices.Client.asset
             *
             * @description
             *
             * Creates a new instance in asset of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Asset` object.)
             * </em>
             */
        R.asset.create = function() {
          var TargetResource = $injector.get("Asset");
          var action = TargetResource["::create::Client::asset"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.asset#createMany
             * @methodOf lbServices.Client.asset
             *
             * @description
             *
             * Creates a new instance in asset of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Asset` object.)
             * </em>
             */
        R.asset.createMany = function() {
          var TargetResource = $injector.get("Asset");
          var action = TargetResource["::createMany::Client::asset"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.asset#destroy
             * @methodOf lbServices.Client.asset
             *
             * @description
             *
             * Deletes asset of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.asset.destroy = function() {
          var TargetResource = $injector.get("Asset");
          var action = TargetResource["::destroy::Client::asset"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.asset#update
             * @methodOf lbServices.Client.asset
             *
             * @description
             *
             * Update asset of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Asset` object.)
             * </em>
             */
        R.asset.update = function() {
          var TargetResource = $injector.get("Asset");
          var action = TargetResource["::update::Client::asset"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Client.smartphones
     * @header lbServices.Client.smartphones
     * @object
     * @description
     *
     * The object `Client.smartphones` groups methods
     * manipulating `Smartphone` instances related to `Client`.
     *
     * Call {@link lbServices.Client#smartphones Client.smartphones()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Client#smartphones
             * @methodOf lbServices.Client
             *
             * @description
             *
             * Queries smartphones of client.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
        R.smartphones = function() {
          var TargetResource = $injector.get("Smartphone");
          var action = TargetResource["::get::Client::smartphones"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.smartphones#count
             * @methodOf lbServices.Client.smartphones
             *
             * @description
             *
             * Counts smartphones of client.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.smartphones.count = function() {
          var TargetResource = $injector.get("Smartphone");
          var action = TargetResource["::count::Client::smartphones"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.smartphones#create
             * @methodOf lbServices.Client.smartphones
             *
             * @description
             *
             * Creates a new instance in smartphones of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
        R.smartphones.create = function() {
          var TargetResource = $injector.get("Smartphone");
          var action = TargetResource["::create::Client::smartphones"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.smartphones#createMany
             * @methodOf lbServices.Client.smartphones
             *
             * @description
             *
             * Creates a new instance in smartphones of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
        R.smartphones.createMany = function() {
          var TargetResource = $injector.get("Smartphone");
          var action = TargetResource["::createMany::Client::smartphones"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.smartphones#destroyAll
             * @methodOf lbServices.Client.smartphones
             *
             * @description
             *
             * Deletes all smartphones of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.smartphones.destroyAll = function() {
          var TargetResource = $injector.get("Smartphone");
          var action = TargetResource["::delete::Client::smartphones"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.smartphones#destroyById
             * @methodOf lbServices.Client.smartphones
             *
             * @description
             *
             * Delete a related item by id for smartphones.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for smartphones
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.smartphones.destroyById = function() {
          var TargetResource = $injector.get("Smartphone");
          var action = TargetResource["::destroyById::Client::smartphones"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.smartphones#findById
             * @methodOf lbServices.Client.smartphones
             *
             * @description
             *
             * Find a related item by id for smartphones.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for smartphones
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
        R.smartphones.findById = function() {
          var TargetResource = $injector.get("Smartphone");
          var action = TargetResource["::findById::Client::smartphones"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Client.smartphones#updateById
             * @methodOf lbServices.Client.smartphones
             *
             * @description
             *
             * Update a related item by id for smartphones.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - client id
             *
             *  - `fk` – `{*}` - Foreign key for smartphones
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
        R.smartphones.updateById = function() {
          var TargetResource = $injector.get("Smartphone");
          var action = TargetResource["::updateById::Client::smartphones"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Invoice
 * @header lbServices.Invoice
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Invoice` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Invoice",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/invoices/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Invoice#prototype$__get__ClientInfo
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Fetches hasOne relation ClientInfo.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - invoice id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
            "prototype$__get__ClientInfo": {
              url: urlBase + "/invoices/:id/ClientInfo",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#prototype$__create__ClientInfo
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Creates a new instance in ClientInfo of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - invoice id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
            "prototype$__create__ClientInfo": {
              url: urlBase + "/invoices/:id/ClientInfo",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#prototype$__update__ClientInfo
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Update ClientInfo of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - invoice id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
            "prototype$__update__ClientInfo": {
              url: urlBase + "/invoices/:id/ClientInfo",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#prototype$__destroy__ClientInfo
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Deletes ClientInfo of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - invoice id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroy__ClientInfo": {
              url: urlBase + "/invoices/:id/ClientInfo",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#create
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/invoices",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#patchOrCreate
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/invoices",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#replaceOrCreate
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/invoices/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#upsertWithWhere
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/invoices/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#exists
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/invoices/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#findById
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/invoices/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#replaceById
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/invoices/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#find
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/invoices",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#findOne
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/invoices/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#updateAll
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/invoices/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#deleteById
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/invoices/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#count
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/invoices/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#prototype$patchAttributes
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - invoice id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/invoices/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#createChangeStream
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/invoices/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invoice#createMany
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/invoices",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Invoice#upsert
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Invoice#updateOrCreate
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Invoice#patchOrCreateWithWhere
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Invoice#update
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Invoice#destroyById
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Invoice#removeById
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Invoice#prototype$updateAttributes
             * @methodOf lbServices.Invoice
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - invoice id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invoice` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Invoice#modelName
        * @propertyOf lbServices.Invoice
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Invoice`.
        */
        R.modelName = "Invoice";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Tariff
 * @header lbServices.Tariff
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Tariff` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Tariff",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/tariffs/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Tariff.evses.findById() instead.
            "prototype$__findById__evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/tariffs/:id/evses/:fk",
              method: "GET",
            },

            // INTERNAL. Use Tariff.evses.destroyById() instead.
            "prototype$__destroyById__evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/tariffs/:id/evses/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Tariff.evses.updateById() instead.
            "prototype$__updateById__evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/tariffs/:id/evses/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Tariff.provider() instead.
            "prototype$__get__provider": {
              url: urlBase + "/tariffs/:id/provider",
              method: "GET",
            },

            // INTERNAL. Use Tariff.tariffcycle() instead.
            "prototype$__get__tariffcycle": {
              url: urlBase + "/tariffs/:id/tariffcycle",
              method: "GET",
            },

            // INTERNAL. Use Tariff.evses() instead.
            "prototype$__get__evses": {
              isArray: true,
              url: urlBase + "/tariffs/:id/evses",
              method: "GET",
            },

            // INTERNAL. Use Tariff.evses.create() instead.
            "prototype$__create__evses": {
              url: urlBase + "/tariffs/:id/evses",
              method: "POST",
            },

            // INTERNAL. Use Tariff.evses.destroyAll() instead.
            "prototype$__delete__evses": {
              url: urlBase + "/tariffs/:id/evses",
              method: "DELETE",
            },

            // INTERNAL. Use Tariff.evses.count() instead.
            "prototype$__count__evses": {
              url: urlBase + "/tariffs/:id/evses/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#create
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/tariffs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#patchOrCreate
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/tariffs",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#replaceOrCreate
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/tariffs/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#upsertWithWhere
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/tariffs/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#exists
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/tariffs/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#findById
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/tariffs/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#replaceById
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/tariffs/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#find
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/tariffs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#findOne
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/tariffs/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#updateAll
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/tariffs/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#deleteById
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/tariffs/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#count
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/tariffs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#prototype$patchAttributes
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariff id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/tariffs/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#createChangeStream
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/tariffs/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariff#createMany
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/tariffs",
              method: "POST",
            },

            // INTERNAL. Use Evse.tariff() instead.
            "::get::Evse::tariff": {
              url: urlBase + "/evses/:id/tariff",
              method: "GET",
            },

            // INTERNAL. Use Site.tariff() instead.
            "::get::Site::tariff": {
              url: urlBase + "/sites/:id/tariff",
              method: "GET",
            },

            // INTERNAL. Use Tariffcycle.tariffs.findById() instead.
            "::findById::Tariffcycle::tariffs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/tariffcycles/:id/tariffs/:fk",
              method: "GET",
            },

            // INTERNAL. Use Tariffcycle.tariffs.destroyById() instead.
            "::destroyById::Tariffcycle::tariffs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/tariffcycles/:id/tariffs/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Tariffcycle.tariffs.updateById() instead.
            "::updateById::Tariffcycle::tariffs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/tariffcycles/:id/tariffs/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Tariffcycle.tariffs() instead.
            "::get::Tariffcycle::tariffs": {
              isArray: true,
              url: urlBase + "/tariffcycles/:id/tariffs",
              method: "GET",
            },

            // INTERNAL. Use Tariffcycle.tariffs.create() instead.
            "::create::Tariffcycle::tariffs": {
              url: urlBase + "/tariffcycles/:id/tariffs",
              method: "POST",
            },

            // INTERNAL. Use Tariffcycle.tariffs.createMany() instead.
            "::createMany::Tariffcycle::tariffs": {
              isArray: true,
              url: urlBase + "/tariffcycles/:id/tariffs",
              method: "POST",
            },

            // INTERNAL. Use Tariffcycle.tariffs.destroyAll() instead.
            "::delete::Tariffcycle::tariffs": {
              url: urlBase + "/tariffcycles/:id/tariffs",
              method: "DELETE",
            },

            // INTERNAL. Use Tariffcycle.tariffs.count() instead.
            "::count::Tariffcycle::tariffs": {
              url: urlBase + "/tariffcycles/:id/tariffs/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Tariff#upsert
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Tariff#updateOrCreate
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Tariff#patchOrCreateWithWhere
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Tariff#update
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Tariff#destroyById
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Tariff#removeById
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Tariff#prototype$updateAttributes
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariff id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Tariff#modelName
        * @propertyOf lbServices.Tariff
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Tariff`.
        */
        R.modelName = "Tariff";

    /**
     * @ngdoc object
     * @name lbServices.Tariff.evses
     * @header lbServices.Tariff.evses
     * @object
     * @description
     *
     * The object `Tariff.evses` groups methods
     * manipulating `Evse` instances related to `Tariff`.
     *
     * Call {@link lbServices.Tariff#evses Tariff.evses()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Tariff#evses
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Queries evses of tariff.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariff id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::get::Tariff::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariff.evses#count
             * @methodOf lbServices.Tariff.evses
             *
             * @description
             *
             * Counts evses of tariff.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariff id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.evses.count = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::count::Tariff::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariff.evses#create
             * @methodOf lbServices.Tariff.evses
             *
             * @description
             *
             * Creates a new instance in evses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariff id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses.create = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::create::Tariff::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariff.evses#createMany
             * @methodOf lbServices.Tariff.evses
             *
             * @description
             *
             * Creates a new instance in evses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariff id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses.createMany = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::createMany::Tariff::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariff.evses#destroyAll
             * @methodOf lbServices.Tariff.evses
             *
             * @description
             *
             * Deletes all evses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariff id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.evses.destroyAll = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::delete::Tariff::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariff.evses#destroyById
             * @methodOf lbServices.Tariff.evses
             *
             * @description
             *
             * Delete a related item by id for evses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariff id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evses
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.evses.destroyById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::destroyById::Tariff::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariff.evses#findById
             * @methodOf lbServices.Tariff.evses
             *
             * @description
             *
             * Find a related item by id for evses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariff id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evses
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses.findById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::findById::Tariff::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariff.evses#updateById
             * @methodOf lbServices.Tariff.evses
             *
             * @description
             *
             * Update a related item by id for evses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariff id
             *
             *  - `fk` – `{*}` - Foreign key for evses
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evses.updateById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::updateById::Tariff::evses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariff#provider
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Fetches belongsTo relation provider.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariff id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
        R.provider = function() {
          var TargetResource = $injector.get("Provider");
          var action = TargetResource["::get::Tariff::provider"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariff#tariffcycle
             * @methodOf lbServices.Tariff
             *
             * @description
             *
             * Fetches belongsTo relation tariffcycle.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariff id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
        R.tariffcycle = function() {
          var TargetResource = $injector.get("Tariffcycle");
          var action = TargetResource["::get::Tariff::tariffcycle"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Cycle
 * @header lbServices.Cycle
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Cycle` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Cycle",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/cycles/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Cycle#prototype$__findById__band
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Find a related item by id for band.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - cycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for band
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "prototype$__findById__band": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/cycles/:id/band/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#prototype$__destroyById__band
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Delete a related item by id for band.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - cycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for band
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__band": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/cycles/:id/band/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#prototype$__updateById__band
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Update a related item by id for band.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - cycle id
             *
             *  - `fk` – `{*}` - Foreign key for band
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "prototype$__updateById__band": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/cycles/:id/band/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#prototype$__get__band
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Queries band of cycle.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - cycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "prototype$__get__band": {
              isArray: true,
              url: urlBase + "/cycles/:id/band",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#prototype$__create__band
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Creates a new instance in band of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - cycle id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "prototype$__create__band": {
              url: urlBase + "/cycles/:id/band",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#prototype$__delete__band
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Deletes all band of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - cycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__band": {
              url: urlBase + "/cycles/:id/band",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#prototype$__count__band
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Counts band of cycle.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - cycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__band": {
              url: urlBase + "/cycles/:id/band/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#create
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/cycles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#patchOrCreate
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/cycles",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#replaceOrCreate
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/cycles/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#upsertWithWhere
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/cycles/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#exists
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/cycles/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#findById
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/cycles/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#replaceById
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/cycles/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#find
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/cycles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#findOne
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/cycles/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#updateAll
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/cycles/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#deleteById
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/cycles/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#count
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/cycles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#prototype$patchAttributes
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - cycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/cycles/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#createChangeStream
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/cycles/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cycle#createMany
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/cycles",
              method: "POST",
            },

            // INTERNAL. Use Tariffcycle.cycle() instead.
            "::get::Tariffcycle::cycle": {
              url: urlBase + "/tariffcycles/:id/cycle",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Cycle#upsert
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Cycle#updateOrCreate
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Cycle#patchOrCreateWithWhere
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Cycle#update
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Cycle#destroyById
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Cycle#removeById
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Cycle#prototype$updateAttributes
             * @methodOf lbServices.Cycle
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - cycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Cycle#modelName
        * @propertyOf lbServices.Cycle
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Cycle`.
        */
        R.modelName = "Cycle";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Provider
 * @header lbServices.Provider
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Provider` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Provider",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/providers/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Provider#create
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/providers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Provider#patchOrCreate
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/providers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Provider#replaceOrCreate
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/providers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Provider#upsertWithWhere
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/providers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Provider#exists
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/providers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Provider#findById
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/providers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Provider#replaceById
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/providers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Provider#find
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/providers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Provider#findOne
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/providers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Provider#updateAll
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/providers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Provider#deleteById
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/providers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Provider#count
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/providers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Provider#prototype$patchAttributes
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - provider id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/providers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Provider#createChangeStream
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/providers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Provider#createMany
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/providers",
              method: "POST",
            },

            // INTERNAL. Use Tariff.provider() instead.
            "::get::Tariff::provider": {
              url: urlBase + "/tariffs/:id/provider",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Provider#upsert
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Provider#updateOrCreate
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Provider#patchOrCreateWithWhere
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Provider#update
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Provider#destroyById
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Provider#removeById
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Provider#prototype$updateAttributes
             * @methodOf lbServices.Provider
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - provider id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Provider` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Provider#modelName
        * @propertyOf lbServices.Provider
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Provider`.
        */
        R.modelName = "Provider";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Usage
 * @header lbServices.Usage
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Usage` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Usage",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/usages/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Usage#create
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/usages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usage#patchOrCreate
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/usages",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usage#replaceOrCreate
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/usages/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usage#upsertWithWhere
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/usages/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usage#exists
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/usages/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usage#findById
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/usages/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usage#replaceById
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/usages/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usage#find
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/usages",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usage#findOne
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/usages/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usage#updateAll
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/usages/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usage#deleteById
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/usages/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usage#count
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/usages/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usage#prototype$patchAttributes
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - usage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/usages/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usage#createChangeStream
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/usages/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Usage#createMany
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/usages",
              method: "POST",
            },

            // INTERNAL. Use Transaction.consumption.findById() instead.
            "::findById::Transaction::consumption": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/transactions/:id/consumption/:fk",
              method: "GET",
            },

            // INTERNAL. Use Transaction.consumption.destroyById() instead.
            "::destroyById::Transaction::consumption": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/transactions/:id/consumption/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Transaction.consumption.updateById() instead.
            "::updateById::Transaction::consumption": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/transactions/:id/consumption/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Transaction.consumption() instead.
            "::get::Transaction::consumption": {
              isArray: true,
              url: urlBase + "/transactions/:id/consumption",
              method: "GET",
            },

            // INTERNAL. Use Transaction.consumption.create() instead.
            "::create::Transaction::consumption": {
              url: urlBase + "/transactions/:id/consumption",
              method: "POST",
            },

            // INTERNAL. Use Transaction.consumption.createMany() instead.
            "::createMany::Transaction::consumption": {
              isArray: true,
              url: urlBase + "/transactions/:id/consumption",
              method: "POST",
            },

            // INTERNAL. Use Transaction.consumption.destroyAll() instead.
            "::delete::Transaction::consumption": {
              url: urlBase + "/transactions/:id/consumption",
              method: "DELETE",
            },

            // INTERNAL. Use Transaction.consumption.count() instead.
            "::count::Transaction::consumption": {
              url: urlBase + "/transactions/:id/consumption/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Usage#upsert
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Usage#updateOrCreate
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Usage#patchOrCreateWithWhere
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Usage#update
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Usage#destroyById
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Usage#removeById
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Usage#prototype$updateAttributes
             * @methodOf lbServices.Usage
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - usage id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Usage` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Usage#modelName
        * @propertyOf lbServices.Usage
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Usage`.
        */
        R.modelName = "Usage";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Asset
 * @header lbServices.Asset
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Asset` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Asset",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/assets/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Asset#getContainers
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * List all storage containers
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * List of containers
             */
            "getContainers": {
              isArray: true,
              url: urlBase + "/assets/containers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#renameContainer
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Rename or merge a storage container
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `newName` – `{string=}` - New container name
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Result
             */
            "renameContainer": {
              url: urlBase + "/assets/containers/:container",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#deleteContainer
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Delete a storage container and all files attached to it
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Result
             */
            "deleteContainer": {
              url: urlBase + "/assets/containers/:container",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#getContainerFiles
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * List all files matched by where in storage container
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `where` – `{object=}` - Criteria to match files
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * List of files metadata
             */
            "getContainerFiles": {
              isArray: true,
              url: urlBase + "/assets/containers/:container/files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#replaceContainerFiles
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Replace a file within a storage container, deleting all of its versions
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * File's metadata
             */
            "replaceContainerFiles": {
              url: urlBase + "/assets/containers/:container/files",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#uploadContainerFiles
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Upload files within a storage container
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * List of files metadata
             */
            "uploadContainerFiles": {
              isArray: true,
              url: urlBase + "/assets/containers/:container/files",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#countContainerFiles
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Count files matched by where in storage container
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `where` – `{object=}` - Criteria to match files
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Result
             */
            "countContainerFiles": {
              url: urlBase + "/assets/containers/:container/files/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#downloadContainerFiles
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Download zipped files matched by where in storage container
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `where` – `{object=}` - Criteria to match files
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Asset` object.)
             * </em>
             */
            "downloadContainerFiles": {
              url: urlBase + "/assets/containers/:container/files/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#downloadContainerFileWhere
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Download a single file matched by where in storage container
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `where` – `{object=}` - Criteria to match files
             *
             *  - `alias` – `{string=}` - File alias. Default: `{$filename}`
             *
             *  - `inline` – `{boolean=}` - Download inline
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Asset` object.)
             * </em>
             */
            "downloadContainerFileWhere": {
              url: urlBase + "/assets/containers/:container/files/downloadOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#getContainerFile
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Get file in storage container
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `file` – `{string=}` - File name
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Asset` object.)
             * </em>
             */
            "getContainerFile": {
              url: urlBase + "/assets/containers/:container/files/:file",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#deleteContainerFile
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Delete a file within a storage container
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `file` – `{string=}` - File name
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Result
             */
            "deleteContainerFile": {
              url: urlBase + "/assets/containers/:container/files/:file",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#downloadContainerFile
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Download file in storage container
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `file` – `{string=}` - File name
             *
             *  - `res` – `{object=}` -
             *
             *  - `alias` – `{string=}` - File alias. Default: `{$filename}`
             *
             *  - `inline` – `{boolean=}` - Download inline
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * File
             */
            "downloadContainerFile": {
              url: urlBase + "/assets/containers/:container/files/:file/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#getFileVersions
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * List all file versions matched by where
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `file` – `{string=}` - File name
             *
             *  - `where` – `{object=}` - Criteria to match files
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * List of files metadata
             */
            "getFileVersions": {
              isArray: true,
              url: urlBase + "/assets/containers/:container/files/:file/versions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#countFileVersions
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Count file versions
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `file` – `{string=}` - File name
             *
             *  - `where` – `{object=}` - Criteria to match files
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * List of files metadata
             */
            "countFileVersions": {
              isArray: true,
              url: urlBase + "/assets/containers/:container/files/:file/versions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#downloadFileVersions
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Download zipped files matched by where in storage container
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `file` – `{string=}` - File name
             *
             *  - `alias` – `{string=}` - File alias (always starts with `{$_id}_`). Default: `{$filename}` for `{$_id}_{$filename}`
             *
             *  - `where` – `{object=}` - Criteria to match files
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Asset` object.)
             * </em>
             */
            "downloadFileVersions": {
              url: urlBase + "/assets/containers/:container/files/:file/versions/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#getFileVersion
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Get file version
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `file` – `{string=}` - File name
             *
             *  - `version` – `{string=}` - Version id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Asset` object.)
             * </em>
             */
            "getFileVersion": {
              url: urlBase + "/assets/containers/:container/files/:file/versions/:version",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#deleteFileVersion
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Delete file version
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `file` – `{string=}` - File name
             *
             *  - `version` – `{string=}` - Version id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Result
             */
            "deleteFileVersion": {
              url: urlBase + "/assets/containers/:container/files/:file/versions/:version",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Asset#downloadFileVersion
             * @methodOf lbServices.Asset
             *
             * @description
             *
             * Download file version
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` - Container name
             *
             *  - `file` – `{string=}` - File name
             *
             *  - `version` – `{string=}` - Version id
             *
             *  - `res` – `{object=}` -
             *
             *  - `alias` – `{string=}` - File alias. Default: `{$_id}_{$filename}`
             *
             *  - `inline` – `{boolean=}` - Download inline
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * File
             */
            "downloadFileVersion": {
              url: urlBase + "/assets/containers/:container/files/:file/versions/:version/download",
              method: "GET",
            },

            // INTERNAL. Use Evse.asset() instead.
            "::get::Evse::asset": {
              url: urlBase + "/evses/:id/asset",
              method: "GET",
            },

            // INTERNAL. Use Evse.asset.create() instead.
            "::create::Evse::asset": {
              url: urlBase + "/evses/:id/asset",
              method: "POST",
            },

            // INTERNAL. Use Evse.asset.createMany() instead.
            "::createMany::Evse::asset": {
              isArray: true,
              url: urlBase + "/evses/:id/asset",
              method: "POST",
            },

            // INTERNAL. Use Evse.asset.update() instead.
            "::update::Evse::asset": {
              url: urlBase + "/evses/:id/asset",
              method: "PUT",
            },

            // INTERNAL. Use Evse.asset.destroy() instead.
            "::destroy::Evse::asset": {
              url: urlBase + "/evses/:id/asset",
              method: "DELETE",
            },

            // INTERNAL. Use Client.asset() instead.
            "::get::Client::asset": {
              url: urlBase + "/clients/:id/asset",
              method: "GET",
            },

            // INTERNAL. Use Client.asset.create() instead.
            "::create::Client::asset": {
              url: urlBase + "/clients/:id/asset",
              method: "POST",
            },

            // INTERNAL. Use Client.asset.createMany() instead.
            "::createMany::Client::asset": {
              isArray: true,
              url: urlBase + "/clients/:id/asset",
              method: "POST",
            },

            // INTERNAL. Use Client.asset.update() instead.
            "::update::Client::asset": {
              url: urlBase + "/clients/:id/asset",
              method: "PUT",
            },

            // INTERNAL. Use Client.asset.destroy() instead.
            "::destroy::Client::asset": {
              url: urlBase + "/clients/:id/asset",
              method: "DELETE",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Asset#modelName
        * @propertyOf lbServices.Asset
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Asset`.
        */
        R.modelName = "Asset";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Alarm
 * @header lbServices.Alarm
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Alarm` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Alarm",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/alarms/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Alarm.evse() instead.
            "prototype$__get__evse": {
              url: urlBase + "/alarms/:id/evse",
              method: "GET",
            },

            // INTERNAL. Use Alarm.site() instead.
            "prototype$__get__site": {
              url: urlBase + "/alarms/:id/site",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#create
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/alarms",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#patchOrCreate
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/alarms",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#replaceOrCreate
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/alarms/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#upsertWithWhere
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/alarms/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#exists
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/alarms/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#findById
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/alarms/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#replaceById
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/alarms/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#find
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/alarms",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#findOne
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/alarms/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#updateAll
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/alarms/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#deleteById
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/alarms/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#count
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/alarms/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#prototype$patchAttributes
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - alarm id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/alarms/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#createChangeStream
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/alarms/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Alarm#createMany
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/alarms",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Alarm#upsert
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Alarm#updateOrCreate
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Alarm#patchOrCreateWithWhere
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Alarm#update
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Alarm#destroyById
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Alarm#removeById
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Alarm#prototype$updateAttributes
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - alarm id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Alarm` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Alarm#modelName
        * @propertyOf lbServices.Alarm
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Alarm`.
        */
        R.modelName = "Alarm";


            /**
             * @ngdoc method
             * @name lbServices.Alarm#evse
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Fetches belongsTo relation evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - alarm id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::get::Alarm::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Alarm#site
             * @methodOf lbServices.Alarm
             *
             * @description
             *
             * Fetches belongsTo relation site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - alarm id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.site = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::get::Alarm::site"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Setting
 * @header lbServices.Setting
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Setting` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Setting",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/settings/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Setting#create
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/settings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#patchOrCreate
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/settings",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#replaceOrCreate
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/settings/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#upsertWithWhere
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/settings/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#exists
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/settings/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#findById
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/settings/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#replaceById
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/settings/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#find
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/settings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#findOne
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/settings/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#updateAll
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/settings/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#deleteById
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/settings/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#count
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/settings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#prototype$patchAttributes
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - setting id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/settings/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#createChangeStream
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/settings/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#createMany
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/settings",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Setting#upsert
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Setting#updateOrCreate
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Setting#patchOrCreateWithWhere
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Setting#update
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Setting#destroyById
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Setting#removeById
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Setting#prototype$updateAttributes
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - setting id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Setting#modelName
        * @propertyOf lbServices.Setting
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Setting`.
        */
        R.modelName = "Setting";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.EvseGroups
 * @header lbServices.EvseGroups
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `EvseGroups` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "EvseGroups",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/evseGroups/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use EvseGroups.site() instead.
            "prototype$__get__site": {
              url: urlBase + "/evseGroups/:id/site",
              method: "GET",
            },

            // INTERNAL. Use EvseGroups.entityId() instead.
            "prototype$__get__entityId": {
              url: urlBase + "/evseGroups/:id/entityId",
              method: "GET",
            },

            // INTERNAL. Use EvseGroups.evse.findById() instead.
            "prototype$__findById__evse": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseGroups/:id/evse/:fk",
              method: "GET",
            },

            // INTERNAL. Use EvseGroups.evse.destroyById() instead.
            "prototype$__destroyById__evse": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseGroups/:id/evse/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use EvseGroups.evse.updateById() instead.
            "prototype$__updateById__evse": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseGroups/:id/evse/:fk",
              method: "PUT",
            },

            // INTERNAL. Use EvseGroups.evse() instead.
            "prototype$__get__evse": {
              isArray: true,
              url: urlBase + "/evseGroups/:id/evse",
              method: "GET",
            },

            // INTERNAL. Use EvseGroups.evse.create() instead.
            "prototype$__create__evse": {
              url: urlBase + "/evseGroups/:id/evse",
              method: "POST",
            },

            // INTERNAL. Use EvseGroups.evse.destroyAll() instead.
            "prototype$__delete__evse": {
              url: urlBase + "/evseGroups/:id/evse",
              method: "DELETE",
            },

            // INTERNAL. Use EvseGroups.evse.count() instead.
            "prototype$__count__evse": {
              url: urlBase + "/evseGroups/:id/evse/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#create
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/evseGroups",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#patchOrCreate
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/evseGroups",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#replaceOrCreate
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/evseGroups/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#upsertWithWhere
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/evseGroups/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#exists
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/evseGroups/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#findById
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/evseGroups/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#replaceById
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/evseGroups/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#find
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/evseGroups",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#findOne
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/evseGroups/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#updateAll
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/evseGroups/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#deleteById
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/evseGroups/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#count
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/evseGroups/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#prototype$patchAttributes
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseGroups id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/evseGroups/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#createChangeStream
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/evseGroups/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#createMany
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/evseGroups",
              method: "POST",
            },

            // INTERNAL. Use Evse.evseGroup() instead.
            "::get::Evse::evseGroup": {
              url: urlBase + "/evses/:id/evseGroup",
              method: "GET",
            },

            // INTERNAL. Use Site.evseGroups.findById() instead.
            "::findById::Site::evseGroups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/evseGroups/:fk",
              method: "GET",
            },

            // INTERNAL. Use Site.evseGroups.destroyById() instead.
            "::destroyById::Site::evseGroups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/evseGroups/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Site.evseGroups.updateById() instead.
            "::updateById::Site::evseGroups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/sites/:id/evseGroups/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Site.evseGroups() instead.
            "::get::Site::evseGroups": {
              isArray: true,
              url: urlBase + "/sites/:id/evseGroups",
              method: "GET",
            },

            // INTERNAL. Use Site.evseGroups.create() instead.
            "::create::Site::evseGroups": {
              url: urlBase + "/sites/:id/evseGroups",
              method: "POST",
            },

            // INTERNAL. Use Site.evseGroups.createMany() instead.
            "::createMany::Site::evseGroups": {
              isArray: true,
              url: urlBase + "/sites/:id/evseGroups",
              method: "POST",
            },

            // INTERNAL. Use Site.evseGroups.destroyAll() instead.
            "::delete::Site::evseGroups": {
              url: urlBase + "/sites/:id/evseGroups",
              method: "DELETE",
            },

            // INTERNAL. Use Site.evseGroups.count() instead.
            "::count::Site::evseGroups": {
              url: urlBase + "/sites/:id/evseGroups/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#upsert
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#updateOrCreate
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#patchOrCreateWithWhere
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#update
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#destroyById
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#removeById
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#prototype$updateAttributes
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseGroups id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseGroups` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.EvseGroups#modelName
        * @propertyOf lbServices.EvseGroups
        * @description
        * The name of the model represented by this $resource,
        * i.e. `EvseGroups`.
        */
        R.modelName = "EvseGroups";


            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#site
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Fetches belongsTo relation site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseGroups id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.site = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::get::EvseGroups::site"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#entityId
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Fetches belongsTo relation entityId.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseGroups id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
        R.entityId = function() {
          var TargetResource = $injector.get("Entity");
          var action = TargetResource["::get::EvseGroups::entityId"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.EvseGroups.evse
     * @header lbServices.EvseGroups.evse
     * @object
     * @description
     *
     * The object `EvseGroups.evse` groups methods
     * manipulating `Evse` instances related to `EvseGroups`.
     *
     * Call {@link lbServices.EvseGroups#evse EvseGroups.evse()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.EvseGroups#evse
             * @methodOf lbServices.EvseGroups
             *
             * @description
             *
             * Queries evse of evseGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseGroups id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::get::EvseGroups::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups.evse#count
             * @methodOf lbServices.EvseGroups.evse
             *
             * @description
             *
             * Counts evse of evseGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseGroups id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.evse.count = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::count::EvseGroups::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups.evse#create
             * @methodOf lbServices.EvseGroups.evse
             *
             * @description
             *
             * Creates a new instance in evse of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseGroups id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse.create = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::create::EvseGroups::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups.evse#createMany
             * @methodOf lbServices.EvseGroups.evse
             *
             * @description
             *
             * Creates a new instance in evse of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseGroups id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse.createMany = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::createMany::EvseGroups::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups.evse#destroyAll
             * @methodOf lbServices.EvseGroups.evse
             *
             * @description
             *
             * Deletes all evse of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseGroups id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.evse.destroyAll = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::delete::EvseGroups::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups.evse#destroyById
             * @methodOf lbServices.EvseGroups.evse
             *
             * @description
             *
             * Delete a related item by id for evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseGroups id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evse
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.evse.destroyById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::destroyById::EvseGroups::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups.evse#findById
             * @methodOf lbServices.EvseGroups.evse
             *
             * @description
             *
             * Find a related item by id for evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseGroups id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evse
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse.findById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::findById::EvseGroups::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseGroups.evse#updateById
             * @methodOf lbServices.EvseGroups.evse
             *
             * @description
             *
             * Update a related item by id for evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseGroups id
             *
             *  - `fk` – `{*}` - Foreign key for evse
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse.updateById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::updateById::EvseGroups::evse"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Tariffcycle
 * @header lbServices.Tariffcycle
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Tariffcycle` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Tariffcycle",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/tariffcycles/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Tariffcycle.cycle() instead.
            "prototype$__get__cycle": {
              url: urlBase + "/tariffcycles/:id/cycle",
              method: "GET",
            },

            // INTERNAL. Use Tariffcycle.tariffs.findById() instead.
            "prototype$__findById__tariffs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/tariffcycles/:id/tariffs/:fk",
              method: "GET",
            },

            // INTERNAL. Use Tariffcycle.tariffs.destroyById() instead.
            "prototype$__destroyById__tariffs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/tariffcycles/:id/tariffs/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Tariffcycle.tariffs.updateById() instead.
            "prototype$__updateById__tariffs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/tariffcycles/:id/tariffs/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Tariffcycle.tariffs() instead.
            "prototype$__get__tariffs": {
              isArray: true,
              url: urlBase + "/tariffcycles/:id/tariffs",
              method: "GET",
            },

            // INTERNAL. Use Tariffcycle.tariffs.create() instead.
            "prototype$__create__tariffs": {
              url: urlBase + "/tariffcycles/:id/tariffs",
              method: "POST",
            },

            // INTERNAL. Use Tariffcycle.tariffs.destroyAll() instead.
            "prototype$__delete__tariffs": {
              url: urlBase + "/tariffcycles/:id/tariffs",
              method: "DELETE",
            },

            // INTERNAL. Use Tariffcycle.tariffs.count() instead.
            "prototype$__count__tariffs": {
              url: urlBase + "/tariffcycles/:id/tariffs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#create
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/tariffcycles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#patchOrCreate
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/tariffcycles",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#replaceOrCreate
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/tariffcycles/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#upsertWithWhere
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/tariffcycles/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#exists
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/tariffcycles/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#findById
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/tariffcycles/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#replaceById
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/tariffcycles/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#find
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/tariffcycles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#findOne
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/tariffcycles/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#updateAll
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/tariffcycles/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#deleteById
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/tariffcycles/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#count
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/tariffcycles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#prototype$patchAttributes
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariffcycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/tariffcycles/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#createChangeStream
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/tariffcycles/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#createMany
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/tariffcycles",
              method: "POST",
            },

            // INTERNAL. Use Tariff.tariffcycle() instead.
            "::get::Tariff::tariffcycle": {
              url: urlBase + "/tariffs/:id/tariffcycle",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#upsert
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#updateOrCreate
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#patchOrCreateWithWhere
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#update
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#destroyById
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#removeById
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#prototype$updateAttributes
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariffcycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariffcycle` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Tariffcycle#modelName
        * @propertyOf lbServices.Tariffcycle
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Tariffcycle`.
        */
        R.modelName = "Tariffcycle";


            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#cycle
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Fetches belongsTo relation cycle.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariffcycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cycle` object.)
             * </em>
             */
        R.cycle = function() {
          var TargetResource = $injector.get("Cycle");
          var action = TargetResource["::get::Tariffcycle::cycle"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Tariffcycle.tariffs
     * @header lbServices.Tariffcycle.tariffs
     * @object
     * @description
     *
     * The object `Tariffcycle.tariffs` groups methods
     * manipulating `Tariff` instances related to `Tariffcycle`.
     *
     * Call {@link lbServices.Tariffcycle#tariffs Tariffcycle.tariffs()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle#tariffs
             * @methodOf lbServices.Tariffcycle
             *
             * @description
             *
             * Queries tariffs of tariffcycle.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariffcycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
        R.tariffs = function() {
          var TargetResource = $injector.get("Tariff");
          var action = TargetResource["::get::Tariffcycle::tariffs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle.tariffs#count
             * @methodOf lbServices.Tariffcycle.tariffs
             *
             * @description
             *
             * Counts tariffs of tariffcycle.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariffcycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.tariffs.count = function() {
          var TargetResource = $injector.get("Tariff");
          var action = TargetResource["::count::Tariffcycle::tariffs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle.tariffs#create
             * @methodOf lbServices.Tariffcycle.tariffs
             *
             * @description
             *
             * Creates a new instance in tariffs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariffcycle id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
        R.tariffs.create = function() {
          var TargetResource = $injector.get("Tariff");
          var action = TargetResource["::create::Tariffcycle::tariffs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle.tariffs#createMany
             * @methodOf lbServices.Tariffcycle.tariffs
             *
             * @description
             *
             * Creates a new instance in tariffs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariffcycle id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
        R.tariffs.createMany = function() {
          var TargetResource = $injector.get("Tariff");
          var action = TargetResource["::createMany::Tariffcycle::tariffs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle.tariffs#destroyAll
             * @methodOf lbServices.Tariffcycle.tariffs
             *
             * @description
             *
             * Deletes all tariffs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariffcycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tariffs.destroyAll = function() {
          var TargetResource = $injector.get("Tariff");
          var action = TargetResource["::delete::Tariffcycle::tariffs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle.tariffs#destroyById
             * @methodOf lbServices.Tariffcycle.tariffs
             *
             * @description
             *
             * Delete a related item by id for tariffs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariffcycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tariffs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tariffs.destroyById = function() {
          var TargetResource = $injector.get("Tariff");
          var action = TargetResource["::destroyById::Tariffcycle::tariffs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle.tariffs#findById
             * @methodOf lbServices.Tariffcycle.tariffs
             *
             * @description
             *
             * Find a related item by id for tariffs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariffcycle id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tariffs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
        R.tariffs.findById = function() {
          var TargetResource = $injector.get("Tariff");
          var action = TargetResource["::findById::Tariffcycle::tariffs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tariffcycle.tariffs#updateById
             * @methodOf lbServices.Tariffcycle.tariffs
             *
             * @description
             *
             * Update a related item by id for tariffs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tariffcycle id
             *
             *  - `fk` – `{*}` - Foreign key for tariffs
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tariff` object.)
             * </em>
             */
        R.tariffs.updateById = function() {
          var TargetResource = $injector.get("Tariff");
          var action = TargetResource["::updateById::Tariffcycle::tariffs"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Backlog
 * @header lbServices.Backlog
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Backlog` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Backlog",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/backlogs/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Backlog.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/backlogs/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#create
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/backlogs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#patchOrCreate
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/backlogs",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#replaceOrCreate
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/backlogs/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#upsertWithWhere
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/backlogs/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#exists
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/backlogs/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#findById
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/backlogs/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#replaceById
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/backlogs/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#find
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/backlogs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#findOne
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/backlogs/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#updateAll
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/backlogs/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#deleteById
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/backlogs/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#count
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/backlogs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#prototype$patchAttributes
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - backlog id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/backlogs/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#createChangeStream
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/backlogs/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#register
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `postData` – `{object}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `resultData` – `{object=}` -
             */
            "register": {
              url: urlBase + "/backlogs/register",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Backlog#createMany
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/backlogs",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Backlog#upsert
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Backlog#updateOrCreate
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Backlog#patchOrCreateWithWhere
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Backlog#update
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Backlog#destroyById
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Backlog#removeById
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Backlog#prototype$updateAttributes
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - backlog id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Backlog` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Backlog#modelName
        * @propertyOf lbServices.Backlog
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Backlog`.
        */
        R.modelName = "Backlog";


            /**
             * @ngdoc method
             * @name lbServices.Backlog#user
             * @methodOf lbServices.Backlog
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - backlog id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::get::Backlog::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Smartphone
 * @header lbServices.Smartphone
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Smartphone` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Smartphone",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/smartphones/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Smartphone.client() instead.
            "prototype$__get__client": {
              url: urlBase + "/smartphones/:id/client",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#create
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/smartphones",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#patchOrCreate
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/smartphones",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#replaceOrCreate
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/smartphones/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#upsertWithWhere
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/smartphones/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#exists
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/smartphones/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#findById
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/smartphones/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#replaceById
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/smartphones/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#find
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/smartphones",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#findOne
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/smartphones/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#updateAll
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/smartphones/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#deleteById
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/smartphones/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#count
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/smartphones/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#prototype$patchAttributes
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - smartphone id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/smartphones/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#createChangeStream
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/smartphones/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#createMany
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/smartphones",
              method: "POST",
            },

            // INTERNAL. Use Client.smartphones.findById() instead.
            "::findById::Client::smartphones": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/clients/:id/smartphones/:fk",
              method: "GET",
            },

            // INTERNAL. Use Client.smartphones.destroyById() instead.
            "::destroyById::Client::smartphones": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/clients/:id/smartphones/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Client.smartphones.updateById() instead.
            "::updateById::Client::smartphones": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/clients/:id/smartphones/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Client.smartphones() instead.
            "::get::Client::smartphones": {
              isArray: true,
              url: urlBase + "/clients/:id/smartphones",
              method: "GET",
            },

            // INTERNAL. Use Client.smartphones.create() instead.
            "::create::Client::smartphones": {
              url: urlBase + "/clients/:id/smartphones",
              method: "POST",
            },

            // INTERNAL. Use Client.smartphones.createMany() instead.
            "::createMany::Client::smartphones": {
              isArray: true,
              url: urlBase + "/clients/:id/smartphones",
              method: "POST",
            },

            // INTERNAL. Use Client.smartphones.destroyAll() instead.
            "::delete::Client::smartphones": {
              url: urlBase + "/clients/:id/smartphones",
              method: "DELETE",
            },

            // INTERNAL. Use Client.smartphones.count() instead.
            "::count::Client::smartphones": {
              url: urlBase + "/clients/:id/smartphones/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Smartphone#upsert
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#updateOrCreate
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#patchOrCreateWithWhere
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#update
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#destroyById
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#removeById
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Smartphone#prototype$updateAttributes
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - smartphone id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Smartphone` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Smartphone#modelName
        * @propertyOf lbServices.Smartphone
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Smartphone`.
        */
        R.modelName = "Smartphone";


            /**
             * @ngdoc method
             * @name lbServices.Smartphone#client
             * @methodOf lbServices.Smartphone
             *
             * @description
             *
             * Fetches belongsTo relation client.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - smartphone id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Client` object.)
             * </em>
             */
        R.client = function() {
          var TargetResource = $injector.get("Client");
          var action = TargetResource["::get::Smartphone::client"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.EvseQol
 * @header lbServices.EvseQol
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `EvseQol` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "EvseQol",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/evseQols/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use EvseQol.evse.findById() instead.
            "prototype$__findById__evse": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseQols/:id/evse/:fk",
              method: "GET",
            },

            // INTERNAL. Use EvseQol.evse.destroyById() instead.
            "prototype$__destroyById__evse": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseQols/:id/evse/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use EvseQol.evse.updateById() instead.
            "prototype$__updateById__evse": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evseQols/:id/evse/:fk",
              method: "PUT",
            },

            // INTERNAL. Use EvseQol.evse() instead.
            "prototype$__get__evse": {
              isArray: true,
              url: urlBase + "/evseQols/:id/evse",
              method: "GET",
            },

            // INTERNAL. Use EvseQol.evse.create() instead.
            "prototype$__create__evse": {
              url: urlBase + "/evseQols/:id/evse",
              method: "POST",
            },

            // INTERNAL. Use EvseQol.evse.destroyAll() instead.
            "prototype$__delete__evse": {
              url: urlBase + "/evseQols/:id/evse",
              method: "DELETE",
            },

            // INTERNAL. Use EvseQol.evse.count() instead.
            "prototype$__count__evse": {
              url: urlBase + "/evseQols/:id/evse/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#create
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/evseQols",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#patchOrCreate
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/evseQols",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#replaceOrCreate
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/evseQols/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#upsertWithWhere
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/evseQols/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#exists
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/evseQols/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#findById
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/evseQols/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#replaceById
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/evseQols/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#find
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/evseQols",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#findOne
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/evseQols/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#updateAll
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/evseQols/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#deleteById
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/evseQols/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#count
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/evseQols/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#prototype$patchAttributes
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseQol id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/evseQols/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#createChangeStream
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/evseQols/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#createMany
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/evseQols",
              method: "POST",
            },

            // INTERNAL. Use Evse.qol.findById() instead.
            "::findById::Evse::qol": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/qol/:fk",
              method: "GET",
            },

            // INTERNAL. Use Evse.qol.destroyById() instead.
            "::destroyById::Evse::qol": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/qol/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.qol.updateById() instead.
            "::updateById::Evse::qol": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/qol/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Evse.qol() instead.
            "::get::Evse::qol": {
              isArray: true,
              url: urlBase + "/evses/:id/qol",
              method: "GET",
            },

            // INTERNAL. Use Evse.qol.create() instead.
            "::create::Evse::qol": {
              url: urlBase + "/evses/:id/qol",
              method: "POST",
            },

            // INTERNAL. Use Evse.qol.createMany() instead.
            "::createMany::Evse::qol": {
              isArray: true,
              url: urlBase + "/evses/:id/qol",
              method: "POST",
            },

            // INTERNAL. Use Evse.qol.destroyAll() instead.
            "::delete::Evse::qol": {
              url: urlBase + "/evses/:id/qol",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.qol.count() instead.
            "::count::Evse::qol": {
              url: urlBase + "/evses/:id/qol/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.EvseQol#upsert
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#updateOrCreate
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#patchOrCreateWithWhere
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#update
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#destroyById
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#removeById
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EvseQol#prototype$updateAttributes
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseQol id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseQol` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.EvseQol#modelName
        * @propertyOf lbServices.EvseQol
        * @description
        * The name of the model represented by this $resource,
        * i.e. `EvseQol`.
        */
        R.modelName = "EvseQol";

    /**
     * @ngdoc object
     * @name lbServices.EvseQol.evse
     * @header lbServices.EvseQol.evse
     * @object
     * @description
     *
     * The object `EvseQol.evse` groups methods
     * manipulating `Evse` instances related to `EvseQol`.
     *
     * Call {@link lbServices.EvseQol#evse EvseQol.evse()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.EvseQol#evse
             * @methodOf lbServices.EvseQol
             *
             * @description
             *
             * Queries evse of evseQol.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseQol id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::get::EvseQol::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseQol.evse#count
             * @methodOf lbServices.EvseQol.evse
             *
             * @description
             *
             * Counts evse of evseQol.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseQol id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.evse.count = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::count::EvseQol::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseQol.evse#create
             * @methodOf lbServices.EvseQol.evse
             *
             * @description
             *
             * Creates a new instance in evse of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseQol id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse.create = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::create::EvseQol::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseQol.evse#createMany
             * @methodOf lbServices.EvseQol.evse
             *
             * @description
             *
             * Creates a new instance in evse of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseQol id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse.createMany = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::createMany::EvseQol::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseQol.evse#destroyAll
             * @methodOf lbServices.EvseQol.evse
             *
             * @description
             *
             * Deletes all evse of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseQol id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.evse.destroyAll = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::delete::EvseQol::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseQol.evse#destroyById
             * @methodOf lbServices.EvseQol.evse
             *
             * @description
             *
             * Delete a related item by id for evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseQol id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evse
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.evse.destroyById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::destroyById::EvseQol::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseQol.evse#findById
             * @methodOf lbServices.EvseQol.evse
             *
             * @description
             *
             * Find a related item by id for evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseQol id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evse
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse.findById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::findById::EvseQol::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.EvseQol.evse#updateById
             * @methodOf lbServices.EvseQol.evse
             *
             * @description
             *
             * Update a related item by id for evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseQol id
             *
             *  - `fk` – `{*}` - Foreign key for evse
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse.updateById = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::updateById::EvseQol::evse"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.EvseMetric
 * @header lbServices.EvseMetric
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `EvseMetric` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "EvseMetric",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/evseMetrics/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#create
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/evseMetrics",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#patchOrCreate
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/evseMetrics",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#replaceOrCreate
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/evseMetrics/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#upsertWithWhere
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/evseMetrics/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#exists
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/evseMetrics/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#findById
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/evseMetrics/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#replaceById
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/evseMetrics/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#find
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/evseMetrics",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#findOne
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/evseMetrics/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#updateAll
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/evseMetrics/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#deleteById
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/evseMetrics/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#count
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/evseMetrics/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#prototype$patchAttributes
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseMetric id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/evseMetrics/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#createChangeStream
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/evseMetrics/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#createMany
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/evseMetrics",
              method: "POST",
            },

            // INTERNAL. Use Evse.metrics.findById() instead.
            "::findById::Evse::metrics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/metrics/:fk",
              method: "GET",
            },

            // INTERNAL. Use Evse.metrics.destroyById() instead.
            "::destroyById::Evse::metrics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/metrics/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.metrics.updateById() instead.
            "::updateById::Evse::metrics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/evses/:id/metrics/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Evse.metrics() instead.
            "::get::Evse::metrics": {
              isArray: true,
              url: urlBase + "/evses/:id/metrics",
              method: "GET",
            },

            // INTERNAL. Use Evse.metrics.create() instead.
            "::create::Evse::metrics": {
              url: urlBase + "/evses/:id/metrics",
              method: "POST",
            },

            // INTERNAL. Use Evse.metrics.createMany() instead.
            "::createMany::Evse::metrics": {
              isArray: true,
              url: urlBase + "/evses/:id/metrics",
              method: "POST",
            },

            // INTERNAL. Use Evse.metrics.destroyAll() instead.
            "::delete::Evse::metrics": {
              url: urlBase + "/evses/:id/metrics",
              method: "DELETE",
            },

            // INTERNAL. Use Evse.metrics.count() instead.
            "::count::Evse::metrics": {
              url: urlBase + "/evses/:id/metrics/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#upsert
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#updateOrCreate
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#patchOrCreateWithWhere
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#update
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#destroyById
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#removeById
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EvseMetric#prototype$updateAttributes
             * @methodOf lbServices.EvseMetric
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - evseMetric id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EvseMetric` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.EvseMetric#modelName
        * @propertyOf lbServices.EvseMetric
        * @description
        * The name of the model represented by this $resource,
        * i.e. `EvseMetric`.
        */
        R.modelName = "EvseMetric";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Payment
 * @header lbServices.Payment
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Payment` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Payment",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/payments/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Payment.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/payments/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#create
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/payments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#patchOrCreate
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/payments",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#replaceOrCreate
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/payments/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#upsertWithWhere
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/payments/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#exists
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/payments/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#findById
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/payments/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#replaceById
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/payments/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#find
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/payments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#findOne
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/payments/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#updateAll
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/payments/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#deleteById
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/payments/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#count
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/payments/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#prototype$patchAttributes
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - payment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/payments/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#createChangeStream
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/payments/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#callback
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * User allowed chargers
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `key` – `{string}` - Chave antiphishing
             *
             *  - `reference` – `{string}` - Referência MB Way
             *
             *  - `request` – `{string}` - Id da transação
             *
             *  - `value` – `{number}` - Valor Pago
             *
             *  - `date` – `{string}` - Data/hora de pagamento
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
            "callback": {
              url: urlBase + "/payments/callback",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#status
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Checks payment status by polling Ifthenpay
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
            "status": {
              url: urlBase + "/payments/:id/status",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Payment#createMany
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/payments",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Payment#upsert
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Payment#updateOrCreate
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Payment#patchOrCreateWithWhere
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Payment#update
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Payment#destroyById
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Payment#removeById
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Payment#prototype$updateAttributes
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - payment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Payment` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Payment#modelName
        * @propertyOf lbServices.Payment
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Payment`.
        */
        R.modelName = "Payment";


            /**
             * @ngdoc method
             * @name lbServices.Payment#user
             * @methodOf lbServices.Payment
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - payment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::get::Payment::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Balance
 * @header lbServices.Balance
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Balance` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Balance",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/balances/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Balance.site() instead.
            "prototype$__get__site": {
              url: urlBase + "/balances/:id/site",
              method: "GET",
            },

            // INTERNAL. Use Balance.transaction() instead.
            "prototype$__get__transaction": {
              url: urlBase + "/balances/:id/transaction",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#create
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/balances",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#patchOrCreate
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/balances",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#replaceOrCreate
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/balances/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#upsertWithWhere
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/balances/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#exists
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/balances/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#findById
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/balances/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#replaceById
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/balances/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#find
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/balances",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#findOne
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/balances/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#updateAll
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/balances/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#deleteById
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/balances/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#count
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/balances/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#prototype$patchAttributes
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - balance id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/balances/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#createChangeStream
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/balances/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Balance#createMany
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/balances",
              method: "POST",
            },

            // INTERNAL. Use User.balances.findById() instead.
            "::findById::User::balances": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/balances/:fk",
              method: "GET",
            },

            // INTERNAL. Use User.balances.destroyById() instead.
            "::destroyById::User::balances": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/balances/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use User.balances.updateById() instead.
            "::updateById::User::balances": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/balances/:fk",
              method: "PUT",
            },

            // INTERNAL. Use User.balances() instead.
            "::get::User::balances": {
              isArray: true,
              url: urlBase + "/users/:id/balances",
              method: "GET",
            },

            // INTERNAL. Use User.balances.create() instead.
            "::create::User::balances": {
              url: urlBase + "/users/:id/balances",
              method: "POST",
            },

            // INTERNAL. Use User.balances.createMany() instead.
            "::createMany::User::balances": {
              isArray: true,
              url: urlBase + "/users/:id/balances",
              method: "POST",
            },

            // INTERNAL. Use User.balances.destroyAll() instead.
            "::delete::User::balances": {
              url: urlBase + "/users/:id/balances",
              method: "DELETE",
            },

            // INTERNAL. Use User.balances.count() instead.
            "::count::User::balances": {
              url: urlBase + "/users/:id/balances/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Balance#upsert
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Balance#updateOrCreate
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Balance#patchOrCreateWithWhere
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Balance#update
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Balance#destroyById
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Balance#removeById
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Balance#prototype$updateAttributes
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - balance id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Balance` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Balance#modelName
        * @propertyOf lbServices.Balance
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Balance`.
        */
        R.modelName = "Balance";


            /**
             * @ngdoc method
             * @name lbServices.Balance#site
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Fetches belongsTo relation site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - balance id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.site = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::get::Balance::site"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Balance#transaction
             * @methodOf lbServices.Balance
             *
             * @description
             *
             * Fetches belongsTo relation transaction.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - balance id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Transaction` object.)
             * </em>
             */
        R.transaction = function() {
          var TargetResource = $injector.get("Transaction");
          var action = TargetResource["::get::Balance::transaction"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Tenant
 * @header lbServices.Tenant
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Tenant` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Tenant",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/tenants/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Tenant.entity() instead.
            "prototype$__get__entity": {
              url: urlBase + "/tenants/:id/entity",
              method: "GET",
            },

            // INTERNAL. Use Tenant.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/tenants/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#create
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/tenants",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#patchOrCreate
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/tenants",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#replaceOrCreate
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/tenants/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#upsertWithWhere
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/tenants/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#exists
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/tenants/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#findById
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/tenants/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#replaceById
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/tenants/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#find
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/tenants",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#findOne
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/tenants/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#updateAll
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/tenants/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#deleteById
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/tenants/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#count
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/tenants/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#prototype$patchAttributes
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tenant id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/tenants/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#createChangeStream
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/tenants/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#massUpdate
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Updates tenant information for user
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `userId` – `{string}` -
             *
             *  - `entities` – `{*}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "massUpdate": {
              url: urlBase + "/tenants/mass",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tenant#createMany
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/tenants",
              method: "POST",
            },

            // INTERNAL. Use User.tenants.findById() instead.
            "::findById::User::tenants": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/tenants/:fk",
              method: "GET",
            },

            // INTERNAL. Use User.tenants.destroyById() instead.
            "::destroyById::User::tenants": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/tenants/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use User.tenants.updateById() instead.
            "::updateById::User::tenants": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/users/:id/tenants/:fk",
              method: "PUT",
            },

            // INTERNAL. Use User.tenants() instead.
            "::get::User::tenants": {
              isArray: true,
              url: urlBase + "/users/:id/tenants",
              method: "GET",
            },

            // INTERNAL. Use User.tenants.create() instead.
            "::create::User::tenants": {
              url: urlBase + "/users/:id/tenants",
              method: "POST",
            },

            // INTERNAL. Use User.tenants.createMany() instead.
            "::createMany::User::tenants": {
              isArray: true,
              url: urlBase + "/users/:id/tenants",
              method: "POST",
            },

            // INTERNAL. Use User.tenants.destroyAll() instead.
            "::delete::User::tenants": {
              url: urlBase + "/users/:id/tenants",
              method: "DELETE",
            },

            // INTERNAL. Use User.tenants.count() instead.
            "::count::User::tenants": {
              url: urlBase + "/users/:id/tenants/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Tenant#upsert
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Tenant#updateOrCreate
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Tenant#patchOrCreateWithWhere
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Tenant#update
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Tenant#destroyById
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Tenant#removeById
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Tenant#prototype$updateAttributes
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tenant id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tenant` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Tenant#modelName
        * @propertyOf lbServices.Tenant
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Tenant`.
        */
        R.modelName = "Tenant";


            /**
             * @ngdoc method
             * @name lbServices.Tenant#entity
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Fetches belongsTo relation entity.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tenant id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Entity` object.)
             * </em>
             */
        R.entity = function() {
          var TargetResource = $injector.get("Entity");
          var action = TargetResource["::get::Tenant::entity"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tenant#user
             * @methodOf lbServices.Tenant
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - tenant id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::get::Tenant::user"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Metric
 * @header lbServices.Metric
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Metric` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Metric",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/metrics/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Metric#create
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/metrics",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#patchOrCreate
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/metrics",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#replaceOrCreate
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/metrics/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#upsertWithWhere
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/metrics/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#exists
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/metrics/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#findById
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/metrics/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#replaceById
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/metrics/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#find
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/metrics",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#findOne
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/metrics/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#updateAll
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/metrics/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#deleteById
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/metrics/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#count
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/metrics/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#prototype$patchAttributes
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - metric id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/metrics/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#createChangeStream
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/metrics/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#siteStatusChart
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém estado das tomadas dos carregadores de uma instalação
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "siteStatusChart": {
              url: urlBase + "/metrics/site/charts/status",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#adminStatusChart
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém estado das tomadas dos carregadores
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "adminStatusChart": {
              url: urlBase + "/metrics/admin/charts/status",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#adminVendorChart
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém dados de carregador por vendedor
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "adminVendorChart": {
              isArray: true,
              url: urlBase + "/metrics/admin/charts/vendor",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#adminTypeChart
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém dados de distribuição do tipo de carregadores
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "adminTypeChart": {
              url: urlBase + "/metrics/admin/charts/type",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#adminSessions
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém total de sessões de carga efetuadas no período indicado, face aos dados homologos
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `period` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "adminSessions": {
              url: urlBase + "/metrics/admin/sessions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#siteIssues
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém número de carregadores com falhas e/ou problemas de ligação numa instalação
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `period` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "siteIssues": {
              url: urlBase + "/metrics/site/issues",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#adminIssues
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém número de carregadores com falhas e/ou problemas de ligação
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "adminIssues": {
              url: urlBase + "/metrics/admin/issues",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#siteCharging
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém número de sessões de carga ativas numa instalação
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "siteCharging": {
              url: urlBase + "/metrics/site/charging",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#adminCharging
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém número de sessões de carga ativas
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "adminCharging": {
              url: urlBase + "/metrics/admin/charging",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#adminEnergy
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém total de energia fornecida no período indicado, face aos dados homologos
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `period` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "adminEnergy": {
              url: urlBase + "/metrics/admin/energy",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#siteEnergy
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém total de energia fornecida no período indicado, face aos dados homologos
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `period` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "siteEnergy": {
              url: urlBase + "/metrics/site/energy",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#adminRevenue
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém total custo gerado pelo gasto de energia no período indicado, face aos dados homologos
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `period` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "adminRevenue": {
              url: urlBase + "/metrics/admin/revenue",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#siteRevenue
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém total custo gerado pelo gasto de energia no período indicado, face aos dados homologos
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `period` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "siteRevenue": {
              url: urlBase + "/metrics/site/revenue",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#adminUsers
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém total de novos utilizadores registados no sistema no período indicado, face aos dados homologos
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `period` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "adminUsers": {
              url: urlBase + "/metrics/admin/users",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#siteSessions
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Obtém total de sessões de carga efetuadas no período indicado, face aos dados homologos, por instalação
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `period` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "siteSessions": {
              url: urlBase + "/metrics/site/sessions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Metric#createMany
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/metrics",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Metric#upsert
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Metric#updateOrCreate
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Metric#patchOrCreateWithWhere
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Metric#update
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Metric#destroyById
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Metric#removeById
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Metric#prototype$updateAttributes
             * @methodOf lbServices.Metric
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - metric id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Metric` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Metric#modelName
        * @propertyOf lbServices.Metric
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Metric`.
        */
        R.modelName = "Metric";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Logger
 * @header lbServices.Logger
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Logger` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Logger",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/loggers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Logger.user() instead.
            "prototype$__get__user": {
              url: urlBase + "/loggers/:id/user",
              method: "GET",
            },

            // INTERNAL. Use Logger.evse() instead.
            "prototype$__get__evse": {
              url: urlBase + "/loggers/:id/evse",
              method: "GET",
            },

            // INTERNAL. Use Logger.site() instead.
            "prototype$__get__site": {
              url: urlBase + "/loggers/:id/site",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#create
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/loggers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#patchOrCreate
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/loggers",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#replaceOrCreate
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/loggers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#upsertWithWhere
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/loggers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#exists
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/loggers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#findById
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/loggers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#replaceById
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/loggers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#find
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/loggers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#findOne
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/loggers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#updateAll
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/loggers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#deleteById
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/loggers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#count
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/loggers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$patchAttributes
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/loggers/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#createChangeStream
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/loggers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#export
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Exportação em XLS
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{string}` - Filter data
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "export": {
              url: urlBase + "/loggers/export",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__findById__user__accessTokens
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__findById__user__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/accessTokens/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroyById__user__accessTokens
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__user__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/accessTokens/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__updateById__user__accessTokens
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__updateById__user__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/accessTokens/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__findById__user__groups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a related item by id for groups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__findById__user__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/groups/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroyById__user__groups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a related item by id for groups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__user__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/groups/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__updateById__user__groups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update a related item by id for groups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__updateById__user__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/groups/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__link__user__groups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Add a related item by id for groups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__link__user__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/groups/rel/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__unlink__user__groups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Remove the groups relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__unlink__user__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/groups/rel/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__exists__user__groups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Check the existence of groups relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__exists__user__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/groups/rel/:fk",
              method: "HEAD",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__findById__user__identities
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a related item by id for identities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for identities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__findById__user__identities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/identities/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroyById__user__identities
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a related item by id for identities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for identities
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__user__identities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/identities/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__updateById__user__identities
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update a related item by id for identities.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for identities
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__updateById__user__identities": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/identities/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__user__site
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Fetches belongsTo relation site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__user__site": {
              url: urlBase + "/loggers/:id/user/site",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__findById__user__access
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__findById__user__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/access/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroyById__user__access
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__user__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/access/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__updateById__user__access
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__updateById__user__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/access/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__findById__user__balances
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a related item by id for balances.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for balances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__findById__user__balances": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/balances/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroyById__user__balances
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a related item by id for balances.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for balances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__user__balances": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/balances/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__updateById__user__balances
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update a related item by id for balances.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for balances
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__updateById__user__balances": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/balances/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__findById__user__tenants
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a related item by id for tenants.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tenants
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__findById__user__tenants": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/tenants/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroyById__user__tenants
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a related item by id for tenants.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tenants
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__user__tenants": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/tenants/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__updateById__user__tenants
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update a related item by id for tenants.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for tenants
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__updateById__user__tenants": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/user/tenants/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__user__accessTokens
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Queries accessTokens of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__user__accessTokens": {
              isArray: true,
              url: urlBase + "/loggers/:id/user/accessTokens",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__user__accessTokens
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in accessTokens of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__user__accessTokens": {
              url: urlBase + "/loggers/:id/user/accessTokens",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__delete__user__accessTokens
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes all accessTokens of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__user__accessTokens": {
              url: urlBase + "/loggers/:id/user/accessTokens",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__count__user__accessTokens
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Counts accessTokens of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__user__accessTokens": {
              url: urlBase + "/loggers/:id/user/accessTokens/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__user__groups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Queries groups of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__user__groups": {
              isArray: true,
              url: urlBase + "/loggers/:id/user/groups",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__user__groups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in groups of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__user__groups": {
              url: urlBase + "/loggers/:id/user/groups",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__delete__user__groups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes all groups of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__user__groups": {
              url: urlBase + "/loggers/:id/user/groups",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__count__user__groups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Counts groups of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__user__groups": {
              url: urlBase + "/loggers/:id/user/groups/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__user__identities
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Queries identities of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__user__identities": {
              isArray: true,
              url: urlBase + "/loggers/:id/user/identities",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__user__identities
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in identities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__user__identities": {
              url: urlBase + "/loggers/:id/user/identities",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__delete__user__identities
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes all identities of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__user__identities": {
              url: urlBase + "/loggers/:id/user/identities",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__count__user__identities
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Counts identities of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__user__identities": {
              url: urlBase + "/loggers/:id/user/identities/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__user__access
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Queries access of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__user__access": {
              isArray: true,
              url: urlBase + "/loggers/:id/user/access",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__user__access
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in access of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__user__access": {
              url: urlBase + "/loggers/:id/user/access",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__delete__user__access
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes all access of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__user__access": {
              url: urlBase + "/loggers/:id/user/access",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__count__user__access
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Counts access of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__user__access": {
              url: urlBase + "/loggers/:id/user/access/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__user__balances
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Queries balances of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__user__balances": {
              isArray: true,
              url: urlBase + "/loggers/:id/user/balances",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__user__balances
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in balances of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__user__balances": {
              url: urlBase + "/loggers/:id/user/balances",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__delete__user__balances
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes all balances of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__user__balances": {
              url: urlBase + "/loggers/:id/user/balances",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__count__user__balances
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Counts balances of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__user__balances": {
              url: urlBase + "/loggers/:id/user/balances/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__user__tenants
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Queries tenants of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__user__tenants": {
              isArray: true,
              url: urlBase + "/loggers/:id/user/tenants",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__user__tenants
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in tenants of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__user__tenants": {
              url: urlBase + "/loggers/:id/user/tenants",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__delete__user__tenants
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes all tenants of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__user__tenants": {
              url: urlBase + "/loggers/:id/user/tenants",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__count__user__tenants
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Counts tenants of user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__user__tenants": {
              url: urlBase + "/loggers/:id/user/tenants/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__evse__site
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Fetches belongsTo relation site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__evse__site": {
              url: urlBase + "/loggers/:id/evse/site",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__findById__evse__transactions
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a related item by id for transactions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for transactions
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__findById__evse__transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/evse/transactions/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroyById__evse__transactions
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a related item by id for transactions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for transactions
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__evse__transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/evse/transactions/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__updateById__evse__transactions
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update a related item by id for transactions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for transactions
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__updateById__evse__transactions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/evse/transactions/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__evse__transaction
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Fetches belongsTo relation transaction.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__evse__transaction": {
              url: urlBase + "/loggers/:id/evse/transaction",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__evse__info
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Fetches belongsTo relation info.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__evse__info": {
              url: urlBase + "/loggers/:id/evse/info",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__evse__evseProfile
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Fetches belongsTo relation evseProfile.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__evse__evseProfile": {
              url: urlBase + "/loggers/:id/evse/evseProfile",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__findById__evse__comments
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a related item by id for comments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for comments
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__findById__evse__comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/evse/comments/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroyById__evse__comments
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a related item by id for comments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for comments
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__evse__comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/evse/comments/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__updateById__evse__comments
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update a related item by id for comments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for comments
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__updateById__evse__comments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/evse/comments/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__evse__tariff
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Fetches belongsTo relation tariff.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__evse__tariff": {
              url: urlBase + "/loggers/:id/evse/tariff",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__evse__asset
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Fetches hasOne relation asset.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__evse__asset": {
              url: urlBase + "/loggers/:id/evse/asset",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__evse__asset
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in asset of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__evse__asset": {
              url: urlBase + "/loggers/:id/evse/asset",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__update__evse__asset
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update asset of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__update__evse__asset": {
              url: urlBase + "/loggers/:id/evse/asset",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroy__evse__asset
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes asset of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroy__evse__asset": {
              url: urlBase + "/loggers/:id/evse/asset",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__evse__evseGroup
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Fetches belongsTo relation evseGroup.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__evse__evseGroup": {
              url: urlBase + "/loggers/:id/evse/evseGroup",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__findById__evse__qol
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a related item by id for qol.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for qol
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__findById__evse__qol": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/evse/qol/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroyById__evse__qol
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a related item by id for qol.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for qol
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__evse__qol": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/evse/qol/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__updateById__evse__qol
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update a related item by id for qol.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for qol
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__updateById__evse__qol": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/evse/qol/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__findById__evse__metrics
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a related item by id for metrics.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for metrics
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__findById__evse__metrics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/evse/metrics/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroyById__evse__metrics
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a related item by id for metrics.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for metrics
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__evse__metrics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/evse/metrics/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__updateById__evse__metrics
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update a related item by id for metrics.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for metrics
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__updateById__evse__metrics": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/evse/metrics/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__evse__transactions
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Queries transactions of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__evse__transactions": {
              isArray: true,
              url: urlBase + "/loggers/:id/evse/transactions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__evse__transactions
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in transactions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__evse__transactions": {
              url: urlBase + "/loggers/:id/evse/transactions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__delete__evse__transactions
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes all transactions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__evse__transactions": {
              url: urlBase + "/loggers/:id/evse/transactions",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__count__evse__transactions
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Counts transactions of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__evse__transactions": {
              url: urlBase + "/loggers/:id/evse/transactions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__evse__comments
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Queries comments of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__evse__comments": {
              isArray: true,
              url: urlBase + "/loggers/:id/evse/comments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__evse__comments
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in comments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__evse__comments": {
              url: urlBase + "/loggers/:id/evse/comments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__delete__evse__comments
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes all comments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__evse__comments": {
              url: urlBase + "/loggers/:id/evse/comments",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__count__evse__comments
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Counts comments of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__evse__comments": {
              url: urlBase + "/loggers/:id/evse/comments/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__evse__qol
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Queries qol of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__evse__qol": {
              isArray: true,
              url: urlBase + "/loggers/:id/evse/qol",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__evse__qol
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in qol of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__evse__qol": {
              url: urlBase + "/loggers/:id/evse/qol",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__delete__evse__qol
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes all qol of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__evse__qol": {
              url: urlBase + "/loggers/:id/evse/qol",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__count__evse__qol
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Counts qol of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__evse__qol": {
              url: urlBase + "/loggers/:id/evse/qol/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__evse__metrics
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Queries metrics of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__evse__metrics": {
              isArray: true,
              url: urlBase + "/loggers/:id/evse/metrics",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__evse__metrics
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in metrics of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__evse__metrics": {
              url: urlBase + "/loggers/:id/evse/metrics",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__delete__evse__metrics
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes all metrics of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__evse__metrics": {
              url: urlBase + "/loggers/:id/evse/metrics",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__count__evse__metrics
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Counts metrics of evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__evse__metrics": {
              url: urlBase + "/loggers/:id/evse/metrics/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__findById__site__evses
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a related item by id for evses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evses
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__findById__site__evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/site/evses/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroyById__site__evses
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a related item by id for evses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evses
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__site__evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/site/evses/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__updateById__site__evses
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update a related item by id for evses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for evses
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__updateById__site__evses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/site/evses/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__site__entity
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Fetches belongsTo relation entity.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__site__entity": {
              url: urlBase + "/loggers/:id/site/entity",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__findById__site__profiles
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a related item by id for profiles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for profiles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__findById__site__profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/site/profiles/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroyById__site__profiles
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a related item by id for profiles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for profiles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__site__profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/site/profiles/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__updateById__site__profiles
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update a related item by id for profiles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for profiles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__updateById__site__profiles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/site/profiles/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__findById__site__access
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__findById__site__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/site/access/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroyById__site__access
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__site__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/site/access/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__updateById__site__access
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__updateById__site__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/site/access/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__site__tariff
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Fetches belongsTo relation tariff.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__site__tariff": {
              url: urlBase + "/loggers/:id/site/tariff",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__findById__site__evseGroups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Find a related item by id for evseGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evseGroups
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__findById__site__evseGroups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/site/evseGroups/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__destroyById__site__evseGroups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a related item by id for evseGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for evseGroups
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__site__evseGroups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/site/evseGroups/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__updateById__site__evseGroups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update a related item by id for evseGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `fk` – `{*}` - Foreign key for evseGroups
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__updateById__site__evseGroups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/loggers/:id/site/evseGroups/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__site__evses
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Queries evses of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__site__evses": {
              isArray: true,
              url: urlBase + "/loggers/:id/site/evses",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__site__evses
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in evses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__site__evses": {
              url: urlBase + "/loggers/:id/site/evses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__delete__site__evses
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes all evses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__site__evses": {
              url: urlBase + "/loggers/:id/site/evses",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__count__site__evses
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Counts evses of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__site__evses": {
              url: urlBase + "/loggers/:id/site/evses/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__site__profiles
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Queries profiles of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__site__profiles": {
              isArray: true,
              url: urlBase + "/loggers/:id/site/profiles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__site__profiles
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in profiles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__site__profiles": {
              url: urlBase + "/loggers/:id/site/profiles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__delete__site__profiles
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes all profiles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__site__profiles": {
              url: urlBase + "/loggers/:id/site/profiles",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__count__site__profiles
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Counts profiles of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__site__profiles": {
              url: urlBase + "/loggers/:id/site/profiles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__site__access
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Queries access of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__site__access": {
              isArray: true,
              url: urlBase + "/loggers/:id/site/access",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__site__access
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in access of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__site__access": {
              url: urlBase + "/loggers/:id/site/access",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__delete__site__access
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes all access of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__site__access": {
              url: urlBase + "/loggers/:id/site/access",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__count__site__access
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Counts access of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__site__access": {
              url: urlBase + "/loggers/:id/site/access/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__get__site__evseGroups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Queries evseGroups of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__get__site__evseGroups": {
              isArray: true,
              url: urlBase + "/loggers/:id/site/evseGroups",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__create__site__evseGroups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Creates a new instance in evseGroups of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "prototype$__create__site__evseGroups": {
              url: urlBase + "/loggers/:id/site/evseGroups",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__delete__site__evseGroups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Deletes all evseGroups of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__site__evseGroups": {
              url: urlBase + "/loggers/:id/site/evseGroups",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$__count__site__evseGroups
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Counts evseGroups of site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__site__evseGroups": {
              url: urlBase + "/loggers/:id/site/evseGroups/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logger#createMany
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/loggers",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Logger#upsert
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Logger#updateOrCreate
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Logger#patchOrCreateWithWhere
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Logger#update
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Logger#destroyById
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Logger#removeById
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Logger#prototype$updateAttributes
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logger` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Logger#modelName
        * @propertyOf lbServices.Logger
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Logger`.
        */
        R.modelName = "Logger";


            /**
             * @ngdoc method
             * @name lbServices.Logger#user
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `User` object.)
             * </em>
             */
        R.user = function() {
          var TargetResource = $injector.get("User");
          var action = TargetResource["::get::Logger::user"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Logger#evse
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Fetches belongsTo relation evse.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Evse` object.)
             * </em>
             */
        R.evse = function() {
          var TargetResource = $injector.get("Evse");
          var action = TargetResource["::get::Logger::evse"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Logger#site
             * @methodOf lbServices.Logger
             *
             * @description
             *
             * Fetches belongsTo relation site.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - logger id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Site` object.)
             * </em>
             */
        R.site = function() {
          var TargetResource = $injector.get("Site");
          var action = TargetResource["::get::Logger::site"];
          return action.apply(R, arguments);
        };


        return R;
      }]);


  module
  .factory('LoopBackAuth', function() {
    var props = ['accessTokenId', 'currentUserId', 'rememberMe'];
    var propsPrefix = '$LoopBack$';

    function LoopBackAuth() {
      var self = this;
      props.forEach(function(name) {
        self[name] = load(name);
      });
      this.currentUserData = null;
    }

    LoopBackAuth.prototype.save = function() {
      var self = this;
      var storage = this.rememberMe ? localStorage : sessionStorage;
      props.forEach(function(name) {
        save(storage, name, self[name]);
      });
    };

    LoopBackAuth.prototype.setUser = function(accessTokenId, userId, userData) {
      this.accessTokenId = accessTokenId;
      this.currentUserId = userId;
      this.currentUserData = userData;
    };

    LoopBackAuth.prototype.clearUser = function() {
      this.accessTokenId = null;
      this.currentUserId = null;
      this.currentUserData = null;
    };

    LoopBackAuth.prototype.clearStorage = function() {
      props.forEach(function(name) {
        save(sessionStorage, name, null);
        save(localStorage, name, null);
      });
    };

    return new LoopBackAuth();

    // Note: LocalStorage converts the value to string
    // We are using empty string as a marker for null/undefined values.
    function save(storage, name, value) {
      try {
        var key = propsPrefix + name;
        if (value == null) value = '';
        storage[key] = value;
      } catch (err) {
        console.log('Cannot access local/session storage:', err);
      }
    }

    function load(name) {
      var key = propsPrefix + name;
      return localStorage[key] || sessionStorage[key] || null;
    }
  })
  .config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('LoopBackAuthRequestInterceptor');
  }])
  .factory('LoopBackAuthRequestInterceptor', ['$q', 'LoopBackAuth',
    function($q, LoopBackAuth) {
      return {
        'request': function(config) {
          // filter out external requests
          var host = getHost(config.url);
          if (host && config.url.indexOf(urlBaseHost) === -1) {
            return config;
          }

          if (LoopBackAuth.accessTokenId) {
            config.headers[authHeader] = LoopBackAuth.accessTokenId;
          } else if (config.__isGetCurrentUser__) {
            // Return a stub 401 error for User.getCurrent() when
            // there is no user logged in
            var res = {
              body: { error: { status: 401 }},
              status: 401,
              config: config,
              headers: function() { return undefined; },
            };
            return $q.reject(res);
          }
          return config || $q.when(config);
        },
      };
    }])

  /**
   * @ngdoc object
   * @name lbServices.LoopBackResourceProvider
   * @header lbServices.LoopBackResourceProvider
   * @description
   * Use `LoopBackResourceProvider` to change the global configuration
   * settings used by all models. Note that the provider is available
   * to Configuration Blocks only, see
   * {@link https://docs.angularjs.org/guide/module#module-loading-dependencies Module Loading & Dependencies}
   * for more details.
   *
   * ## Example
   *
   * ```js
   * angular.module('app')
   *  .config(function(LoopBackResourceProvider) {
   *     LoopBackResourceProvider.setAuthHeader('X-Access-Token');
   *  });
   * ```
   */
  .provider('LoopBackResource', function LoopBackResourceProvider() {
    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} header The header name to use, e.g. `X-Access-Token`
     * @description
     * Configure the REST transport to use a different header for sending
     * the authentication token. It is sent in the `Authorization` header
     * by default.
     */
    this.setAuthHeader = function(header) {
      authHeader = header;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the header name that is used for sending the authentication token.
     */
    this.getAuthHeader = function() {
      return authHeader;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} url The URL to use, e.g. `/api` or `//example.com/api`.
     * @description
     * Change the URL of the REST API server. By default, the URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.setUrlBase = function(url) {
      urlBase = url;
      urlBaseHost = getHost(urlBase) || location.host;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the URL of the REST API server. The URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.getUrlBase = function() {
      return urlBase;
    };

    this.$get = ['$resource', function($resource) {
      var LoopBackResource = function(url, params, actions) {
        var resource = $resource(url, params, actions);

        // Angular always calls POST on $save()
        // This hack is based on
        // http://kirkbushell.me/angular-js-using-ng-resource-in-a-more-restful-manner/
        resource.prototype.$save = function(success, error) {
          // Fortunately, LoopBack provides a convenient `upsert` method
          // that exactly fits our needs.
          var result = resource.upsert.call(this, {}, this, success, error);
          return result.$promise || result;
        };
        return resource;
      };

      LoopBackResource.getUrlBase = function() {
        return urlBase;
      };

      LoopBackResource.getAuthHeader = function() {
        return authHeader;
      };

      return LoopBackResource;
    }];
  });
})(window, window.angular);
