import {
  Observable
} from 'rxjs';


export default class NotificationService {
  constructor($q, Notification, AuthenticationService) {
    this.$q = $q;
    this.Notification = Notification;
    this.Auth = AuthenticationService;
  }

  listener = () => {
    let observable = Observable.create(observer => {
      let a = [];
      let src = new EventSource('/api/events/change-stream?_format=event-stream');
      src.addEventListener('data', evt => {
        observer.next(a);
      });
      src.onerror((e) => {
        observer.complete();
      });
      observer.next(a);
    });
    return observable;
  }

  load = () => {
    let defer = this.$q.defer();
    let userId = this.Auth.getId();
    this.Notification.count({
      where: {
        userId: userId,
        seen: false
      }
    }).$promise.then(c => {
      this.Notification.find({
        filter: {
          order: 'id DESC',
          where: {
            userId: userId
          },
          include: ['event'],
          limit: 5
        }
      }).$promise.then(r => {
        defer.resolve({
          unseen: c.count,
          last: r
        });
      });
    })
    return defer.promise;
  }
}

NotificationService.$inject = ['$q', 'Notification', 'AuthenticationService'];
