export default class GroupListController {
  constructor(GroupService, AuthorizationService, $state, AuthenticationService, UIService) {
    this.Group = GroupService;
    this.Authorization = AuthorizationService;
    this.Auth = AuthenticationService;
    this.$state = $state;
    this.UI = UIService;
    this.filter = "";
    this.loadData();
  }

  loadData = () => {
    this.Group.list(this.createFilter()).then(r => {
      this.data = r.data;
      this.total = r.total;
      this.start = 1 || 0;
      this.end = r.data.length || 0;
      this.loaded = true;
    });
  }

  viewGroup = row => {
    if (this.Authorization.canPerform(['editEntity'])) {
      this.$state.go('app.group.details', {
        id: row.id
      })
    }
  }

  add = () => {
    if (this.Authorization.canPerform(['editEntity'])) {
      this.$state.go('app.group.add')
    }
  }

  createFilter = () => {
    return this.filter;
  };

  clear = () => {
    this.filter = "";
    this.loadData();
  };

  delete = (row) => {
    this.UI.showConfirm(`Tem a certeza que pretende eliminar o grupo ${row.name}?`).then(r => {
      this.Group.delete(row.id).then(r => {
        this.UI.addToast("Grupo de balanceamento de carga eliminado com sucesso");
        this.loadData();
      }).catch(e => {
        this.UI.addToast("Ocorreu um erro ao eliminar grupo de balanceamento de carga");
      })

    })
  }
}

GroupListController.$inject = ['GroupService', 'AuthorizationService', '$state', "AuthenticationService", "UIService"];
