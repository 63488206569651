export default class AdminSocketController {
    constructor($state, Evse, UIService) {
        this.$state = $state;
        this.Evse = Evse;
        this.UI = UIService;
        this.loadData();
    }

    loadData = () => {
        this.loaded = false;
        this.Evse.connected().$promise.then(r => {
            this.list = r;
            this.loaded = true;
        }).catch(e => {
            console.log(e);
            this.UI.addToast("Ocorreu um erro a ligar ao módulo OCPP");
        });
    }

    adopt = row => {
        this.UI.showDialog({
            controller: ['$scope', 'EvseProfile', 'Site', ($scope, EvseProfile, Site) => {
                $scope.loaded = false;

                $scope.data = {};

                $scope.profiles = [];

                $scope.ok = () => {
                    $scope.$close($scope.data);
                }

                $scope.cancel = () => {
                    $scope.$dismiss();
                }

                $scope.groupFn = (item) => {
                    return item.entity.name;
                }

                $scope.newProfile = () => {
                    this.UI.showDialog({
                        template: require('./profile.dialog.html'),
                        controller: ['$scope', $scope => {
                            $scope.data = {
                                connectors: [],
                                partNumbers: []
                            };

                            $scope.ok = () => {
                                $scope.$close($scope.data);
                            }

                            $scope.cancel = () => {
                                $scope.$dismiss();
                            }

                            $scope.add = () => {
                                $scope.isAdding = true;
                                $scope.connector = {}
                            }

                            $scope.delete = (index) => {
                                $scope.data.connectors.splice(index, 1);
                            }

                            $scope.save = () => {
                                $scope.isAdding = false;
                                $scope.data.connectors = $scope.data.connectors || [];
                                let exists = $scope.data.connectors.find(r => r.connectorId == $scope.connector.connectorId);
                                if (exists == undefined) {
                                    $scope.data.connectors.push(angular.copy($scope.connector));
                                }
                                $scope.connector = {};

                            }
                        }]
                    }).then(r => {
                        EvseProfile.create(r).$promise.then(profile => {
                            $scope.profiles.push(profile);
                            $scope.data.profile = profile;
                        });
                    })
                }

                EvseProfile.find().$promise.then(p => {
                    $scope.profiles = p;
                    Site.find({
                        filter: {
                            where: {
                                active: true
                            },
                            include: {
                                relation: 'entity',
                                scope: {
                                    where: {
                                        active: true
                                    }
                                }
                            }
                        }
                    }).$promise.then(r => {
                        $scope.list = r.filter(a => a.entity != null);
                        $scope.loaded = true;
                    });
                });
            }],
            template: require('./adopt.dialog.html')
        }).then(r => {
            this.Evse.prototype$updateAttributes(
                { id: row.evseId }, {
                siteId: r.site.id,
                profileId: r.profile.id
            }).$promise.then(r => {
                this.UI.addToast("Carregador associado a instalação");
                this.loadData();
            });
        }).catch(e => {

        });
    }
}

AdminSocketController.$inject = ['$state', 'Evse', 'UIService'];
