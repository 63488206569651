export default class CreationController {
    constructor($state, UIService, PublicService) {
        this.$state = $state;
        this.title = "Criação de conta";
        this.description = "Preencha o formulário abaixo para completar o processo de registo para a aplicação.";
        this.button = "CONCLUIR REGISTO";
        this.UI = UIService;
        this.Public = PublicService;
        this.password = "";
        this.confirm = "";
        this.token = $state.params.token;
        if (this.token == null) {
            this.$state.go('app.dashboard');
        }
    }

    setPassword = () => {
        // valida passwords
        if (this.password.localeCompare(this.confirm) != 0) {
            this.UI.addToast("As passwords introduzidas não condizem");
            return;
        } else {
            // Atualiza password do cliente
            this.Public.setPassword(this.token, this.password).then(r => {
                this.UI.addToast("Registo concluído com sucesso");
            }).catch(e => {
                this.UI.addToast("Ocorreu um erro ao concluir registo");
            });
        }
    }
}

CreationController.$inject = ['$state', 'UIService', 'PublicService'];