export function routes($stateProvider) {
  $stateProvider
    .state('app.site', {
      url: '/site',
      abstract: true,
      template: '<ui-view></ui-view>'
    }).state('app.site.list', {
      url: '?{page:int}&{order:string}&{sort:string}&{term:string}',
      template: require('./list/view.html'),
      controller: 'SiteListController',
      controllerAs: 'vm',
      role: ['readSite']
    }).state('app.site.details', {
      url: '/{id}',
      template: require('./details/view.html'),
      controller: 'SiteDetailsController',
      controllerAs: 'vm',
      role: ['readSite']
    }).state('app.site.new', {
      url: '/add/?{entityId}',
      template: require('./details/view.html'),
      controller: 'SiteDetailsController',
      controllerAs: 'vm',
      role: ['createSite']
    });
}

routes.$inject = ['$stateProvider'];
