export default class PublicService {
    constructor($q, Client) {
        this.$q = $q;
        this.Client = Client;
    }

    setPassword = (token, password) => {
        let defer = this.$q.defer();
        this.Client.updatePasswordFromToken({
            access_token: token
        },{
            newPassword: password
        }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
        return defer.promise;
    }
}

PublicService.$inject = ['$q', 'Client'];