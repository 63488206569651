export function routes($stateProvider) {
  $stateProvider
    .state('app', {
      template: require('./layout/layout.view.html'),
      controller: 'LayoutController',
      controllerAs: 'vm'
    })
    .state('public', {
      abstract: true,
      template: require('./layout/public.view.html'),
    })
    .state('paywall', {
      url: '/paywall',
      abstract: true,
      template: require('./layout/paywall.view.html'),
    })
    .state('auth', {
      template: require('./layout/main.view.html'),
    })
    .state('app.dashboard', {
      template: require('./dashboard/dashboard.view.html'),
      url: '/',
      title: 'Dashboard',
      controller: 'DashboardController',
      controllerAs: 'vm'
    })
    .state('app.dashboard.admin', {
      template: require('./dashboard/admin/view.html'),
      title: 'Dashboard',
      controller: 'DashboardAdminController',
      controllerAs: 'vm'
    })
    .state('app.dashboard.manager', {
      template: require('./dashboard/manager/view.html'),
      title: 'Dashboard',
      controller: 'DashboardManagerController',
      controllerAs: 'vm'
    })
    .state('app.profile', {
      template: require('./profile/profile.view.html'),
      url: '/profile?link',
      title: 'Perfil',
      controller: 'ProfileController',
      controllerAs: 'vm',
      role: ['$authenticated']
    });
}

routes.$inject = ['$stateProvider'];
