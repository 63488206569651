import angular from 'angular';

export default angular.module('app.interface.infinitescroll', [])
    .directive('pmdInfiniteScroll', () => {
        return function (scope, element, attr) {
            var raw = element[0];

            element.bind('scroll', function () {
                if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
                    scope.$apply(attr.pmdInfiniteScroll);
                }
            })
        }
    }).run(['$rootScope', ($rootScope) => {
        $rootScope.uiModules = $rootScope.uiModules || [];
        let properties = {
            name: 'Infinite Scroll',
            description: 'Infinite Scrolls page or container',
            version: '1.0.0'
        };
        if ($rootScope.uiModules.indexOf(properties) === -1) {
            $rootScope.uiModules.push(properties);
        }
    }]).name;
