export function routes($stateProvider) {
    $stateProvider
        .state('public.creation', {
            url: '/account-creation?{token:string}',
            template: require('./creation/view.html'),
            controller: 'CreationController',
            controllerAs: 'vm'
        })
        .state('public.recover', {
            url: '/account-recover?{token:string}',
            template: require('./creation/view.html'),
            controller: 'RecoverController',
            controllerAs: 'vm'
        });
}

routes.$inject = ['$stateProvider'];
