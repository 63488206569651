export function routes($stateProvider) {
  $stateProvider
    .state('app.group', {
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.group.list', {
      url: '/group',
      template: require('./list/view.html'),
      controller: 'GroupListController',
      controllerAs: 'vm',
      role: ['editEntity']
    })
    .state('app.group.details', {
      url: '/group/{id}',
      template: require('./details/view.html'),
      controller: 'GroupDetailsController',
      controllerAs: 'vm',
      role: ['editEntity']
    })
    .state('app.group.add', {
      url: '/group/add',
      template: require('./details/view.html'),
      controller: 'GroupDetailsController',
      controllerAs: 'vm',
      role: ['editEntity']
    });
}

routes.$inject = ['$stateProvider'];
