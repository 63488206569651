export default class AdminListUserController {
  constructor($state, UIService, UserService, AuthenticationService) {
    this.$state = $state;
    this.UIService = UIService;
    this.UserService = UserService;
    this.AuthenticationService = AuthenticationService;
    this.filter = {
      term: this.$state.params.term || "",
      page: this.$state.params.page || 1,
      sort: this.$state.params.sort || "asc",
      order: this.$state.params.order || "name",
      site: this.$state.params.site || false
    };
    this.loadData();
  }

  clear = () => {
    this.filter = {
      term: '',
      page: this.$state.params.page || 1,
      sort: "asc",
      order: "name",
      site: this.$state.params.site || false
    };
    this.loadData();
  };

  filterSite = (value) => {
    this.filter.site = value;
    this.loadData();
  }

  createFilter = () => {
    let where = {};
    if (this.filter.term.length > 0) {
      where = {
        or: [
          { email: { like: this.filter.term } },
          { name: { like: this.filter.term } },
          { phone: { like: this.filter.term } },
        ],
      }
    }
    if (this.filter.site) {
      where.siteId = this.AuthenticationService.getUser().siteId;
    }
    return {
      filter: {
        where: where,
        include: ['groups', {
          relation: 'tenants',
          scope: {
            include: 'entity'
          }
        }],
        limit: 20,
        order: `${this.filter.order} ${this.filter.sort}`,
        skip: (this.filter.page - 1) * 15,
      }
    };
  };

  next = () => {
    if (this.end < this.total) {
      this.filter.page++;
    }
    this.loadData();
  };

  previous = () => {
    if (this.filter.page > 1) {
      this.filter.page--;
    }
    this.loadData();
  };

  loadData = () => {
    this.loaded = false;
    this.$state.go("app.administration.users.list", this.filter, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.UserService.list(this.createFilter())
      .then((r) => {
        r.data.forEach(row => {
          row.tenants = row.tenants.filter(t => t.entity && t.entity.name);
        });
        this.data = r.data;
        this.total = r.total;
        this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
        this.end =
          r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
        this.loaded = true;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  isCurrentUser = (user) => {
    return this.AuthenticationService.getId() === user.id;
  };

  blockUser = (user) => {
    if (user.blocked || this.AuthenticationService.getId() === user.id) return;
    let confirm = this.UIService.showConfirm('Deseja bloquear utilizador?');
    confirm.then(() => {
      // OK
      user.blocked = true;
      user.$save().then(() => {
        this.UIService.addToast('Utilizador bloqueado');
      });
    }).catch(() => {
      // Cancel
    });
  };

  blockCondition = (user) => {
    return user.blocked || this.AuthenticationService.getId() === user.id;
  };

  removeUser = (user) => {
    this.UIService.showConfirm('Deseja remover utilizador ' + user.name + ' do sistema?').then(() => {
      this.UserService.delete(user.id).then((result) => {
        this.loadData();
        this.UIService.addSnackbar('Utilizador removido', 'Anular', true).then((res) => {
          if (res) {
            delete user.id;
            let uuid = (a) => (a ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid));
            user.password = uuid();
            this.UserService.update(user).then((res) => {
              this.loadData();
            });
          }
        });
      });
    });
  }
}

AdminListUserController.$inject = ['$state', 'UIService', 'AdminUserService', 'AuthenticationService'];
