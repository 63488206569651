export default class ProfileService {
  constructor($q, AuthenticationService, Site, Profile, Evse, Transaction, Client, Smartphone) {
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.Site = Site;
    this.Profile = Profile;
    this.Evse = Evse;
    this.Transaction = Transaction;
    this.Client = Client;
    this.Smartphone = Smartphone;
  }

  list = (filter) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();

    this.Site.find({
      filter: {
        where: {
          active: true,
          entityId: {
            inq: user.entityIds
          }
        }
      }
    }).$promise.then(s => {
      let sites = s.map(a => a.id);
      filter.where.siteId = { inq: sites };

      this.Profile.count({
        where: filter.where,
      }).$promise.then(c => {
        this.Profile.find({
          filter: filter
        }).$promise.then(r => {
          return defer.resolve({
            total: c.count,
            data: r
          });
        }).catch(e => defer.reject(e));
      }).catch(e => defer.reject(e));
    }).catch(e => defer.reject(e));
    return defer.promise;
  };

  get = id => {
    let defer = this.$q.defer();
    this.Profile.findById({
      id: id,
      filter: {
        include: [
          {
            relation: 'accesses',
            scope: {
              include: 'evse'
            }
          },
          {
            relation: 'site'
          },
          {
            relation: 'client',
            scope: {
              include: 'smartphones'
            }
          }
        ]
      }
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  delete = id => {
    let defer = this.$q.defer();
    this.Profile.prototype$updateAttributes({
      id: id,
    }, { active: false }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  deletePhone = id => {
    let defer = this.$q.defer();
    this.Smartphone.deleteById({
      id: id,
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };


  save = data => {
    let defer = this.$q.defer();
    // Register client
    if (!data.issueDate)
      data.issueDate = moment();
    // Set lastModified = now
    data.lastModified = moment();
    // Remove start date and end date if expires is set
    if (!data.expires) {
      delete data.startDate;
      delete data.endDate;
    }
    data.cards = data.cards || [];

    data.cards.forEach(c => {
      if (!c.expires) {
        delete c.startDate;
        delete c.endDate;
      }
    });
    // Update access
    let accessList = angular.copy(data.chargers);
    delete data.chargers;
    this.Profile.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  evses = siteId => {
    let defer = this.$q.defer();
    this.Evse.find({
      filter: {
        where: {
          siteId: siteId,
        }
      }
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  consumption = id => {
    let defer = this.$q.defer();
    this.Transaction.clientConsumption({
      clientId: id
    }).$promise.then(c => {
      defer.resolve(c);
    }).catch(e => defer.reject(e));
    return defer.promise;
  };

  // identifiers: [1981954194, "041A0D0ABF6681", 987654321]
  findExisting = (identifiers, site) => {
    let defer = this.$q.defer();
    let existingIds = [];
    let tasks = [];
    for (let i = 0; i < identifiers.length; i++) {
      let def2 = this.$q.defer();
      this.Profile.find({
        filter: {
          where: {
            and: [{
              siteId: site.id
            }, {
              cards: {
                elemMatch: {
                  no: identifiers[i].toString()
                }
              }
            }]
          }
        }
      }).$promise.then((p) => {
        if (p && p.length === 1) {
          existingIds.push(identifiers[i]);
          def2.resolve(i);
        } else {
          def2.resolve();
        }
      }).catch(errProfile => {
        def2.reject(errProfile);
      });
      tasks.push(def2.promise);
    }

    this.$q.all(tasks).then((p) => {
      defer.resolve(existingIds);
    }).catch(err => {
      defer.reject(err);
    });

    return defer.promise;
  };

  // Convert Excel date to string date
  numberToDate = (number) => {
    if (isNaN(number) || number < 1) return null;
    else return moment('31-12-1899', 'DD-MM-YYYY').add(number - 1, 'd');
  };

  // Create Profiles
  createProfiles = (newProfiles) => {
    let defer = this.$q.defer();
    let newP = 0;
    let tasks = [];
    for (let i = 0; i < newProfiles.length; i++) {
      let def2 = this.$q.defer();
      this.Profile.find({
        filter: {
          where: {
            cards: {
              elemMatch: {
                no: newProfiles[i].no
              }
            }
          }
        }
      }).$promise.then((p) => {
        if (p && p.length === 1) { // Already exists. Skip.
          def2.resolve();
        } else { // Create and increment.
          this.createProfileClient(newProfiles[i]).then(n => {
            console.log(n);
            newP = newP + 1;
            def2.resolve();

          }).catch(err => {
            def2.reject(err);
          });
        }
      }).catch(err => {
        def2.reject();
      });

      tasks.push(def2.promise);
    }
    this.$q.all(tasks).then(q => {
      defer.resolve({ count: newP, error: null });
    }).catch(errAll => {
      defer.resolve({ count: newP, error: errAll });
    });

    return defer.promise;
  };

  uuid = (a) => a ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, this.uuid);

  createProfileClient = (profile) => {
    let defer = this.$q.defer();
    if (profile.email != null && profile.email.length > 0) {
      this.Client.find({
        filter: {
          where: {
            email: profile.email
          }
        }
      }).$promise.then((cl) => {
        console.log(cl);
        if (cl && cl.length === 1) { // Client exists, use it
          profile.clientId = cl[0].id;
          this.createProfile(profile).then(p => {
            console.log(p);
            defer.resolve(p);
          }).catch(err => {
            defer.reject(err);
          });
        } else { // Client not found, create it
          this.Client.create({
            email: profile.email,
            password: this.uuid(),
            createdAt: moment(),
          }).$promise.then(client => {
            profile.clientId = client.id;
            this.createProfile(profile).then(p => {
              console.log(p);
              defer.resolve(p);
            }).catch(err => {
              defer.reject(err);
            });
          }).catch(err => {
            defer.reject(err);
          });
        }
      }).catch((error) => {
        console.log(error);
      });

    } else {
      this.createProfile(profile);
    }
    return defer.promise;
  };

  createProfile = (profile) => {
    let defer = this.$q.defer();
    this.Profile.create({
      active: true,
      issueDate: moment.utc(),
      lastModified: moment.utc(),
      name: profile.name,
      description: profile.description,
      access: true,
      expires: profile.endDate != null && moment(profile.endDate).isValid() || false,
      startDate: profile.startDate,
      endDate: profile.endDate,
      cards: [{
        no: profile.no,
        description: profile.identifierDescription,
        issueDate: moment(),
        startDate: profile.identifierStartDate,
        endDate: profile.identifierEndDate,
        lastModified: moment(),
        blocked: false
      }],
      siteId: profile.siteId,
    }).$promise.then(p => {
      defer.resolve(p);
    }).catch(err => {
      defer.reject(err);
    });
    console.log("Promise");
    return defer.promise;
  };


  // Change Profiles
  changeProfiles = async (alteredProfiles) => {
    let defer = this.$q.defer();
    let altered = 0;
    let tasks = [];
    // TODO - If necessary to deal with repeats, uncomment and finish
    // for (let i = 0; i < alteredProfiles.length; i++) {
    //   let def2 = this.$q.defer();
    //
    //   this.Profile.find({
    //     filter: {
    //       where: {
    //         cards: {
    //           elemMatch: {
    //             no: alteredProfiles[i].no
    //           }
    //         }
    //       },
    //       include: 'client'
    //     }
    //   }).$promise.then((p) => {
    //     if (p && p.length === 1) { // Already exists. Check if email matches.
    //       let oldProfile = p[0];
    //       if (oldProfile.client) { // This Profile has a client, check if new has it too and if it's different
    //         if (oldProfile.client.email.toUpperCase() === alteredProfiles[i].email.toUpperCase()) { // Same email, just update the profile
    //
    //
    //         } else { // Using a different client for this profile, remove the card from this one and add a new profile with the data
    //
    //         }
    //       } else { // Old profile doesn't have client
    //
    //       }
    //
    //       def2.resolve();
    //
    //     } else { // Does not exist anymore (?). Just create it and see if client exists.
    //       this.createProfileClient(alteredProfiles[i]).then(n => {
    //         altered = altered + 1; // Best you can do in this scenario.
    //         def2.resolve();
    //       }).catch(err => {
    //         def2.reject(err);
    //       });
    //     }
    //   }).catch(err => {
    //     def2.reject();
    //   });
    //
    //   tasks.push(def2.promise);
    // }
    this.$q.all(tasks).then(q => {
      defer.resolve({ count: altered, error: null });
    }).catch(errAll => {
      defer.resolve({ count: altered, error: errAll });
    });

    return defer.promise;
  };

};

ProfileService.$inject = ['$q', 'AuthenticationService', 'Site', 'Profile', 'Evse', 'Transaction', 'Client', 'Smartphone'];
