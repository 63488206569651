import {routes} from './routes';
import ProfileService from './service';
import ClientListController from './list/controller';
import ClientDetailsController from './details/controller';
import ProfileUploaderController from "./list/files.dialog.controller";

  export default angular.module('app.client', [])
    .config(routes)
    .service('ProfileService', ProfileService)
    .controller('ClientListController', ClientListController)
    .controller('ClientDetailsController', ClientDetailsController)
    .controller('ProfileUploaderController', ProfileUploaderController)
    .name;
