import angular from 'angular';
import modalHelper from "./modalHelper";

export default angular.module('app.interface.dialogs', [modalHelper])
  .controller('DialogController', ['$scope', '$dialog', 'message', function ($scope, $dialog, message) {

    $scope.message = message;

    $scope.ok = () => {
      $dialog.close(true);
    };

    $scope.cancel = () => {
      $dialog.dismiss('cancel');
    };

  }])
  .factory('Dialog', ['$rootScope', '$modal', function ($rootScope, $modal) {
    return {
      waiting: () => {
        return $modal.open({
          keyboard: false,
          backdrop: 'static',
          size: 'sm',
          template: '<div style="padding: 24px 16px 16px 16px;"><pmd-spinner></pmd-spinner></div>'
        });
      },
      alert: message => {
        return $modal.open({
          keyboard: true,
          backdrop: 'static',
          template: require('./templates/alert.html'),
          controller: 'DialogController',
          resolve: {
            message: function () {
              return message;
            }
          }
        }).result;
      },
      confirm: (message) => {
        return $modal.open({
          keyboard: true,
          backdrop: 'static',
          template: require('./templates/confirm.html'),
          controller: 'DialogController',
          resolve: {
            message: function () {
              return message;
            }
          }
        }).result;
      },
      dialog: options => {
        return $modal.open(options).result;
      }
    }
  }])
  .run(['$rootScope', ($rootScope) => {
    $rootScope.uiModules = $rootScope.uiModules || [];
    let properties = {
      name: 'Dialogs',
      description: 'Dialog & Bottom Sheets',
      version: '1.0.2'
    };
    if ($rootScope.uiModules.indexOf(properties) === -1) {
      $rootScope.uiModules.push(properties);
    }
  }])
  .name;
