export default class AdministrationService {
  constructor(Rolemap, Role, Users, Group, Rolegroup, Settings, $q, Entity, Payment, Tenant, Access) {
    this.Rolemap = Rolemap;
    this.Role = Role;
    this.Settings = Settings;
    this.Users = Users;
    this.Group = Group;
    this.Rolegroup = Rolegroup;
    this.$q = $q;
    this.Entity = Entity;
    this.Payment = Payment;
    this.Tenant = Tenant;
    this.Access = Access;
  }

  getEntities = () => {
    let defer = this.$q.defer();
    this.Entity.find({
      filter: {
        where: { active: true }
      }
    }).$promise.then((r) => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  /* Role related functions */

  getSettings = () => {
    let defer = this.$q.defer();
    this.Settings.find({}).$promise.then((settings) => defer.resolve(settings));
    return defer.promise;
  };

  saveSetting = (data) => {
    let defer = this.$q.defer();
    this.Settings.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  getGroups = () => {
    let defer = this.$q.defer();
    this.Group.find({}).$promise.then((groups) => {
      defer.resolve(groups);
    });
    return defer.promise;
  };

  deleteGroup = id => {
    let defer = this.$q.defer();
    this.Group.deleteById({
      id: id
    }).$promise.then((users) => {
      defer.resolve(users);
    });
    return defer.promise;
  }

  getGroupInfo = (roleId) => {
    let defer = this.$q.defer();
    this.Group.findOne({
      filter: {
        where: {
          id: roleId,
          active: true,
        },
        include: [
          "users",
          {
            relation: "roles",
            scope: {
              include: "feature",
            },
          },
        ],
      },
    }).$promise.then((r) => {
      defer.resolve(r);
    });
    return defer.promise;
  };

  getGroupRoles = (roleId) => {
    let defer = this.$q.defer();
    this.Rolegroup.find({
      filter: {
        where: {
          groupId: roleId,
        },
        include: {
          relation: "role",
          scope: {
            include: "feature",
          },
        },
      },
    }).$promise.then((roles) => {
      defer.resolve(roles);
    });
    return defer.promise;
  };

  updateGroup = (group, featuredRoles, users) => {
    let defer = this.$q.defer();
    let roles = [];
    // Ungroup the roles
    featuredRoles.forEach((f) => {
      f.roles.forEach((role) => {
        roles.push(role);
      });
    });

    this.Group.updateInfo({
      id: group.id,
      users: users,
      roles: roles,
    }).$promise.then((result) => {
      group.edited = new Date();
      group.$save().then(() => {
        defer.resolve();
      });
    });

    return defer.promise;
  };

  groupHasUsers = (id) => {
    let defer = this.$q.defer();
    this.Group.findOne({
      filter: {
        where: {
          id: id,
        },
        include: "users",
      },
    }).$promise.then((res) => {
      if (res.users.length > 0) {
        defer.resolve(true);
      } else {
        defer.resolve(false);
      }
    });
    return defer.promise;
  };

  createGroup = (group) => {
    let defer = this.$q.defer();

    group.edited = new Date();

    this.Group.create(group)
      .$promise.then((data) => {
        defer.resolve(data);
      })
      .catch((err) => {
        defer.reject(err);
      });

    return defer.promise;
  };

  createPayment = data => {
    return this.Payment.create(data).$promise;
  }

  /* User related functions */
  getUsersInRole = (roleId) => {
    let defer = this.$q.defer();
    this.Group.find({
      filter: {
        fields: {
          id: true,
        },
        where: {
          id: roleId,
        },
        include: "users",
      },
    }).$promise.then((role) => {
      let users = [];
      role.forEach((r) => {
        r.users.forEach((u) => {
          users.push(u);
        });
      });
      defer.resolve(users);
    });
    return defer.promise;
  };

  getUsersNotInRole = (roleId) => {
    let defer = this.$q.defer();

    this.getUsersInRole(roleId).then((users) => {
      let ids = _.map(users, (u) => {
        return u.id;
      });

      this.Users.find({
        filter: {
          where: {
            id: {
              nin: ids,
            },
          },
        },
      }).$promise.then((list) => defer.resolve(list));
    });

    return defer.promise;
  };

  setPropertyUser = (user, property) => {
    return this.Users.prototype$patchAttributes(
      {
        id: this.user.id,
      },
      property
    ).$promise;
  }

  getUsers = () => {
    let defer = this.$q.defer();
    this.Users.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then((users) => {
      defer.resolve(users);
    });
    return defer.promise;
  };

  deleteUser = id => {
    let defer = this.$q.defer();
    this.Users.deleteById({
      id: id
    }).$promise.then((users) => {
      defer.resolve(users);
    });
    return defer.promise;
  }

  getUser = (id) => {
    let defer = this.$q.defer();
    let from = moment().subtract(6, 'month');
    let to = moment();
    this.Users.find({
      filter: {
        where: {
          id: id,
        },
        include: [{
          relation: 'tenants',
          scope: {
            include: 'entity'
          }
        }, {
          relation: 'balances',
          scope: {
            where: {
              timestamp: {
                between: [from, to]
              }
            },
            include: ['transaction', {
              relation: 'site',
              scope: {
                include: 'entity'
              }
            }],
            order: 'timestamp DESC'
          }
        }],
      },
    }).$promise.then((user) => {
      defer.resolve(user);
    });
    return defer.promise;
  };

  createUser = (data) => {
    let defer = this.$q.defer();

    // Populate data
    data.blocked = false;
    data.createdAt = new Date();

    this.Users.create(data)
      .$promise.then((result) => {
        defer.resolve(result);
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  };

  updateUser = (user) => {
    let defer = this.$q.defer();
    this.Users.upsert(user).$promise.then((result) => {
      defer.resolve(result);
    }).catch((err) => {
      defer.reject(err);
    });
    return defer.promise;
  };

  getPayments = (filter) => {
    let defer = this.$q.defer();
    this.Payment.count({ where: filter.where }).$promise.then(c => {
      this.Payment.find({filter: filter}).$promise.then(r => {
        defer.resolve({
          total: c.count,
          data: r
        });
      }).catch(e => defer.reject(e));
    }).catch(e => defer.reject(e));
    return defer.promise;
  }

  pollPayment = id => {
    return this.Payment.status({ id: id }).$promise;
  }


  setEntityUser = (user, entity) => {
    return this.Tenant.create({
      userId: user,
      entityId: entity
    }).$promise;
  }

  getTenantSites = (id) => {
    let defer = this.$q.defer();
    this.Tenant.find({
      filter: {
        where: {
          userId: id
        },
        include: {
          relation: 'entity',
          scope: {
            include: {
              relation: 'sites',
              scope: {
                where: {
                  active: true
                }
              }
            }
          }
        }
      }
    }).$promise.then(r => {
      this.Users.findOne({
        filter: {
          where: {
            id: id
          },
          include: 'access'
        }
      }).$promise.then(b => {
        let list = [];
        r.forEach(row => {
          list.push(row.entity);
        });
        list.forEach(l => {
          l.sites.forEach(x => {
            let isPicked = b.access.find(r => r.siteId == x.id);
            x.picked = isPicked != undefined;
          });
        });
        defer.resolve(list);
      });
    });
    return defer.promise;
  }

  removeAccessArray = (user, sites) => {
    let defer = this.$q.defer();
    let siteIds = sites.map(r => r.id);
    console.log(siteIds);
    this.Access.find({
      filter: {
        where: {
          userId: user,
          siteId: {
            inq: siteIds
          }
        }
      }
    }).$promise.then(r => {
      if (r.length == 0) {
        defer.resolve();
      } else {
        let promises = [];
        r.forEach(row => {
          promises.push(this.Access.deleteById({ id: row.id }).$promise);
        });
        this.$q.all(promises).then(r => {
          defer.resolve();
        }).catch(e => {
          defer.reject();
        });
      }
    });
    return defer.promise;
  }

  setAccessArray = (user, sites) => {
    let defer = this.$q.defer();
    let promises = [];
    sites.forEach(row => {
      promises.push(this.setAccess(row.id, user));
    });
    this.$q.all(promises).then(r => {
      defer.resolve(r);
    })
    return defer.promise;
  }

  removeAccess = (site, user) => {
    let defer = this.$q.defer();
    this.Access.find({
      filter: {
        where: {
          userId: user,
          siteId: site,
        },
        limit: 1
      }
    }).$promise.then(r => {
      if (r.length == 0) {
        defer.resolve();
      } else {
        this.Access.deleteById({ id: r[0].id }).$promise.then(_ => {
          defer.resolve();
        })
      }
    });
    return defer.promise;
  }

  setAccess = (site, user) => {
    let defer = this.$q.defer();
    this.Access.find({
      filter: {
        where: {
          userId: user,
          siteId: site,
        }
      }
    }).$promise.then(r => {
      if (r.length > 0) {
        defer.resolve();
      } else {
        this.Access.create({
          userId: user,
          siteId: site,
          expires: false,
          begin: moment.utc(),
          modified: moment.utc(),
        }).$promise.then(_ => {
          defer.resolve();
        })
      }
    });
    return defer.promise;
  }

  updateEntities = (user, entities) => {
    return this.Tenant.massUpdate({ userId: user, entities: entities }).$promise;
  }

  setUserPassword = (userId, password) => {
    return this.Users.setNewPassword({
      id: userId,
      password: password
    }).$promise;
  }

  identifierExists = (identifier) => {
    let defer = this.$q.defer();

    this.Users.count({
      where: {
        cards: {
          elemMatch: identifier
        }
      }
    }).$promise.then(r => {
      defer.resolve(r.count == 0);
    }).catch(e => {
      defer.reject();
    });
    return defer.promise;
  }
}

AdministrationService.$inject = ["Rolemap", "Role", "User", "Group", "Rolegroup", "Setting", "$q", "Entity", "Payment", "Tenant", "Access"];
