export default class ReportService {
  constructor($q, Entity, Site, Payment, User, Balance) {
    this.$q = $q;
    this.Entity = Entity;
    this.Site = Site;
    this.Payment = Payment;
    this.User = User;
    this.Balance = Balance;
  }

  entity = (id, date) => {
    return this.Entity.consumption({ id: id, date: moment.utc(date).toISOString(), }).$promise;
  }

  site = (id, date) => {
    return this.Site.consumption({ id: id, date: moment.utc(date).toISOString(), }).$promise;
  }

  user = (id, date) => {
    let from = moment.utc(date).startOf('month');
    let to = moment.utc(date).startOf('month');
    //TODO: Fix time interval (broken atm)
    return this.Balance.find({
      filter: {
        where: {
          userId: id
        },
        include: 'site',
        order: 'timestamp DESC'
      }
    }).$promise;
  }

  getUsers = () => {
    return this.User.find({
      where: {
        active: true
      }
    }).$promise;
  }

}

ReportService.$inject = ['$q', 'Entity', 'Site', 'Payment', 'User', 'Balance']
