export default class AdminViewerController {
  constructor(Evse) {
    this.Evse = Evse;

    this.loaded = false;
    this.loadData();
  }

  loadData = () => {
    let a = moment().startOf('month');
    let b = moment();
    this.loaded = false;
    this.Evse.find({
      filter: {
        where: {
          active: true
        },
        include: ['site']
      }
    }).$promise.then(r => {
      this.data = r;
      this.data.forEach(r => {
        if (!r.offline) {
          this.Evse.qol({
            id: r.id, filter: {
              where: {
                timestamp: {
                  between: [a, b]
                }
              }
            }
          }).$promise.then(_ => {
            r.qol = _;
          });
        }
      })
      this.loaded = true;
    })
  }

  calcQOL = data => {
    if (data.offline) {
      return "-";
    }
    data.qol = data.qol || [];
    let count = data.qol.length;
    let ok = data.qol.filter(r => r.value == 1) || [];
    let t = (ok.length / count) * 100;
    if (isNaN(t)) {
      t = 0;
    }
    return t.toFixed(0);
  }
}

AdminViewerController.$inject = ['Evse'];
