import moment from "moment";

export default class TariffWizardController {
  constructor($state, TariffService, UIService) {
    this.$state = $state;
    this.Tariff = TariffService;
    this.UI = UIService;
    this.loadData();

    this.aux = {
      step: 1,
    };

    this.data = {
      evses: [],
    };
  }

  loadData = () => {
    this.Tariff.cycles()
      .then((r) => {
        this.loaded = true;
        this.cycles = r;
        this.aux.cycle = r[0];
      })
      .catch((e) => {
        this.UI.addToast("Ocorreu um erro ao carregador ciclos horárias");
      });

    this.Tariff.providers()
      .then((r) => {
        this.loaded = true;
        this.providers = r;
      })
      .catch((e) => {
        this.UI.addToast("Ocorreu um erro ao carregador fornecedores");
      });

    this.Tariff.chargers()
      .then((r) => {
        this.loaded = true;
        let t = r.map((r) => r.evses);
        this.evses = [];
        t.forEach((e) => this.evses.push(...e));
      })
      .catch((e) => {
        this.UI.addToast("Ocorreu um erro ao carregador instalações");
      });
  };

  dayOfWeek = (num) => {
    switch (num.dayOfWeek) {
      case 1:
        return "Segunda-Feira";
      case 2:
        return "Terça-Feira";
      case 3:
        return "Quarta-Feira";
      case 4:
        return "Quinta-Feira";
      case 5:
        return "Sexta-Feira";
      case 6:
        return "Sábado";
      case 7:
        return "Domingo";
    }
  };

  apply = (row) => {
    if (angular.isUndefined(this.aux.band)) {
      return;
    }
    row.band = angular.copy(this.aux.band);
  };

  clear = (row) => {
    row.band = undefined;
  };

  applyHover = ($event, row) => {
    if (angular.isUndefined(this.aux.band)) {
      return;
    }
    if ($event.buttons == 1) row.band = angular.copy(this.aux.band);
    if ($event.buttons == 2) row.band = undefined;
  };

  selectBand = (band) => {
    this.aux.band = band;
    this.UI.addToast(`${band.name} seleccionado`);
  };

  readableEvseFilter = () => {
    if (!this.evses) {
      return "";
    }

    if (this.data.evses.length === this.evses.length) {
      return "Todas os carregadores selecionados";
    }

    if (this.data.evses.length === 1) {
      return this.data.evses[0].alias;
    } else {
      return `${this.data.evses.length} carregadores selecionados`;
    }
  };

  filterEvses = () => {
    let evses = this.evses;
    console.log(evses);
    this.dialogFilter(evses, "alias").then((r) => {
      this.data.evses = r;
    });
  };

  dialogFilter = (list, property) => {
    return this.UI.showDialog({
      template: require("./select.dialog.html"),
      controller: [
        "$scope",
        "$filter",
        ($scope, $filter) => {
          // Arrays of data
          $scope.property = property;
          $scope.list = list || [];
          // Search filter
          $scope.filter = "";
          // Pagination
          $scope.pagination = {
            perPage: 10,
            current: 0,
            previous: () => {
              if ($scope.pagination.current > 0) $scope.pagination.current--;
            },
            onFilter: () => {
              const filteredArray = $filter("filter")(
                $scope.list,
                $scope.filter
              );
              const afterFilter =
                Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
              if (afterFilter < $scope.pagination.current) {
                $scope.pagination.current = afterFilter;
              }
            },
            next: () => {
              if ($scope.pagination.current < $scope.pagination.total() - 1)
                $scope.pagination.current++;
            },
            total: () => {
              const filteredArray = $filter("filter")(
                $scope.list,
                $scope.filter
              );
              return Math.ceil(
                filteredArray.length / $scope.pagination.perPage
              );
            },
          };

          $scope.select = function (obj) {
            obj.selected = !obj.selected;
          };

          $scope.cancel = function () {
            $scope.$dismiss("cancel");
          };

          $scope.canOk = () => {
            let selected = $scope.list.find((r) => r.selected);
            return selected != null;
          };

          $scope.ok = () => {
            let selected = $scope.list.filter((r) => r.selected);
            $scope.$close(selected);
          };
        },
      ],
    });
  };

  submit = () => {
    // Criação do modelo para submissão
    // Fornecedor
    this.data.providerId = this.aux.provider.id;
    // ciclo horária
    this.data.tariffcycleId = this.aux.cycle.id;

    // Gravar
    this.Tariff.save(this.data)
      .then((r) => {
        this.UI.addToast("Tarifário registado com sucesso!");
        this.$state.go('app.tariff.list');
      })
      .catch((e) => {
        this.UI.addToast("Ocorreu um erro ao gravar tarifário");
      });
  };
}

TariffWizardController.$inject = ["$state", "TariffService", "UIService"];
