export default class ReportSiteController {
  constructor($state, UIService, ReportService, AuthenticationService) {
    this.$state = $state;
    this.UI = UIService;
    this.Report = ReportService;
    let user = AuthenticationService.getUser();
    this.id = user.site.id;
    this.consumptionLoaded = false;
    this.date = moment.utc();
    this.loadData();
  }

  loadData = () => {
    this.consumptionLoaded = false;
    this.Report.site(this.id, this.date).then(origin => {
      this.consumption = origin;
      this.consumptionLoaded = true;
    }).catch(e => {
      this.UI.addToast("Não foi possível gerar resumos mensais da instalação");
    })
  }

  exportXLS = (row) => {
    this.exportConsumption(row, { format: 'export', extension: 'xls' });
  }

  exportPDF = (row) => {
    this.exportConsumption(row, { format: 'report', extension: 'PDF' });
  }

  exportConsumption = (row, format) => {
    let from = moment.utc(row.data).startOf('month');
    let to = moment.utc(row.data).endOf('month');
    let url = `/api/sites/${this.id}/${format.format}?from=${from}&to=${to}`;
    var downloadLink = angular.element("<a></a>");
    downloadLink.attr("href", url);
    downloadLink.attr("download", `export.${format.extension}`);
    downloadLink[0].click();
    this.UI.addToast("Download iniciado, aguarde por favor");
  }
}

ReportSiteController.$inject = ['$state', 'UIService', 'ReportService', 'AuthenticationService'];
