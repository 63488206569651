window.$ = window.jQuery;
/* Ensure async/await funcionality */
require("regenerator-runtime/runtime");
/* Angular Dependencies */
import angular from "angular";
let uiRouter = require("@uirouter/angularjs").default;
import config from "./app.config";

/* Client Modules */
import lbServices from "./modules/loopback/lbServices";
import Interface from "./modules/interface";
import Authentication from "./modules/authentication";
import Administration from "./modules/administration";
import Authorization from "./modules/authorization";
import { ApplicationInterceptor } from "./app.interceptor";

import angularLocalePT from "angular-i18n/pt-pt";
import ngResource from "angular-resource";
import ngSanitize from "angular-sanitize";
import ngCookies from "angular-cookies";
import ngAnimate from "angular-animate";
import uiSelect from "ui-select";
import chartJS from "angular-chart.js";
import "angular-file-upload";
require("angular-schema-form");
require("angular-schema-form-bootstrap");

/* Client modules */
import EVSE from "./modules/evse";
import Entity from "./modules/entity";
import Sites from "./modules/site";
import Client from "./modules/client";
import Transaction from "./modules/transaction";
import Tariff from "./modules/tariff";
import Events from "./modules/event";
import Groups from "./modules/group";
import Tariffcycle from "./modules/tariffcycle";
import Provider from "./modules/provider";
import Public from "./modules/public";
import Paywall from "./modules/paywall";
import Reports from "./modules/reports";

import { ngTableModule } from 'ng-table/bundles/ng-table';
import "@uirouter/angularjs/release/stateEvents";
//require("@mdi/font/css/materialdesignicons.min.css");
require("ui-select/dist/select.min.css");
moment.locale("pt");

let app = angular.module("app", [
  uiRouter,
  "ui.router.state.events",
  ngResource,
  ngSanitize,
  ngAnimate,
  ngCookies,
  uiSelect,
  ngTableModule.name,
  "angularFileUpload",
  "schemaForm",
  angularLocalePT,
  chartJS,
  lbServices,
  Interface,
  Authentication,
  Authorization,
  Administration,
  EVSE,
  Entity,
  Sites,
  Client,
  Transaction,
  Tariff,
  //Analytics,
  Events,
  Groups,
  Tariffcycle,
  Provider,
  Public,
  Paywall,
  Reports
]);

app.config(config);
app.run(ApplicationInterceptor);
app.run([
  "$rootScope",
  ($rootScope) => {
    $rootScope.uiBase = {
      name: "SLT MongoDB",
      description: "Streamline Admin Template - MongoDB",
      version: "2.0.2",
    };
  },
]);

app.run([
  "$templateCache",
  function (templateCache) {
    templateCache.put(
      "decorators/bootstrap/default.html",
      require("./modules/interface/templates/formfield.html")
    );
  },
]);

// UI-Select config
app.config([
  "uiSelectConfig",
  function (uiSelectConfig) {
    uiSelectConfig.theme = "bootstrap";
    uiSelectConfig.resetSearchInput = false;
    uiSelectConfig.skipFocusser = true;
  },
]);

// Add refresh shortcut to do an F5 via $state
app.config([
  "$provide",
  function ($provide) {
    $provide.decorator("$state", [
      "$delegate",
      "$stateParams",
      function ($delegate, $stateParams) {
        $delegate.refresh = function () {
          return location.reload(true);
        };
        return $delegate;
      },
    ]);
  },
]);

// ChartJS Provieder
app.config([
  "ChartJsProvider",
  function (ChartJsProvider) {
    Chart.plugins.register({
      afterDatasetsDraw: function (chartInstance, easing) {
        return;
        // To only draw at the end of animation, check for easing === 1
        var ctx = chartInstance.chart.ctx;

        chartInstance.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.getDatasetMeta(i);
          let ctrl = meta.controller.chart;
          let datalabel = ctrl.options.datalabel;
          if (!meta.hidden) {
            if (datalabel == undefined || datalabel == true) {
              meta.data.forEach(function (element, index) {
                // Draw the text in black, with the specified font
                ctx.fillStyle = "rgb(0, 0, 0)";

                var fontSize = 10;
                var fontStyle = "normal";
                var fontFamily = "Roboto";
                ctx.font = Chart.helpers.fontString(
                  fontSize,
                  fontStyle,
                  fontFamily
                );

                // Just naively convert to string for now
                var dataString = dataset.data[index].toString();

                // Make sure alignment settings are correct
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";

                var padding = 0;
                var position = element.tooltipPosition();
                ctx.fillText(
                  dataString,
                  position.x,
                  position.y - fontSize / 2 - padding
                );
              });
            }
          }
        });
      },
    });
  }
]);
