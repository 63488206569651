export default class ProfileDetailsController {
  constructor($state, UIService, ProfileService) {
    this.$state = $state;
    this.UI = UIService;
    this.Profile = ProfileService;
    this.siteId = $state.params.siteId;
    this.id = $state.params.id;

    this.loaded = false;

    if (this.id) {
      this.label = "Editar"
      this.loadData();
    } else {
      this.data = {
        chargers: [],
        cards: [],
        access: true,
        siteId: this.siteId
      }
      this.label = "Adicionar"
      this.loadChargers();
    }

    this.transferOpts = {
      label: 'alias',
      key: 'id',
      list: []
    }
  }

  addCard = () => {
    this.UI.showDialog({
      template: require('./card.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.data = {

        };

        $scope.ok = () => {
          $scope.$close($scope.data);
        }

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        }
      }]
    }).then(r => {
      if (r) {
        // Verifica se o cartão já existe
        this.Profile.findExisting([r.no], this.siteId).then(a => {
          if (a.length === 0) {
            r.issueDate = moment();
            r.lastModified = r.issueDate;
            this.data.cards = this.data.cards || [];
            this.data.cards.push(r);
            this.save();
          } else {
            this.UI.addToast("O cartão introduzido já se encontra registado");
          }
        })
      }
    });
  }

  loadChargers = () => {
    this.Profile.evses(this.siteId).then(c => {
      this.loaded = true;
      this.transferOpts.list = c;
    }).catch(() => {
      this.loaded = true;
      this.UI.addToast("Ocorreu um erro ao carregar carregadores");
    });
  }

  loadData = () => {
    this.Profile.evses(this.siteId).then(c => {
      this.transferOpts.list = c;
      this.Profile.get(this.id).then(r => {
        console.log(r);
        this.loaded = true;
        r.chargers = [];
        r.accesses.forEach(a => {
          r.chargers.push(a.evse);
        });
        this.Profile.consumption(this.id).then(r => {
          this.createConsumptionChart(r);
        });
        this.data = r;
      }).catch(e => {
        this.loaded = true;
        this.UI.addToast("Ocorreu um erro ao carregar cliente");
      })
    });
  }

  createConsumptionChart = data => {
    let labels = [];
    let chartData = [[]];
    data.forEach(row => {
      labels.push(row.date);
      chartData[0].push(row.value);
    });


    let dataset = [];
    chartData.forEach(d => {
      dataset.push({
        borderColor: "#78002e",
        backgroundColor: "#78002e",
      });
    });

    this.consumption = {
      labels: labels,
      data: chartData,
      dataset: dataset,
      options: {
        elements: {
          line: {
            fill: false
          }
        },
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: false,
            },
            display: true
          }],

          yAxes: [{
            display: true,
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    }
  }

  save = () => {
    if (this.validate()) {
      this.data.active = this.data.active || true;
      this.Profile.save(this.data).then(r => {
        this.$state.go('app.client.list');
        if (this.id) {
          this.UI.addToast("Cliente atualizado com sucesso");
        } else {
          this.UI.addToast("Cliente adicionado com sucesso");
        }
      }).catch(e => {
        this.UI.addToast("Não foi possível gravar cliente");
      });
    }
  }

  removeCard = index => {
    this.UI.showConfirm(`Deseja remover o cartão selecionado?`).then(r => {
      if (r) {
        this.data.cards.splice(index, 1);
      }
    });
  }

  removePhone = row => {
    this.UI.showConfirm(`Deseja remover o smartphone selecionado?`).then(r => {
      if (r) {
        this.Profile.deletePhone(row.id).then(r => {
          this.loaded = false;
          this.UI.addToast("Smartphone removido com sucesso");
          this.loadData();
        });
      }
    });
  }

  validate = () => {
    if (!this.data.name) {
      this.UI.addToast("Insira o nome do cliente");
      return false;
    }
    if (this.data.expires && (!moment(this.data.startDate).isValid() || !moment(this.data.endDate).isValid())) {
      this.UI.addToast("Indique as datas de expiração do cliente");
      return false;
    }
    if (this.data.cards.length > 0) {
      let error = this.data.cards.find(r => {
        return r.expires && (!moment(r.startDate).isValid() || !moment(r.endDate).isValid())
      });
      if (error) {
        this.UI.addToast("Indique as datas de expiração do cartão");
        return false;
      }
    }
    return true;
  }
}

ProfileDetailsController.$inject = ['$state', 'UIService', 'ProfileService'];
