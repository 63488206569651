export default class RecoverController {
    constructor($state, UIService, PublicService) {
        this.$state = $state;
        this.title = "Recuperação de conta";
        this.description = "Preencha o formulário abaixo para definir uma nova password.";
        this.button = "ALTERAR PASSWORD";
        this.UI = UIService;
        this.Public = PublicService;
        this.password = "";
        this.confirm = "";
        this.token = $state.params.token;
        if (this.token == null) {
            this.$state.go('app.dashboard');
        }
    }

    setPassword = () => {
        // valida passwords
        if (this.password.localeCompare(this.confirm) != 0) {
            this.UI.addToast("As passwords introduzidas não condizem");
            return;
        } else {
            // Atualiza password do cliente
            this.Public.setPassword(this.token, this.password).then(r => {
                this.UI.addToast("Password alterada com sucesso");
            }).catch(e => {
                this.UI.addToast("O token de acesso expirou.");
            });
        }
    }
}

RecoverController.$inject = ['$state', 'UIService', 'PublicService'];