export default class ForgotPasswordController {
  constructor($rootScope, $state, AuthenticationService, UIService) {
    this.$state = $state;
    this.Auth = AuthenticationService;
    this.UI = UIService;
    this.email = "";
    this.waiting = false;

    $rootScope.$on("$receivedServerStatus", (evt, data) => {
      this.providers = data.providers;
      this.data = data;
      if (!data.environment.includes("production")) {
        this.env = "Ambiente de desenvolvimento";
      }
    });
  }

  resetPassword = () => {
    this.waiting = true;
    this.Auth.resetPassword(this.email)
      .then((res) => {
        this.waiting = false;
        this.UI.addToast("Foi enviado um email para reposição de password");
      })
      .catch((err) => {
        console.log(err);
        this.waiting = false;
        switch (err.data.error.statusCode) {
          case 404:
            this.UI.addToast("Email não registado!");
            break;
          case 403:
            break;
        }
      });
  };
}

ForgotPasswordController.$inject = [
  "$rootScope",
  "$state",
  "AuthenticationService",
  "UIService"
];
